import QrCodeComponent from 'components/Form/QrCodeComponent';
import Header from 'components/Headers/Header.js';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { updateLandingPage } from 'services/User/LandingPage';
import { getLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import { BodyTitle } from 'views/Profile/styles';

const LandingPageMultiProduct2023View = () => {
  const linksDefault = [
    {
      title: 'Antecipação de Saque-aniversário FGTS',
      description: 'Antecipe seu saldo FGTS. A menor taxa do mercado.',
      link: null,
      target: 'blank',
    },
  ];
  const defaultAdvantages = [
    {
      title: 'Crédito',
      description: 'Sem consulta ao SPC e SERASA',
    },
    {
      title: 'Simulação em diversas instituições financeiras',
      description:
        'Receba propostas das melhores instituições financeiras do Brasil',
    },
    {
      title: 'Rapidez no recebimento',
      description: 'Receba a partir de 3 minutos, após a aprovação do crédito',
    },
    {
      title: 'Sem Boletos',
      description: 'Parcelas descontadas direto do seu FGTS',
    },
  ];
  const [links, setLinks] = useState(linksDefault);
  const [advantages, setAdvantages] = useState(defaultAdvantages);
  const [landingPage, setLandingPage] = useState({
    contactPhone: null,
    textContactPhone: null,
    image: null,
    bgPrimary: null,
    bgSecondary: null,
    colorPrimary: null,
    title: null,
    description: null,
  });

  const handleAdd = () => {
    setLinks([
      ...links,
      {
        title: 'Título',
        link: 'https://www.seulink.com.br',
        target: '_blank',
        btnColor: '#ffffff',
        textColor: '#333333',
      },
    ]);
  };

  const handleRemove = (key) => {
    if (links.length <= 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops...',
        text: 'Você não pode remover todos os links!',
      });
      return;
    }

    setLinks(
      links.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  const handleAdvantageAdd = () => {
    setAdvantages([
      ...advantages,
      {
        title: 'Título',
        description: '',
      },
    ]);
  };

  const handleAdvantageRemove = (key) => {
    if (advantages.length <= 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops...',
        text: 'Você não pode remover todas as Vantagens',
      });
      return;
    }

    setAdvantages(
      advantages.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  const handleGetLandingPage = () => {
    Swal.close();
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLandingPage('landingpage-multproduct-2023').then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setLandingPage(result);
      setLinks(result?.infoPage?.links ?? linksDefault);
      setAdvantages(result?.infoPage?.advantages ?? defaultAdvantages);
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...landingPage,
      infoPage: {
        ...landingPage.infoPage,
        links: links,
        advantages: advantages,
      },
    };
    updateLandingPage('landingpage-multproduct-2023', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <section className="mt--8 container-fluid">
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h2>Landing Page - MultiProdutos 2023</h2>
              </Col>
              <Col className="text-right">
                <a
                  href={landingPage.url}
                  target="_blank"
                  className="btn btn-link btn-sm"
                  size="sm"
                >
                  <i className="fas fa-external-link-alt" />
                  {` `}
                  Ver Landing Page
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              <h3 className="m-0 text-white">
                Atenção: A izidev não realiza configuração no píxel e
                tagmanager. Não nos responsábilizamos pelo trabalho realizado
                pela equipe de marketing ou terceiros.
              </h3>
            </Alert>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID PIXEL</Label>
                      <Input
                        value={landingPage?.infoPage?.pixel}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              pixel: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID VERIFICAÇÃO DOMINIO PIXEL</Label>
                      <Input
                        value={landingPage?.infoPage?.pixelDomainVerification}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              pixelDomainVerification: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID TAG MANAGER</Label>
                      <Input
                        value={landingPage?.infoPage?.tagManager}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              tagManager: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodeComponent url={landingPage.url} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2 className="m-0">Informações da página</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Telefone Suporte com DDD</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.contactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        contactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Texto Padrão WhatsApp</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.textContactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        textContactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Instagram</Label>
                  <Input
                    placeholder="Link do instagram"
                    type="url"
                    value={landingPage?.infoPage?.instagram}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          instagram: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Facebook</Label>
                  <Input
                    placeholder="Link do Facebook"
                    type="url"
                    value={landingPage?.infoPage?.facebook}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          facebook: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Youtube</Label>
                  <Input
                    placeholder="Link do Youtube"
                    type="url"
                    value={landingPage?.infoPage?.youtube}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          youtube: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h2 className="m-0">Landing Page - Personalizar</h2>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <BodyTitle>TEXTO PRINCIPAL</BodyTitle>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Titulo</Label>
                  <Input
                    value={landingPage?.infoPage?.title}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          title: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <BodyTitle>Cores</BodyTitle>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Cor Principal</Label>
                  <Input
                    value={landingPage?.infoPage?.colorPrimary}
                    type="color"
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          colorPrimary: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Cor Botão</Label>
                  <Input
                    value={landingPage?.infoPage?.btnColorPrimary}
                    type="color"
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          btnColorPrimary: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <BodyTitle>Outras configurações</BodyTitle>
            {links.length > 0 && (
              <Card className="shadow mt-3">
                <CardHeader>
                  <Row>
                    <Col>
                      <h3 className="m-0">
                        <i className="fas fa-link" /> Cards
                      </h3>
                    </Col>
                    <Col className="text-right">
                      <Button color="primary" onClick={handleAdd}>
                        <i className="fas fa-plus" /> Adicionar Novo Card
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {links.map((link, key) => (
                    <Card key={key} className="shadow mb-3">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>Título do Card</Label>
                              <InputGroup>
                                <Input
                                  className="font-weight-bold font-20"
                                  placeholder="Título"
                                  value={link.title}
                                  maxLength="60"
                                  onChange={(ev) => {
                                    const newLinks = [...links];
                                    links[key].title = ev.target.value;
                                    setLinks(newLinks);
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Descrição do Card</Label>
                              <InputGroup>
                                <Input
                                  className="font-weight-bold font-20"
                                  placeholder="Descrição do Link"
                                  value={link.description}
                                  maxLength="60"
                                  onChange={(ev) => {
                                    const newLinks = [...links];
                                    links[key].description = ev.target.value;
                                    setLinks(newLinks);
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>URL da página</Label>
                          <InputGroup size="sm">
                            <Input
                              className="font-12"
                              placeholder="URL - Página"
                              value={link.link}
                              onChange={(ev) => {
                                const newLinks = [...links];
                                links[key].link = ev.target.value;
                                setLinks(newLinks);
                              }}
                              maxLength="120"
                            />
                            <select
                              className="form-control form-control-md col-md-4 border"
                              value={link.target}
                              onChange={(ev) => {
                                const newLinks = [...links];
                                links[key].target = ev.target.value;
                                setLinks(newLinks);
                              }}
                            >
                              <option value="_self">
                                Abrir link na mesma aba
                              </option>
                              <option value="_blank" selected>
                                Abrir link em nova aba
                              </option>
                            </select>
                          </InputGroup>
                        </FormGroup>
                        {links.length > 1 && (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleRemove(key)}
                          >
                            <i className="fas fa-trash-alt" /> Remover
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            )}

            {advantages.length > 0 && (
              <Card className="shadow mt-3">
                <CardHeader>
                  <Row>
                    <Col>
                      <h3 className="m-0">
                        <i className="fas fa-link" /> Vantagens
                      </h3>
                    </Col>
                    <Col className="text-right">
                      <Button color="primary" onClick={handleAdvantageAdd}>
                        <i className="fas fa-plus" /> Adicionar Nova Vantagem
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {advantages.map((advantage, key) => (
                    <Card key={key} className="shadow mb-3">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>Título</Label>
                              <InputGroup>
                                <Input
                                  className="font-weight-bold font-20"
                                  placeholder="Título"
                                  value={advantage.title}
                                  maxLength="60"
                                  onChange={(ev) => {
                                    const newLinks = [...links];
                                    advantages[key].title = ev.target.value;
                                    setLinks(newLinks);
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Descrição</Label>
                              <InputGroup>
                                <Input
                                  className="font-weight-bold font-20"
                                  placeholder="Descrição do Link"
                                  value={advantage.description}
                                  maxLength="60"
                                  onChange={(ev) => {
                                    const newLinks = [...links];
                                    advantages[key].description =
                                      ev.target.value;
                                    setLinks(newLinks);
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {advantages.length > 1 && (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleAdvantageRemove(key)}
                          >
                            <i className="fas fa-trash-alt" /> Remover
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2>Imagem destaque</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peoplesmartphone.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peoplesmartphone2.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone3.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone3.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peoplesmartphone3.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone4.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone4.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peoplesmartphone4.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman1.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldwoman1.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peopleoldwoman1.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldwoman2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peopleoldwoman2.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldman1.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldman1.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peopleoldman1.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldman2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldman2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                        checked={
                          landingPage.image === 'peopleoldman2.png'
                            ? true
                            : false
                        }
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button color="warning" size="lg" onClick={handleSubmit}>
              SALVAR ALTERAÇÃO
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  );
};

export default LandingPageMultiProduct2023View;
