import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import { showCardResumeDigitationService } from 'services/Digitation/SimulationService';
import CardResumoComponent from './CardResumoComponent';
import { moneyMask } from 'utils/mask';

const CardSimulation = ({ serviceId }) => {
  const userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const [filter, setFilter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [resumeSimulation, setResumeSimulation] = useState({
    simulation: 0,
    paid: {
      total: 0.0,
      qtd: 0,
    },
    total: {
      total: 0.0,
      qtd: 0,
    },
  });

  const handleResume = (days = 0) => {
    setLoading(true);
    setFilter(days);
    setResumeSimulation({
      simulation: 0,
      paid: {
        total: 0.0,
        qtd: 0,
      },
      total: {
        total: 0.0,
        qtd: 0,
      },
    });
    showCardResumeDigitationService(serviceId, days)
      .then(({ data }) => {
        const { resultSet } = data;
        setLoading(false);
        setResumeSimulation(resultSet);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const toggleVisibility = () => {
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    localStorage.setItem('IZIDEV_SHOW_VALUES', JSON.stringify(newVisibility));
  };

  useEffect(() => {
    if (userData?.showCardsFgts || userData?.showCardsFgts > 0) {
      handleResume(0);
    }
  }, []);

  useEffect(() => {
    const savedVisibility = localStorage.getItem('IZIDEV_SHOW_VALUES');
    if (savedVisibility !== null) {
      setIsVisible(JSON.parse(savedVisibility));
    }
  }, []);

  if (!userData?.showCardsFgts || userData?.showCardsFgts <= 0) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow mb-4 bg-secondary">
        <CardBody>
          <div className="mb-4 d-flex align-items-md-center align-items-start flex-md-row flex-column">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button
                  size="sm"
                  color={filter === 0 ? 'primary' : 'white'}
                  onClick={() => {
                    handleResume(0);
                  }}
                >
                  Hoje
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  color={filter === 7 ? 'primary' : 'white'}
                  onClick={() => {
                    handleResume(7);
                  }}
                >
                  Últimos 7 dias
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  color={filter === 30 ? 'primary' : 'white'}
                  onClick={() => {
                    handleResume(30);
                  }}
                >
                  Últimos 30 dias
                </Button>
              </InputGroupAddon>
            </InputGroup>

            <Button onClick={toggleVisibility} className="mt-md-0 mt-3">
              {isVisible ? (
                <i className="fa fa-eye" />
              ) : (
                <i className="fa fa-eye-slash" />
              )}
            </Button>
          </div>
          <Row>
            <Col md="4" className="d-flex align-content-stretch">
              <CardResumoComponent
                name="Simulações"
                value={resumeSimulation.simulation}
                colorValue="default"
                icon="fas fa-fire-alt"
                isLoading={loading}
              />
            </Col>
            <Col md="4" className="d-flex align-content-stretch">
              <CardResumoComponent
                name="Propostas pagas"
                value={
                  isVisible
                    ? `R$ ${moneyMask(
                        resumeSimulation?.paid?.total.toFixed(2)
                      )}`
                    : '--'
                }
                qtd={resumeSimulation?.paid?.qtd}
                colorValue="success"
                icon="fas fa-piggy-bank"
                isLoading={loading}
              />
            </Col>
            <Col md="4" className="d-flex align-content-stretch">
              <CardResumoComponent
                name="Total propostas"
                value={
                  isVisible
                    ? `R$ ${moneyMask(
                        resumeSimulation?.total?.total.toFixed(2)
                      )}`
                    : '--'
                }
                qtd={resumeSimulation?.total?.qtd}
                colorValue="primary"
                icon="fas fa-receipt"
                isLoading={loading}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* <Row>
        <Col md="4">
          <Card className="mt-2 mb-2">
            <CardHeader>
              <Title className="text-primary">
                <i className="fas fa-fire-alt" /> Qtd. Simulações
              </Title>
            </CardHeader>
            <CardBody className="pt-2 pb-2 bg-secondary">
              <h1 className="m-0">
                {resumeSimulation.simulation.qtdTotal ?? 0}
              </h1>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mt-2 mb-2">
            <CardHeader>
              <Title className="text-info">
                <i className="fas fa-fingerprint" /> Qtd. Simulações Únicas
              </Title>
            </CardHeader>
            <CardBody className="pt-2 pb-2 bg-secondary">
              <h1 className="m-0">
                {resumeSimulation.simulation.qtdUnique ?? 0}
              </h1>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mt-2 mb-2">
            <CardHeader>
              <Title className="text-success">
                <i className="fas fa-receipt" /> Contratos Digitados
              </Title>
            </CardHeader>
            <CardBody className="pt-2 pb-2 bg-secondary">
              <Badge color="success" className="font-20 mb-1 mt-1">
                {resumeSimulation.proposal.qtdTotal ?? 0}
              </Badge>
              {resumeSimulation?.proposal?.vlrTotal > 0 &&
                userData?.showCardsFgts > 0 && (
                  <>
                    {' / '}
                    <Badge color="primary" className="font-20 mb-1 mt-1">
                      R$ {moneyMask(resumeSimulation.proposal.vlrTotal ?? 0)}
                    </Badge>
                  </>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </>
  );
};

export default CardSimulation;
