import api from 'services/api';

export const resumeCards = () => {
  return api.get('v1/dashboard/resume');
};

export const showDashboardResume = (filter) => {
  return api.get('v2/report/dashboard/resume', {
    params: filter
  });
};
