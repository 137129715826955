import api from 'services/api';

export const listAllTableRuleGrandino = async (data) => {
  return await api.get(`v2/seller/grandino/table-comission-rule`, {
    params: data
  });
};

export const updateTableRuleGrandino = async (data) => {
  return await api.put(`v2/seller/grandino/table-comission-rule`, data);
};
