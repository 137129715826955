export const BenefitsImgEnum = [
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-1.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-2.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-3.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-4.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-5.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-6.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-7.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-8.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-9.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-10.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-11.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-12.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-13.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-14.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-15.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-16.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-17.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-18.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-19.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-20.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-21.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-22.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-23.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-24.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-25.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-26.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-27.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-28.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-29.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-30.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-31.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-32.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-33.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-34.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-35.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-36.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-37.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-38.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-39.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-40.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-41.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-42.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-43.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-44.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-45.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-46.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-47.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-48.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-49.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-50.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-51.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-52.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-53.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-54.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-55.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-56.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-57.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-58.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-59.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-60.png',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-61.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/benefits/benefit-62.jpg'
];
