import QRCode from 'qrcode.react';
import BeatLoader from 'react-spinners/BeatLoader';

import { Input } from 'components/Form';
import PreferenceBank from '../preferenceBank';

import { QRWrapper } from './styles';

export const AccountForm = ({
  dataPromoter,
  handleChange,
  contactEmail,
  contactMovelPhone,
  preferenceBank,
  urlSimulator,
  nameSlug,
  notificationPhone
}) => {
  return (
    <form>
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="row">
            <div className="col-12 col-lg-6">
              <Input
                label="Nome"
                placeholder="Username"
                name="username"
                value={dataPromoter.name}
                readOnly
              />
            </div>

            <div className="col-12 col-lg-6">
              <Input
                label="E-mail"
                placeholder="E-mail"
                name="email"
                value={dataPromoter.email}
                readOnly
              />
            </div>

            <div className="col-12 col-lg-6">
              <Input
                label="Razão social"
                placeholder="Razão social"
                name="corporative-name"
                value={dataPromoter.corporativeName}
                readOnly
              />
            </div>
            <div className="col-12 col-lg-6">
              <Input
                label="CNPJ"
                placeholder="CNPJ"
                name="document"
                value={dataPromoter.document}
                readOnly
              />
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-12 col-lg-6">
              <Input
                label="E-mail de Suporte"
                placeholder="E-mail para contato do cliente"
                name="contactEmail"
                type="email"
                value={contactEmail}
                onChange={handleChange}
                onBlur={handleChange}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Input
                label="Telefone WhatsApp de suporte"
                placeholder="Telefone Whatsapp para contado do cliente"
                name="contactMovelPhone"
                value={contactMovelPhone}
                onChange={handleChange}
                onBlur={handleChange}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Input
                label="Tel. WhatsApp notificação"
                placeholder="Telefone Whatsapp para contado do cliente"
                name="notificationPhone"
                value={notificationPhone}
                onChange={handleChange}
                onBlur={handleChange}
              />
            </div>

            <div className="col-12 col-lg-6">
              <PreferenceBank
                preferenceBank={preferenceBank}
                handleChange={handleChange}
              />
            </div>

            <div className="col-12 col-lg-6">
              <Input
                label="Nome simulador"
                placeholder="Nome apresentado na url após o '/'"
                name="nameSlug"
                value={nameSlug}
                onChange={handleChange}
                onBlur={handleChange}
                small={
                  <>
                    <strong>ATENÇÃO:</strong> Alterando este nome irá mudar o
                    QRCode e a URL.
                  </>
                }
                smallTheme="danger"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 d-flex align-items-center">
          {urlSimulator ? (
            <QRWrapper>
              <h3 className="d-lg-none">QRCode</h3>
              <QRCode value={urlSimulator} size={200} />
              <Input
                label="URL"
                placeholder="URL"
                name="QrCodeURL"
                value={urlSimulator}
                clipboard={urlSimulator}
                readOnly
              />
            </QRWrapper>
          ) : (
            <QRWrapper>
              <BeatLoader color="#5e72e4" size={20} />
            </QRWrapper>
          )}
        </div>
      </div>
    </form>
  );
};
