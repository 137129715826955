import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 100%;

  > h3 {
    align-self: baseline;
    font-size: 13px;

    @media ${device.desktopLG} {
      font-size: 11px;
    }

    @media ${device.desktopXL} {
      font-size: 13px;
    }
  }

  > div {
    width: 100%;
  }
`;
