import api from 'services/api';

const getAll = async (
  filter = { name: null, email: null, type: null, status: null }
) => {
  return await api.get('v1/user/all', {
    params: filter
  });
};

const insert = async (data) => {
  return await api.post('v1/user', data);
};

const update = async (data) => {
  return await api.put(`v1/user/${data.id}`, data);
};

const remove = async (id) => {
  return await api.delete(`v1/user/${id}`);
};

const deleteUser = async (id) => {
  return await api.delete(`v1/user/delete/${id}`);
};

const getQtdLiberty = async () => {
  return await api.get('v1/user/userLiberty');
};

const getModulesService = async (id = null) => {
  return await api.get('v1/user/module', {
    params: {
      id: id
    }
  });
};

const indexUserSupervisor = async () => {
  return await api.get('v2/user/supervisor');
};

const userListOptionService = async () => {
  return await api.get('v2/user/option/list');
};

const userSaveService = async (body) => {
  return await api.post('v2/user', body);
};

const userShowService = async (id) => {
  return await api.get(`v2/user/${id}`);
};

const showLinkIndicationUserService = async (userId) => {
  return await api.get(`v2/user/${userId}/link-indication`);
};

export {
  getAll,
  insert,
  update,
  remove,
  getQtdLiberty,
  getModulesService,
  indexUserSupervisor,
  userListOptionService,
  deleteUser,
  userSaveService,
  userShowService,
  showLinkIndicationUserService
};
