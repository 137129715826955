import { useHistory } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardFooter, Row } from 'reactstrap';
import { BankSelectData } from '../service/bankSelectData';

const BankList = ({
  bank,
  isRecommended = false,
  hasFgts = true,
  hasAuxilioBrasil = false,
  hasINSS = false,
  hasCardBenefit = false,
  hasPersonalCredit = false,
  hasLinkFormalization = false,
  updateStatusProposal = false,
  isNew = false,
}) => {
  const result = BankSelectData(bank);
  let bankUsed = result.img;
  let nameBank = result.name;

  const history = useHistory();

  return (
    <Card className="mt-3 mb-3 shadow w-100">
      <CardBody className="pb-0">
        {isRecommended && (
          <Badge color="secondary" className="font-14 pl-4 pr-4 w-100 mb-2">
            RECOMENDADO
          </Badge>
        )}
        {isNew && (
          <Badge color="success" className="font-14 pl-4 pr-4 w-100">
            NOVO
          </Badge>
        )}
        <img src={bankUsed} alt={nameBank} width="80%" />
      </CardBody>
      <CardBody>
        <h2 className="m-0">Vantagen(s)</h2>
        <div>
          {hasFgts ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> FGTS
            </p>
          ) : (
            ''
          )}
          {hasAuxilioBrasil ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> AUXÍLIO BRASIL
            </p>
          ) : (
            ''
          )}
          {hasINSS ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> INSS
            </p>
          ) : (
            ''
          )}
          {hasCardBenefit ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> CARTÃO BENEFÍCIO
            </p>
          ) : (
            ''
          )}
          {hasPersonalCredit ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> CRÉDITO PESSOAL
            </p>
          ) : (
            ''
          )}
          {hasLinkFormalization ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> LINK DE FORMALIZAÇÃO
            </p>
          ) : (
            ''
          )}
          {updateStatusProposal ? (
            <p className="m-0 text-success">
              {' '}
              <i className="fas fa-check" /> ATUALIZA STATUS DA PROPOSTA
            </p>
          ) : (
            ''
          )}
        </div>
        <Row>
          {/* <Col md="12" className="">
            {api.active === true && (
              <a
                href={`https://wa.me/55${api.whatsapp}?text=${api.text}`}
                className="btn btn-info mt-3 w-100"
                target="_blank"
              >
                <i className="fas fa-laptop-code" /> Solicitar API
              </a>
            )}
          </Col> */}
        </Row>
      </CardBody>
      <CardFooter>
        <Button
          outline
          color="primary w-100"
          onClick={() => {
            history.push(`/admin/access-bank-configure/${bank}`);
          }}
        >
          <i className="fas fa-cog" /> CONFIGURAR
        </Button>
      </CardFooter>
    </Card>
  );
};

export default BankList;
