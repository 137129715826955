import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { listStoreToFilter } from 'services/Store/StoreService';

const InputStoreComponent = ({
  filter = {},
  setFilter = () => {},
  isMulti = false,
}) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleIndexIndication = async () => {
    const result = await listStoreToFilter();
    setIsLoading(false);
    if (!result) {
      setDataList([]);
      return;
    }
    setDataList(result?.resultSet ?? []);
  };

  useEffect(() => {
    handleIndexIndication();
  }, []);

  return (
    <FormGroup>
      <Label>Loja</Label>
      <Select
        isMulti={isMulti}
        className="basic-single"
        classNamePrefix="select"
        options={dataList.map((store) => {
          return {
            label: `${store.id} - ${store.name} [${store.type}]`,
            value: store.id,
          };
        })}
        isLoading={isLoading}
        isClearable
        placeholder={`Selecione...`}
        onChange={(res) => {
          if (!res) {
            setFilter({
              ...filter,
              storeId: null,
            });
            return;
          }

          setFilter({
            ...filter,
            storeId: isMulti === true ? res : res.value,
          });
        }}
      />
    </FormGroup>
  );
};

export default InputStoreComponent;
