import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';

import Header from 'components/Headers/Header';
import { getPromoter } from 'services/Promoter/PromoterService';

import { Input } from 'components/Form';

import {
  Card,
  CardHeader,
  HeaderTitle,
  CardBody,
  BodyTitle,
  RadioGroup,
  Label,
  ExampleCard,
  ExampleButton,
  CodeCard
} from './styles';

export const Integrations = () => {
  const [nameSlug, setNameSlug] = useState('');
  const [integrationOption, setIntegrationOption] = useState('default');
  const [values, setValues] = useState({
    buttonPosition: 'bottom: 20px;right: 20px;left:unset;top:unset;',
    buttonColor: 'background:#4c4ca9;',
    buttonTextColor: 'color:#ffffff;'
  });

  const defaultScript = `<script id="simulation-script" src="https://storage.googleapis.com/simulador-fgts/scripts/script-izidev-modal-simulator.js" simulate-icon="true" simulate-icon-styles="${Object.values(
    values
  ).join('')}" emp="${nameSlug}" ></script>`;

  const myButtonScriptButton = `<button onclick="openSimulation()">Abrir Simulador</button>`;
  const myButtonScript = `<script id="simulation-script" src="https://storage.googleapis.com/simulador-fgts/scripts/script-izidev-modal-simulator.js" emp="${nameSlug}" ></script>`;

  const iFrameScript = `<iframe src="https://simulador.izidev.com.br/${nameSlug}/minimal" id="iframe-simulate" allowfullscreen="true" frameborder="0" scrolling="true" height="100%" style="display:flex;margin:0 auto;width:100%"></iframe>`;

  const onCopyText = () => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Campo copiado com sucesso'
    });
  };

  useEffect(() => {
    const getResumePromoter = async () => {
      const {
        data: { resultSet }
      } = await getPromoter();
      setNameSlug(resultSet?.nameSlug);
    };

    getResumePromoter();
  }, []);

  return (
    <>
      <Header
        showCards={false}
        titlePage="Integrações - Personalizar Simulador"
      />
      <section className="mt--8 container-fluid">
        <div className="row">
          <div className="col-12">
            <Card>
              <CardHeader>
                <HeaderTitle>Personalizar Simulador</HeaderTitle>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <BodyTitle>Tipo de Integração</BodyTitle>
                    <RadioGroup>
                      <div className="custom-control custom-radio">
                        <input
                          className="custom-control-input mr-0"
                          type="radio"
                          id="default"
                          name="integration-type"
                          value="default"
                          onChange={(event) =>
                            setIntegrationOption(event.target.value)
                          }
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="default"
                        >
                          Padrão - Com botão flutuando na tela.
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id="myButton"
                          name="integration-type"
                          value="myButton"
                          onChange={(event) =>
                            setIntegrationOption(event.target.value)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="myButton"
                        >
                          Personalizado - Irei utilizar meu próprio botão para
                          abrir o simulador.
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          className="custom-control-input"
                          type="radio"
                          id="iFrame"
                          name="integration-type"
                          value="iFrame"
                          onChange={(event) =>
                            setIntegrationOption(event.target.value)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="iFrame"
                        >
                          Personalizado - Irei chamar o simulador a partir de um
                          Iframe.
                        </label>
                      </div>
                    </RadioGroup>

                    {integrationOption === 'default' && (
                      <>
                        <BodyTitle className="mt-lg-5">
                          Botão para abrir o simulador
                        </BodyTitle>
                        <Label>Posição do botão</Label>
                        <RadioGroup>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input mr-0"
                              type="radio"
                              id="baixo-direito"
                              name="button-position"
                              value="bottom: 20px;right: 20px;left:unset;top:unset;"
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  buttonPosition: event.target.value
                                })
                              }
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="baixo-direito"
                            >
                              Baixo-Direito
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id="baixo-esquerdo"
                              name="button-position"
                              value="bottom: 20px;left: 20px;right:unset;top:unset;"
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  buttonPosition: event.target.value
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="baixo-esquerdo"
                            >
                              Baixo-Esquerdo
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id="cima-direito"
                              name="button-position"
                              value="top: 20px;right: 20px;bottom:unset;left:unset;"
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  buttonPosition: event.target.value
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="cima-direito"
                            >
                              Cima-Direito
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id="cima-esquerdo"
                              name="button-position"
                              value="top: 20px;left: 20px;bottom:unset;right:unset;"
                              onChange={(event) =>
                                setValues({
                                  ...values,
                                  buttonPosition: event.target.value
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="cima-esquerdo"
                            >
                              Cima-Esquerdo
                            </label>
                          </div>
                        </RadioGroup>
                        <Input
                          name="button-color"
                          label="Cor do botão"
                          type="color"
                          value={values.buttonColor}
                          onChange={(event) =>
                            setValues({
                              ...values,
                              buttonColor: `background:${event.target.value};`
                            })
                          }
                        />
                        <Input
                          name="button-text-color"
                          label="Cor do texto do botão"
                          type="color"
                          value={values.buttonTextColor}
                          onChange={(event) =>
                            setValues({
                              ...values,
                              buttonTextColor: `color:${event.target.value};`
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    {integrationOption === 'default' && (
                      <>
                        <ExampleCard>
                          <ExampleButton
                            customStyles={Object.values(values).join('')}
                          >
                            Simular
                          </ExampleButton>
                        </ExampleCard>
                        <CodeCard>
                          <code>{defaultScript}</code>
                        </CodeCard>
                        <CopyToClipboard
                          text={defaultScript}
                          onCopy={onCopyText}
                        >
                          <button
                            type="button"
                            className={`btn btn-md btn-primary w-100`}
                          >
                            Copiar
                          </button>
                        </CopyToClipboard>
                      </>
                    )}
                    {integrationOption === 'myButton' && (
                      <>
                        <BodyTitle>Exemplo de botão</BodyTitle>
                        <CodeCard>
                          <code>{myButtonScriptButton}</code>
                        </CodeCard>
                        <CopyToClipboard
                          text={myButtonScriptButton}
                          onCopy={onCopyText}
                        >
                          <button
                            type="button"
                            className={`btn btn-md btn-primary w-100`}
                          >
                            Copiar
                          </button>
                        </CopyToClipboard>

                        <BodyTitle className="mt-4">Script</BodyTitle>
                        <CodeCard>
                          <code>{myButtonScript}</code>
                        </CodeCard>
                        <CopyToClipboard
                          text={myButtonScript}
                          onCopy={onCopyText}
                        >
                          <button
                            type="button"
                            className={`btn btn-md btn-primary w-100`}
                          >
                            Copiar
                          </button>
                        </CopyToClipboard>
                      </>
                    )}
                    {integrationOption === 'iFrame' && (
                      <>
                        <CodeCard>
                          <code>{iFrameScript}</code>
                        </CodeCard>
                        <CopyToClipboard
                          text={iFrameScript}
                          onCopy={onCopyText}
                        >
                          <button
                            type="button"
                            className={`btn btn-md btn-primary w-100`}
                          >
                            Copiar
                          </button>
                        </CopyToClipboard>
                      </>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};
