import Header from 'components/Headers/Header';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { insertProposalManualService } from 'services/Digitation/DigitationService';
import { searchIndicationService } from 'services/Promoter/IndicationService';
import { viaCEP } from 'services/cepApi';
import { brasilApiCep } from 'services/cepApi';
import Swal from 'sweetalert2';
import { maskDate, cepMask, phoneMask, maskCPF, moneyMask } from 'utils/mask';

const CreateOperationManualView = () => {
  const history = useHistory();
  const inputRequired = [
    'febrabanId',
    'statusId',
    'numberProposal',
    'valueFinally',
    'valueLiberty',
    'cpf',
    'address',
    'numberAddress',
    'district',
    'city',
    'state',
  ];
  const operationDefault = {
    febrabanId: null,
    statusId: null,
    numberProposal: null,
    numberProposalBank: null,
    valueFinally: null,
    valueFinaly: null,
    cpf: null,
    name: null,
    birthDate: null,
    whatsappNumber: null,
    email: null,
    documentRg: null,
    dateEmissionRg: null,
    stateRg: null,
    sex: null,
    motherName: null,
    cep: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    state: null,
    bank: null,
    agency: null,
    numberAccount: null,
    digitAccount: null,
    typeAccount: null,
    pix: null,
    indicationId: null,
  };
  const [operation, setOperation] = useState(operationDefault);
  const [indications, setIndications] = useState([]);
  const [searchIndication, setSearchIndication] = useState('');

  const handleSearchIndication = () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    searchIndicationService({ search: searchIndication })
      .then(({ data }) => {
        Swal.close();
        setIndications(data?.resultSet ?? []);
        console.log(data);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleIdentifyAddressUserByCEP = async (ev) => {
    setOperation({
      ...operation,
      cep: cepMask(ev.target.value),
    });

    Swal.fire({
      title: 'Consultando CEP',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await brasilApiCep(ev.target.value)
      .then((res) => {
        Swal.close();
        const address = res.data;
        setOperation({
          ...operation,
          address: address.street,
          district: address.neighborhood,
          city: address.city,
          state: address.state,
        });
      })
      .catch(async () => {
        viaCEP(ev.target.value)
          .then((res) => {
            Swal.close();
            const address = res.data;
            setOperation({
              ...operation,
              address: address.logradouro,
              district: address.bairro,
              city: address.localidade,
              state: address.uf,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`,
            });
            setOperation({
              ...operation,
              address: null,
              district: null,
              city: null,
              state: null,
            });
          });
      });
  };

  const handleRequiredInputs = () => {
    let isConfirmed = true;

    inputRequired.map((input) => {
      if (!operation[input]) return (isConfirmed = false);
    });

    return isConfirmed;
  };

  const handleSubmit = () => {
    if (!handleRequiredInputs()) {
      Swal.fire({
        icon: 'error',
        title: 'Ops',
        text: 'Existem campos obrigatórios que não foram preenchidos',
      });
      return;
    }

    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    insertProposalManualService(operation, 2)
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Proposta salva com sucesso, você pode acompanhar no menu FGTS -> PROPOSTAS DIGITADAS!',
          icon: 'success',
        }).then(() => {
          setOperation(operationDefault);
          history.push('/admin/fgts/proposal');
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Header titlePage="FGTS - Digitar Proposta Manual" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h1>
              <i className="fab fa-wpforms" /> FGTS - Digitar Operação Manual
            </h1>
          </CardHeader>
          <CardBody>
            <Alert color="primary">
              <i className="far fa-question-circle" /> Todos os campos com (*)
              são obrigatórios.
            </Alert>
            <Card className="shadow">
              <CardHeader>
                <h2 className="m-0">
                  <i className="fas fa-receipt" /> Detalhes da Proposta
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="febrabanId">
                        Financeira <span className="text-danger">*</span>
                      </Label>
                      <select
                        id="febrabanId"
                        className="form-control"
                        value={operation.febrabanId}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            febrabanId: ev.target.value,
                          })
                        }
                      >
                        <option value="">Selecione...</option>
                        <option value="626">C6 Consignado</option>
                        <option value="149">FACTA Financeira</option>
                        <option value="074">SAFRA</option>
                        <option value="623">PAN</option>
                        <option value="389">MERCANTIL</option>
                        <option value="329">iCred</option>
                        <option value="465">Quero+Crédito</option>s
                        <option value="318">BMG</option>
                        <option value="329-2">SABEMI</option>
                        <option value="274">NOVO SAQUE</option>
                        <option value="243">BANCO MASTER</option>
                        <option value="329-3">PRATA DIGITAL</option>
                        <option value="324">LOTUS MAIS</option>
                        <option value="457">UY3</option>
                        <option value="531">PRESENÇA BANK</option>
                        <option value="329-6">VCTEX</option>
                        <option value="329-4">V8 DIGITAL</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="statusId">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <select
                        id="statusId"
                        className="form-control"
                        value={operation.statusId}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            statusId: ev.target.value,
                          })
                        }
                      >
                        <option value="">SELECIONE...</option>
                        <option value="1">AGUARDANDO</option>
                        <option value="2">PAGO</option>
                        <option value="3">ANALISE</option>
                        <option value="4">PENDENCIA</option>
                        <option value="5">REPROVADO</option>
                        <option value="6">CANCELADO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="numberProposal">
                        Nº da Proposta <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="numberProposal"
                        value={operation.numberProposal}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            numberProposal: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="numberProposalBank">Nº do Contrato</Label>
                      <Input
                        id="numberProposalBank"
                        value={operation.numberProposalBank}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            numberProposalBank: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="valueLiberty">
                        Valor Liberado <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="valueLiberty"
                        placeholder="0,00"
                        value={operation.valueLiberty}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            valueLiberty: moneyMask(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="valueFinally">
                        Valor Contrato <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="valueFinaly"
                        placeholder="0,00"
                        value={operation.valueFinally}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            valueFinally: moneyMask(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="valueFinally">Data do contrato</Label>
                      <Input
                        id="valueFinaly"
                        placeholder="--/--/----"
                        value={operation.createdAt}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            createdAt: maskDate(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="shadow mt-4">
              <CardHeader>
                <h2 className="m-0">
                  <i className="fas fa-user-check" /> Cliente
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="cpf">
                        CPF <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="cpf"
                        placeholder="000.000.000-00"
                        value={operation.cpf}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            cpf: maskCPF(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="name">
                        Nome <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="name"
                        value={operation.name}
                        onChange={(ev) =>
                          setOperation({ ...operation, name: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="birthDate">
                        Data de nascimento{' '}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="birthDate"
                        value={operation.birthDate}
                        maxLength={10}
                        placeholder="--/--/----"
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            birthDate: maskDate(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="whatsappNumber">
                        WhatsApp <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="whatsappNumber"
                        placeholder="(00) 0 0000-0000"
                        value={operation.whatsappNumber}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            whatsappNumber: phoneMask(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="email">E-mail</Label>
                      <Input
                        id="email"
                        value={operation.email}
                        onChange={(ev) =>
                          setOperation({ ...operation, email: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="documentRg">
                        RG <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="documentRg"
                        value={operation.documentRg}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            documentRg: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="dateEmissionRg">
                        Data de Emissão <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="dateEmissionRg"
                        maxLength={10}
                        placeholder="--/--/----"
                        value={operation.dateEmissionRg}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            dateEmissionRg: maskDate(ev.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="stateRg">
                        Estado Emissor <span className="text-danger">*</span>
                      </Label>
                      <select
                        className="form-control"
                        id="stateRg"
                        value={operation.stateRg}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            stateRg: ev.target.value,
                          })
                        }
                      >
                        <option value="" disabled="">
                          Selecione...
                        </option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="sex">
                        Sexo <span className="text-danger">*</span>
                      </Label>
                      <select
                        id="sex"
                        className="form-control"
                        value={operation.sex}
                        onChange={(ev) =>
                          setOperation({ ...operation, sex: ev.target.value })
                        }
                      >
                        <option value="">Selecione...</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="motherName">
                        Nome da mãe <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="motherName"
                        value={operation.motherName}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            motherName: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="shadow mt-4">
              <CardHeader>
                <h2 className="m-0">
                  <i className="fas fa-map-marked-alt" /> Endereço
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="cep">
                        CEP <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="cep"
                        placeholder="00000-000"
                        value={operation.cep}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            cep: cepMask(ev.target.value),
                          })
                        }
                        onBlur={handleIdentifyAddressUserByCEP}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="address">
                        Endereço <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="address"
                        value={operation.address}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            address: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="numberAddress">
                        Número <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="numberAddress"
                        value={operation.numberAddress}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            numberAddress: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="district">
                        Bairro <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="district"
                        value={operation.district}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            district: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="city">
                        Cidade <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="city"
                        readOnly
                        value={operation.city}
                        onChange={(ev) =>
                          setOperation({ ...operation, city: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="state">
                        Estado <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="state"
                        readOnly
                        value={operation.state}
                        onChange={(ev) =>
                          setOperation({ ...operation, state: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="shadow mt-4">
              <CardHeader>
                <h2 className="m-0">
                  <i className="fas fa-piggy-bank" /> Dados Bancários
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="bank">Banco</Label>
                      <select
                        className="form-control"
                        id="bank"
                        value={operation.bank}
                        onChange={(ev) =>
                          setOperation({ ...operation, bank: ev.target.value })
                        }
                      >
                        <option value="">SELECIONE</option>
                        <option value="104">
                          104 - CAIXA ECONOMICA FEDERAL
                        </option>
                        <option value="001">001 - BANCO DO BRASIL S.A.</option>
                        <option value="237">237 - BANCO BRADESCO</option>
                        <option value="341">341 - BANCO ITAÚ S.A.</option>
                        <option value="336">336 - BANCO C6 S.A.</option>
                        <option value="033">033 - BANCO SANTANDER S.A.</option>
                        <option value="151">
                          151 - BANCO NOSSA CAIXA S.A.
                        </option>
                        <option value="745">745 - BANCO CITIBANK S.A.</option>
                        <option value="041">
                          041 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                        </option>
                        <option value="756">756 - BANCO SICOOB</option>
                        <option value="748">748 - BANCO SICREDI</option>
                        <option value="085">
                          085 - COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                        </option>
                        <option value="399">399 - HSBC BANK BRASIL S.A</option>
                        <option value="756">
                          756 - BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                        </option>
                        <option value="041">041 - BANRISUL</option>
                        <option value="212">212 - BANCO ORIGINAL</option>
                        <option value="260">
                          260 - NU BANK PAGAMENTOS S.A.
                        </option>
                        <option value="318">318 - BANCO BMG S.A.</option>
                        <option value="336">
                          336 - BANCO C6 S.A. (C6 BANK)
                        </option>
                        <option value="077">077 - BANCO INTER S.A.</option>
                        <option value="623">623 - BANCO PAN</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="agency">Agência</Label>
                      <Input
                        id="agency"
                        value={operation.agency}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            agency: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="numberAccount">Conta</Label>
                      <Input
                        id="numberAccount"
                        value={operation.numberAccount}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            numberAccount: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="digitAccount">Digito da Conta</Label>
                      <Input
                        id="digitAccount"
                        value={operation.digitAccount}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            digitAccount: ev.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="typeAccount">Tipo de conta</Label>
                      <select
                        id="typeAccount"
                        name="typeAccount"
                        className="form-control"
                        value={operation.typeAccount}
                        onChange={(ev) =>
                          setOperation({
                            ...operation,
                            typeAccount: ev.target.value,
                          })
                        }
                      >
                        <option value="">SELECIONE</option>
                        <option value="1">CONTA CORRENTE</option>
                        <option value="2">POUPANÇA</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="pix">Chave PIX</Label>
                      <Input
                        id="pix"
                        value={operation.pix}
                        onChange={(ev) =>
                          setOperation({ ...operation, pix: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="shadow mt-4">
              <CardHeader>
                <h2 className="m-0">
                  <i className="far fa-handshake" /> Indicação
                </h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Buscar indicação / vendedor</Label>
                      <InputGroup>
                        <Input
                          placeholder="Informar nome ou Código indicação"
                          onChange={(ev) => {
                            setSearchIndication(ev.target.value);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            color="success"
                            onClick={handleSearchIndication}
                          >
                            Buscar
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {indications.length > 0 && (
                  <Card className="shadow mt-3">
                    <CardHeader>Indicação / Vendedor</CardHeader>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Nome</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {indications.map((indication, key) => (
                          <tr
                            key={key}
                            className={
                              indication.id == operation.indicationId
                                ? `bg-secondary font-weight-bold`
                                : `bg-none`
                            }
                          >
                            <td>{indication.uuid}</td>
                            <td>{indication.name}</td>
                            <td className="text-right">
                              {indication.id != operation.indicationId && (
                                <Button
                                  color="secondary"
                                  size="sm"
                                  value={indication.id}
                                  onClick={(ev) => {
                                    setOperation({
                                      ...operation,
                                      indicationId: ev.target.value,
                                    });
                                  }}
                                >
                                  <i className="fas fa-mouse-pointer" />{' '}
                                  Selecione
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                )}
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" size="lg" onClick={handleSubmit}>
              <i className="fas fa-save" /> Salvar proposta
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};
export default CreateOperationManualView;
