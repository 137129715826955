import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SampleModalView from 'components/Modals/SampleModalView';

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Card,
  CardBody,
  Badge,
  Button,
  Collapse,
  CardHeader,
  Alert,
} from 'reactstrap';
import { IndexNotificationSystemService } from 'services/Register/NotificationService';
import Swal from 'sweetalert2';
import { getConfigCrm } from 'utils/GetConfigCrm';
import { useHistory } from 'react-router-dom';

const AdminNavbar = (props) => {
  const [collapse, setCollapse] = useState(0);
  const toggle = (number) => setCollapse(number);
  const [modalNotification, setModalNotification] = useState(false);
  const [listNotification, setListNotification] = useState([]);
  const isIndication = localStorage.getItem('IZIDEV_AUTH_ISINDICATION');
  const history = useHistory();

  const [userData, setUserData] = useState({
    id: null,
    name: null,
    level: null,
    email: null,
  });

  // await localStorage.setItem(
  //   'IZIDEV_NOTIFICATION_USER',
  //   JSON.stringify(res?.data?.user?.dateNotification ?? null)
  // );

  const configCrm = getConfigCrm();

  const handleSetUserData = async () => {
    const userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
    setUserData(userData);

    if (
      userData?.dateNotification &&
      userData?.dateNotification !=
        JSON.parse(localStorage.getItem('IZIDEV_NOTIFICATION_USER'))
    ) {
      setModalNotification(true);
      handleNotification();
      localStorage.setItem(
        'IZIDEV_NOTIFICATION_USER',
        JSON.stringify(userData?.dateNotification)
      );
    }

    if (Number(userData?.isUpdateUser) === 1) {
      history.push('/admin/izisign/update-user');
    }
  };

  const handleNotification = async () => {
    setCollapse(0);
    setListNotification([]);
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setModalNotification(true);
    IndexNotificationSystemService()
      .then(({ data }) => {
        Swal.close();
        setListNotification(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(async () => {
    handleSetUserData();
  }, [props?.urlLogo]);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <Badge color="secondary">
              <i className="fas fa-star" /> {userData.level}
            </Badge>
            <div>
              <Button color="none" onClick={handleNotification}>
                <i
                  className={`far fa-bell ${
                    modalNotification ? `text-success` : `text-white`
                  } fa-2x`}
                />
              </Button>
            </div>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require('../../assets/img/theme/team-4-800x800.jpg')
                          .default
                      }
                    />
                  </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <Card className="bg-dark border-dark">
                      <CardBody className="text-white pt-1 pb-1">
                        <p className="m-0 font-12">
                          <i className="fas fa-user" />{' '}
                          <strong>
                            {userData.id} - {userData.name}
                          </strong>
                        </p>
                        <p className="m-0 font-12">
                          <i className="far fa-envelope" />{' '}
                          <strong>{userData.email}</strong>
                        </p>
                      </CardBody>
                    </Card>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem vindo!</h6>
                </DropdownItem>
                {/* <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider /> */}
                {isIndication === 'false' ? (
                  <>
                    <DropdownItem
                      href={'https://wa.me/551150439783'}
                      target="_blank"
                    >
                      <i className="ni ni-spaceship" />
                      <span>Atendimento</span>
                    </DropdownItem>
                  </>
                ) : (
                  <></>
                )}

                <DropdownItem
                  href={'https://backoffice.izichat.com.br/'}
                  target="_blank"
                >
                  <i className="fab fa-whatsapp" />
                  <span>izichat</span>
                </DropdownItem>
                <DropdownItem href={'/admin/update-password'}>
                  <i className="ni ni-lock-circle-open" />
                  <span>Alterar senha</span>
                </DropdownItem>
                <DropdownItem href={configCrm?.pathLogin ?? '/auth'}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>

      {/* MODAL SHOW */}
      <SampleModalView
        labelModal="Informativos"
        icon="far fa-bell"
        isOpen={modalNotification}
        setIsOpen={setModalNotification}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {}}
        showBtnSubmit={false}
        size="xl"
      >
        {listNotification.length > 0 ? (
          listNotification.map((register, key) => (
            <Card className="shadow mt-2 mb-2" key={key}>
              <CardHeader onClick={() => toggle(key)}>
                <span className="float-right">
                  {collapse == 1 ? (
                    <i className="fas fa-chevron-up" />
                  ) : (
                    <i className="fas fa-chevron-down" />
                  )}
                </span>
                <h2>
                  {register.title}{' '}
                  {register.diff <= 1 && <Badge color="success">Novo</Badge>}
                </h2>
              </CardHeader>
              <Collapse isOpen={collapse == key}>
                <CardBody>
                  <Badge color="secondary">{register.createdAt}</Badge>
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{ __html: register.description }}
                  />
                </CardBody>
              </Collapse>
            </Card>
          ))
        ) : (
          <Alert color="secondary">
            Não foram identificados registros de notificação
          </Alert>
        )}
      </SampleModalView>
    </>
  );
};

export default AdminNavbar;
