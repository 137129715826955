import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/css/layout.css';

import AdminLayout from './layouts/Admin.js';
// import AuthLayout from './layouts/Auth.js';
import Register from 'views/examples/Register.js';
import NewLogin from 'views/External/Login/NewLogin.js';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <NewLogin {...props} />} />
      <Route
        path="/login/:nameSlug"
        render={(props) => <NewLogin {...props} />}
      />
      <Route path="/login" render={(props) => <NewLogin {...props} />} />
      <Route path="/register">
        <Register />
      </Route>
      <Redirect from="/" to="/auth" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
