import api from 'services/api';

export const insertService = async (data) => {
  return await api.post(`v2/seller/comission/table`, data);
};

export const listService = async (data) => {
  return await api.get(`v2/seller/comission/table`, {
    params: data,
  });
};

export const inactiveService = async (id) => {
  return await api.post(`v2/seller/comission/table/inactive/${id}`);
};

export const listComissionProposal = async (data) => {
  return await api.get(`v2/seller/comission/proposal`, {
    params: data,
  });
};

export const tableComissionService = async (data) => {
  return await api.get(`v2/seller/table-comission`, {
    params: data,
  });
};

export const reportMyComissionService = async (data) => {
  return await api.get(`v2/seller/mycomission`, {
    params: data,
  });
};
