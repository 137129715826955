import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table
} from 'reactstrap';
import { listContractSignTemplateService } from 'services/Register/ContractSign';
import { listContractSignConfigUpdateUserService } from 'services/Register/ContractSign';
import { createContractSignConfigUpdateUserService } from 'services/Register/ContractSign';
import Swal from 'sweetalert2';

const ConfigUpdateUserView = () => {
  const [template, setTemplate] = useState(null);
  const [register, setRegister] = useState({
    id: null,
    contractSignTemplateId: null,
    havePermissionSimulation: 0,
    blockAccessToExpire: 0,
    daysToExpireContract: 0
  });
  const [registers, setRegisters] = useState([]);
  const [list, setList] = useState([]);

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    createContractSignConfigUpdateUserService(register)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro inserido com sucesso!'
        });
        setRegister({
          id: null,
          contractSignTemplateId: null,
          havePermissionSimulation: 0,
          blockAccessToExpire: 0,
          daysToExpireContract: 0
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listContractSignTemplateService()
      .then(({ data }) => {
        setRegisters(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListConfig = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listContractSignConfigUpdateUserService()
      .then(({ data }) => {
        setList(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList();
    handleListConfig();
  }, []);

  return (
    <>
      <Header titlePage="IZISIGN - CONFIGURAR ASSINATURA DO CONTRATO" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h3>IZISIGN - CONFIGURAR ASSINATURA DO CONTRATO</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Modelo Contrato</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      let data = registers.filter(
                        (value) => value.id == ev.target.value
                      );
                      setTemplate(data[0] ?? {});
                      setRegister({
                        ...register,
                        contractSignTemplateId: ev.target.value
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    {registers.map((value, key) => (
                      <option value={value.id} key={key}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                {template?.id && (
                  <Card className="shadow mb-4">
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>ENVIA SMS</h4>
                          {template?.useSms > 0 ? (
                            <Badge color="success">SIM</Badge>
                          ) : (
                            <Badge color="danger">NÃO</Badge>
                          )}
                        </Col>
                        <Col md="3">
                          <h4>TOKEN WHATSAPP</h4>
                          {template?.useWhatsapp > 0 ? (
                            <Badge color="success">SIM</Badge>
                          ) : (
                            <Badge color="danger">NÃO</Badge>
                          )}
                        </Col>
                        <Col md="3">
                          <h4>CONFIRMA E-MAIL</h4>
                          {template?.useEmail > 0 ? (
                            <Badge color="success">SIM</Badge>
                          ) : (
                            <Badge color="danger">NÃO</Badge>
                          )}
                        </Col>
                        <Col md="3">
                          <h4>ATUALIZAR DADOS USUÁRIO</h4>
                          {template?.isUpdateDataUser > 0 ? (
                            <Badge color="success">SIM</Badge>
                          ) : (
                            <Badge color="danger">NÃO</Badge>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            <Card className="shadow">
              <CardHeader>
                <h4>Configuração</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Pode simular</Label>
                      <select
                        className="form-control"
                        value={register.havePermissionSimulation}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            havePermissionSimulation: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Validade</Label>
                      <select
                        className="form-control"
                        value={register.daysToExpireContract}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            daysToExpireContract: ev.target.value
                          });
                        }}
                      >
                        <option value={1} selected>
                          1 dia
                        </option>
                        <option value={5}>5 dias</option>
                        <option value={10}>10 dias</option>
                        <option value={20}>20 dias</option>
                        <option value={30}>30 dias</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Bloqueia acesso após expirar</Label>
                      <select
                        className="form-control"
                        value={register.havePermissionSimulation}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            havePermissionSimulation: ev.target.value
                          });
                        }}
                      >
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              <i className="fas fa-save" /> SALVAR
            </Button>
          </CardFooter>
        </Card>
        {list.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>
              <h4>Listagem - Templates Cadastrados</h4>
            </CardHeader>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>DATA CADASTRO</th>
                    <th>MODELO CONTRATO</th>
                    <th>PODE SIMULAR</th>
                    <th>VALIDADE</th>
                    <th>BLOQUEIA ACESSO APÓS EXPIRAR</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {list.map((value, key) => (
                    <tr key={key}>
                      <td>{value.createdAt}</td>
                      <td>{value.name}</td>
                      <td>
                        {value.havePermissionSimulation > 0 ? (
                          <Badge color="success" className="font-12">
                            SIM
                          </Badge>
                        ) : (
                          <Badge color="danger" className="font-12">
                            NÃO
                          </Badge>
                        )}
                      </td>
                      <td>
                        <h2>{value.daysToExpireContract} dias</h2>
                      </td>
                      <td>
                        {value.blockAccessToExpire > 0 ? (
                          <Badge color="success" className="font-12">
                            SIM
                          </Badge>
                        ) : (
                          <Badge color="danger" className="font-12">
                            NÃO
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ConfigUpdateUserView;
