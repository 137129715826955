import { useEffect } from 'react';
import { useState } from 'react';
import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import { tableTaxService } from 'services/Digitation/IntegrationService';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import MyTablesConsignedComponent from '../myTablesConsignedComponent';

const AccessBankSafraForm = ({ febrabanId, data, setData }) => {
  const [tableSafra, setTableSafra] = useState([]);
  const [activeTab, setActiveTab] = useState('2');

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleGetTableTaxSafra = async () => {
    await tableTaxService()
      .then((res) => {
        setTableSafra(res.data.resultSet);
      })
      .catch(() => {
        setTableSafra([]);
      });
  };

  useEffect(() => {
    if (!febrabanId || febrabanId !== '074') return;
    handleGetTableTaxSafra();
  }, []);

  if (!febrabanId || febrabanId !== '074') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login de API <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha da API <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value),
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="mt-4">
        <h3>Configurações</h3>
        <Nav tabs className='mt-3'>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active cursor-pointer' : 'cursor-pointer'}
              onClick={() => { toggleTab('1'); }}
            >
              FGTS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active cursor-pointer' : 'cursor-pointer'}
              onClick={() => { toggleTab('2'); }}
            >
              Consignado
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className='border'>
          <TabPane tabId="1">
            <Row className='px-3 py-4'>
              <Row className='w-100 mt-0 mx-4 bg-secondary border rounded p-4 flex-column'>
                <h3>Configurar - FGTS</h3>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Tabela FGTS</Label>
                      {tableSafra.length > 0 ? (
                        <select
                          className="form-control"
                          value={data.tableAmmount}
                          onChange={(ev) => {
                            setData({
                              ...data,
                              tableAmmount: ev.target.value,
                            });
                          }}
                        >
                          <option value="">Selecione...</option>
                          {tableSafra.length > 0 ? (
                            tableSafra.map((register, key) => (
                              <option value={register.id} key={key}>
                                {register.descricao}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </select>
                      ) : (
                        <p className="text-danger">Carregando Tabelas...</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Valor Mínimo liberado FGTS{' '}
                        <i
                          className="fas fa-question-circle"
                          id="minValueLibertyFgts"
                        />
                      </Label>
                      <Input
                        type="text"
                        autoComplete="false"
                        value={data.minValueLibertyFgts}
                        placeholder="Ex: 0,00"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            minValueLibertyFgts: moneyMask(ev.target.value),
                          });
                        }}
                      />
                      <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                        Se for preenchido este campo, a simulação será realizada a
                        partir do valor informado. Caso o valor liberado do FGTS esteja
                        abaixo do valor informado, gerará erro!
                      </UncontrolledTooltip>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Valor Máximo liberado FGTS{' '}
                        <i
                          className="fas fa-question-circle"
                          id="maxValueLibertyFgts"
                        />
                      </Label>
                      <Input
                        type="text"
                        autoComplete="false"
                        value={data.maxValueLibertyFgts}
                        placeholder="Ex: 0,00"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            maxValueLibertyFgts: moneyMask(ev.target.value),
                          });
                        }}
                      />
                      <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                        Se for preenchido este campo, a simulação será realizada até o
                        valor informado. Caso o valor liberado do FGTS ultrapasse o
                        informado, gerará erro!
                      </UncontrolledTooltip>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Ativar Consulta FGTS</Label>
                      <select
                        type="text"
                        autoComplete="false"
                        value={data.useFgts}
                        placeholder="Ex: 000.000.000-00"
                        className="form-control"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            useFgts: ev.target.value,
                          });
                        }}
                      >
                        <option value="1">SIM</option>
                        <option value="0">NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Ativar Simulador MultiBancos</Label>
                      <select
                        type="text"
                        autoComplete="false"
                        value={data.useMultBankFgts}
                        placeholder="Ex: 000.000.000-00"
                        className="form-control"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            useMultBankFgts: ev.target.value,
                          });
                        }}
                      >
                        <option value="1">SIM</option>
                        <option value="0">NÃO</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </Row>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className='p-4'>
              <Col>
                <MyTablesConsignedComponent febrabanId={febrabanId} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </CardBody>
  );
};

export default AccessBankSafraForm;
