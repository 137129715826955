import api from 'services/api';

export const ReDigitationService = async (simulationId, data) => {
  return await api.post(`v1/digitation/proposal/${simulationId}`, data);
};

export const ReSimulationMessageError = async () => {
  return await api.get(`v1/report/lead/re-simulation/message`);
};

export const createScheduleSimulationService = async (data) => {
  return await api.post(`v2/report/schedule-simulation`, data);
};

export const listScheduleSimulationService = async () => {
  return await api.get(`v2/report/schedule-simulation`);
};

export const inactiveScheduleSimulationService = async (id) => {
  return await api.put(`v2/report/schedule-simulation/${id}`);
};

export const DigitationProposalFGTSService = async (simulationId, data) => {
  return await api.post(`v3/proposal/fgts/${simulationId}`, data);
};
