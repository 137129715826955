export const simulationConsignedValidator = (contract) => {
  const missingFields = [];

  if (!contract.cpf) {
    missingFields.push('CPF não informado.');
  }

  if (!contract.birthDate) {
    missingFields.push('Data de Nascimento não informada.');
  }

  if (!contract.registry) {
    missingFields.push('O número da Matricula não foi informado.');
  }

  if (!contract.value) {
    missingFields.push('O Valor da Parcela não foi informado.');
  }

  if (contract.febrabanId === null) {
    missingFields.push('O Banco não foi selecionado.');
  }

  if (contract.febrabanId === '623' && !contract.serviceBank) {
    missingFields.push('O convênio não foi selecionado.');
  }

  if (contract.febrabanId === '074' && !contract.serviceBank) {
    missingFields.push('O Convênio não foi selecionado.');
  }

  return missingFields;
};

export const proposalConsignedValidator = (contract) => {
  const missingFields = [];

  if (!contract.whatsappNumber) {
    missingFields.push('Número de telefone não informado.');
  }

  if (!contract.name) {
    missingFields.push('O Nome não foi informado.');
  }

  if (!contract.email) {
    missingFields.push('O E-mail não foi informado.');
  }

  if (!contract.sex) {
    missingFields.push('O Sexo não foi selecionado.');
  }

  if (!contract.motherName) {
    missingFields.push('O Nome da Mãe não foi preenchido.');
  }

  if (!contract.cep) {
    missingFields.push('O CEP não foi informado.');
  }

  if (!contract.address) {
    missingFields.push('O Endereço não foi preenchido.');
  }

  if (!contract.numberAddress) {
    missingFields.push('O Número do Endereço não foi informado.');
  }

  if (!contract.district) {
    missingFields.push('O Bairro não foi informado.');
  }

  if (!contract.city) {
    missingFields.push('A Cidade não foi informada.');
  }

  if (!contract.state) {
    missingFields.push('O Estado não foi informado.');
  }

  if (!contract.documentRg) {
    missingFields.push('O RG não foi informado.');
  }

  if (!contract.dateEmissionRg) {
    missingFields.push('A Data de emissão do RG não foi preenchida.');
  }

  if (!contract.organEmissionRg) {
    missingFields.push('O Órgão emissor do RG não foi informado.');
  }

  if (!contract.stateRg) {
    missingFields.push('O Estado Emissor do RG não foi informado.');
  }

  if (!contract.birthDate) {
    missingFields.push('A data de Nascimento não foi informada.');
  }

  if (!contract.bank) {
    missingFields.push('O Banco não foi selecionado.');
  }

  if (!contract.agencyBank) {
    missingFields.push('A Agência não foi informada.');
  }

  if (!contract.numberAccountBank) {
    missingFields.push('A Conta Bancária não foi informada.');
  }

  if (!contract.digitAccountBank) {
    missingFields.push('O Dígito da Conta não foi informado.');
  }

  if (!contract.typeAccountBank) {
    missingFields.push('O Tipo da Conta não foi informado.');
  }

  return missingFields;
};
