import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from 'reactstrap';
import { storeIziLink, showIziLink } from 'services/User/LandingPage';
import Swal from 'sweetalert2';

const IziLinkView = () => {
  const linksDefault = [
    {
      title: '',
      link: '',
      target: '_blank',
      btnColor: '#ffffff',
      textColor: '#333333',
    },
  ];
  const [header, setHeader] = useState('');
  const [result, setResult] = useState({});
  const [links, setLinks] = useState(linksDefault);

  const handleAdd = () => {
    setLinks([
      ...links,
      {
        title: 'Título',
        link: 'https://www.seulink.com.br',
        target: '_blank',
        btnColor: '#ffffff',
        textColor: '#333333',
      },
    ]);
  };

  const handleRemove = (key) => {
    setLinks(
      links.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  const handleShow = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    showIziLink()
      .then(({ data }) => {
        const { resultSet } = data;

        Swal.close();

        if (!resultSet?.page || resultSet?.page?.links?.length <= 0) return;

        setResult(resultSet);
        setHeader(resultSet?.page?.header ?? '');
        setLinks(resultSet?.page?.links ?? linksDefault);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    storeIziLink({
      header: header,
      links: links,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Alteração realizada com sucesso!',
        }).then((confirm) => {
          if (!confirm.isConfirmed) return;
          window.location.reload();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleShow();
  }, []);

  return (
    <>
      <Header titlePage="IZILINK - Tudo em apenas um Link" showCards={false} />
      <Container className="mt--9" fluid>
        <Alert color="warning">
          <div>
            <i className="fas fa-info-circle" /> Com esta ferramenta você poderá
            criar uma página com links de atalho, ideal para publicar no perfil
            do Instagram e em outras mídias sociais.
          </div>
        </Alert>
        <Alert color="secondary">
          <div>
            <i className="fas fa-question-circle" /> Caso tenha uma sugestão
            para melhoria de nosso sistema, informe-nos em nossa central de
            atendimento, via Chat ou WhatsApp
          </div>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            {result?.id > 0 && (
              <a
                href={result.url}
                target="_blank"
                className="btn btn-link float-right mt-0"
              >
                <i className="fas fa-external-link-alt" /> Ver página
              </a>
            )}

            <h2 className="m-0">
              <i className="fas fa-cogs" /> Configurar página
            </h2>
          </CardHeader>
          <CardBody>
            <div>
              <Button
                className="w-100"
                size="lg"
                color="primary"
                onClick={handleAdd}
              >
                <i className="fas fa-plus" /> Adicionar link
              </Button>
            </div>
            <div>
              <Button
                className="w-100 mt-3"
                size="lg"
                color="success"
                onClick={handleSubmit}
              >
                <i className="fas fa-save" /> Salvar alterações
              </Button>
            </div>
          </CardBody>
        </Card>
        <Card className="shadow mt-3 mb-3">
          <CardHeader>
            <h3 className="m-0">
              <i className="fas fa-heading" /> Cabeçalho
            </h3>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <InputGroup>
                <textarea
                  className="form-control"
                  maxLength={140}
                  value={header}
                  onChange={(ev) => {
                    setHeader(ev.target.value);
                  }}
                />
              </InputGroup>
            </FormGroup>
            <p className="m-0">
              Caracteres: <strong>{header.length}</strong> de{' '}
              <strong>140</strong>
            </p>
          </CardBody>
        </Card>
        {links.length > 0 && (
          <Card className="shadow mt-3">
            <CardHeader>
              <h3 className="m-0">
                <i className="fas fa-link" /> Links
              </h3>
            </CardHeader>
            <CardBody>
              {links.map((link, key) => (
                <Card key={key} className="shadow mb-3">
                  <CardBody>
                    <FormGroup>
                      <Label>Título do Link</Label>
                      <InputGroup>
                        <Input
                          className="font-weight-bold font-20"
                          placeholder="Título"
                          value={link.title}
                          maxLength="60"
                          onChange={(ev) => {
                            const newLinks = [...links];
                            links[key].title = ev.target.value;
                            setLinks(newLinks);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label>URL da página</Label>
                      <InputGroup size="sm">
                        <Input
                          className="font-12"
                          placeholder="URL - Página"
                          value={link.link}
                          onChange={(ev) => {
                            const newLinks = [...links];
                            links[key].link = ev.target.value;
                            setLinks(newLinks);
                          }}
                          maxLength="120"
                        />
                        <select
                          className="form-control form-control-md col-md-4 border"
                          value={link.target}
                          onChange={(ev) => {
                            const newLinks = [...links];
                            links[key].target = ev.target.value;
                            setLinks(newLinks);
                          }}
                        >
                          <option value="_self">Abrir link na mesma aba</option>
                          <option value="_blank" selected>
                            Abrir link em nova aba
                          </option>
                        </select>
                      </InputGroup>
                    </FormGroup>
                    {links.length > 1 && (
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleRemove(key)}
                      >
                        <i className="fas fa-trash-alt" /> Remover
                      </Button>
                    )}
                  </CardBody>
                </Card>
              ))}
            </CardBody>
          </Card>
        )}

        <Card className="shadow mt-4">
          <CardBody>
            <div>
              <Button
                className="w-100"
                size="lg"
                color="primary"
                onClick={handleAdd}
              >
                <i className="fas fa-plus" /> Adicionar link
              </Button>
            </div>
            <div>
              <Button
                className="w-100 mt-3"
                size="lg"
                color="success"
                onClick={handleSubmit}
              >
                <i className="fas fa-save" /> Salvar alterações
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default IziLinkView;
