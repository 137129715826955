import Header from 'components/Headers/Header';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { BodyTitle } from 'views/Profile/styles';
const IndicationFormView = () => {
  const [tabConfiguration, setTabConfiguration] = useState(1);

  return (
    <>
      <Header
        titlePage="INDICAÇÃO - FORMULÁRIO DE CAPTAÇÃO"
        showCards={false}
      />
      <Container className="mt--8" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2 className="m-0">INDICAÇÃO - FORMULÁRIO DE CAPTAÇÃO</h2>
          </CardHeader>
          <CardBody className="bg-secondary">
            <Card className="shadow">
              <CardHeader>
                <h3 className="m-0">
                  <i className="fas fa-cogs" /> Configurar
                </h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      active={tabConfiguration === 0}
                      onClick={() => setTabConfiguration(0)}
                    >
                      <i className="fas fa-cog" /> Configuração de cadastro
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={tabConfiguration === 1}
                      onClick={() => setTabConfiguration(1)}
                    >
                      <i className="fas fa-question" /> Cabeçalho site
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={tabConfiguration === 2}
                      onClick={() => setTabConfiguration(2)}
                    >
                      <i className="fas fa-question" /> Perguntas frequentes
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={tabConfiguration}
                  className="border rounded p-4"
                >
                  <TabPane tabId={0}>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Gerar link após concluir cadastro</Label>
                          <select className="form-control">
                            <option value={0}>NÃO</option>
                            <option value={1}>SIM</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={1}>
                    <BodyTitle>Cores</BodyTitle>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Cor cabeçalho - Logo</Label>
                          <Input type="color" />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Botão atendimento</Label>
                          <Input type="color" />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Fundo</Label>
                          <Input type="color" />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Botão Formulário</Label>
                          <Input type="color" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <BodyTitle>Descrição</BodyTitle>
                    <ReactQuill
                      style={{ height: 350 }}
                      className="mb-5"
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            { color: ['#dfdfdf', '#f3f3f3', 'red'] },
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          [],
                          ['clean'],
                        ],
                      }}
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video',
                      ]}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default IndicationFormView;
