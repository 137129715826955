import { FormGroup, Label, Input } from 'reactstrap';

const InputComponent = ({
  label,
  type,
  value,
  select = [],
  nameField = 'name',
  disabled,
  onChange,
  isRequired = false,
  placeholder
}) => {
  return (
    <>
      <FormGroup>
        <Label>
          {label} {isRequired && <span className="text-danger">*</span>}
        </Label>
        {type !== 'select' ? (
          <Input
            type={type}
            onChange={onChange}
            value={value}
            disabled={disabled}
            required={isRequired}
            placeholder={placeholder}
            autoComplete="new-password"
          />
        ) : (
          <>
            <select
              className="form-control"
              value={value}
              onChange={onChange}
              disabled={disabled}
              required={isRequired}
            >
              {select.map((option, key) => (
                <option key={key} value={option.value}>
                  {option[nameField]}
                </option>
              ))}
            </select>
          </>
        )}
      </FormGroup>
    </>
  );
};

export default InputComponent;
