import { Badge, Card, CardBody, Col, Row } from 'reactstrap';

const CardResumoComponent = ({
  name = null,
  value = null,
  colorValue = 'primary',
  icon = null,
  qtd = null,
  isLoading = false,
}) => {
  return (
    <>
      <Card className="shadow w-100">
        <CardBody>
          <Row>
            <Col
              md="3"
              sm="12"
              className="d-flex align-content-stretch justify-content-center align-items-center"
            >
              <i className={`${icon} fa-2x`} />
            </Col>
            <Col className="d-flex align-content-stretch">
              <div className="w-100">
                <p className="m-0 w-100 font-14">
                  {name}{' '}
                  {qtd !== null && (
                    <>
                      <Badge color={colorValue}>{qtd}</Badge>
                    </>
                  )}
                </p>
                <h2 className={`text-${colorValue} mb-0`}>
                  {!isLoading ? (
                    <>{value}</>
                  ) : (
                    <>
                      <i className="fas fa-circle-notch fa-spin" />
                    </>
                  )}
                </h2>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CardResumoComponent;
