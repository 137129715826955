import SampleModalView from 'components/Modals/SampleModalView';
import { BenefitsImgEnum } from '../Enum/BenefitsEnum';
import { Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import { useState } from 'react';

const ModalImgBenefitComponent = ({
  isOpen,
  setIsOpen = () => {},
  item,
  handleSubmit = () => {}
}) => {
  const [imgSelected, setImgSelected] = useState('');

  useState(() => {
    setImgSelected(item);
  }, []);

  return (
    <>
      <SampleModalView
        labelModal="Icones - Vantagens"
        icon="far fa-window-restore"
        btnSubmitLabel={'Selecionar'}
        btnSubmitAction={() => {
          console.log(imgSelected);
          handleSubmit(imgSelected);
          setIsOpen(false);
        }}
        showBtnSubmit={true}
        size="xl"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Row style={{ maxHeight: '600px', overflow: 'auto' }}>
          {BenefitsImgEnum.map((img, key) => (
            <Col key={key} md="3">
              <Label>
                <Card className="shadow m-2">
                  <CardBody>
                    <img src={img} alt="img" width="100%" />
                  </CardBody>
                  <CardFooter>
                    <span className="ml-3">
                      <Input
                        type="radio"
                        name="imgBenefits"
                        value={img}
                        checked={item === img ? 'checked' : null}
                        onChange={(ev) => {
                          setImgSelected(ev.target.value);
                        }}
                      />
                      Selecione
                    </span>
                  </CardFooter>
                </Card>
              </Label>
            </Col>
          ))}
        </Row>
      </SampleModalView>
    </>
  );
};

export default ModalImgBenefitComponent;
