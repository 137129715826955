export const EnumBanks = [
  { code: '104', name: 'CAIXA ECONOMICA FEDERAL' },
  { code: '001', name: 'BANCO DO BRASIL S.A.' },
  { code: '237', name: 'BANCO BRADESCO S.A.' },
  { code: '341', name: 'BANCO ITAÚ S.A.' },
  { code: '336', name: 'BANCO C6 S.A.' },
  { code: '033', name: 'BANCO SANTANDER S.A.' },
  { code: '151', name: 'BANCO NOSSA CAIXA S.A.' },
  { code: '745', name: 'BANCO CITIBANK S.A.' },
  { code: '041', name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
  { code: '756', name: 'BANCO SICOOB' },
  { code: '748', name: 'BANCO SICREDI' },
  { code: '085', name: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
  { code: '399', name: 'HSBC BANK BRASIL S.A' },
  { code: '756', name: 'BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.' },
  { code: '041', name: 'BANRISUL S.A.' },
  { code: '212', name: 'BANCO ORIGINAL S.A.' },
  { code: '260', name: 'NU BANK PAGAMENTOS S.A.' },
  { code: '318', name: 'BANCO BMG S.A.' },
  { code: '336', name: 'BANCO C6 S.A. (C6 BANK)' },
  { code: '077', name: 'BANCO INTER S.A.' },
];

export const EnumBanksIntegration = [
  { code: '623', name: '623 - PAN' },
  { code: '074', name: '074 - SAFRA' },
  { code: '626', name: '626 - C6 BANK' },
  { code: '149', name: '149 - FACTA' },
  { code: '389', name: '389 - MERCANTIL' },
  { code: '329', name: '329 - ICRED' },
  { code: '318', name: '318 - BMG' },
  { code: '465', name: '465 - QUERO+CREDITO' },
  { code: '274', name: '274 - NOVO SAQUE' },
  { code: '321', name: '321 - CREFAZ' },
  { code: '329-2', name: '329-2 - SABEMI' },
  { code: '243', name: '243 - BANCO MASTER' },
  { code: '373', name: '373 - UPP' },
  { code: '329-3', name: '329-3 - PRATA DIGITAL' },
  { code: '329-4', name: '329-4 - V8 DIGITAL' },
  { code: '324', name: '324 - LOTUS MAIS' },
  { code: '457', name: '457 - UY3' },
  { code: '329-5', name: '329-5 - GRANDINOBANK' },
  { code: '531', name: 'PRESENÇA BANK' },
  { code: '329-6', name: 'VCTEX' },
];

export const EnumBanksImportLead = [
  { code: '623', name: '623 - PAN' },
  { code: '074', name: '074 - SAFRA' },
  { code: '626', name: '626 - C6 BANK' },
  { code: '149', name: '149 - FACTA' },
  { code: '329', name: '329 - ICRED' },
  { code: '318', name: '318 - BMG' },
  { code: '274', name: '274 - NOVO SAQUE' },
  { code: '457', name: '457 - UY3' },
];
