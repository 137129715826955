import api from 'services/api';

export const indexProposalReport = async (filter) => {
  return await api.get(`v2/fgts/report/proposal`, {
    params: filter
  });
};

export const indexProposalReportCsv = async (filter) => {
  return await api.get(`v2/fgts/report/proposal/csv`, {
    params: filter
  });
};

export const indexProposalReportGrandino = async (filter) => {
  return await api.get(`v2/fgts/report/grandino/proposal`, {
    params: filter
  });
};

export const indexProposalReporttGrandinoCsv = async (filter) => {
  return await api.get(`v2/fgts/report/grandino/proposal/csv`, {
    params: filter
  });
};

export const indexSimulationReportGrandino = async (filter) => {
  return await api.get(`v2/fgts/report/grandino/simulation`, {
    params: filter
  });
};

export const indexSimulationReportGrandinoCsv = async (filter) => {
  return await api.get(`v2/fgts/report/grandino/simulation/csv`, {
    params: filter
  });
};

export const showProposal = async (simulationId, proposalId) => {
  return await api.get(`v2/digitation/proposal/${simulationId}/${proposalId}`);
};

export const storeProposal = async (simulationId, proposalId, data) => {
  return await api.post(
    `v2/digitation/proposal/${simulationId}/${proposalId}`,
    data
  );
};

export const showProposalDetails = async (proposalId) => {
  return await api.get(`v2/proposal/details/${proposalId}`);
};

export const showSimulationDetails = async (proposalId) => {
  return await api.get(`v2/simulation/details/${proposalId}`);
};

export const showStatusProposal = async (febrabanId, proposalId) => {
  return await api.get(`v2/proposal/status-bank/${febrabanId}/${proposalId}`);
};

export const IndexProposalDefaultReport = async (serviceId, data) => {
  return await api.get(`v2/report/proposal/${serviceId}`, {
    params: data
  });
};

export const UpdateStatusProposal = async (proposalId, statusId) => {
  return await api.put(`v2/proposal/status/${proposalId}`, {
    statusId
  });
};
