import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
  Button,
  Alert,
  CardBody,
  FormGroup,
  Label,
  Input,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { getAll, remove, getQtdLiberty, deleteUser } from 'services/User/User';

import Header from 'components/Headers/Header';
import { scheduleExcel } from 'services/Report/Schedule';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { listUserUpdateByUserService } from 'services/Register/ContractSign';
import { inactiveUserUpdateService } from 'services/Register/ContractSign';
import SelectSupervisorComponent from './component/SelectSupervisorComponent';
import LevelAccessListView from './component/LevelAccessListView';
import ModalLinkIndicationComponent from './component/ModalLinkIndicationComponent';
import OptionUserComponent from './component/OptionUserComponent';
import UserDigitationComponent from './component/UserDigitationComponent';
import { HeaderTitle } from 'views/Integrations/styles';

const User = () => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const history = useHistory();
  const [filter, setFilter] = useState({
    name: null,
    email: null,
    type: null,
    status: null,
  });
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [listData, setListData] = useState([]);
  const [qtdUserLiberty, setQtdUserLibety] = useState({ qtd: 0, qtdUsers: 0 });
  const [openLevelAccess, setOpenLevelAccess] = useState(false);

  const [userSelected, setUserSelected] = useState(null);
  const [openLinkIndication, setOpenLinkIndication] = useState(false);
  const [openUserDigitationComponent, setOpenUserDigitationComponent] =
    useState(false);

  const list = async (isMore) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
    };

    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0,
      });

      filterSearch = {
        ...filter,
        previusLimit: 0,
      };
    }

    await getAll(filterSearch)
      .then((result) => {
        Swal.close();
        setFilter({
          ...filter,
          previusLimit: result?.data?.resultSet?.limit ?? 0,
        });
        if (isMore) {
          setListData([...listData, ...result?.data?.resultSet?.result]);
          setShowBtnMoreRegisters(result?.data?.resultSet?.result?.length > 0);
        } else {
          setListData(result?.data?.resultSet?.result);
          setShowBtnMoreRegisters(
            result?.data?.resultSet?.result?.length >= 15
          );
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const removeUser = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'cancelar',
    })
      .then(async () => {
        Swal.fire({
          title: 'Atualizando registro!',
          message: 'Por favor aguarde',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await remove(id).then(() => {
          Swal.close();
          list();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDeleteUser = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Tem certeza que deseja excluir o usuário? será irreversível',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'cancelar',
    })
      .then(async () => {
        Swal.fire({
          title: 'Excluindo!',
          message: 'Por favor aguarde',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await deleteUser(id).then(() => {
          Swal.close();
          list();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const getQtdUserLiberty = async () => {
    getQtdLiberty()
      .then((res) => {
        setQtdUserLibety(res.data.resultSet);

        const qtd = res.data.resultSet.qtdUsers - res.data.resultSet.qtd;

        if (qtd <= 0) {
          Swal.fire({
            icon: 'info',
            title: 'Notificação',
            text: 'Seu pacote de usuários finalizou, entre em contato com seu representante comercial IZIDEV, atualize seu plano',
          });
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const [showUserUpdate, setShowUserUpdate] = useState(null);
  const [listUserUpdate, setListUserUpdate] = useState([]);

  const handleShowUserUpdate = (index) => {
    if (showUserUpdate === index) return setShowUserUpdate(null);

    setShowUserUpdate(index);
  };

  const handleListUpdateUserByUserId = async (userId, index) => {
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await listUserUpdateByUserService(userId)
      .then(({ data }) => {
        Swal.close();
        setListUserUpdate(data?.resultSet ?? []);
        handleShowUserUpdate(index);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInactiveUpdateUser = (id, userId, index) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja cancelar? será irreversível',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, cancelar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) {
        return;
      }

      Swal.fire({
        title: 'Cancelando',
        message: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      inactiveUserUpdateService(userId, id)
        .then(async () => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            message: 'Atualização de cadastro cancelada com sucesso',
          });
          await handleListUpdateUserByUserId(userId, index);
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    list();
    getQtdUserLiberty();
  }, []);

  return (
    <>
      <Header showCards={false} titlePage="Usuários do sistema" />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Row>
              <Col xl="4">
                <Card className="">
                  <CardBody className="text-center">
                    <h3 className="m-0">Registros disponíveis</h3>
                    <h1 className="m-0">
                      {qtdUserLiberty.qtdUsers - qtdUserLiberty.qtd}
                    </h1>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card className="bg-white">
                  <CardBody className="text-center">
                    <h3 className="m-0">Total de registros</h3>
                    <h1 className="m-0">{qtdUserLiberty.qtd}</h1>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card className="shadow mt-2">
              <CardHeader>
                <h3>Filtro - Usuários de sistema</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input
                        id="name"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            name: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="email">E-mail</Label>
                      <Input
                        type="email"
                        id="email"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            email: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="type">Tipo</Label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            type: ev.target.value,
                          });
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="USER">Usuário</option>
                        <option value="SUPERVISOR">Supervisor</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="status">Status</Label>
                      <select
                        className="form-control"
                        id="status"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            status: ev.target.value,
                          });
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <SelectSupervisorComponent
                      onChange={(e) => {
                        console.log(e);
                        setFilter({
                          ...filter,
                          supervisor: e,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <Button
                  color="success"
                  onClick={() => {
                    list(false);
                  }}
                >
                  <i className="fas fa-search" /> Buscar
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(`/admin/user/register/new`);
                  }}
                >
                  <i className="fas fa-user-plus" /> Novo registro
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpenLevelAccess(true);
                  }}
                >
                  <i className="far fa-window-restore" /> Nível de acesso
                </Button>
                {userDataConfig?.canExportExcel > 0 && (
                  <Button
                    color="success"
                    onClick={() => {
                      scheduleExcel(
                        13,
                        {
                          ...filter,
                          previusLimit: 0,
                        },
                        history
                      );
                    }}
                  >
                    <i className="fas fa-file-excel" /> Exportar Excel
                  </Button>
                )}
              </CardBody>
            </Card>
            <Card className="shadow mt-2">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Lista de usuários</h3>
                  </div>
                </Row>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <Table className="align-items-center table-flush">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">USUÁRIO SISTEMA</th>
                          <th scope="col">NÍVEL DE ACESSO</th>
                          <th scope="col">STATUS</th>
                          <th scope="col">DETALHES</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {listData.map((register, key) => (
                          <>
                            <tr key={`user-${key}`}>
                              <td>{register.id}</td>
                              <td>
                                <p className="m-0">
                                  Nome: <strong>{register.name}</strong>
                                </p>
                                <p className="m-0">
                                  E-mail: <strong>{register.email}</strong>
                                </p>
                              </td>
                              <td>
                                <p className="m-0">{register.levelName}</p>
                                {Number(register.levelActive) === 0 && (
                                  <Badge color="warning">NÍVEL INATIVO</Badge>
                                )}
                                {Number(register.levelActive) < 0 && (
                                  <Badge color="danger">NÍVEL EXCLUIDO</Badge>
                                )}
                              </td>
                              <td>
                                <Badge
                                  color={
                                    Number(register.active)
                                      ? 'success'
                                      : 'danger'
                                  }
                                  className="font-14"
                                >
                                  {Number(register.active)
                                    ? 'ATIVO'
                                    : 'INATIVO'}
                                </Badge>
                              </td>
                              <td>
                                {register?.indicationId &&
                                  register?.indicationId > 0 && (
                                    <>
                                      <div className="p-2 bg-secondary mb-1 mt-1 border-1">
                                        <HeaderTitle>
                                          Link de Indicação
                                        </HeaderTitle>
                                        <p className="m-0">
                                          Nome:{' '}
                                          <strong>
                                            {register.indicationName}
                                          </strong>
                                        </p>
                                        <p className="m-0">
                                          Código :{' '}
                                          <strong>
                                            {register.indicationUuid}
                                          </strong>
                                        </p>
                                        <p className="m-0">
                                          Status :{' '}
                                          <Badge
                                            color={
                                              register.indicationActive > 0
                                                ? 'success'
                                                : 'danger'
                                            }
                                          >
                                            {register.indicationActive > 0
                                              ? 'ATIVO'
                                              : 'INATIVO'}
                                          </Badge>
                                        </p>
                                      </div>
                                    </>
                                  )}
                                {register?.supervisorName && (
                                  <>
                                    <div className="p-2 bg-secondary mb-1 mt-1 border-1">
                                      <HeaderTitle>Supervisor</HeaderTitle>
                                      <p className="m-0">
                                        Nome:{' '}
                                        <strong>
                                          {register.supervisorName}
                                        </strong>
                                      </p>
                                      <p className="m-0">
                                        E-mail:{' '}
                                        <strong>
                                          {register.supervisorEmail}
                                        </strong>
                                      </p>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td className="text-right">
                                <OptionUserComponent
                                  key={key}
                                  register={register}
                                  setUserSelected={setUserSelected}
                                  setOpenLinkIndication={setOpenLinkIndication}
                                  removeUser={removeUser}
                                  handleDeleteUser={handleDeleteUser}
                                  handleListUpdateUserByUserId={() => {
                                    handleListUpdateUserByUserId(
                                      register.id,
                                      `userUpdate-${key}`
                                    );
                                  }}
                                  setOpenUserDigitationComponent={
                                    setOpenUserDigitationComponent
                                  }
                                />
                              </td>
                            </tr>
                            {showUserUpdate === `userUpdate-${key}` && (
                              <tr key={`userUpdate-${key}`}>
                                <td colSpan={6}>
                                  <Card className="shadow">
                                    <CardHeader>
                                      <h3>Atualizações de cadastro</h3>
                                    </CardHeader>
                                    {listUserUpdate.length > 0 && (
                                      <Table responsive>
                                        <thead>
                                          <tr className="bg-primary text-white">
                                            <th>CADASTRO</th>
                                            <th>NOME</th>
                                            <th>CPF</th>
                                            <th>WHATSAPP</th>
                                            <th>STATUS</th>
                                            <th>STATUS ASSINATURA</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {listUserUpdate.map(
                                            (userUpdate, idx) => (
                                              <tr key={`listUser-${idx}`}>
                                                <td>{userUpdate.createdAt}</td>
                                                <td>{userUpdate.name}</td>
                                                <td>{userUpdate.document}</td>
                                                <td>{userUpdate.phone}</td>
                                                <td>
                                                  <Badge
                                                    className="font-12"
                                                    color={
                                                      userUpdate.status ==
                                                      'ATIVO'
                                                        ? 'success'
                                                        : 'danger'
                                                    }
                                                  >
                                                    {userUpdate.status}
                                                  </Badge>
                                                </td>
                                                <td>
                                                  <Badge
                                                    className="font-12"
                                                    color={
                                                      userUpdate.statusSign ==
                                                      'PENDENTE'
                                                        ? 'warning'
                                                        : 'success'
                                                    }
                                                  >
                                                    {userUpdate.statusSign}
                                                  </Badge>
                                                </td>
                                                <td>
                                                  <UncontrolledDropdown>
                                                    <DropdownToggle
                                                      caret
                                                      color="secondary"
                                                    >
                                                      Opções
                                                    </DropdownToggle>
                                                    <DropdownMenu dark>
                                                      {userUpdate.status ===
                                                        'ATIVO' && (
                                                        <DropdownItem
                                                          onClick={() => {
                                                            handleInactiveUpdateUser(
                                                              userUpdate.id,
                                                              register.id,
                                                              `listUser-${idx}`
                                                            );
                                                          }}
                                                        >
                                                          <i className="fas fa-ban" />{' '}
                                                          Cancelar atualização
                                                        </DropdownItem>
                                                      )}

                                                      <DropdownItem
                                                        href={
                                                          userUpdate.urlSign
                                                        }
                                                      >
                                                        <i className="fas fa-file-signature" />{' '}
                                                        Link para assinatura
                                                      </DropdownItem>
                                                      {userUpdate.urlContractSigned && (
                                                        <DropdownItem
                                                          href={
                                                            userUpdate.urlContractSigned
                                                          }
                                                        >
                                                          <i className="fas fa-signature" />{' '}
                                                          Contrato assinado
                                                        </DropdownItem>
                                                      )}
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    )}

                                    {listUserUpdate.length <= 0 && (
                                      <Alert color="secondary">
                                        Não foram identificadas atualização de
                                        cadastro para o usuário
                                      </Alert>
                                    )}
                                  </Card>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                        {showBtnMoreRegisters && (
                          <tr>
                            <td colSpan={6}>
                              <Button
                                className="w-100"
                                onClick={() => {
                                  setFilter({ ...filter });
                                  list(true);
                                }}
                              >
                                Carregar mais...
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possui Logins no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <LevelAccessListView
        isOpen={openLevelAccess}
        setIsOpen={setOpenLevelAccess}
      />

      <ModalLinkIndicationComponent
        userId={openLinkIndication ? userSelected : 0}
        isOpen={openLinkIndication}
        setIsOpen={setOpenLinkIndication}
      />

      <UserDigitationComponent
        userId={openUserDigitationComponent ? userSelected : 0}
        isOpen={openUserDigitationComponent}
        setIsOpen={setOpenUserDigitationComponent}
      />
    </>
  );
};

export default User;
