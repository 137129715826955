import Header from 'components/Headers/Header';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableHeader } from 'components/Table';
import { TableBody } from 'components/Table';
import { TableData } from 'components/Table';
import { Table } from 'components/Table';
import { CaretRight } from 'phosphor-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown
} from 'reactstrap';
import Swal from 'sweetalert2';
import { MobileTitle } from 'views/Dashboard/styles';
import { ContentWrapper } from 'views/Dashboard/styles';
import {
  listBanksImportedLeadService,
  listFileImportedService,
  startProcessImportedService,
  stopProcessImportedService,
  uploadFileImportFgtsService
} from '../../services/Import/ImportFgtsService';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const ImportLeadView = () => {
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const [file, setFile] = useState(null);
  const [form, setForm] = useState([]);
  const [result, setResult] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);
  const [banks, setBanks] = useState([]);

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const upload = (file, appendData = [], onUploadProgress) => {
    let formData = new FormData();

    formData.append('upload', file);
    if (appendData.length > 0) {
      appendData.map((register) => {
        formData.append(register.name, register.code);
      });
    }

    return uploadFileImportFgtsService(formData, onUploadProgress);
  };

  const handleUploadFile = () => {
    Swal.fire({
      title: 'Enviando arquivo',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    upload(
      file,
      form.map((bank) => {
        return { name: 'febrabanId[]', code: bank?.value };
      }),
      () => { }
    )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Arquivo enviado com sucesso, agora basta aguardar processamento da rotina!'
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListImportedFiles = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setResult([]);
    listFileImportedService()
      .then((res) => {
        Swal.close();
        setResult(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleStopStart = (id, action) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem Certeza que deseja realizar esta ação?',
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim',
      showCancelButton: true
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Atualizando',
        text: 'Por favor, aguarde...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      if (action === 'stop') {
        stopProcessImportedService(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: 'Processamento Parado'
            });
          })
          .catch((err) => {
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
              ? 'Ocorreu um erro inesperado'
              : err.response.data.message;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `[${code}] ${message}`
            });
          });

        return;
      }

      startProcessImportedService(id)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Processamento Iniciado'
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  const handleListBanksImportedLead = () => {
    listBanksImportedLeadService().then(({ data }) => {
      setBanks(data?.resultSet ?? []);
    });
  };

  useEffect(() => {
    handleListBanksImportedLead();
    handleListImportedFiles();
  }, []);

  return (
    <>
      <Header titlePage="FGTS - IMPORTAÇÃO DE LEADS" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <i className="fas fa-file-csv" /> FGTS - Importação de Leads
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <i className="far fa-question-circle" /> Modelo de planilha para
              importação -{' '}
              <a
                href="https://storage.googleapis.com/simulador-fgts/modelo/MODELO-PLANILHA-IMPORTACAO-LEADS.csv"
                download
              >
                <strong>BAIXAR</strong>
              </a>
            </Alert>
            <Alert color="secondary">
              <i className="far fa-question-circle" /> Acompanhe os resultados
              no menu:{' '}
              <a href="/admin/fgts/schedule">
                <strong>FGTS {`->`} Retrabalhar Lead</strong>
              </a>
            </Alert>
            <FormGroup>
              <Label>Financeira</Label>
              <Select
                components={animatedComponents}
                options={banks.map((register) => {
                  return { value: register.febraban, label: register.name };
                })}
                placeholder="Selecione..."
                isMulti
                onChange={(selectData) => {
                  console.log(selectData);
                  setForm(selectData);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Arquivo CSV <Badge color="info">Tamanho máximo 2MB</Badge>
              </Label>
              <Input
                type="file"
                accept=".csv"
                className="form-control"
                onChange={(ev) => {
                  const file = ev.target.files[0];

                  if (!file) return;

                  const sizeFile = file.size / 1024 / 1024;

                  if (sizeFile > 2) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Error',
                      text: 'O arquivo não pode ser importado. Por favor, reduza a quantidade de registros e importe novamente!'
                    });
                    return;
                  }

                  setFile(file);
                }}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleUploadFile}>
              <i className="fas fa-file-import" /> Importar
            </Button>
          </CardFooter>
        </Card>

        {result.length > 0 && (
          <Card className="mt-3">
            <CardHeader>Últimas Importações</CardHeader>
            <CardBody className="p-2 m-0">
              <Alert color="secondary">
                <h1>
                  <i className="fas fa-exclamation-circle" /> Atenção
                </h1>
                <p>
                  - Os arquivos importados ficarão disponíveis por 1(um) dia.
                  Após esse prazo, por segurança, serão excluídos,
                  automaticamente, pelo sistema.
                </p>
                <p>
                  - Realize a importação no período das{' '}
                  <strong>07h às 18h</strong>.
                </p>
                <p>
                  - Após a importação, os arquivos serão processados no período
                  das <strong>19h às 07h</strong>.
                </p>
              </Alert>
            </CardBody>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>DATA IMPORTAÇÃO</TableHead>
                  <TableHead>DETALHES DA IMPORTAÇÃO</TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    DETALHES DA CONSULTA
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    ARQUIVO
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell" />
                  <TableHead className="d-xl-none" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {result.map((register, key) => (
                  <>
                    <TableRow key={key}>
                      <TableData>{register.createdAt}</TableData>
                      <TableData>
                        <p className="m-0">
                          Status: <strong>{register.status}</strong>
                        </p>
                        <p className="m-0">
                          Data atualização: <strong>{register.updateAt}</strong>
                        </p>
                        {register?.banks?.length > 0 && (
                          <>
                            <p className="m-0 mt-2">Bancos selecinados</p>
                            {register?.banks.map((bank, key) => (
                              <Badge color="info" key={key} className="mr-2">
                                {bank?.name}
                              </Badge>
                            ))}
                          </>
                        )}
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        <p className="m-0">
                          Pendentes:{' '}
                          <Badge color="warning">{register.qtdPendent}</Badge>
                        </p>
                        <p className="m-0">
                          Processados:{' '}
                          <Badge color="success">{register.qtdProcess}</Badge>
                        </p>
                        <p className="m-0">
                          Cancelados:{' '}
                          <Badge color="danger">{register.qtdCancel}</Badge>
                        </p>
                        <p className="m-0">
                          Total:{' '}
                          <Badge color="info">{register.totalRegister}</Badge>
                        </p>
                        <p className="m-0">
                          Primeiro processamento:{' '}
                          <strong>{register.firstProcess}</strong>
                        </p>
                        <p className="m-0">
                          Ultimo processamento:{' '}
                          <strong>{register.actualProcess}</strong>
                        </p>
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        {register?.dayAfterImport <= 1 && (
                          <a
                            href={register.urlFile}
                            className="btn btn-secondary"
                            target="blank"
                          >
                            <i className="fas fa-link" /> Arquivo Importado
                          </a>
                        )}
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            {(register.firstProcess > 0 ||
                              register.actualProcess) && (
                                <DropdownItem
                                  onClick={() => {
                                    history.push(
                                      `/admin/fgts/lead/imported/${register.id}`
                                    );
                                  }}
                                >
                                  <i className="fas fa-link" /> Ver registros
                                </DropdownItem>
                              )}
                            <DropdownItem
                              onClick={() => {
                                handleStopStart(register.id, 'stop');
                              }}
                            >
                              <i className="fas fa-hand-paper" /> Parar lista
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                handleStopStart(register.id, 'start');
                              }}
                            >
                              <i className="fas fa-fire-alt" /> Reprocessar
                              lista
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </TableData>
                      <TableData className="d-xl-none">
                        <CaretRight
                          active={tableDetails === key}
                          onClick={() => handleTableDetails(key)}
                        />
                      </TableData>
                    </TableRow>
                    {tableDetails === key && (
                      <TableRow className="d-xl-none">
                        <TableData colSpan={10}>
                          <ContentWrapper className="d-lg-none">
                            <MobileTitle>Data Atualização</MobileTitle>
                            {register.updateAt}
                          </ContentWrapper>
                          <ContentWrapper>
                            <a
                              href={register.urlFile}
                              className="btn btn-secondary"
                              target="blank"
                            >
                              <i className="fas fa-link" /> Arquivo Importado
                            </a>
                          </ContentWrapper>
                        </TableData>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ImportLeadView;
