import { useState } from "react";
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Table } from "reactstrap";
import { ChangeIndicationSimulationService } from "services/Digitation/SimulationService";
import { searchIndicationService } from "services/Promoter/IndicationService";
import Swal from "sweetalert2";

const SearchAndSelectIndicationComponent = ({
   simulationId = null,
   indication = {
      name: null,
      id: null,
      uuid: null
   },
   title = "Buscar indicação / vendedor",
   nameButtonSelect = "Selecione",
   isSave = false
}) => {
   const [operation, setOperation] = useState({});
   const [indications, setIndications] = useState([]);
   const [searchIndication, setSearchIndication] = useState('');


   const handleSearchIndication = () => {
      Swal.fire({
         title: 'Buscando',
         text: 'Por favor, aguarde.',
         allowOutsideClick: false,
         allowEscapeKey: false,
         didOpen: () => {
            Swal.showLoading();
         }
      });
      searchIndicationService({ search: searchIndication })
         .then(({ data }) => {
            Swal.close();
            setIndications(data?.resultSet ?? []);
         })
         .catch((err) => {
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
               ? 'Ocorreu um erro inesperado'
               : err.response.data.message;
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `[${code}] ${message}`
            });
         });
   };


   const handleUpdate = async (simulationId) => {
      Swal.fire({
         icon: 'question',
         title: 'Confirmação',
         text: 'Tem certeza que deseja realizar a alteração?',
         showCancelButton: true,
         cancelButtonText: 'Não, sair',
         confirmButtonText: 'Sim, alterar'
      }).then(async (confirm) => {
         if (!confirm.isConfirmed) return;



         Swal.fire({
            title: 'Salvando',
            text: 'Por favor, aguarde.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
               Swal.showLoading();
            }
         });

         const result = await ChangeIndicationSimulationService(simulationId, operation.indicationId);

         if (result) {
            Swal.fire({
               icon: 'Success',
               title: 'Sucesso',
               text: 'Alteração realizada com sucesso'
            }).then(() => {
               window.location.reload();
               return;
            })
         }

         Swal.close();

      })
   }

   return <>
      <Card className="shadow mt-4">
         <CardHeader>
            <h2 className="m-0">
               <i className="far fa-handshake" /> Indicação
            </h2>
         </CardHeader>
         <CardBody>
            {indication?.id > 0 && (
               <>
                  <h3 className="m-0">Vendedor / Indicação selecionado</h3>
                  <Alert color="secondary">
                     <p className="m-0">Nome: <strong>{indication.name}</strong></p>
                     <p className="m-0">Codigo: <strong>{indication.uuid}</strong></p>
                  </Alert>
               </>
            )}
            <Row>
               <Col md="12">
                  <FormGroup>
                     <Label>{title}</Label>
                     <InputGroup>
                        <Input
                           placeholder="Informar nome ou Código indicação"
                           onChange={(ev) => {
                              setSearchIndication(ev.target.value);
                           }}
                        />
                        <InputGroupAddon addonType="append">
                           <Button
                              color="success"
                              onClick={handleSearchIndication}
                           >
                              Buscar
                           </Button>
                        </InputGroupAddon>
                     </InputGroup>
                  </FormGroup>
               </Col>
            </Row>
            {indications.length > 0 && (
               <Card className="shadow mt-3">
                  <CardHeader>Indicação / Vendedor</CardHeader>
                  <Table responsive>
                     <thead>
                        <tr>
                           <th>Código</th>
                           <th>Nome</th>
                           <th />
                        </tr>
                     </thead>
                     <tbody>
                        {indications.map((indication, key) => (
                           <tr
                              key={key}
                              className={
                                 indication.id == operation.indicationId
                                    ? `bg-secondary font-weight-bold`
                                    : `bg-none`
                              }
                           >
                              <td>{indication.uuid}</td>
                              <td>{indication.name}</td>
                              <td className="text-right">
                                 {indication.id != operation.indicationId && (
                                    <Button
                                       color="secondary"
                                       size="sm"
                                       value={indication.id}
                                       onClick={(ev) => {
                                          setOperation({
                                             ...operation,
                                             indicationId: ev.target.value
                                          });
                                       }}
                                    >
                                       <i className="fas fa-mouse-pointer" />{' '}
                                       {nameButtonSelect}
                                    </Button>
                                 )}
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               </Card>
            )}
         </CardBody>
         {(isSave && operation.indicationId > 0) && (<CardFooter>
            <Button color="warning" onClick={() => handleUpdate(simulationId)}><i className="fas fa-edit" /> Alterar indicador / vendedor</Button>
         </CardFooter>)}

      </Card>
   </>;

}

export default SearchAndSelectIndicationComponent;