import { maskDocument, phoneMask } from 'utils/mask';

import {
  CreatedAt,
  Actuating,
  ClientName,
  Subtitle,
  WhatsAppLink,
  Button
} from './styles';

export const DataCadastro = ({ data }) => {
  return (
    <>
      <CreatedAt>{data.createdAt}</CreatedAt>
      {data.UserActionProposal && (
        <Actuating>
          ATUANDO:
          <div className="badge badge-info">
            <span>{data.UserActionProposal}</span>
          </div>
        </Actuating>
      )}
    </>
  );
};

export const Cliente = ({ data }) => {
  return (
    <>
      <ClientName>{data.name ? data.name : 'Não identificado =('}</ClientName>
      <Subtitle>
        Documento:{' '}
        <strong className="font-weight-bold">{maskDocument(data.cpf)}</strong>
      </Subtitle>
      <Subtitle>
        Simulações:{' '}
        <div className="badge badge-success">
          <strong>{data.qtd}</strong>
        </div>
      </Subtitle>
      <Subtitle>
        Código Indicado:{' '}
        <strong className="font-weight-bold text-warning">
          {data.codeIndication}
        </strong>
      </Subtitle>
      <Subtitle>
        Nome Indicado:{' '}
        <strong className="font-weight-bold text-warning">
          {data.nameIndication}
        </strong>
      </Subtitle>
      <WhatsAppLink
        className="btn btn-link"
        href={data.urlWhatsapp}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-whatsapp" /> {phoneMask(data.whatsappNumber)}
      </WhatsAppLink>
    </>
  );
};

export const Valores = ({ data }) => {
  return (
    <>
      <Subtitle>
        VALOR FGTS: <strong>R$ {data.valueFinally}</strong>
      </Subtitle>
      <Subtitle>
        VALOR LIBERADO: <strong>R$ {data.valueLiberty}</strong>
      </Subtitle>
      <Subtitle>
        VALOR SOLICITADO: <strong>R$ {data.valueSolicited}</strong>
      </Subtitle>
    </>
  );
};

export const Status = ({ data }) => {
  return (
    <>
      {data.numberProposal && (
        <Subtitle>
          Nº Proposta:{' '}
          <div className="badge badge-warning">{data.numberProposal}</div>
        </Subtitle>
      )}
      <Subtitle>
        Plataforma: <strong>{data.status}</strong>
      </Subtitle>
      <Subtitle>
        Status Banco:{' '}
        <strong>
          {data.statusBank ? data.statusBank : 'Aguardando atualização'}
        </strong>
      </Subtitle>
      {data.messageReturnService && (
        <>
          <hr className="my-2" />
          <Subtitle className="text-danger">
            <strong>{data.messageReturnService}</strong>
          </Subtitle>
        </>
      )}
    </>
  );
};

export const Outros = ({ onClick }) => {
  return (
    <Button className="btn btn-success btn-sm" onClick={onClick}>
      Simular
    </Button>
  );
};
