import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import ModalImgBenefitComponent from './ModalImgBenefitComponent';

const BenefitItemComponent = ({
  key,
  benefits,
  handleAdvantageRemove,
  item,
  setItem = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Card key={key} className="shadow mb-3">
        <CardBody>
          <Row>
            <Col md="12" className="pb-4 ">
              {item?.icon && (
                <Card className="shadow mb-3">
                  <CardBody>
                    <img src={item.icon} width={120} alt="img" />
                  </CardBody>
                </Card>
              )}
              <Button
                color="success"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                ADICIONAR IMAGEM
              </Button>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Título</Label>
                <InputGroup>
                  <Input
                    className="font-weight-bold font-20"
                    placeholder="Título"
                    value={item.title}
                    maxLength="60"
                    onChange={(ev) => {
                      setItem({
                        ...item,
                        title: ev.target.value
                      });
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Descrição</Label>
                <InputGroup>
                  <Input
                    className="font-weight-bold font-20"
                    placeholder="Descrição do Link"
                    value={item.description}
                    maxLength="120"
                    onChange={(ev) => {
                      setItem({
                        ...item,
                        description: ev.target.value
                      });
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {benefits.length > 1 && (
            <Button
              color="danger"
              size="sm"
              onClick={() => handleAdvantageRemove(key)}
            >
              <i className="fas fa-trash-alt" /> Remover
            </Button>
          )}
        </CardBody>
      </Card>

      <ModalImgBenefitComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        form={item?.icon ?? ''}
        handleSubmit={(e) => {
          setItem({
            ...item,
            icon: e
          });
        }}
      />
    </>
  );
};

export default BenefitItemComponent;
