import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { updatePassword } from 'services/AuthService';
import Swal from 'sweetalert2';

const ResetPasswordView = () => {
  const history = useHistory();
  const [register, setRegister] = useState({
    password: null,
    newPassword: null,
    repeatPassword: null,
  });
  const [msgError, setMsgError] = useState('');
  const [disableBtnSave, setDisableBtnSave] = useState(true);
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    repeatPassword: false,
  });

  const handleValidatePassword = (password) => {
    const characters = (password.match(/[A-Za-z]/g) || []).length;
    const num = (password.match(/[0-9]/g) || []).length;
    const symbol = (password.match(/\W|_/g) || []).length;

    setDisableBtnSave(true);

    if (characters < 1) {
      setMsgError('A senha deve ter ao menos (1)uma letra');
      return;
    }
    if (num < 1) {
      setMsgError('A senha deve conter ao menos 1(um) numero');
      return;
    }
    if (symbol < 1) {
      setMsgError('A senha deve conter ao menos 1(um) caractere especial');
      return;
    }

    if (password.length < 6) {
      setMsgError('A senha deve conter ao menos 6 caracteres');
      return;
    }

    setMsgError('');
  };

  const handleDisableBtn = () => {
    setDisableBtnSave(true);

    if (!register.password) {
      setMsgError('É preciso informar sua senha atual');
      setDisableBtnSave(true);
      return;
    }

    if (!register.newPassword) {
      setMsgError('É necessário informar a nova senha');
      setDisableBtnSave(true);
      return;
    }

    if (!register.repeatPassword) {
      setMsgError('É necessário repetir a nova senha');
      setDisableBtnSave(true);
      return;
    }

    if (register.newPassword !== register.repeatPassword) {
      setMsgError(
        'As senhas informadas, não são identicas, por favor verifique'
      );
      setDisableBtnSave(true);
      return;
    }

    handleValidatePassword(register.newPassword);

    if (msgError !== '') {
      return;
    }

    setMsgError('');
    setDisableBtnSave(false);
  };

  const handleSubmit = async () => {
    handleDisableBtn();

    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await updatePassword({
      oldPassword: register.password,
      newPassword: register.newPassword,
      confirmPassword: register.repeatPassword,
    })
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Senha alterada com sucesso, realize login novamente com a nova senha!',
        }).then(() => {
          history.push('/auth');
        });
        setRegister({
          password: null,
          newPassword: null,
          repeatPassword: null,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({ ...prev, [field]: !prev[field] }));
  };

  useEffect(() => {
    handleDisableBtn();
  }, [msgError]);

  return (
    <>
      <Header showCards={false} titlePage="Usuários do sistema" />
      <Container className="mt--8" fluid>
        <Alert color="secondary">
          <i className="far fa-question-circle" /> <strong>Atenção:</strong>{' '}
          Para garantir a proteção de seus dados e manter os mais altos padrões
          de segurança, a troca de senha do sistema será necessária a cada 60
          (sessenta) dias.
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-key" /> Alterar senha
            </h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Senha atual</Label>
                  <InputGroup>
                    <Input
                      type={showPassword.currentPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      value={register.password}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          password: ev.target.value,
                        });
                        handleDisableBtn();
                      }}
                      onBlur={() => handleDisableBtn()}
                    />
                    <InputGroupText
                      onClick={() => togglePasswordVisibility('currentPassword')}
                      style={{ cursor: 'pointer', borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }}
                    >
                      <i className={`fa ${showPassword.currentPassword ? 'fa-eye' : 'fa-eye-slash'}`} />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Nova senha</Label>
                  <InputGroup>
                    <Input
                      type={showPassword.newPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      value={register.newPassword}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          newPassword: ev.target.value,
                        });
                        handleDisableBtn();
                      }}
                      onBlur={() => handleDisableBtn()}
                    />
                    <InputGroupText
                      onClick={() => togglePasswordVisibility('newPassword')}
                      style={{ cursor: 'pointer', borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }}
                    >
                      <i className={`fa ${showPassword.newPassword ? 'fa-eye' : 'fa-eye-slash'}`} />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Confirmar nova senha</Label>
                  <InputGroup>
                    <Input
                      type={showPassword.repeatPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      value={register.repeatPassword}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          repeatPassword: ev.target.value,
                        });
                        handleDisableBtn();
                      }}
                      onBlur={() => handleDisableBtn()}
                    />
                    <InputGroupText
                      onClick={() => togglePasswordVisibility('repeatPassword')}
                      style={{ cursor: 'pointer', borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }}
                    >
                      <i className={`fa ${showPassword.repeatPassword ? 'fa-eye' : 'fa-eye-slash'}`} />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {msgError && <Alert color="warning">{msgError}</Alert>}
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              disabled={disableBtnSave}
              onClick={handleSubmit}
            >
              Realizar alteração da senha
            </Button>
          </CardFooter>
        </Card>
        <Alert color="secondary" className="mt-2">
          <h3>Dicas</h3>
          <p className="m-0">
            - A senha deve conter no minimo 6(seis) caracteres
          </p>
          <p className="m-0">- A senha deve conter ao menos 1(um) número</p>
          <p className="m-0">
            - A senha deve conter ao menos 1(um) caractere especial
          </p>
        </Alert>
      </Container>
    </>
  );
};

export default ResetPasswordView;
