import api from 'services/api';

export const ShowClientProposalService = (document, force = 0) => {
  return api.get(`v2/client/proposal/${document}`, {
    params: {
      force: force,
    },
  });
};

export const IndexClientProposalService = (clientId) => {
  return api.get(`v2/client/proposal/list/${clientId}`);
};

export const ReportClientBirthDateService = (filter) => {
  return api.get(`v3/client/report/birthdate`, {
    params: filter,
  });
};
