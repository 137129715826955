import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { updateUserContractSignService } from 'services/Register/ContractSign';
import { cepMask, maskCPF, phoneMask } from 'utils/mask';
import Swal from 'sweetalert2';
import { showUserContractSignService } from 'services/Register/ContractSign';
import { useHistory } from 'react-router-dom';
import { getCepService } from 'services/Utils/CepService';

const ContractSignUpdateUserView = () => {
  const history = useHistory();
  const [register, setRegister] = useState({
    name: null,
    document: null,
    phone: null,
    zipCode: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    state: null,
    complement: null,
    pix: null,
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [contract, setContract] = useState(null);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (!register.state || register.state == '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Estado não identificado, favor informar um CEP válido',
      });
      return;
    }

    if (!register.city || register.city == '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Cidade não identificada, favor informar um CEP válido',
      });
      return;
    }

    setOpenConfirmModal(true);
  };

  const handleSave = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Seus dados estão corretos?',
      showCancelButton: true,
      cancelButtonText: 'Não, alterar',
      confirmButtonText: 'Sim, finalizar',
    }).then((confirme) => {
      setOpenConfirmModal(false);
      if (!confirme.isConfirmed) {
        return;
      }
      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      updateUserContractSignService(register)
        .then(({ data }) => {
          setContract(data.resultSet.contract);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Registro atualizado com sucesso!',
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const handleZipCode = (value) => {
    value = cepMask(value);

    if (value.length < 9) {
      setRegister({
        ...register,
        zipCode: value,
      });
      return;
    }
    Swal.fire({
      title: 'Buscando CEP',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    getCepService(value)
      .then(({ data }) => {
        Swal.close();
        setRegister({
          ...register,
          zipCode: value,
          district: data?.district ?? null,
          address: data?.address ?? null,
          city: data?.city ?? null,
          state: data?.state ?? null,
        });
      })
      .catch((err) => {
        setRegister({
          ...register,
          zipCode: value,
          district: '',
          address: '',
          city: '',
          state: '',
        });
        const code = '000000';
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleShowUser = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    showUserContractSignService()
      .then(({ data }) => {
        Swal.close();
        const user = data?.resultSet?.user;
        setRegister({
          ...register,
          name: user.name,
          phone: phoneMask(user.phone),
          zipCode: cepMask(user.cep),
          address: user.address,
          numberAddress: user.numberAddress,
          district: user.district,
          city: user.city,
          state: user.state,
          complement: user.complement,
          pix: user.pix,
        });
        setContract(data?.resultSet?.contract ?? '');

        if (data?.resultSet?.status == 'signed') {
          let userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
          userData.isUpdateUser = 0;
          localStorage.setItem('IZIDEV_USER_DATA', JSON.stringify(userData));
          history.push('/admin/index');
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleShowUser();
  }, []);

  return (
    <>
      <Header titlePage="ATUALIZAÇÃO DE CADASTRO" />
      <Container className="mt--9" fluid>
        <Form onSubmit={handleSubmit}>
          {!contract && (
            <Card className="shadow">
              <CardHeader className="border-0">
                <span className="text-danger float-right">
                  Os campos com * são obrigatórios
                </span>
                <h2>
                  <i className="fas fa-user-edit" /> ATUALIZAÇÃO DE CADASTRO
                </h2>
              </CardHeader>
              <CardBody>
                <Alert color="warning" className="mb-4">
                  <p className="m-0">
                    - Para continuar a utilização do serviço, será necessário
                    confirmar seus dados cadastrais.
                  </p>
                  <p className="m-0">
                    - Preencha o formulário com informações válidas para
                    continuar.
                  </p>
                </Alert>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>
                        Nome Completo <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        value={register.name}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            name: ev.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>
                        CPF <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="000.000.000-00"
                        value={register.document}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            document: maskCPF(ev.target.value),
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>
                        WhatsApp <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="(00) 0 0000-0000"
                        value={register.phone}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            phone: phoneMask(ev.target.value),
                          });
                        }}
                        required
                      />
                      <p className="text-danger">
                        <i className="fas fa-exclamation-triangle" />{' '}
                        <strong>Atenção:</strong> Informar número válido
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Card className="shadow mt-4">
                  <CardHeader className="border-0">
                    <h4>
                      <i className="fas fa-map-marked-alt" /> Endereço
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label>
                            CEP <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder="00000-000"
                            value={register.zipCode}
                            onChange={(ev) => {
                              handleZipCode(ev.target.value);
                              setRegister({
                                ...register,
                                zipCode: cepMask(ev.target.value),
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            Endereço <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            value={register.address}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                address: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>
                            Nº <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            value={register.numberAddress}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                numberAddress: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>
                            Bairro <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            value={register.district}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                district: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>
                            Cidade <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            readOnly
                            value={register.city}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>
                            Estado <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            readOnly
                            value={register.state}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label>Complemento</Label>
                          <Input
                            type="text"
                            value={register.complement}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                complement: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="shadow mt-4">
                  <CardHeader className="border-0">
                    <h4>
                      <i className="fas fa-piggy-bank" /> Dados bancários - PIX
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label>
                            CHAVE PIX <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder="Celular, telefone, e-mail ou chave aleatória"
                            value={register.pix}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                pix: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
              <CardFooter>
                <Button color="success" size="lg">
                  <i className="fas fa-save" /> SALVAR PARA CONTINUAR
                </Button>
              </CardFooter>
            </Card>
          )}

          {contract && (
            <Card className="shadow">
              <CardHeader>
                <h2>
                  <i className="fas fa-file-signature" /> Assinar contrato
                </h2>
              </CardHeader>
              <CardBody>
                <Alert color="secondary">
                  <h2>{register.name}</h2>
                  <h3>
                    Falta pouco para finalizar! Realize a assinatura do
                    contrato, clicando no botão abaixo:
                  </h3>
                </Alert>
                <a
                  className="btn btn-lg btn-success w-100"
                  href={contract}
                  target="blank"
                >
                  <i className="fas fa-signature" /> CLIQUE PARA ASSINAR
                </a>
              </CardBody>
            </Card>
          )}
        </Form>
      </Container>

      <Modal size="lg" isOpen={openConfirmModal}>
        <ModalHeader>
          <h1>
            <i className="fas fa-check" /> Confirmação
          </h1>
        </ModalHeader>
        <ModalBody>
          <Alert color="success">
            <h2>Agradecemos por preencher o formulário</h2>
            <p className="m-0">
              Confirme, abaixo, as informações preenchidas, para assinatura dos
              termos
            </p>
          </Alert>
          <Card className="shadow border-0">
            <CardHeader>
              <h3>
                <i className="fas fa-user" /> Informações Pessoais
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Nome Completo</Label>
                    <p>{register.name}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>CPF</Label>
                    <p>{register.document}</p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>WhatsApp</Label>
                    <p>{register.phone}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow border-0 mt-3">
            <CardHeader>
              <h3>
                <i className="fas fa-map-marked-alt" /> Endereço
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label>CEP</Label>
                    <p>{register.zipCode}</p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Endereço</Label>
                    <p>{register.address}</p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Nº endereço</Label>
                    <p>{register.numberAddress}</p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Bairro</Label>
                    <p>{register.district}</p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Cidade</Label>
                    <p>{register.city}</p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Estado</Label>
                    <p>{register.state}</p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Complemento</Label>
                    <p>{register.complement}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow border-0 mt-3">
            <CardHeader>
              <h3>
                <i className="fas fa-piggy-bank" /> Dados bancários
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label>PIX</Label>
                    <p>{register.pix}</p>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSave}>
            SIM, CONFIRMAR
          </Button>
          <Button
            color="warning"
            onClick={() => {
              setOpenConfirmModal(false);
            }}
          >
            NÃO, ALTERAR INFORMAÇÕES
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ContractSignUpdateUserView;
