import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { listTagsService } from 'services/Register/TagService';

const SelectTagComponent = ({
  label = 'Etiquetas',
  id = 'select-tag',
  onChange = () => {},
}) => {
  const [list, setList] = useState([]);

  const handleList = () => {
    listTagsService().then((res) => {
      setList(res.data.resultSet);
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <FormGroup>
        <Label for={id}>{label}</Label>
        <Select
          id={id}
          isMulti
          className="basic-single"
          classNamePrefix="select"
          isClearable
          isLoading={list.length <= 0}
          options={list.map((res) => {
            return {
              value: res.id,
              label: res.title,
            };
          })}
          onChange={(res) => {
            onChange(
              res.map((res) => {
                return res.value;
              })
            );
          }}
          placeholder={`Selecione...`}
        />
      </FormGroup>
    </>
  );
};

export default SelectTagComponent;
