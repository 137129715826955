import axios from 'axios';
import Swal from 'sweetalert2';

export const brasilApiCep = async (cep) => {
  return await axios
    .create({ baseURL: 'https://brasilapi.com.br/api/cep/v1/' })
    .get(cep);
};

export const viaCEP = async (cep) => {
  return await axios
    .create({ baseURL: `https://viacep.com.br/ws/${cep}/json/` })
    .get();
};

export const handleSearchCepService = async (cep, setDataCep = () => {}) => {
  Swal.fire({
    title: 'Realizando Digitação...',
    message: 'Por favor aguarde',
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  await brasilApiCep(cep)
    .then(({ data }) => {
      Swal.close();
      setDataCep(data);
    })
    .catch(async () => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `CEP Não encontrado`
      });
    });
};
