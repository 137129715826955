import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  ModalFooter,
  Button
} from 'reactstrap';
import { maskCPF } from 'utils/mask';

const FilterSimulationDashboard = (props) => {
  const {
    className,
    isModal,
    state,
    setDocument,
    setDateEnd,
    setDateStart,
    handleList,
    setStatus,
    setError,
    setNumberProposal,
    setStatusBank
  } = props;

  const [modal, setModal] = useState(false);
  const [cpf, setCpf] = useState('');

  const handleChange = (event) => {
    if (event.target.name === 'dateStart') {
      setDateStart(event.target.value);
    }
    if (event.target.name === 'dateEnd') {
      setDateEnd(event.target.value);
    }
    if (event.target.name === 'document') {
      setDocument(event.target.value);
      setCpf(maskCPF(event.target.value));
    }
    if (event.target.name === 'status') {
      setStatus(event.target.value);
    }
    if (event.target.name === 'error') {
      setError(event.target.value);
    }
    if (event.target.name === 'numberProposal') {
      setNumberProposal(event.target.value);
    }
    if (event.target.name === 'statusBank') {
      setStatusBank(event.target.value);
    }
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  useEffect(() => {
    setModal(isModal);
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={closeModal}>Filtrar registros</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label>Periodo Cadastro</Label>
                <Row>
                  <Col xl="6">
                    <Input
                      type="date"
                      name="dateStart"
                      className="form-control form-control-sm"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </Col>
                  <Col xl="6">
                    <Input
                      type="date"
                      name="dateEnd"
                      className="form-control form-control-sm"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>CPF</Label>
                <Input
                  type="text"
                  value={cpf}
                  name="document"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Proposta</Label>
                <Input
                  type="text"
                  name="numberProposal"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <Label>Status</Label>
                <select
                  name="status"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className="form-control from-control-sm"
                >
                  <option value="">TODOS</option>
                  <option value="5">SIMULAÇÃO INICIALIZADA</option>
                  <option value="1">INICIOU SIMULAÇÃO</option>
                  <option value="4">SIMULAÇÃO NÃO FINALIZADA</option>
                  <option value="2">DIGITAÇÃO REALIZADA</option>
                  <option value="3">
                    DIGITAÇÃO REALIZADA / NÃO FINALIZADA
                  </option>
                  <option value="6">DIGITAÇÃO INICIALIZADA</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <Label>Status Banco</Label>
                <Input
                  type="text"
                  name="statusBank"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <Label>Erro</Label>
                <Input
                  type="text"
                  name="error"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button onClick={handleList} className="btn btn-info">
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FilterSimulationDashboard;
