import SampleModalView from 'components/Modals/SampleModalView';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Table
} from 'reactstrap';
import { listTemplateService } from 'services/Register/SmsService';
import { inactiveTemplateService } from 'services/Register/SmsService';
import { createTemplateService } from 'services/Register/SmsService';
import Swal from 'sweetalert2';

const SmsTemplateComponent = ({ isOpen = false, setIsOpen = () => {} }) => {
  const [register, setRegister] = useState({
    message: '',
    title: ''
  });
  const [list, setList] = useState([]);

  const handleSubmit = () => {
    if (!register?.title || register.title === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'O titulo é obrigatório'
      });
      return;
    }
    if (!register?.message || register.message === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A mensagem é obrigatória'
      });
      return;
    }
    if (register.message.length > 144) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A mensagem pode conter no maximo 144 caracteres'
      });
      return;
    }

    Swal.fire({
      title: 'Inserindo registro',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    createTemplateService(register)
      .then(() => {
        setIsOpen(true);
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro incluido com sucesso!'
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleList = () => {
    listTemplateService()
      .then(({ data }) => {
        setList(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleInactive = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Você deseja remover o registro?',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, remover'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Removendo registro',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      inactiveTemplateService(id)
        .then(() => {
          handleList();
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Registro removido com sucesso!'
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <SampleModalView
        labelModal="Modelo de SMS"
        icon="fas fa-paper-plane"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showBtnSubmit={false}
        showBtnClose={false}
      >
        <Card className="shadow">
          <CardBody>
            <FormGroup>
              <Label>Titulo do modelo</Label>
              <Input
                value={register.title}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    title: ev.target.value
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Mensagem padrão{' '}
                <Badge color="info">{register.message.length}</Badge>
              </Label>
              <textarea
                className="form-control"
                maxLength={144}
                style={{ height: 100 }}
                value={register.message}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    message: ev.target.value
                  });
                }}
              />
            </FormGroup>
            <Alert color="secondary">
              <p className="m-0">
                A mensagem deve conter no{' '}
                <strong>maximo 144 caracteres.</strong>
              </p>
            </Alert>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              Salvar
            </Button>
          </CardFooter>
        </Card>
        {list?.length > 0 && (
          <Card className="shadow mt-3">
            <CardHeader>Modelos cadastrados</CardHeader>
            <Table>
              <thead>
                <tr>
                  <th>DATA CADASTRO</th>
                  <th>NOME DO MODELO</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.map((template, key) => (
                  <tr key={key}>
                    <td>{template.createdAt}</td>
                    <td>{template.title}</td>
                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleInactive(template.id)}
                      >
                        Remover
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </SampleModalView>
    </>
  );
};

export default SmsTemplateComponent;
