import { useEffect, useState } from 'react';
import Header from 'components/Headers/Header';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroup,
  CardFooter,
  Button,
} from 'reactstrap';
import ChartPie from './component/ChartPie';
import ChartLine from './component/ChartLine';
import ChartBar from './component/ChartBar';
import CardConversionComponent from './component/CardConversionComponent';
import { panelFgtsService } from 'services/Report/Panel';
import CardConversionBankComponent from './component/CardConversionBankComponent';
import Swal from 'sweetalert2';

const Panel = () => {
  const [conversion, setConversion] = useState({
    qtdSimulation: 0,
    qtdProposal: 0,
    qtdConversion: 0,
  });
  const [conversionBank, setConversionBank] = useState([]);
  const [proposalSalePerPeriod, setProposalSalePerPeriod] = useState([]);
  const [proposalStatusPerPeriod, setProposalStatusPerPeriod] = useState([]);
  const [dataSimulationGroupPerDay, setDataSimulationGroupPerDay] = useState(
    []
  );
  const [avgSimulation, setAvgSimulation] = useState([]);
  const [avgProposal, setAvgProposal] = useState([]);
  const [top10State, setTop10State] = useState([]);
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
  });

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    panelFgtsService({
      ...filter,
    })
      .then(({ data }) => {
        Swal.close();
        console.log(data.resultSet);
        setConversion(data?.resultSet?.conversion?.result ?? {});
        setConversionBank(data?.resultSet?.conversion?.bank ?? []);

        setProposalSalePerPeriod({
          labels: data?.resultSet?.salePerPeriod.labels,
          datasets: [
            {
              label: 'Venda por período',
              data: data?.resultSet?.salePerPeriod.datasets.data,
              borderColor: '#5e72e4',
            },
          ],
        });

        setProposalStatusPerPeriod({
          labels: data?.resultSet?.statusPerPeriod.labels,
          datasets: [
            {
              label: 'Venda por período',
              data: data?.resultSet?.statusPerPeriod.data,
              backgroundColor: [
                '#ef476f',
                '#ffd166',
                '#06d6a0',
                '#118ab2',
                '#073b4c',
                '#277da1',
              ],
            },
          ],
        });

        setDataSimulationGroupPerDay({
          labels: data?.resultSet?.lastSimulationPerPeriod.labels,
          datasets: data?.resultSet?.lastSimulationPerPeriod.dataSets,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2>Filtro</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      value={filter.dateStart}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      value={filter.dateEnd}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    value={filter.febrabanId}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">457 - UY3</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleList}>
              Filtrar
            </Button>
          </CardFooter>
        </Card>

        <CardConversionComponent conversion={conversion} />

        <CardConversionBankComponent conversionBank={conversionBank} />

        <Row>
          <Col xl="12">
            <ChartLine
              title={`VENDA POR PERÍODO - STATUS PAGO`}
              subtitle={`POR PERÍODO`}
              data={proposalSalePerPeriod}
              background="bg-default mb-4"
            />
          </Col>
          <Col xl="12" className="mb-4">
            <ChartPie
              title="VENDA POR STATUS"
              subtitle="POR PERÍODO"
              data={proposalStatusPerPeriod}
            />
          </Col>
          <Col xl="12">
            <ChartBar
              title="SIMULAÇÕES"
              subtitle="POR PERÍODO"
              data={dataSimulationGroupPerDay}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="6" className="mt-4">
            <ChartBar
              title="TICKET MÉDIO - SIMULAÇÃO"
              subtitle="ULTIMOS 12 MESES"
              data={avgSimulation}
            />
          </Col>
          <Col xl="6" className="mt-4">
            <ChartBar
              title="TICKET MÉDIO - DIGITAÇÃO PAGA"
              subtitle="ULTIMOS 12 MESES"
              data={avgProposal}
            />
          </Col>
          <Col xl="12" className="mt-4">
            <ChartBar
              title="TICKET MÉDIO - DIGITAÇÃO PAGA POR ESTADO"
              subtitle="ULTIMOS 12 MESES"
              data={top10State}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Panel;
