import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Container = styled.th`
  padding: 12px 8px;
  background: #f6f9fc;

  font-size: 13px;
  color: #8898aa;
  text-transform: uppercase;

  &:first-child {
    padding-left: 16px !important;
  }

  &:last-child {
    padding-right: 16px;
  }

  @media ${device.desktopXL} {
    &:nth-last-child(2) {
      padding-right: 16px;
    }
  }
`;
