import React, { useState, useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { getStyles } from 'services/Promoter/PromoterService';

import routes from 'routes.js';

import { Main } from './styles';
import Swal from 'sweetalert2';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [urlLogo, setUrlLog] = useState(
    require('../assets/img/brand/logo.gif').default
  );
  const [navbarCollapsed, setNavbarCollapsed] = useState(
    localStorage.getItem('navbarCollapsed')
  );
  const [mobileOpen, setMobileOpen] = useState(false);

  const styles = async () => {
    await getStyles()
      .then((res) => {
        const result = res.data;
        setUrlLog(result.resultSet.logo);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  styles();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    setMobileOpen(false);
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        if (!prop.childrens) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              serviceId={prop?.serviceId ?? null}
            />
          );
        } else {
          return prop.childrens.map((children, cKey) => (
            <Route
              path={children.layout + children.path}
              component={children.component}
              key={cKey}
              serviceId={children?.serviceId ?? null}
            />
          ));
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return 'Página';
  };

  useEffect(() => {
    const navbarCollapsed = localStorage.getItem('navbarCollapsed');
    setNavbarCollapsed(navbarCollapsed);
  }, []);

  const handleCollapse = () => {
    if (navbarCollapsed) {
      localStorage.removeItem('navbarCollapsed');
      setNavbarCollapsed(false);
    } else {
      localStorage.setItem('navbarCollapsed', true);
      setNavbarCollapsed(true);
    }
  };

  return (
    <Main navbarCollapsed={navbarCollapsed}>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: '#',
          imgSrc: urlLogo,
          imgAlt: '...',
        }}
        handleCollapse={handleCollapse}
        navbarCollapsed={navbarCollapsed}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          urlLogo={urlLogo}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </Main>
  );
};

export default Admin;
