import dayjs from 'dayjs';
import 'dayjs/locale/es';

export const isEmpty = (value, message = 'Registro não informado') => {
  if (!value || value === '' || value === undefined || value === 'undefined') {
    throw new Error(message);
  }
};

export const emailIsValid = (email) => {
  if (email.replace(/[^@]/g, '') !== '@') {
    throw new Error(`O E-mail informado não é valido`);
  }
};

export const dateIsValid = (date, nameField = 'Data') => {
  dayjs.locale('es');

  if (date.length < 10 || date.length > 10) {
    throw new Error(
      `O valor informado no campo: ${nameField} é inválido, por favor verifique`
    );
  }
};
