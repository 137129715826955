import { maskDocument, phoneMask } from 'utils/mask';

import {
  CreatedAt,
  Actuating,
  ClientName,
  Subtitle,
  WhatsAppLink,
  Button
} from './styles';

export const DataCadastro = ({ data }) => {
  return (
    <>
      <CreatedAt>{data.createdAt}</CreatedAt>
      {data.UserActionProposal && (
        <Actuating>
          ATUANDO:
          <div className="badge badge-info">
            <span>{data.UserActionProposal}</span>
          </div>
        </Actuating>
      )}

      <Actuating>
        <div className="badge badge-secondary">
          <span>{data.localeSimulation}</span>
        </div>
      </Actuating>
    </>
  );
};

export const Cliente = ({ data }) => {
  return (
    <>
      <ClientName>{data.name ? data.name : 'Não identificado =('}</ClientName>
      <Subtitle>
        Documento:{' '}
        <strong className="font-weight-bold">{maskDocument(data.cpf)}</strong>
      </Subtitle>
      <Subtitle>
        Data Nascimento:{' '}
        <strong className="font-weight-bold">{data.birthDate}</strong>
      </Subtitle>
      <Subtitle>
        Simulações:{' '}
        <div className="badge badge-success">
          <strong>{data.qtd}</strong>
        </div>
      </Subtitle>
      <Subtitle>
        Código Indicado:{' '}
        <strong className="font-weight-bold text-warning">
          {data.codeIndication}
        </strong>
      </Subtitle>
      <Subtitle>
        Nome Indicado:{' '}
        <strong className="font-weight-bold text-warning">
          {data.nameIndication}
        </strong>
      </Subtitle>
      <p className="text-primary font-weight-bold">
        {phoneMask(data.whatsappNumber)}{' '}
        <WhatsAppLink
          className="btn pl-1 pr-1 btn-success"
          href={data.urlWhatsapp}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp" />
        </WhatsAppLink>
      </p>
    </>
  );
};

export const Valores = ({ data }) => {
  return (
    <>
      {data.multBank.length > 0 ? (
        data.multBank.map((register, key) => (
          <div key={key} className="border-bottom pb-2 pt-2">
            <Subtitle>
              Banco: <strong>{register.bankName}</strong>
            </Subtitle>
            {register.error ? (
              <Subtitle style={{ maxWidth: '400px' }}>
                <strong className="text-danger">{register.error}</strong>
              </Subtitle>
            ) : (
              <Subtitle>
                VALOR LIBERADO:{' '}
                <strong>R$ {register.totalCreditLiberty}</strong>
              </Subtitle>
            )}
          </div>
        ))
      ) : (
        <>
          <Subtitle>
            VALOR FGTS: <strong>R$ {data.valueFinally}</strong>
          </Subtitle>
          <Subtitle>
            VALOR LIBERADO: <strong>R$ {data.valueLiberty}</strong>
          </Subtitle>
          <Subtitle>
            VALOR SOLICITADO: <strong>R$ {data.valueSolicited}</strong>
          </Subtitle>
        </>
      )}
    </>
  );
};

export const Status = ({ data }) => {
  return (
    <>
      {data.numberProposal && (
        <Subtitle>
          Nº Proposta:{' '}
          <div className="badge badge-warning">
            {data.numberProposalBank
              ? data.numberProposalBank
              : data.numberProposal}
          </div>
        </Subtitle>
      )}
      <Subtitle>
        Banco:{' '}
        <strong>
          {data.febrabanId} - {data.nameBank}
        </strong>
      </Subtitle>
      {data?.tableName && (
        <Subtitle>
          Tabela:{' '}
          <strong>
            {data.tableCode} - {data.tableName}
          </strong>
        </Subtitle>
      )}
      <Subtitle>
        Plataforma: <strong>{data.status}</strong>
      </Subtitle>
      <Subtitle>
        Status Banco:{' '}
        <strong>
          {data.statusBank ? data.statusBank : 'Aguardando atualização'}
        </strong>
      </Subtitle>
      {data.messageReturnService && (
        <>
          <hr className="my-2" />
          <Subtitle className="text-danger">
            <strong>{data.messageReturnService}</strong>
          </Subtitle>
        </>
      )}
    </>
  );
};

export const Outros = ({ onClick }) => {
  return (
    <Button className="btn btn-secondary btn-sm" onClick={onClick}>
      Detalhes
    </Button>
  );
};

export const Buttons = ({
  title,
  onClick,
  className = 'btn btn-secondary btn-sm'
}) => {
  return (
    <Button className={className} onClick={onClick}>
      {title}
    </Button>
  );
};
