import { useEffect, useState } from 'react';
import { Card, CardHeader, Container, Col, Row, CardBody } from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { showRegister } from 'services/Consigned/ClientSearchService';
import { moneyMask } from 'utils/mask';

const DetailsMargem = () => {
  let { id } = useParams();
  const [result, setResult] = useState({});

  const handleOnLoad = async () => {
    Swal.fire({
      title: 'Carregando, por favor aguarde!',
      text: 'Carregando, por favor aguarde!',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await showRegister(id).then((res) => {
      setResult(res.data.resultSet);
      Swal.close();
    });
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-md-6">
                    <h3 className="mb-0">Detalhes do Cliente</h3>
                  </div>
                  <div className="col-md-6 text-right">
                    <a
                      className="btn btn-md btn-warning"
                      href="/admin/search-margem"
                    >
                      Voltar
                    </a>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {result.register ? (
                  <>
                    <Row>
                      <Col md="6" className="form-group">
                        <h5>Servidor</h5>
                        <h3>{result.register.name}</h3>
                      </Col>
                      <Col md="3" className="form-group">
                        <h5>Matricula</h5>
                        <h3>{result.register.registry}</h3>
                      </Col>
                      <Col md="3" className="form-group">
                        <h5>CPF</h5>
                        <h3>{result.register.cpf}</h3>
                      </Col>
                      <Col md="6" className="form-group">
                        <h5>TIPO SERVIDOR</h5>
                        <h3>{result.register.typeService}</h3>
                      </Col>
                      <Col md="6" className="form-group">
                        <h5>ORGÃO</h5>
                        <h3>{result.register.organ}</h3>
                      </Col>
                      <Col md="6" className="form-group">
                        <h5>SITUAÇÃO</h5>
                        <h3>{result.register.situation}</h3>
                      </Col>
                      <Col md="3" className="form-group">
                        <h5>DATA ADMISSÃO</h5>
                        <h3>{result.register.dateAdmission}</h3>
                      </Col>
                      <Col md="3" className="form-group">
                        <h5>DATA DEMISSÃO</h5>
                        <h3>{result.register.dateDemission}</h3>
                      </Col>
                    </Row>
                    <div className="alert alert-secondary">
                      <h2 className="m-0">Resultado Margem</h2>
                    </div>
                    <table className="table table-bordered">
                      <tr>
                        <th>Serviço</th>
                        <th>Margem Total</th>
                        <th>Descontos</th>
                        <th>Margem Disponível</th>
                      </tr>
                      {result.margem.map((register, key) => (
                        <tr key={key}>
                          <td>{register.name}</td>
                          <td>R$ {moneyMask(register.value)}</td>
                          <td>R$ {moneyMask(register.discount)}</td>
                          <td>R$ {moneyMask(register.margemLiberty)}</td>
                        </tr>
                      ))}
                    </table>
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailsMargem;
