import api from 'services/api';
const SimulationPersonalCreditService = async (simulation) => {
  return await api.post(`v2/personal-credit/simule`, simulation);
};

const ProposalPersonalCreditService = async (simulationId, proposal) => {
  return await api.post(
    `v2/personal-credit/proposal/${simulationId}`,
    proposal
  );
};

const SimulePersonalCreditCrefazService = async (data) => {
  return await api.post(`v2/personal-credit/crefaz/simule`, data);
};

const InstallmentPersonalCreditCrefazService = async (data) => {
  return await api.get(`v2/personal-credit/crefaz/installment`, {
    params: data
  });
};

const SelectOfferPersonalCreditCrefazService = async (simulationId, data) => {
  return await api.put(
    `v2/personal-credit/crefaz/select-offer/${simulationId}`,
    data
  );
};

const UploadPersonalCreditCrefazService = async (simulationId, data) => {
  return await api.post(
    `v2/personal-credit/crefaz/upload/${simulationId}`,
    data
  );
};

const ProposalPersonalCreditCrefazService = async (simulationId, data) => {
  return await api.post(
    `v2/personal-credit/crefaz/proposal/${simulationId}`,
    data
  );
};

export {
  SimulationPersonalCreditService,
  ProposalPersonalCreditService,
  SimulePersonalCreditCrefazService,
  InstallmentPersonalCreditCrefazService,
  SelectOfferPersonalCreditCrefazService,
  UploadPersonalCreditCrefazService,
  ProposalPersonalCreditCrefazService
};
