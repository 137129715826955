import InputOperatorView from 'components/Form/Input/InputOperatorView';
import SampleModalView from 'components/Modals/SampleModalView';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { storeScheduleUserService } from 'services/Register/ScheduleUserService';
import Swal from 'sweetalert2';

const FormScheduleComponent = ({
  toggleModal,
  setToggleModal,
  calendarRef,
  name = null,
  document = null
}) => {
  const [register, setRegister] = useState({
    title: null,
    date: null,
    color: '#3788d8',
    display: 'block',
    extendedProps: {
      user: {
        id: null,
        name: null
      },
      description: null
    }
  });

  useEffect(() => {
    if (name && name !== '' && toggleModal === true) {
      setRegister({
        title: document !== null ? 'Agendar atendimento' : '',
        date: null,
        color: '#3788d8',
        display: 'block',
        extendedProps: {
          user: {
            id: null,
            name: null
          },
          description:
            document !== null
              ? `Entrar em contato, CLIENTE: ` + name + ` / CPF: ` + document
              : ''
        }
      });
    }
  }, [toggleModal]);

  const handleSubmit = async () => {
    if (!register.title) {
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      showCancelButton: true,
      confirmButtonText: 'Sim, confirmar e salvar!',
      cancelButtonText: 'Não, sair!'
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Salvando',
        text: 'por favor aguarde!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await storeScheduleUserService({
        title: register.title,
        description: register.extendedProps.description,
        color: register.color,
        display: register.display,
        date: register.date,
        userId: register.userId
      })
        .then(() => {
          Swal.close();
          if (calendarRef) {
            let calendarApi = calendarRef.current.getApi();
            calendarApi.addEvent(register);
          }
          setToggleModal(false);

          setRegister({
            title: null,
            date: null,
            color: '#3788d8',
            display: 'block',
            extendedProps: {
              name: null,
              lastName: null,
              description: null
            }
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  return (
    <>
      <SampleModalView
        labelModal="Inserir registro na agenda"
        isOpen={toggleModal}
        setIsOpen={setToggleModal}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={handleSubmit}
        size="xl"
      >
        <div className="shadow p-3 rounded">
          <Row>
            <Col md="12">
              <InputOperatorView
                filter={register}
                setFilter={setRegister}
                isMulti={true}
              />
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="title">Titulo</Label>
                <Input
                  id="title"
                  max={60}
                  value={register.title}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      title: ev.target.value
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="description">Descrição</Label>
                <textarea
                  id="description"
                  className="form-control"
                  maxLength={140}
                  value={register.extendedProps.description}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      extendedProps: {
                        ...register.extendedProps,
                        description: ev.target.value
                      }
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="date">Data</Label>
                <InputGroup>
                  <Input
                    id="date"
                    type="date"
                    value={register.date ?? ''}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        date: ev.target.value
                      });
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="color">Cor</Label>
                <Input
                  id="color"
                  type="color"
                  value={register.color ?? '#3788d8'}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      color: ev.target.value
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </SampleModalView>
    </>
  );
};

export default FormScheduleComponent;
