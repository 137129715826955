import InputComponent from 'components/Form/Input/InputComponent';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import Header from 'components/Headers/Header';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import { listLevelAccessService } from 'services/User/LevelAccess';
import { EnumBanks } from 'utils/EnumBanks';
import { maskCPF, phoneMask } from 'utils/mask';
import { useEffect } from 'react';
import LevelAccessListView from './component/LevelAccessListView';
import { indexUserSupervisor } from 'services/User/User';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import { userSaveService } from 'services/User/User';
import { userShowService } from 'services/User/User';
import { cepMask } from 'utils/mask';
import InputIndicationView from 'components/Form/Input/InputIndicationView';

const RegisterUserView = () => {
  const history = useHistory();
  const { id: userId } = useParams();
  const [openLevelAccess, setOpenLevelAccess] = useState(false);
  const [form, setForm] = useState({
    name: null,
    document: null,
    phone: null,
    makeSale: 0,
    indicationId: null,
    supervisorId: null,
    cep: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    state: null,
    bank: null,
    agency: null,
    numberAccount: null,
    typeAccount: null,
    pix: null
  });
  const [listAccess, setListAccess] = useState([]);
  const [listSupervisor, setListSupervisor] = useState([]);
  const [levelAccessSelected, setLevelAccessSelected] = useState({});

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    Swal.fire({
      title: 'Salvando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await userSaveService(form)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: !form?.id
            ? 'Registro Inserido com sucesso'
            : 'Registro atualizado com sucesso'
        }).then(() => {
          history.push('/admin/user/list');
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListSupervisor = async () => {
    await indexUserSupervisor().then((res) => {
      setListSupervisor(res.data.resultSet);
    });
  };

  const handleListLevelAccess = async () => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    await listLevelAccessService({ active: 1 })
      .then(({ data }) => {
        Swal.close();
        setListAccess(data?.resultSet ?? []);

        if (form.levelAccessId > 0 && data?.resultSet?.length > 0) {
          const selected = data?.resultSet.filter((e) => {
            return e.id == form.levelAccessId;
          });

          if (selected.length > 0) {
            setLevelAccessSelected(selected[0]);
            if (selected[0].isSupervisor > 0) {
              setForm({
                ...form,
                supervisorId: null
              });
            }
          }
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleShowUser = async (id) => {
    await userShowService(id)
      .then(({ data }) => {
        setForm({
          ...(data?.resultSet ?? {}),
          phone: phoneMask(data?.resultSet?.phone ?? ''),
          document: maskCPF(data?.resultSet?.document ?? ''),
          cep: cepMask(data?.resultSet?.cep ?? ''),
          makeSale: data?.resultSet?.indicationId > 0 ? 1 : 0
        });

        setLevelAccessSelected({ id: data?.resultSet?.levelAccessId });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        }).then(() => {
          history.push('/admin/user/list');
        });
      });
  };

  const handleSetLevelAccessId = (id) => {
    const newForm = form;
    newForm.levelAccessId = id ?? '';

    const selected = listAccess.filter((e) => {
      return e.id == id;
    });

    if (selected.length > 0) {
      setLevelAccessSelected(selected[0]);
      if (selected[0].isSupervisor > 0) {
        newForm.supervisorId = null;
      }
    }

    setForm(newForm);
  };

  useEffect(async () => {
    if (userId > 0) {
      await handleShowUser(userId ?? 0);
    }

    await handleListSupervisor();
  }, []);

  useEffect(async () => {
    await handleListLevelAccess();
  }, [openLevelAccess]);

  if (userId > 0 && !form?.id) {
    return (
      <>
        <Header showCards={false} titlePage="Usuários do sistema" />
        <Container className="mt--8" fluid>
          <Alert color="info">Carregando registro, por favor aguarde...</Alert>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header showCards={false} titlePage="Usuários do sistema" />
      <Container className="mt--8" fluid>
        <Button
          color="warning"
          className="mb-4"
          onClick={() => {
            history.push('/admin/user/list');
          }}
        >
          <i className="fas fa-chevron-left" /> Voltar
        </Button>
        <Form onSubmit={handleSubmit}>
          <Card className="shadow">
            <CardHeader>
              <i className="fas fa-user" /> Registro - Usuário de sistema
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <InputComponent
                    label="Nome"
                    isRequired={true}
                    value={form?.name ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, name: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="4">
                  <InputComponent
                    label="CPF"
                    value={form?.document ?? ''}
                    isRequired={true}
                    onChange={(ev) => {
                      setForm({ ...form, document: maskCPF(ev.target.value) });
                    }}
                  />
                </Col>
                <Col md="4">
                  <InputComponent
                    label="Telefone p/ Contato"
                    isRequired={true}
                    value={form?.phone ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, phone: phoneMask(ev.target.value) });
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>
              <i className="fas fa-key" /> Acesso ao sistema
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>
                      Nível de acesso <span className="text-danger">*</span>
                    </Label>
                    <InputGroup>
                      <select
                        className="form-control"
                        onChange={(ev) => {
                          const levelAccessId = ev.target.value;

                          const selected = listAccess.filter((e) => {
                            return e.id == form.levelAccessId;
                          });

                          if (
                            form.id > 0 &&
                            selected.length > 0 &&
                            selected[0].isSupervisor > 0
                          ) {
                            Swal.fire({
                              icon: 'question',
                              title: 'Confirmação',
                              text: 'Esse usuário, deixando de ser Supervisor, perderá os vínculos com todos os demais usuários. Deseja realizar a alteração?',
                              showCancelButton: true,
                              cancelButtonText: 'Não, sair',
                              confirmButtonText: 'Sim, alterar'
                            }).then((confirm) => {
                              if (!confirm.isConfirmed) return;

                              handleSetLevelAccessId(levelAccessId);
                            });

                            return;
                          }

                          handleSetLevelAccessId(levelAccessId);
                        }}
                        value={form?.levelAccessId ?? ''}
                        required
                      >
                        <option value="" selected disabled>
                          Selecione...
                        </option>
                        {listAccess.map((level, key) => (
                          <option key={key} value={level.id}>
                            {level.name}
                          </option>
                        ))}
                      </select>
                      <InputGroupAddon addonType="append">
                        <Button
                          color="info"
                          onClick={() => {
                            setOpenLevelAccess(true);
                          }}
                        >
                          <i className="fas fa-plus" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <InputComponent
                    label="E-mail"
                    isRequired={true}
                    value={form?.email ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, email: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="4">
                  <InputComponent
                    label="Senha de acesso"
                    isRequired={!form?.id}
                    value={form?.password ?? ''}
                    type="password"
                    onChange={(ev) => {
                      setForm({ ...form, password: ev.target.value });
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>
              <i className="fas fa-cart-arrow-down" /> Vendas
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <InputComponent
                    label="Realiza vendas?"
                    value={form?.makeSale ?? ''}
                    type="select"
                    select={[
                      { name: 'NÃO', value: 0 },
                      { name: 'SIM', value: 1 }
                    ]}
                    onChange={(ev) => {
                      const newForm = form;
                      form.makeSale = ev.target.value;
                      if (Number(form?.makeSale) === 0) {
                        newForm.indicationId = null;
                        newForm.supervisorId = null;
                      }
                      setForm({ ...newForm });
                    }}
                  />
                </Col>
                {form.makeSale > 0 && (
                  <Col>
                    <InputIndicationView
                      filter={form}
                      setFilter={setForm}
                      defaultValue={{
                        value: form.indicationId,
                        label: form.indicationName
                      }}
                    />
                  </Col>
                )}
                {(form.indicationId > 0 && !levelAccessSelected?.id) ||
                  (form.indicationId > 0 &&
                    levelAccessSelected?.id &&
                    Number(levelAccessSelected?.isSupervisor) !== 1 && (
                      <Col>
                        <InputComponent
                          label="Supervisor / Gerente responsável"
                          value={form?.supervisorId ?? ''}
                          type="select"
                          select={[
                            { name: 'Selecione...', value: '' },
                            ...listSupervisor.map((idc) => {
                              return { value: idc.id, name: idc.name };
                            })
                          ]}
                          onChange={(ev) => {
                            setForm({ ...form, supervisorId: ev.target.value });
                          }}
                        />
                      </Col>
                    ))}
              </Row>
              {form.indicationId > 0 && (
                <Alert color="warning">
                  <i className="fas fa-exclamation-triangle" /> Ao selecionar a
                  opção <strong>Link de indicação / Venda</strong>, o usuário de
                  sistema irá visualizar apenas o que ele vender.
                </Alert>
              )}
              {levelAccessSelected.isSupervisor <= 0 && form.supervisorId > 0 && (
                <Alert color="warning">
                  <i className="fas fa-exclamation-triangle" /> Ao selecionar o
                  campo <strong>Supervisor / Gerente responsável</strong>, o
                  supervisor selecionado terá visão de todas as vendas
                  realizadas por este usuário de sistema
                </Alert>
              )}
            </CardBody>
          </Card>

          <Card className="shadow mt-4">
            <CardHeader>
              <i className="fas fa-map-marked-alt" /> Endereço
            </CardHeader>
            <CardBody>
              <FormRegisterAddressComponent
                register={form}
                setRegister={setForm}
                isRequired={false}
              />
            </CardBody>
          </Card>

          <Card className="shadow mt-4">
            <CardHeader>
              <i className="fas fa-piggy-bank" /> Dados bancários
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <InputComponent
                    label="Banco"
                    value={form?.bank ?? ''}
                    type="select"
                    select={[{ code: '', name: 'Selecione...' }, ...EnumBanks]}
                    onChange={(ev) => {
                      setForm({ ...form, bank: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="3">
                  <InputComponent
                    label="Agência"
                    value={form?.agency ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, agency: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="3">
                  <InputComponent
                    label="Nº da conta"
                    value={form?.numberAccount ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, numberAccount: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="6">
                  <InputComponent
                    label="Tipo de conta"
                    value={form?.typeAccount ?? ''}
                    type="select"
                    select={[
                      { name: 'Conta corrente', value: 'CC' },
                      { name: 'Conta poupança', value: 'PP' }
                    ]}
                    onChange={(ev) => {
                      setForm({ ...form, typeAccount: ev.target.value });
                    }}
                  />
                </Col>
                <Col md="6">
                  <InputComponent
                    label="CHAVE PIX"
                    value={form?.pix ?? ''}
                    onChange={(ev) => {
                      setForm({ ...form, pix: ev.target.value });
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Button color="success">
                <i className="fas fa-save" /> Salvar
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  history.push('/admin/user/list');
                }}
              >
                <i className="fas fa-chevron-left" /> Voltar
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
      <LevelAccessListView
        isOpen={openLevelAccess}
        setIsOpen={setOpenLevelAccess}
      />
    </>
  );
};

export default RegisterUserView;
