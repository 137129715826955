/*eslint-disable*/
import { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { List } from 'phosphor-react';

import {
  MobileHeader,
  NavContent,
  Logo,
  Navbar,
  NavbarHeader,
  Nav,
  NavItem,
  MobileBackground,
  Divisor,
  Caret,
} from './styles';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { useEffect } from 'react';

const Sidebar = ({
  routes,
  logo,
  handleCollapse,
  navbarCollapsed,
  mobileOpen,
  setMobileOpen,
}) => {
  const history = useHistory();
  const [updatePassModal, setUpdatePassModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [modules, setModules] = useState([
    {
      name: 'SMS - CAMPANHA',
      path: '/sms/campaign',
      new: '0',
    },
    {
      name: 'SMS - RELATÓRIO DE ENVIOS',
      path: '/sms/list',
      new: '0',
    },
    {
      name: 'SMS - CRÉDITOS',
      path: '/sms/recharge-credit',
      new: '0',
    },
  ]);
  const modulesStorage = JSON.parse(
    localStorage.getItem('IZIDEV_MODULE_SYSTEM')
  );
  const isIndication = localStorage.getItem('IZIDEV_AUTH_ISINDICATION');
  const userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));

  useEffect(() => {
    if (!modulesStorage) {
      history.push('/auth');
    }

    if (modulesStorage?.length > 0) {
      setModules(modulesStorage);
    }
  }, []);

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }

  const withChildrens = routes.filter((route) => !!route?.childrens);

  const availableRoutes = routes.filter((route) =>
    modules.some((module) => module.path === route.path)
  );

  const availableChildrens = withChildrens.filter((route) =>
    route.childrens.find((children) =>
      modules.some((module) => module.path === children.path)
    )
  );

  const resolvedRoutes = [...availableRoutes, ...availableChildrens];

  const availableChildren = (children) => {
    return modules.some((module) => module.path === children);
  };

  const isNew = (path) => {
    if (modules.some((module) => module.path === path && module.new === '1'))
      return <span className="badge badge-success ml-1">NOVO</span>;

    return;
  };

  const isNewChildren = (children) => {
    if (children.length <= 0) return <></>;

    let haveNew = false;
    return children.map((register) => {
      if (haveNew) return;

      if (
        modules.some(
          (module) => module.path === register.path && module.new === '1'
        )
      ) {
        haveNew = true;
        return (
          <span className="badge badge-success ml-1" key={register.path}>
            NOVO
          </span>
        );
      }
      return <></>;
    });
  };

  const handleSelectedMenu = (path) => {
    if (path === selectedMenu) {
      setSelectedMenu('');
    } else {
      setSelectedMenu(path);
    }
  };

  const handleSearchModule = (search) => {
    if (!search || search === '') {
      setModules(modulesStorage);
      return;
    }

    setModules(
      modules.filter((module) => {
        return module.name
          .toLowerCase()
          .match(new RegExp(search.toLowerCase()));
      })
    );
  };

  return (
    <>
      <MobileHeader>
        <NavContent>
          <NavLink {...navbarBrandProps}>
            <Logo alt={logo?.imgAlt} src={logo?.imgSrc} />
          </NavLink>
          <List
            size={24}
            cursor="pointer"
            onClick={() => setMobileOpen(!mobileOpen)}
          />
        </NavContent>
      </MobileHeader>
      <MobileBackground
        navbar={mobileOpen}
        onClick={() => setMobileOpen(!mobileOpen)}
      />
      <Navbar
        collapsed={navbarCollapsed}
        mobile={mobileOpen}
        className={`navbar-vertical fixed-left navbar-expand-lg overflow-auto`}
        onClick={() => {
          if (Number(userData?.isUpdateUser) === 1) {
            history.push('/admin/izisign/update-user');
          }

          if (userData?.isChangePassword === true) {
            history.push('/admin/update-password');
          }
        }}
      >
        <NavbarHeader navbar={navbarCollapsed}>
          <NavLink {...navbarBrandProps}>
            <Logo className="hovered" alt={logo?.imgAlt} src={logo?.imgSrc} />
          </NavLink>
          <List size={24} cursor="pointer" onClick={handleCollapse} />
        </NavbarHeader>
        {!navbarCollapsed && (
          <div className="pr-4 pl-4">
            <FormGroup>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Buscar menu"
                  onChange={(ev) => {
                    handleSearchModule(ev.target.value ?? '');
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </div>
        )}

        <Nav>
          {isIndication === 'false' && (
            <>
              <a href="https://backoffice.izichat.com.br/" target="_blank">
                <NavItem navbar={navbarCollapsed} name="IZICHAT">
                  <div>
                    <i className="fab fa-whatsapp" />
                    <p className="hovered">IZICHAT</p>
                  </div>
                </NavItem>
              </a>

              <Divisor />
            </>
          )}

          {resolvedRoutes.map((route) =>
            !route.childrens ? (
              <NavLink
                key={route.name}
                to={route.layout + route.path}
                activeClassName="selected"
              >
                <NavItem navbar={navbarCollapsed} name={route.name}>
                  <div>
                    <i className={route.icon}></i>
                    <p className="hovered">
                      {route.name}
                      {isNew(route.path)}
                    </p>
                  </div>
                </NavItem>
              </NavLink>
            ) : (
              <>
                <NavItem
                  navbar={navbarCollapsed}
                  name={route.name}
                  onClick={() => handleSelectedMenu(route?.name)}
                  key={route.name}
                >
                  <div>
                    <i className={route.icon}></i>
                    <p className="hovered">{route.name}</p>
                    {isNewChildren(route.childrens)}
                  </div>
                  <Caret active={selectedMenu === route.name} />
                </NavItem>
                {selectedMenu === route.name &&
                  !navbarCollapsed &&
                  route.childrens.map((children) => (
                    <>
                      {availableChildren(children.path) && (
                        <NavLink
                          to={route.layout + children.path}
                          activeClassName="selected"
                          key={children.name}
                        >
                          <NavItem
                            navbar={navbarCollapsed}
                            name={children.name}
                          >
                            <div>
                              <i className={children.icon}></i>
                              <p className="hovered">
                                {children.name}
                                {isNew(children.path)}
                              </p>
                            </div>
                          </NavItem>
                        </NavLink>
                      )}
                    </>
                  ))}
              </>
            )
          )}
          <Divisor />
          {isIndication === 'false' ? (
            <>
              <a href="https://wa.me/551150439783" target="_blank">
                <NavItem navbar={navbarCollapsed} name="Atendimento">
                  <div>
                    <i className="ni ni-spaceship"></i>
                    <p className="hovered">Atendimento</p>
                  </div>
                </NavItem>
              </a>
            </>
          ) : (
            <></>
          )}
          <NavItem
            navbar={navbarCollapsed}
            name="Alterar senha"
            onClick={() => history.push('/admin/update-password')}
          >
            <div>
              <i className="ni ni-lock-circle-open"></i>
              <p className="hovered">Alterar senha</p>
            </div>
          </NavItem>
        </Nav>
      </Navbar>
    </>
  );
};

export default Sidebar;
