import api from 'services/api';

const getAll = async () => {
  return await api.get('v1/access-bank');
};

const insert = async (data) => {
  return await api.post('v1/access-bank', data);
};

const remove = async (id) => {
  return await api.delete(`v1/access-bank/${id}`);
};

const update = async (data) => {
  return await api.put(`v1/access-bank/${data.id}`, data);
};

const showAccessBank = async (febrabanId) => {
  return await api.get(`v2/access-bank/${febrabanId}`);
};

const storeAccessBank = async (data) => {
  return await api.post(`v2/access-bank`, data);
};

const listBanksActiveService = async (data) => {
  return await api.get(`v2/access-bank-active`, data);
};

const updateOrderBankService = async (data) => {
  return await api.put(`v2/access-bank-order`, data);
};

const listBanksLibertyService = async (filter) => {
  const url = 'v3/access-bank/banks';
  const params = filter ? { filter } : {};
  return await api.get(url, { params });
};

const listRuleService = async (data = {}) => {
  return await api.get(`v2/access-bank/table-rule`, {
    params: data,
  });
};

const createRuleService = async (data) => {
  return await api.post(`v2/access-bank/table-rule`, data);
};

const activeInactiveRuleService = async (id) => {
  return await api.put(`v2/access-bank/table-rule/${id}`);
};

const listAllTablesService = async (febrabanId, filter) => {
  return await api.get(`v3/comission/table/bank/${febrabanId}/all`, {
    params: filter,
  });
};

const listTablesService = async (febrabanId, serviceId) => {
  return await api.get(`v3/comission/tablePromoter/${febrabanId}/${serviceId}`);
};

const saveSelectedTablesService = async (data) => {
  return await api.post(`v3/comission/tablePromoter`, data);
};

const activeInactiveTableService = async (id, installment) => {
  return await api.put(`v3/comission/tablePromoter`, {
    tableComissionBankId: id,
    installment: installment
  });
};

const getTypeService = async () => {
  return await api.get(`v3/product/group`);
};

const getProductService = async (filter) => {
  return await api.get(`v3/product`, {
    params: filter,
  });
};

export {
  getAll,
  insert,
  remove,
  update,
  showAccessBank,
  storeAccessBank,
  listBanksActiveService,
  updateOrderBankService,
  listBanksLibertyService,
  listRuleService,
  createRuleService,
  activeInactiveRuleService,
  listAllTablesService,
  saveSelectedTablesService,
  listTablesService,
  activeInactiveTableService,
  getTypeService,
  getProductService,
};
