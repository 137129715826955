import api from 'services/api';

export const IndexContractSignService = (filter) => {
  return api.get(`v2/contractSign`, {
    params: filter
  });
};

export const IndexResumeContractSignService = () => {
  return api.get(`v2/contractSign/resume`);
};

export const createContractSignTemplateService = (data) => {
  return api.post(`v2/contractSign/template`, data);
};

export const listContractSignTemplateService = () => {
  return api.get(`v2/contractSign/template`);
};

export const createContractSignConfigUpdateUserService = (data) => {
  return api.post(`v2/contractSign/config-update`, data);
};

export const listContractSignConfigUpdateUserService = () => {
  return api.get(`v2/contractSign/config-update`);
};

export const updateUserContractSignService = (data) => {
  return api.put(`v2/contractSign/update-user`, data);
};

export const showUserContractSignService = () => {
  return api.get(`v2/contractSign/user`);
};

export const showStatusContractSignService = (id) => {
  return api.get(`v2/contractSign/status/${id}`);
};

export const listUserUpdateByUserService = (id) => {
  return api.get(`v2/contractSign/update-user/${id}`);
};

export const inactiveUserUpdateService = (userId, id) => {
  return api.delete(`v2/contractSign/update-user/${userId}/${id}`);
};

export const reportContractSignGenerateService = (data) => {
  return api.get(`v2/contractSign/user-update/report-contract-generate`, {
    params: data
  });
};
