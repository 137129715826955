import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { moneyMask } from "utils/mask";

const MarginResultComponent = ({
    result,
    setIsOpen,
    setMarginSelected,
    renderSelectButton,
}) => {

    return (
        <>
            <div className="w-100">
                <h2>
                    <i className="fas fa-hand-holding-heart" /> Benefício
                </h2>
                <Badge color="warning" className="mb-2">
                    Consultas restantes: {result.credits}
                </Badge>
                {result?.benefit?.length > 0 && (
                    <Row>
                        {result.benefit.map((benefit) => (
                            <Col
                                md="6"
                                xs="12"
                                className="w-100 mb-3 d-flex align-items-stretch"
                                key={benefit.number}
                            >
                                <Card className="shadow bg-primary text-white w-100">
                                    <CardHeader>
                                        <h3 className="m-0">
                                            {benefit.code} - {benefit.description}
                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <p className="font-14 m-0">Matricula</p>
                                                <p className="font-16 m-0 font-weight-bold">
                                                    {benefit.number}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="font-14 m-0">Benefício</p>
                                                <p className="font-16 m-0 font-weight-bold">
                                                    {moneyMask(benefit.value)}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="font-14 m-0">Margem RMC</p>
                                                <p className="font-16 m-0 font-weight-bold">
                                                    {moneyMask(benefit.loanAvailableRMC)}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="font-12 m-0">Margem RCC</p>
                                                <p className="font-16 m-0 font-weight-bold">
                                                    {moneyMask(benefit.loanAvailableRCC)}
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {renderSelectButton && (
                                        <CardFooter>
                                            <Button
                                                className="w-100"
                                                color="warning"
                                                onClick={() => {
                                                    setMarginSelected({
                                                        ...result,
                                                        selected: benefit,
                                                    });
                                                    setIsOpen(false);
                                                }}
                                            >
                                                SELECIONAR
                                            </Button>
                                        </CardFooter>
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}

                <Card className="bg-white shadow">
                    <CardHeader>
                        <h3 className="m-0">
                            <i className="fas fa-user" /> Informações Pessoais
                        </h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="12">
                                <strong>Nome</strong>
                                <p>{result.name}</p>
                            </Col>
                            <Col lg="4">
                                <strong>CPF</strong>
                                <p>{result.cpf}</p>
                            </Col>
                            <Col lg="4">
                                <strong>Dt. Nascimento</strong>
                                <p>{result.birthDate}</p>
                            </Col>
                            <Col lg="4">
                                <strong>RG</strong>
                                <p>{result.documentRG}</p>
                            </Col>
                            <Col md="12">
                                <strong>Nome da Mãe</strong>
                                <p>{result.motherName}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className="bg-white shadow mt-3">
                    <CardHeader>
                        <h3 className="m-0">
                            <i className="fas fa-map-marker-alt" /> Endereço
                        </h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="4">
                                <strong>CEP</strong>
                                <p>{result.zipCode}</p>
                            </Col>
                            <Col lg="4">
                                <strong>Endereço</strong>
                                <p>{result.street}</p>
                            </Col>
                            <Col lg="4">
                                <strong>Bairro</strong>
                                <p>{result.district}</p>
                            </Col>
                            <Col md="12">
                                <strong>Estado</strong>
                                <p>{result.state}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </>
    )
};

export default MarginResultComponent;