import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Row,
  Col,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { updateIndication } from 'services/Promoter/IndicationService';
import ViewLinkAndQrCode from './ViewLinkAndQrcode';
import { phoneMask } from 'utils/mask';
import { handleHasPermission } from 'utils/ModuleUtils';

const DetailsIndication = (props) => {
  const { className, isModal, state, data, list } = props;

  const [modal, setModal] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});
  const [tabActive, setTabActive] = useState('1');

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const handleUpdate = () => {
    Swal.fire({
      title: 'Realizando alteração',
      // text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    updateIndication(data.id, dataUpdate).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Registro alterado com sucesso!',
      }).then((confirm) => {
        if (!confirm.isConfirmed) return;

        list();
      });
    });
  };

  useEffect(() => {
    setModal(isModal);
    setDataUpdate({ ...data, contactPhone: phoneMask(data?.contactPhone) });

    if (!handleHasPermission('/fgts/proposal')) {
      setTabActive(0);
    }
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop={true}
        size="xl"
      >
        <ModalHeader toggle={closeModal}>Inserir Registro</ModalHeader>
        <ModalBody>
          <Alert color="success">
            <i className="fas fa-question-circle" /> Você pode copiar o link de
            indicado ou enviar o QRCode, para ter uma simulação indicada
          </Alert>
          <Row>
            <Col md="6">
              <p className="m-0">Nome</p>
              <FormGroup>
                <Input
                  value={dataUpdate.name}
                  onChange={(ev) => {
                    setDataUpdate({
                      ...dataUpdate,
                      name: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <p className="m-0">WhatsApp Suporte</p>
              <FormGroup>
                <Input
                  value={dataUpdate.contactPhone}
                  onChange={(ev) => {
                    setDataUpdate({
                      ...dataUpdate,
                      contactPhone: phoneMask(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <p className="m-0">Codigo indicação</p>
              <h3>{dataUpdate.uuid}</h3>
            </Col>
          </Row>
          <div>
            <Nav tabs>
              {handleHasPermission('/fgts/proposal') && (
                <NavItem>
                  <NavLink
                    className={tabActive === '1' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('1');
                    }}
                  >
                    Simulador FGTS
                  </NavLink>
                </NavItem>
              )}
              {handleHasPermission('/landing-page/simulator/multi-banks') && (
                <NavItem>
                  <NavLink
                    className={tabActive == '2' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('2');
                    }}
                  >
                    MultiBancos FGTS
                  </NavLink>
                </NavItem>
              )}
              {handleHasPermission('/landing-page/fgts') && (
                <NavItem>
                  <NavLink
                    className={tabActive == '4' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('4');
                    }}
                  >
                    LP FGTS
                  </NavLink>
                </NavItem>
              )}

              {handleHasPermission('/landing-page/consigned') && (
                <NavItem>
                  <NavLink
                    className={tabActive == '5' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('5');
                    }}
                  >
                    LP Consignado
                  </NavLink>
                </NavItem>
              )}

              {handleHasPermission('/landing-page/iziform') && (
                <NavItem>
                  <NavLink
                    className={tabActive == '6' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('6');
                    }}
                  >
                    iziForm
                  </NavLink>
                </NavItem>
              )}

              {handleHasPermission('/personal-credit/digitation') && (
                <NavItem>
                  <NavLink
                    className={tabActive == '8' ? 'active' : ''}
                    onClick={() => {
                      setTabActive('8');
                    }}
                  >
                    Novo Saque
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId="1" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlSimulation}
                />
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlMultBank}
                />
              </TabPane>
              <TabPane tabId="4" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlLpFgts}
                />
              </TabPane>
              <TabPane tabId="5" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlLpConsigned}
                />
              </TabPane>
              <TabPane tabId="6" className="p-3">
                <Alert color="secondary">
                  <h3>Formulário de captação de lead</h3>
                  <p>
                    Este formulário é utilizado apenas para salvar os leads
                    digitados, sem interação com API.{' '}
                    <strong>
                      Ideal para você que pretende vender qualquer tipo de
                      produto.
                    </strong>
                  </p>
                </Alert>
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlIziForm}
                />
              </TabPane>
              {/* <TabPane tabId="7" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlCardBenefit}
                />
              </TabPane> */}
              <TabPane tabId="8" className="p-3">
                <ViewLinkAndQrCode
                  dataUpdate={dataUpdate}
                  url={dataUpdate.urlPersonalCredit}
                />
              </TabPane>
            </TabContent>
          </div>

          <div className="mt-4">
            <h3>Dados bancários</h3>
            <div className="bg-secondary p-3">
              <Row>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="bank">Banco</label>
                    <select
                      value={dataUpdate.febrabanId}
                      id="bank"
                      name="bank"
                      className="form-control"
                      onChange={(ev) => {
                        setDataUpdate({
                          ...dataUpdate,
                          febrabanId: ev.target.value,
                        });
                      }}
                    >
                      <option value="">SELECIONE</option>
                      <option value="104">CAIXA ECONOMICA FEDERAL</option>
                      <option value="001">BANCO DO BRASIL S.A.</option>
                      <option value="237">BANCO BRADESCO</option>
                      <option value="341">BANCO ITAÚ S.A.</option>
                      <option value="336">BANCO C6 S.A.</option>
                      <option value="033">BANCO SANTANDER S.A.</option>
                      <option value="151">BANCO NOSSA CAIXA S.A.</option>
                      <option value="745">BANCO CITIBANK S.A.</option>
                      <option value="041">
                        BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                      </option>
                      <option value="756">BANCO SICOOB</option>
                      <option value="748">BANCO SICREDI</option>
                      <option value="085">
                        COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                      </option>
                      <option value="399">HSBC BANK BRASIL S.A</option>
                      <option value="756">
                        BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                      </option>
                      <option value="041">BANRISUL</option>
                      <option value="212">BANCO ORIGINAL</option>
                      <option value="260">NU BANK PAGAMENTOS S.A.</option>
                      <option value="318">BANCO BMG S.A.</option>
                      <option value="336">BANCO C6 S.A. (C6 BANK)</option>
                      <option value="077">BANCO INTER S.A.</option>
                    </select>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group">
                    <label htmlFor="agency">Agência</label>
                    <input
                      value={dataUpdate.numberAgency}
                      id="agency"
                      name="agency"
                      className="form-control"
                      placeholder="Informe o nome da Indicação"
                      onChange={(ev) => {
                        setDataUpdate({
                          ...dataUpdate,
                          numberAgency: ev.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group">
                    <label htmlFor="numberAccount">
                      Número da conta com digito
                    </label>
                    <input
                      value={dataUpdate.numberAccount}
                      id="numberAccount"
                      name="numberAccount"
                      className="form-control"
                      placeholder="Informe o nome da Indicação"
                      onChange={(ev) => {
                        setDataUpdate({
                          ...dataUpdate,
                          numberAccount: ev.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="typeAccount">Tipo de conta</label>
                    <select
                      value={dataUpdate.typeAccount}
                      id="typeAccount"
                      name="typeAccount"
                      className="form-control"
                      onChange={(ev) => {
                        setDataUpdate({
                          ...dataUpdate,
                          typeAccount: ev.target.value,
                        });
                      }}
                    >
                      <option value="">SELECIONE</option>
                      <option value="1">CONTA CORRENTE</option>
                      <option value="2">POUPANÇA</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4">
            <h3>PIX</h3>
            <div className="bg-secondary p-3">
              <Row>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="pix">Chave PIX</label>
                    <input
                      value={dataUpdate.pix}
                      id="pix"
                      name="pix"
                      className="form-control"
                      placeholder="Informe sua chave PIX, e-mail, telefone, cpf, cnpj ou aleatória"
                      onChange={(ev) => {
                        setDataUpdate({
                          ...dataUpdate,
                          pix: ev.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handleUpdate}>
            ALTERAR
          </Button>
          <Button color="secondary" onClick={closeModal}>
            SAIR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DetailsIndication;
