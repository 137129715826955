import Header from 'components/Headers/Header.js';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Title } from 'views/Dashboard/styles';
import NotificationMessageComponent from './Components/NotificationMessageComponent';
import { showCredentialExternalService } from 'services/Automation/AutomationMessageService';
import { useEffect } from 'react';
import { useState } from 'react';
import { saveCredentialExternalService } from 'services/Automation/AutomationMessageService';
import Swal from 'sweetalert2';

const NotificationView = () => {
  const [credential, setCredential] = useState({
    name: 'IZICHAT',
    login: null,
    password: null,
    active: 0,
  });
  const [automation, setAutomation] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);

  const handleShowCredential = async () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const result = await showCredentialExternalService('IZICHAT');
    if (result && result?.status === true) {
      setCredential(result.resultSet);
    }
    Swal.close();
  };

  const handleSave = async () => {
    Swal.fire({
      title: 'Salvando registro',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const result = await saveCredentialExternalService({
      ...credential,
      name: 'IZICHAT',
    });

    if (result && result?.status === true) {
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Credenciais salvas com sucesso!',
      });
    }
  };

  const handleSaveAutomation = (service = null) => {
    setAutomation(service);
    setModalMessage(true);
  };

  useEffect(() => {
    handleShowCredential();
  }, []);

  return (
    <>
      <Header titlePage="PRINCIPAL - SIMULAÇÕES FGTS" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="far fa-envelope" /> Notificações
            </h2>
          </CardHeader>
          <CardBody>
            <Card className="shadow">
              <CardHeader>
                <Title>Credênciais de API IZICHAT</Title>
              </CardHeader>
              <CardBody>
                <Alert color="secondary">
                  <p className="m-0">
                    <i className="fa-solid fa-circle-info" /> Para utilizar esta
                    funcionalidade, é necessário ter acesso à ferramenta
                    Izichat. Entre em contato com seu gerente comercial da
                    Izidev ou com o suporte para mais informações.
                  </p>
                </Alert>
                <div>
                  <Label for="status">
                    Status <span className="text-danger">*</span>
                  </Label>
                  <div>
                    <Label className="switch">
                      <Input
                        id="status"
                        type="checkbox"
                        checked={credential.active}
                        onChange={(ev) => {
                          setCredential({
                            ...credential,
                            active: ev.target.checked,
                          });
                        }}
                      />
                      <span className="slider round" />
                    </Label>
                  </div>
                </div>
                <Row>
                  <Col>
                    <Label>ApiKey</Label>
                    <Input
                      type="text"
                      value={credential.login}
                      onChange={(ev) => {
                        setCredential({
                          ...credential,
                          login: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <Label>Token</Label>
                    <Input
                      type="text"
                      value={credential.password}
                      onChange={(ev) => {
                        setCredential({
                          ...credential,
                          password: ev.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={handleSave}>
                  Salvar
                </Button>
              </CardFooter>
            </Card>

            <Row>
              <Col md="6">
                <Card className="mt-4 shadow bg-info">
                  <CardHeader>
                    <Title>
                      <i className="fas fa-plus" /> Nova Proposta
                    </Title>
                  </CardHeader>
                  <CardBody>
                    <Alert color="secondary">
                      Será enviado uma notificação para seu cliente sempre que
                      houver uma proposta gerada.
                    </Alert>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="secondary"
                      className="w-100"
                      onClick={() => handleSaveAutomation('NEW_PROPOSTAL')}
                    >
                      <i className="fas fa-cog" /> Configurar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mt-4 shadow bg-secondary">
                  <CardHeader>
                    <Title>
                      <i className="fas fa-link" /> Link de Formalização
                    </Title>
                  </CardHeader>
                  <CardBody>
                    <Alert color="secondary">
                      Será enviado uma notificação o Link de formalização da
                      proposta, quando houver disponível
                    </Alert>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="secondary"
                      className="w-100"
                      onClick={() => handleSaveAutomation('LINK_FORMALIZATION')}
                    >
                      <i className="fas fa-cog" /> Configurar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mt-4 shadow bg-warning">
                  <CardHeader>
                    <Title>
                      <i className="fas fa-link" /> Proposta Cancelada
                    </Title>
                  </CardHeader>
                  <CardBody>
                    <Alert color="secondary">
                      Será enviado uma notificação ao cliente caso a proposta
                      seja cancelada ou reprovada
                    </Alert>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="secondary"
                      className="w-100"
                      onClick={() => handleSaveAutomation('CANCEL_PROPOSAL')}
                    >
                      <i className="fas fa-cog" /> Configurar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mt-4 shadow bg-success">
                  <CardHeader>
                    <Title>
                      <i className="fas fa-link" /> Proposta Paga
                    </Title>
                  </CardHeader>
                  <CardBody>
                    <Alert color="secondary">
                      Será enviada uma notificação ao cliente quando a proposta
                      estiver com status Pago
                    </Alert>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="secondary"
                      className="w-100"
                      onClick={() => handleSaveAutomation('FINISH_PROPOSAL')}
                    >
                      <i className="fas fa-cog" /> Configurar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <NotificationMessageComponent
        automation={automation}
        isOpen={modalMessage}
        setIsOpen={setModalMessage}
      />
    </>
  );
};

export default NotificationView;
