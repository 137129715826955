import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Row,
  Col
} from 'reactstrap';
import { insert, update } from 'services/AccessBank/AccessBankService';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import { maskDocument } from 'utils/mask';
import { statesList } from 'utils/stateList';

const RegisterAccessBank = (props) => {
  const {
    className,
    isModal,
    state,
    list,
    handleSteps,
    accessBank,
    setAccessBank
  } = props;

  const [modal, setModal] = useState(false);
  const [bank, setBank] = useState('626');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [, setTypeId] = useState(1);
  const [isBlocked, setIsBlocked] = useState(true);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [codUserDigitation, setCodUserDigitation] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [stateAccess, setStateAccess] = useState('AC');

  const handleSetValue = async (event) => {
    if (event.target.id === 'bank') {
      setBank(event.target.value);
      handleChangeFebrabanBank(event.target.value);
    }
    if (event.target.id === 'loginBank') {
      setLogin(event.target.value);
    }
    if (event.target.id === 'passwordBank') {
      setPassword(event.target.value);
    }
    if (event.target.id === 'cpf') {
      setCpf(maskDocument(event.target.value));
    }
    if (event.target.id === 'name') {
      setName(event.target.value);
    }
    if (event.target.id === 'codUserDigitation') {
      setCodUserDigitation(event.target.value);
    }
    if (event.target.id === 'clientId') {
      setClientId(event.target.value);
    }
    if (event.target.id === 'clientSecret') {
      setClientSecret(event.target.value);
    }
    if (event.target.id === 'stateAccess') {
      setStateAccess(event.target.value);
    }

    setIsBlocked(true);
    if (
      bank !== '' &&
      login !== '' &&
      password !== '' &&
      name !== '' &&
      cpf !== '' &&
      (bank === '626' || bank === '074' || bank === '149') &&
      codUserDigitation !== ''
    ) {
      setIsBlocked(false);
    }

    if (
      bank !== '' &&
      login !== '' &&
      password !== '' &&
      name !== '' &&
      cpf !== '' &&
      bank === '623' &&
      clientId !== '' &&
      clientSecret !== ''
    ) {
      setIsBlocked(false);
    }

    if (
      bank !== '' &&
      login !== '' &&
      password !== '' &&
      name !== '' &&
      cpf !== '' &&
      bank === '389' &&
      stateAccess !== '' &&
      codUserDigitation !== ''
    ) {
      setIsBlocked(false);
    }
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Inserindo registro',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setIsBlocked(true);
    await insert({
      febraban: bank,
      login: login,
      password: password,
      service: 2,
      apiTypeId: 1,
      name: name,
      cpf: cpf,
      codUserDigitation: codUserDigitation,
      token: clientSecret,
      apiKey: clientId,
      ufUserDigitation: stateAccess
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Parabens!',
          text: `O registro foi inserido com sucesso`
        });

        if (handleSteps) {
          closeModal();
          handleSteps(null);
        } else {
          setIsBlocked(false);
          list();
          handleToggle();
          setLogin('');
          setPassword('');
          setName('');
          setCpf('');
          setCodUserDigitation('');
          setClientId('');
          setClientSecret('');
          setTypeId(1);
        }

        if (handleSteps) {
          closeModal();
          handleSteps(4);
        } else {
          setIsBlocked(false);
          list();
          handleToggle();
          setLogin('');
          setPassword('');
          setName('');
          setCpf('');
          setCodUserDigitation('');
          setClientId('');
          setClientSecret('');
          setTypeId(1);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSubmitUpdate = async () => {
    Swal.fire({
      title: 'Atualizando registro',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    update({
      id: accessBank.id,
      febraban: bank,
      login: login,
      password: password,
      service: 2,
      apiTypeId: 1,
      name: name,
      cpf: cpf,
      codUserDigitation: codUserDigitation,
      token: clientSecret,
      apiKey: clientId,
      ufUserDigitation: stateAccess
    })
      .then(() => {
        setIsBlocked(false);
        handleToggle();
        setLogin('');
        setPassword('');
        setName('');
        setCpf('');
        setCodUserDigitation('');
        setClientId('');
        setClientSecret('');
        setTypeId(1);
        setAccessBank(null);

        Swal.fire({
          icon: 'success',
          title: 'Parabens!',
          text: `O registro foi atualizado com sucesso`
        }).then(() => {
          list();
          closeModal();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleChangeFebrabanBank = (value) => {
    switch (value) {
      case '074':
        return (
          <Alert color="warning">
            <i className="fas fa-question-circle" /> Integração{' '}
            <strong>OFICIAL API SAFRA</strong>, por favor informar dados de
            acesso a API do banco Safra
          </Alert>
        );
      case '149':
        return (
          <Alert color="warning">
            <i className="fas fa-question-circle" /> Integração{' '}
            <strong>OFICIAL API FACTA FINANCEIRA</strong>, por favor informar
            dados de acesso a API do banco Facta
          </Alert>
        );
      case '389':
        return (
          <Alert color="warning">
            <i className="fas fa-question-circle" /> Integração{' '}
            <strong>OFICIAL API DO MERCANTIL</strong>, por favor informar dados
            de acesso a API do banco Mercantil
          </Alert>
        );
    }
    return <></>;
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setAccessBank({});
    setIsBlocked(false);
    list();
    handleToggle();
    setLogin('');
    setPassword('');
    setName('');
    setCpf('');
    setCodUserDigitation('');
    setClientId('');
    setClientSecret('');
    setTypeId(1);
    setModal(false);
    state(false);
  };

  useEffect(() => {
    setBank('626');
    setModal(isModal);

    if (accessBank && accessBank.id) {
      setBank(accessBank.febrabanCode);
      setLogin(accessBank.login);
      setPassword('');
      setTypeId(1);
      setName(accessBank.nameUser);
      setCpf(maskCPF(accessBank.documentUser));
      setCodUserDigitation(accessBank.idBankUser);
      setClientId(accessBank.apiKey);
      setClientSecret(accessBank.token);
      setStateAccess('AC');
    }
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop={handleSteps ? 'static' : true}
        size="lg"
      >
        <ModalHeader toggle={closeModal}>Inserir Registro</ModalHeader>
        <ModalBody>
          <Alert color="success">
            <i className="fas fa-question-circle" /> Preencha o formulário
            abaixo com os <strong>dados do banco</strong>.<br />
            <i className="fas fa-question-circle" /> Todas as integrações são{' '}
            <strong>OFICIAIS</strong> a partir de <strong>APIs</strong> plugadas
            diretamente com os bancos.
          </Alert>
          {handleChangeFebrabanBank(bank)}

          {bank !== '626' ? (
            <Alert color="danger">
              <i className="fas fa-question-circle" />{' '}
              <strong>
                ATENÇÃO: NÃO UTILIZAR LOGIN E SENHA COMUM!!! UTILIZAR OS DADOS
                DE ACESSO DE API! CASO NÃO POSSUA ESTES DADOS, ENTRE EM CONTATO
                COM SEU COMERCIAL OU BANCO PARA SOLICITAR!
              </strong>
            </Alert>
          ) : (
            <></>
          )}

          <div className="form-group">
            <label htmlFor="bank">
              Banco <span className="badge badge-info">Obrigatório</span>
            </label>
            <select
              id="bank"
              className="form-control"
              onChange={handleSetValue}
              onBlur={handleSetValue}
              value={bank}
              disabled={accessBank && accessBank.id ? true : false}
            >
              <option value="626">C6 Consignado</option>
              <option value="149">FACTA Financeira</option>
              <option value="623">PAN</option>
              <option value="074">SAFRA</option>
              <option value="389">BANCO MERCANTIL</option>
            </select>
          </div>
          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="clientId">
                  {bank == '626'
                    ? 'Login do banco'
                    : bank == '389'
                    ? 'ClientId'
                    : 'Login da API'}{' '}
                  <span className="badge badge-info">Obrigatório</span>
                </label>
                <input
                  id="loginBank"
                  name="loginBank"
                  className="form-control"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                  value={login}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="passwordBank">
                  {bank == '626'
                    ? 'Senha do banco'
                    : bank == '389'
                    ? 'ClientSecret'
                    : 'Senha da API'}{' '}
                  <span className="badge badge-info">Obrigatório</span>
                </label>
                <input
                  id="passwordBank"
                  name="passwordBank"
                  className="form-control"
                  type="password"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
          </Row>
          <hr />
          <h4>Dados do usuário digitador - Certificado</h4>
          <div className="form-group">
            <label htmlFor="name">
              Nome <span className="badge badge-info">Obrigatório</span>
            </label>
            <input
              id="name"
              name="name"
              className="form-control"
              onChange={handleSetValue}
              onBlur={handleSetValue}
              value={name}
            />
          </div>
          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="cpf">
                  CPF <span className="badge badge-info">Obrigatório</span>
                </label>
                <input
                  id="cpf"
                  name="cpf"
                  className="form-control"
                  value={cpf}
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="codUserDigitation">
                  {bank === '149'
                    ? 'Login Certificado Facta'
                    : 'Cod. Usuario digitador'}
                  {bank === '389' ? (
                    <span className="badge badge-info">Obrigatório</span>
                  ) : (
                    <></>
                  )}
                  {bank === '149' ? (
                    <span className="badge badge-info">Obrigatório</span>
                  ) : (
                    <></>
                  )}
                  {bank === '626' ? (
                    <span className="badge badge-info">Obrigatório</span>
                  ) : (
                    <></>
                  )}
                </label>
                <input
                  id="codUserDigitation"
                  name="codUserDigitation"
                  className="form-control"
                  autoComplete="off"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                  value={codUserDigitation}
                />
              </div>
            </Col>
          </Row>
          {bank === '623' ? (
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="clientId">
                    ClientId{' '}
                    <span className="badge badge-info">Obrigatório</span>
                  </label>
                  <input
                    id="clientId"
                    name="clientId"
                    className="form-control"
                    value={clientId}
                    onChange={handleSetValue}
                    onBlur={handleSetValue}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="clientSecret">
                    ClientSecret{' '}
                    <span className="badge badge-info">Obrigatório</span>
                  </label>
                  <input
                    id="clientSecret"
                    name="clientSecret"
                    className="form-control"
                    value={clientSecret}
                    onChange={handleSetValue}
                    onBlur={handleSetValue}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {bank === '389' ? (
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="stateAccess">
                    Estado Usuario Digitador{' '}
                    <span className="badge badge-info">Obrigatório</span>
                  </label>
                  <select
                    id="stateAccess"
                    className="form-control"
                    value={stateAccess}
                    onChange={handleSetValue}
                    onBlur={handleSetValue}
                  >
                    {statesList.map((result) => (
                      <option key={result.label} value={result.value}>
                        {result.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter>
          {accessBank && accessBank.id ? (
            <Button color="primary" onClick={handleSubmitUpdate}>
              ALTERAR
            </Button>
          ) : (
            <Button color="primary" disabled={isBlocked} onClick={handleSubmit}>
              SALVAR
            </Button>
          )}

          {handleSteps ? (
            <></>
          ) : (
            <Button color="secondary" onClick={closeModal}>
              SAIR
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RegisterAccessBank;
