import api from 'services/api';
import apiExternal from 'services/apiExternal';
// import Swal from "sweetalert2";

/**
 * @param {name : null, email : null, password : null, corporativeName : null, document : null, cep : null, address : null, district : null, city : null, number :null, complement : null, contactMovelPhone :null, contactEmail : null, phone : null, uf : null} data
 * @returns
 */
export const createPromoter = async (
  data = {
    name: null,
    email: null,
    password: null,
    corporativeName: null,
    document: null,
    cep: null,
    address: null,
    district: null,
    city: null,
    number: null,
    complement: null,
    contactMovelPhone: null,
    contactEmail: null,
    phone: null,
    uf: null
  }
) => {
  return api.post('v1/promoter', data);
};

export const getPromoter = async () => {
  return await api.get('v1/promoter');
};

export const updateSimple = async (data) => {
  return await api.put('v1/promoter/simple', data);
};

export const updateStep = async (step) => {
  return await api.put('v1/promoter/step', { step });
};

export const getStyles = async () => {
  return await api.get('v1/styles');
};

export const updateStyles = async ({
  logo,
  bgColorPrimary,
  bgColorSecondary,
  colorPrimary,
  tagManager,
  imagePeople
}) => {
  return await api.put('v1/styles', {
    logo,
    bgColorPrimary,
    bgColorSecondary,
    colorPrimary,
    tagManager,
    imagePeople
  });
};

export const showLicense = async () => {
  return await api.get('v2/license');
};

export const promoterStyles = async (nameSlug) => {
  return await apiExternal.get(`v1/promoter-styles/${nameSlug}`);
};
