import api from 'services/api';

const indexScheduleUserService = () => {
  return api.get('v2/schedule-user');
};

const indexScheduleUserTodayService = () => {
  return api.get('v2/schedule-user/today');
};

const storeScheduleUserService = ({
  title,
  description,
  color,
  display,
  date,
  userId
}) => {
  return api.post('v2/schedule-user', {
    title,
    description,
    color,
    display,
    date,
    userId
  });
};

export {
  indexScheduleUserService,
  storeScheduleUserService,
  indexScheduleUserTodayService
};
