import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  ModalFooter,
  Button
} from 'reactstrap';
import { getPromoter, updateSimple } from 'services/Promoter/PromoterService';
import PreferenceBank from '../../Profile/component/preferenceBank';
import Swal from 'sweetalert2';

const ModalStep1 = (props) => {
  const { className, isModal, state, handleSteps } = props;

  const [modal, setModal] = useState(false);
  const [dataPromoter, setDataPromoter] = useState({});
  const [contactEmail, setContactEmail] = useState(null);
  const [contactMovelPhone, setContactMovelPhone] = useState(null);
  const [preferenceBank, setPreferenceBank] = useState(null);

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const promoter = async () => {
    await getPromoter().then((res) => {
      setDataPromoter(res.data.resultSet);
      setContactEmail(res.data.resultSet.contactEmail);
      setContactMovelPhone(res.data.resultSet.contactMovelPhone);
      setPreferenceBank(res.data.resultSet.preferenceBank);
    });
  };

  const handleChange = async (event) => {
    if (event.target.name === 'contactEmail') {
      setContactEmail(event.target.value);
    }
    if (event.target.name === 'contactMovelPhone') {
      setContactMovelPhone(event.target.value);
    }
    if (event.target.name === 'preferenceBank') {
      setPreferenceBank(event.target.value);
    }
  };

  const handleSubmit = async () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta alteração?',
      showCancelButton: true,
      cancelButtonText: 'NÃO, SAIR',
      confirmButtonText: 'SIM, SALVAR'
    }).then((res) => {
      if (!res.isConfirmed) return;

      Swal.fire({
        title: 'Alterando informações',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      updateSimple({
        contactEmail,
        contactMovelPhone,
        preferenceBank
      })
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Atualização realizada',
            icon: 'success'
          });
          closeModal();
          handleSteps(2);
        })
        .catch(() => {
          Swal.fire({
            title: 'Ops, ocorreu um erro',
            text: 'Ops ocorreu um erro',
            icon: 'error'
          });
        });
    });
  };

  useEffect(() => {
    setModal(isModal);
    promoter();
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>Informações da conta</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Form>
                <div>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Username"
                              type="text"
                              value={dataPromoter.name}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              E-mail
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              value={dataPromoter.email}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Razão social
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              value={dataPromoter.corporativeName}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              CNPJ
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              value={dataPromoter.document}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              E-mail de Suporte
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="E-mail para contato do cliente"
                              type="email"
                              name="contactEmail"
                              value={contactEmail}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Telefone WhatsApp de suporte
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="Telefone Whatsapp para contado do cliente"
                              type="text"
                              name="contactMovelPhone"
                              value={contactMovelPhone}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PreferenceBank
                            preferenceBank={preferenceBank}
                            handleChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button onClick={handleSubmit} color="success">
            Salvar alterações
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalStep1;
