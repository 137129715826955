import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import { updateTableRuleGrandino } from 'services/Comission/TableComissionGrandinoBank';
import { listAllTableRuleGrandino } from 'services/Comission/TableComissionGrandinoBank';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';

const TableComissionTransferView = () => {
  const [stage, setStage] = useState('list');
  const [registers, setRegisters] = useState([]);
  const [tableRule, setTableRule] = useState({});
  const tableEnum = {
    1: 'PLATINUM',
    2: 'ONYX',
    3: 'TOPAZIO',
    4: 'SAFIRA'
  };
  const typeEnum = {
    tac: 'TAC',
    spread: 'AGIL'
  };

  const handleList = () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listAllTableRuleGrandino()
      .then(({ data }) => {
        Swal.close();
        setRegisters(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSave = () => {
    Swal.fire({
      title: 'Salvando, Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    updateTableRuleGrandino(tableRule)
      .then(() => {
        Swal.close();
        handleList();
        setStage('list');
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleEdit = (data) => {
    setTableRule({
      ...data,
      valueStart: moneyMask(data.valueStart),
      valueEnd: moneyMask(data.valueEnd),
      amount: moneyMask(data.amount)
    });
    setStage('form');
  };

  const handleClose = () => {
    handleList();
    setStage('list');
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="FGTS: Proposta FGTS" />
      <Container className="mt--9" fluid>
        {stage === 'list' && (
          <Card className="shadow">
            <CardHeader>Regras de repasse</CardHeader>
            <Table className="align-items-center table-flush table-striped">
              <thead>
                <tr className="thead-light">
                  <th>Tabela</th>
                  <th>Vlr. Inicial</th>
                  <th>Vlr. Final</th>
                  <th>Repasse</th>
                  <th>Tipo</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {registers.map((register, key) => (
                  <tr key={key}>
                    <td>
                      <strong className="font-14 font-weight-bold">
                        {register.tableComissionId} -{' '}
                        {tableEnum[register.tableComissionId]}
                      </strong>
                    </td>
                    <td>R$ {moneyMask(register.valueStart)}</td>
                    <td>R$ {moneyMask(register.valueEnd)}</td>
                    <td>{moneyMask(register.amount)} %</td>
                    <td>
                      <Badge
                        color={
                          typeEnum[register.type] == 'TAC' ? 'info' : 'success'
                        }
                        className="font-12"
                      >
                        {typeEnum[register.type]}
                      </Badge>
                    </td>
                    <td className="text-right">
                      <Button
                        color="warning"
                        onClick={() => handleEdit(register)}
                      >
                        Editar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}

        {stage === 'form' && (
          <Card className="shadow">
            <CardHeader>Editar</CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label>Tabela</Label>
                    <select
                      className="form-control"
                      value={tableRule.tableComissionId}
                      onChange={(ev) => {
                        setTableRule({
                          ...tableRule,
                          tableComissionId: ev.target.value
                        });
                      }}
                    >
                      {/* <option value={1}>PLATINUM</option>
                      <option value={2}>ONYX</option>
                      <option value={3}>TOPAZIO</option> */}
                      <option value={4}>SAFIRA</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Valor Inicial</Label>
                    <Input
                      placeholder="0,00"
                      value={tableRule.valueStart}
                      onChange={(ev) => {
                        setTableRule({
                          ...tableRule,
                          valueStart: moneyMask(ev.target.value)
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Valor Final</Label>
                    <Input
                      placeholder="0,00"
                      value={tableRule.valueEnd}
                      onChange={(ev) => {
                        setTableRule({
                          ...tableRule,
                          valueEnd: moneyMask(ev.target.value)
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Repasse</Label>
                    <Input
                      placeholder="0"
                      value={tableRule.amount}
                      onChange={(ev) => {
                        setTableRule({
                          ...tableRule,
                          amount: moneyMask(ev.target.value)
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Tipo</Label>
                    <select
                      className="form-control"
                      value={tableRule.type}
                      onChange={(ev) => {
                        setTableRule({
                          ...tableRule,
                          type: ev.target.value
                        });
                      }}
                    >
                      <option value="tac">TAC</option>
                      <option value="spread">AGIL</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Card className="shadow">
                <CardHeader>Informações Bancárias Transferência</CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <Label>Razão Social</Label>
                        <Input
                          value={tableRule.nameAccount}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              nameAccount: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Código Financeira</Label>
                        <Input
                          value={tableRule.bankCode}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              bankCode: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Agencia</Label>
                        <Input
                          value={tableRule.agencyAccount}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              agencyAccount: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Nº Conta</Label>
                        <Input
                          value={tableRule.accountNumber}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              accountNumber: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Digito</Label>
                        <Input
                          value={tableRule.digitAccount}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              digitAccount: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label>Documento</Label>
                        <Input
                          value={tableRule.documentNumber}
                          onChange={(ev) => {
                            setTableRule({
                              ...tableRule,
                              documentNumber: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
            <CardFooter>
              <Button color="success" onClick={handleSave}>
                Salvar
              </Button>
              <Button color="danger" onClick={handleClose}>
                Sair
              </Button>
            </CardFooter>
          </Card>
        )}
      </Container>
    </>
  );
};

export default TableComissionTransferView;
