import {
  Alert,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { moneyMask } from 'utils/mask';

const AccessBankPresencaBankForm = ({ febrabanId, data, setData }) => {
  if (!febrabanId || febrabanId !== '531') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas FGTS
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Acompanhamento de Propostas
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Link de formalização
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar - FGTS</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Valor Mínimo{' '}
                <i
                  className="fas fa-question-circle"
                  id="minValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.minValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    minValueLibertyFgts: moneyMask(ev.target.value),
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada a
                partir do valor informado. Caso o valor liberado do FGTS esteja
                abaixo do valor informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Valor Máximo{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value),
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Simulador MultiBancos</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useMultBankFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useMultBankFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankPresencaBankForm;
