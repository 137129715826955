import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Tooltip,
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
import PaginationSelector from './PaginationSelector';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import { phoneMask } from 'utils/mask';
import SampleModalView from 'components/Modals/SampleModalView';
import { useState } from 'react';
import { WhatsAppLink } from '../styles';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';

import '../style/style.scss';

const CardSimulationDashboardComponent = ({
  toggleShowList = 1,
  listData = [],
  setDataSimule = () => {},
  openModalDigitationFGTS = () => {},
  pages,
  handleList = () => {},
  setPage = () => {},
  page,
  reSendLinkMercantil = () => {},
  cancelProposalICred = () => {},
  reSendProposalICred = () => {},
  getLinkFormalize = () => {},
  openModalNote = () => {},
  handleTakeAct = () => {},
  setToggleScheduleComponent = () => {},
  toggleScheduleComponent = null,
  setScheduleDocumentClient = () => {},
  openModalInfo = () => {},
  setTagDataModal = () => {},
  setResendPayment = () => {},
  resendPayment = null,
  setSendSms = () => {},
  sendSms = null,
}) => {
  const history = useHistory();
  const [modalResultSimulation, setModalResultSimulation] = useState(false);
  const [resultSimulation, setResultSimulation] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState([]);

  const handleSelectColorStatus = (status) => {
    switch (status) {
      case 'SIMULAÇÃO NÃO FINALIZADA':
        return 'warning';
      case 'DIGITAÇÃO REALIZADA':
        return 'success';
    }
    return 'primary';
  };

  const toggleTooltip = (id) => {
    setTooltipOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      [id]: !prevTooltipOpen[id],
    }));
  };

  if (toggleShowList > 0) {
    return <></>;
  }

  return (
    <>
      <Card className="mt-3 mb-3">
        <CardBody>
          <Button
            color="primary"
            onClick={() => {
              history.push('/admin/fgts/operation');
            }}
          >
            <i className="far fa-file-alt" /> DIGITAR FGTS{' '}
            <Badge color="success">NOVO</Badge>
          </Button>
          <Button
            className="btn btn-success btn-md btn-lg-md mt-sm-0 mt-2"
            onClick={() => {
              setDataSimule({});
              openModalDigitationFGTS();
            }}
          >
            <i className="far fa-file-alt" /> DIGITAR FGTS
          </Button>
        </CardBody>
      </Card>
      {listData.length > 0 && (
        <>
          <PaginationSelector
            pages={pages}
            list={handleList}
            setPage={setPage}
            page={page}
          />
          <Row className="mt-4">
            {listData.map((register, index) => {
              const statusBankTooltipId = `statusBank-${register.id}-${index}`;
              const febrabanTooltipId = `febraban-${register.id}-${index}`;
              const nameTooltipId = `name-${register.id}-${index}`;
              const proposalTooltipId = `proposal-${register.id}-${index}`;
              const codeIndicationTooltipId = `codeIndication-${register.id}-${index}`;
              const localeSimulationTooltip = `localeSimulation-${register.id}-${index}`;
              const statusTooltip = `status-${register.id}-${index}`;
              const nameIndicationTooltip = `nameIndication-${register.id}-${index}`;

              return (
                <Col
                  md="4"
                  className="d-flex align-items-stretch"
                  key={register.id}
                >
                  <Card className="shadow mb-4 w-100">
                    <CardBody>
                      {register?.messageReturnService && (
                        <Alert color="warning">
                          {register?.messageReturnService}
                        </Alert>
                      )}
                      <div className="mb-2">
                        <Badge
                          id={localeSimulationTooltip}
                          color="secondary"
                          className="font-12 w-100 overflow-none"
                          onMouseEnter={() =>
                            toggleTooltip(localeSimulationTooltip)
                          }
                          onMouseLeave={() =>
                            toggleTooltip(localeSimulationTooltip)
                          }
                        >
                          {register.localeSimulation}
                        </Badge>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[localeSimulationTooltip] || false}
                          target={localeSimulationTooltip}
                          toggle={() => toggleTooltip(localeSimulationTooltip)}
                        >
                          {register.localeSimulation}
                        </Tooltip>
                        <Badge
                          id={statusTooltip}
                          className="font-12 mt-2 w-100 overflow-none"
                          color={handleSelectColorStatus(register.status)}
                          onMouseEnter={() => toggleTooltip(statusTooltip)}
                          onMouseLeave={() => toggleTooltip(statusTooltip)}
                        >
                          {register.status}
                        </Badge>

                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[statusTooltip] || false}
                          target={statusTooltip}
                          toggle={() => toggleTooltip(statusTooltip)}
                        >
                          {register.status}
                        </Tooltip>
                        <Badge
                          id={statusBankTooltipId}
                          className="font-12 mt-2 w-100 overflow-none"
                          color="secondary"
                          onMouseEnter={() =>
                            toggleTooltip(statusBankTooltipId)
                          }
                          onMouseLeave={() =>
                            toggleTooltip(statusBankTooltipId)
                          }
                        >
                          {register.statusBank}
                        </Badge>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[statusBankTooltipId] || false}
                          target={statusBankTooltipId}
                          toggle={() => toggleTooltip(statusBankTooltipId)}
                        >
                          {register.statusBank}
                        </Tooltip>
                      </div>

                      {register.febrabanId != '999' && (
                        <>
                          {BankSelectData(register.febrabanId)?.img && (
                            <img
                              src={BankSelectData(register.febrabanId).img}
                              alt={register.nameBank}
                              width="40%"
                            />
                          )}

                          <p
                            className="m-0 overflow-none"
                            id={febrabanTooltipId}
                            onMouseEnter={() =>
                              toggleTooltip(febrabanTooltipId)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(febrabanTooltipId)
                            }
                          >
                            <strong>{register.nameBank}</strong>
                          </p>

                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[febrabanTooltipId] || false}
                            target={febrabanTooltipId}
                            toggle={() => toggleTooltip(febrabanTooltipId)}
                          >
                            {register.nameBank}
                          </Tooltip>
                        </>
                      )}

                      <h3
                        className="mt-3 mb-0  overflow-none"
                        color="secondary"
                        id={nameTooltipId}
                        onMouseEnter={() => toggleTooltip(nameTooltipId)}
                        onMouseLeave={() => toggleTooltip(nameTooltipId)}
                      >
                        {register.name}
                      </h3>

                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[nameTooltipId] || false}
                        target={nameTooltipId}
                        toggle={() => toggleTooltip(nameTooltipId)}
                      >
                        {register.name}
                      </Tooltip>

                      {register.numberProposal && (
                        <>
                          <p
                            className="m-0 overflow-none"
                            id={proposalTooltipId}
                            onMouseEnter={() =>
                              toggleTooltip(proposalTooltipId)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(proposalTooltipId)
                            }
                          >
                            Proposta: <strong>{register.numberProposal}</strong>
                          </p>

                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[proposalTooltipId] || false}
                            target={proposalTooltipId}
                            toggle={() => toggleTooltip(proposalTooltipId)}
                          >
                            {register.numberProposal}
                          </Tooltip>
                        </>
                      )}
                      <p className="m-0">
                        Vlr Liberado:{' '}
                        <strong>
                          R$ {moneyMask(register?.valueLiberty ?? 0.0)}
                        </strong>
                      </p>
                      <p className="m-0">
                        Vlr Total:{' '}
                        <strong>
                          R$ {moneyMask(register?.valueFinally ?? 0.0)}
                        </strong>
                      </p>
                      <p className="m-0">
                        CPF: <strong>{maskCPF(register.cpf)}</strong>
                      </p>
                      <p className="m-0">
                        WhatsApp:{' '}
                        <strong>
                          {phoneMask(register.whatsappNumber)}{' '}
                          <WhatsAppLink
                            className="btn pl-1 pr-1 btn-success"
                            href={register.urlWhatsapp}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-whatsapp" />
                          </WhatsAppLink>
                        </strong>
                      </p>
                      <p className="m-0">
                        Data simulação: <strong>{register.createdAt}</strong>
                      </p>

                      {register?.codeIndication && (
                        <Alert color="success" className="mt-2 text-white">
                          <h4 className="m-0">Indicação / Vendedor</h4>
                          <p
                            className="m-0 font-12 overflow-none"
                            id={codeIndicationTooltipId}
                            onMouseEnter={() =>
                              toggleTooltip(codeIndicationTooltipId)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(codeIndicationTooltipId)
                            }
                          >
                            Código: <strong>{register.codeIndication}</strong>
                          </p>
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[codeIndicationTooltipId] || false
                            }
                            target={codeIndicationTooltipId}
                            toggle={() =>
                              toggleTooltip(codeIndicationTooltipId)
                            }
                          >
                            {register.codeIndication}
                          </Tooltip>

                          <p
                            className="m-0 font-12 overflow-none"
                            id={nameIndicationTooltip}
                            onMouseEnter={() =>
                              toggleTooltip(nameIndicationTooltip)
                            }
                            onMouseLeave={() =>
                              toggleTooltip(nameIndicationTooltip)
                            }
                          >
                            Nome:{' '}
                            <strong className="font-weight-bold">
                              {register.nameIndication}
                            </strong>
                          </p>

                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[nameIndicationTooltip] || false}
                            target={nameIndicationTooltip}
                            toggle={() => toggleTooltip(nameIndicationTooltip)}
                          >
                            {register.nameIndication}
                          </Tooltip>
                        </Alert>
                      )}

                      {register?.multBank?.length > 0 && (
                        <Alert color="secondary">
                          <p className="m-0 font-weight-bold">
                            Resultado simulação:{' '}
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => {
                                setModalResultSimulation(true);
                                setResultSimulation(register);
                              }}
                            >
                              {register?.multBank?.length}{' '}
                              <i className="far fa-folder-open" />
                            </Button>
                          </p>
                        </Alert>
                      )}

                      <div className="mt-3">
                        <a
                          className="btn btn-none mr-0"
                          id={`detailsTooltip-${register.id}`}
                          href={`/admin/simulation-details/${register.id ?? 0}`}
                          target="_blank"
                        >
                          <i className="fas fa-tag" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`detailsTooltip-${register.id}`] ||
                              false
                            }
                            target={`detailsTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(`detailsTooltip-${register.id}`)
                            }
                          >
                            Detalhes da simulação
                          </Tooltip>
                        </a>

                        <Button
                          color="none"
                          className="mr-0 text-success"
                          id={`formalizationLinkTooltip-${register.id}`}
                          onClick={() => {
                            getLinkFormalize(
                              register.numberProposal,
                              register.febrabanId
                            );
                          }}
                        >
                          <i className="fas fa-link" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[
                                `formalizationLinkTooltip-${register.id}`
                              ] || false
                            }
                            target={`formalizationLinkTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(
                                `formalizationLinkTooltip-${register.id}`
                              )
                            }
                          >
                            Link de formalização
                          </Tooltip>
                        </Button>

                        <Button
                          color="none"
                          className="mr-0"
                          id={`scheduleTooltip-${register.id}`}
                          onClick={() => {
                            setToggleScheduleComponent(
                              !toggleScheduleComponent
                            );
                            setScheduleDocumentClient({
                              name: register.name,
                              document: register.cpf,
                            });
                          }}
                        >
                          <i className="far fa-clock" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`scheduleTooltip-${register.id}`] ||
                              false
                            }
                            target={`scheduleTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(`scheduleTooltip-${register.id}`)
                            }
                          >
                            Agendar atendimento
                          </Tooltip>
                        </Button>

                        <Button
                          color="none"
                          className="mr-0 text-info"
                          id={`simulateAgainTooltip-${register.id}`}
                          onClick={() => {
                            if (
                              register.localeSimulation ===
                              'INTERNO FGTS MULTITABELAS'
                            ) {
                              history.push(
                                `/admin/fgts/operation?cpf=${register.cpf}&birthDate=${register.birthDate}&whatsapp=${register.whatsappNumber}&name=${register.name}`
                              );
                              return;
                            }

                            handleTakeAct(register.id, register);
                          }}
                        >
                          <i className="far fa-sticky-note" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[
                                `simulateAgainTooltip-${register.id}`
                              ] || false
                            }
                            target={`simulateAgainTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(
                                `simulateAgainTooltip-${register.id}`
                              )
                            }
                          >
                            Simular novamente
                          </Tooltip>
                        </Button>

                        {register.proposalId > 0 && (
                          <Button
                            color="none"
                            className="mr-0"
                            id={`editTooltip-${register.id}`}
                            onClick={() => openModalInfo(register)}
                          >
                            <i className="fas fa-edit" />
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[`editTooltip-${register.id}`] ||
                                false
                              }
                              target={`editTooltip-${register.id}`}
                              toggle={() =>
                                toggleTooltip(`editTooltip-${register.id}`)
                              }
                            >
                              Digitar novamente
                            </Tooltip>
                          </Button>
                        )}

                        <Button
                          color="none"
                          className="mr-0"
                          id={`tagsTooltip-${register.id}`}
                          onClick={() => {
                            setTagDataModal({
                              isOpen: true,
                              clientId: register.clientId,
                            });
                          }}
                        >
                          <i className="fas fa-tags" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`tagsTooltip-${register.id}`] || false
                            }
                            target={`tagsTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(`tagsTooltip-${register.id}`)
                            }
                          >
                            Etiquetas
                          </Tooltip>
                        </Button>

                        {register.febrabanId === '329-5' && (
                          <Button
                            color="none"
                            className="mr-0 text-warning"
                            id={`resendProposalTooltip-${register.id}`}
                            onClick={() => {
                              setResendPayment({
                                ...resendPayment,
                                proposalId: register.proposalId,
                                simulationid: register.id,
                                febrabanId: '329-5',
                                isOpen: true,
                              });
                            }}
                          >
                            <i className="fas fa-retweet" />
                            <Tooltip
                              placement="top"
                              isOpen={
                                tooltipOpen[
                                  `resendProposalTooltip-${register.id}`
                                ] || false
                              }
                              target={`resendProposalTooltip-${register.id}`}
                              toggle={() =>
                                toggleTooltip(
                                  `resendProposalTooltip-${register.id}`
                                )
                              }
                            >
                              Reapresentar proposta
                            </Tooltip>
                          </Button>
                        )}

                        {register.febrabanId == '389' &&
                          register.status === 'DIGITAÇÃO REALIZADA' && (
                            <Button
                              color="none"
                              className="mr-0 text-info"
                              id={`resendSmsTooltip-${register.id}`}
                              onClick={() => reSendLinkMercantil(register.id)}
                            >
                              <i className="fas fa-window-close" />
                              <Tooltip
                                placement="top"
                                isOpen={
                                  tooltipOpen[
                                    `resendSmsTooltip-${register.id}`
                                  ] || false
                                }
                                target={`resendSmsTooltip-${register.id}`}
                                toggle={() =>
                                  toggleTooltip(
                                    `resendSmsTooltip-${register.id}`
                                  )
                                }
                              >
                                Reenviar SMS Mercantil
                              </Tooltip>
                            </Button>
                          )}

                        {register.febrabanId == '329' &&
                        register.status === 'DIGITAÇÃO REALIZADA' ? (
                          <>
                            <Button
                              color="none"
                              className="mr-0 text-danger"
                              id={`cancelProposalTooltip-${register.id}`}
                              onClick={() => cancelProposalICred(register.id)}
                            >
                              <i className="fas fa-window-close" />
                              <Tooltip
                                placement="top"
                                isOpen={
                                  tooltipOpen[
                                    `cancelProposalTooltip-${register.id}`
                                  ] || false
                                }
                                target={`cancelProposalTooltip-${register.id}`}
                                toggle={() =>
                                  toggleTooltip(
                                    `cancelProposalTooltip-${register.id}`
                                  )
                                }
                              >
                                Cancelar proposta
                              </Tooltip>
                            </Button>

                            <Button
                              color="none"
                              className="mr-0 text-info"
                              id={`resendFormalizationLinkTooltip-${register.id}`}
                              onClick={() =>
                                reSendProposalICred(
                                  register.id,
                                  register.whatsappNumber,
                                  register.numberProposal
                                )
                              }
                            >
                              <i className="fas fa-link" />
                              <Tooltip
                                placement="top"
                                isOpen={
                                  tooltipOpen[
                                    `resendFormalizationLinkTooltip-${register.id}`
                                  ] || false
                                }
                                target={`resendFormalizationLinkTooltip-${register.id}`}
                                toggle={() =>
                                  toggleTooltip(
                                    `resendFormalizationLinkTooltip-${register.id}`
                                  )
                                }
                              >
                                Reenviar link de formalização
                              </Tooltip>
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}

                        <Button
                          color="none"
                          className="mr-0"
                          id={`sendSmsTooltip-${register.id}`}
                          onClick={() => {
                            setSendSms({
                              ...sendSms,
                              openModal: true,
                              number: register.whatsappNumber,
                              message: `Olá ${register.name}`,
                            });
                          }}
                        >
                          <i className="fas fa-paper-plane" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`sendSmsTooltip-${register.id}`] ||
                              false
                            }
                            target={`sendSmsTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(`sendSmsTooltip-${register.id}`)
                            }
                          >
                            Enviar SMS
                          </Tooltip>
                        </Button>

                        <Button
                          color="none"
                          className="mr-0"
                          id={`notesTooltip-${register.id}`}
                          onClick={() => {
                            openModalNote(register);
                          }}
                        >
                          <i className="fas fa-comment-alt" />
                          <Tooltip
                            placement="top"
                            isOpen={
                              tooltipOpen[`notesTooltip-${register.id}`] ||
                              false
                            }
                            target={`notesTooltip-${register.id}`}
                            toggle={() =>
                              toggleTooltip(`notesTooltip-${register.id}`)
                            }
                          >
                            Anotações
                          </Tooltip>
                        </Button>
                      </div>

                      {register?.tags?.length > 0 && (
                        <div className="mt-3">
                          {register.tags.map((tag, tagKey) => (
                            <Badge
                              key={tagKey}
                              className={`${tag.color} mr-1 mb-1 font-12 shadow`}
                            >
                              {tag.title}
                            </Badge>
                          ))}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>

          <PaginationSelector
            pages={pages}
            list={handleList}
            setPage={setPage}
            page={page}
          />
        </>
      )}

      <SampleModalView
        labelModal="FGTS - Resultado de simulações"
        icon="far fa-window-restore"
        isOpen={modalResultSimulation}
        setIsOpen={setModalResultSimulation}
        showBtnSubmit={false}
        size="xl"
      >
        {resultSimulation.id > 0 && (
          <>
            <Card className="shadow">
              <CardHeader>
                <h3 className="m-0">Cliente</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <p className="m-0">Nome</p>
                    <p className="m-0 font-weight-bold">
                      {resultSimulation.name}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="m-0">CPF</p>
                    <p className="m-0 font-weight-bold">
                      {maskCPF(resultSimulation.cpf)}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="m-0">Data de nascimento</p>
                    <p className="m-0 font-weight-bold">
                      {resultSimulation.birthDate}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="m-0">WhatsApp</p>
                    <p className="m-0 font-weight-bold">
                      {phoneMask(resultSimulation.whatsappNumber)}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="shadow mt-3">
              <CardHeader>
                <h3 className="m-0">Resultado da simulação</h3>
              </CardHeader>
              <CardBody>
                {resultSimulation.multBank.map((multBank, key) => (
                  <Card className="shadow mb-2" key={key}>
                    <CardHeader>{multBank.bankName}</CardHeader>
                    <CardBody>
                      {multBank.error && (
                        <Alert color="warning">{multBank.error}</Alert>
                      )}

                      {multBank.totalCreditLiberty > 0 && (
                        <>
                          <p>{multBank.tableName}</p>
                          <p>
                            Vlr. Liberado:{' '}
                            <strong>{multBank.totalCreditLiberty}</strong>
                          </p>
                          <p>
                            Vlr. Total:{' '}
                            <strong>{multBank.totalCreditAccountFGTS}</strong>
                          </p>
                        </>
                      )}
                    </CardBody>
                  </Card>
                ))}
              </CardBody>
            </Card>
          </>
        )}
      </SampleModalView>
    </>
  );
};

export default CardSimulationDashboardComponent;
