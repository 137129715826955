import styled, { css } from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Card = styled.div`
  background: #ffffff;
  padding: 16px 0;
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  padding: 0 16px 16px;
`;

export const HeaderTitle = styled.h3`
  color: #32325d;
  font-weight: 600;
  font-size: 13px;

  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 17px;
  }
`;

export const CardBody = styled.div`
  background: #f7fafc;
  padding: 16px;
`;

export const BodyTitle = styled.p`
  text-transform: uppercase;
  color: #8898aa;
  font-size: 12px;
  font-weight: 600;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 600;

  @media ${device.desktopLG} {
    font-size: 11px;
  }

  @media ${device.desktopXL} {
    font-size: 13px;
  }
`;

export const ExampleCard = styled.div`
  position: relative;
  border: 1px solid #e9ecef;
  height: 298px;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
`;

export const ExampleButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 20px;
  border: none;
  background: #4c4ca9;
  color: white;
  border-radius: 4px;
  padding: 0 20px;
  height: 40px;
  cursor: pointer;

  ${({ customStyles }) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

export const CodeCard = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  background: #161b22;
  padding: 16px;

  code {
    color: #79c0ff;
  }
`;
