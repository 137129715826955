import api from 'services/api';

export const salePerPeriod = async (period) => {
  return api.get(`v1/panel/proposal/sale-per-period/${period}`);
};

export const statusPerPeriod = async (period) => {
  return api.get(`v1/panel/poposal/status-per-periodr/${period}`);
};

export const simulationGroupPerDay = async (period) => {
  return api.get(`v1/panel/simulation/group-per-day/${period}`);
};

export const panelFgts = async () => {
  return api.get(`v1/panel/fgts`);
};

export const panelFgtsService = async (data) => {
  return api.get(`v2/report/panel`, {
    params: data
  });
};
