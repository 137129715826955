import InputIndicationView from 'components/Form/Input/InputIndicationView';
import Header from 'components/Headers/Header';
import { TableRow } from 'components/Table';
import { TableBody } from 'components/Table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table
} from 'reactstrap';
import { ReportSimulationAbandonedService } from 'services/Report/Leads/SimulationAbandonedService';
import { scheduleExcel } from 'services/Report/Schedule';
import Swal from 'sweetalert2';

const SimulationAbandonedReportView = () => {
  const history = useHistory();
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    indicationId: null,
    pageActual: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [registers, setRegisters] = useState([]);

  const handleSubmit = (isMore = false) => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    setIsLoading(true);
    if (!isMore) {
      setRegisters([]);
    }

    let filterSearch = {
      ...filter
    };

    if (!isMore) {
      setFilter({
        ...filter,
        pageActual: 0
      });

      filterSearch = {
        ...filter,
        pageActual: 0
      };
    }

    ReportSimulationAbandonedService(filterSearch)
      .then(({ data }) => {
        Swal.close();
        setIsLoading(false);
        if (!data?.resultSet?.data) {
          setShowBtnMoreRegisters(false);
          return;
        }

        setFilter({
          ...filter,
          pageActual: data.resultSet.pagination
        });

        if (isMore) {
          setRegisters([...registers, ...data?.resultSet?.data]);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length > 0);
        } else {
          setRegisters(data?.resultSet?.data ?? []);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleExportCsv = (isAnalytic = 0) => {
    scheduleExcel(
      15,
      {
        ...filter,
        isAnalytic: isAnalytic
      },
      history
    );
  };

  return (
    <>
      <Header titlePage="FGTS - SIMULAÇÕES NÃO CONCLUIDAS" />
      <Container className="mt--9" fluid>
        <Alert color="secondary" className="shadow">
          <i className="fas fa-exclamation-triangle fa-3x" />
          <p className="mt-2">
            Este relatório tem como objetivo facilitar a recuperação de
            simulações realizadas no dia anterior, visando impulsionar as
            vendas. Embora todos os clientes listados tenham passado por
            simulações, eles possuíam saldo disponível e não finalizaram
            transações na izidev.{' '}
          </p>
          <p>
            Essa situação representa uma oportunidade valiosa para estimular a
            produção. Recomenda-se realizar novas simulações para os clientes e
            verificar a disponibilidade de saldo remanescente.
          </p>
          <p className="font-weight-bold">
            O relatório é renovado diariamente à meia-noite, consolidando todas
            as simulações efetuadas no dia anterior com saldos pendentes de
            conclusão.
          </p>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-piggy-bank" /> Simulações não concluídas -
              Filtro
            </h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      value={filter.dateStart}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border"
                      value={filter.dateEnd}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value
                        });
                      }}
                    />

                    <select
                      className="form-control border pl-2"
                      id="typeDate"
                      name="typeDate"
                    >
                      <option value="createdAt">DATA DE CADASTRO</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <InputIndicationView filter={filter} setFilter={setFilter} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              className="mr-2"
              onClick={() => {
                setRegisters([]);
                handleSubmit();
              }}
            >
              Buscar
            </Button>
            <Button
              color="success"
              className="mr-2"
              onClick={() => {
                handleExportCsv();
              }}
            >
              <i className="fas fa-file-csv" /> Excel Sintetico
            </Button>
            <Button
              color="success"
              className="mr-2"
              onClick={() => {
                handleExportCsv(1);
              }}
            >
              <i className="fas fa-file-csv" /> Excel Analitico
            </Button>
          </CardFooter>
        </Card>

        {isLoading && (
          <Alert color="secondary" className="text-center mt-4 border shadow">
            Carregando...
          </Alert>
        )}

        {registers.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>Registros</CardHeader>
            <Table responsive>
              <thead className="bg-secondary">
                <tr>
                  <th>DATA</th>
                  <th>CLIENTE</th>
                  <th>INDICAÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {registers.map((register, key) => (
                  <tr key={key}>
                    <td>
                      <p className="m-0">
                        Data de Cadastro: <strong>{register.createdAt}</strong>
                      </p>
                      <p className="m-0">
                        Data da Simulação:{' '}
                        <strong>{register.dateSimulation}</strong>
                      </p>
                    </td>
                    <td>
                      <p className="m-0">
                        Nome: <strong>{register.name}</strong>
                      </p>
                      <p className="m-0">
                        CPF: <strong>{register.cpf}</strong>
                      </p>
                      <p className="m-0">
                        Data de Nascimento:{' '}
                        <strong>{register.birthDate}</strong>
                      </p>
                      <p className="m-0">
                        Contato:{' '}
                        <strong className="text-primary">
                          {register.whatsappNumber}
                          {` `}
                          <a
                            className="btn btn-success btn-sm"
                            href={`https://wa.me/55${register.whatsappNumber.replace(
                              /[^0-9]/g,
                              ''
                            )}`}
                            target="_blank"
                          >
                            <i className="fab fa-whatsapp" />
                          </a>
                        </strong>
                      </p>
                    </td>
                    <td>
                      <p className="m-0">
                        Nome: <strong>{register.nameIndication}</strong>
                      </p>
                      <p className="m-0">
                        Código: <strong>{register.codeIndication}</strong>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
              {showBtnMoreRegisters && (
                <TableBody>
                  <TableRow>
                    <td colSpan={5}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          setFilter({ ...filter });
                          handleSubmit(true);
                        }}
                      >
                        Carregar mais...
                      </Button>
                    </td>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default SimulationAbandonedReportView;
