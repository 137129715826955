import { Button, Card, CardBody, CardFooter } from 'reactstrap';
import { moneyMask } from 'utils/mask';

const CardSomulationConsignedCompoenent = ({
  simulation,
  selectSimulation = () => {},
}) => {
  return (
    <>
      <Card className="shadow w-100">
        <CardBody>
          <p className="m-0">Nome da tabela:</p>
          <h2 className="m-0">
            {simulation.code} - {simulation.table}
          </h2>
          <p className="m-0 mt-2">
            Prazo: <strong>{simulation.installment} parcelas</strong>
          </p>
          <p className="m-0">
            Primeiro vencimento: <strong>{simulation.startDateExpire}</strong>
          </p>
          <p className="m-0">
            Último vencimento: <strong>{simulation.endDateExpire}</strong>
          </p>
          <p className="m-0">
            Valor contrato:{' '}
            <strong>
              R$
              {moneyMask(Number(simulation.valueFinancial).toFixed(2))}
            </strong>
          </p>
          <p className="m-0">
            IOF: <strong>{moneyMask(Number(simulation.iof).toFixed(2))}</strong>
          </p>
          <p className="m-0">
            Taxa mês:{' '}
            <strong>{moneyMask(Number(simulation.tax).toFixed(2))}</strong>
          </p>
          <p className="m-0 font-22">
            Valor cliente:{' '}
            <strong className=" text-success">
              R$ {moneyMask(Number(simulation.valueClient).toFixed(2))}
            </strong>
          </p>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            className="w-100 m-0"
            onClick={() => selectSimulation(simulation)}
          >
            <i className="fas fa-check mr-2" /> SELECIONAR
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default CardSomulationConsignedCompoenent;
