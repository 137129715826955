import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import { Badge, Card, CardHeader, Container, Table } from 'reactstrap';
import { IndexResumeContractSignService } from 'services/Register/ContractSign';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';

const ContractSignResumeView = () => {
  const [registers, setRegisters] = useState([]);

  const handleFilter = () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    IndexResumeContractSignService()
      .then(({ data }) => {
        Swal.close();
        setRegisters(data?.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleFilter();
  }, []);

  return (
    <>
      <Header titlePage="IZISIGN - Resumo geral" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>IZISIGN - Resumo geral</h2>
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <th>MÊS</th>
                <th>QTD. TOKEN</th>
                <th>QTD. ASSINATURA</th>
                <th>VALOR ESTIMADO</th>
              </tr>
            </thead>
            <tbody>
              {registers.map((register, key) => (
                <tr key={key}>
                  <td className="font-18">{register.month}</td>
                  <td className="font-18">
                    <Badge color="info" className="font-14">
                      {register.qtdTokenSend}
                    </Badge>
                  </td>
                  <td className="font-18">
                    <Badge color="success" className="font-14">
                      {register.qtd}
                    </Badge>
                  </td>
                  <td className="font-18">
                    <span className="font-weight-bold">
                      R$ {moneyMask(register.value)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default ContractSignResumeView;
