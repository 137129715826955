import { useEffect } from 'react';
import { useState } from 'react';

const {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Row,
  Col,
} = require('reactstrap');

const UserDigitationPrataConfigComponent = ({
  bank,
  handleSubmit = () => {},
}) => {
  const [userDigitation, setUserDigitation] = useState({
    login: null,
    password: null,
    bankId: null,
  });

  useEffect(() => {
    setUserDigitation({
      login: bank.userDigitation.login,
      password: bank.userDigitation.password,
      bankId: bank.id,
    });
  }, []);

  if (bank.febraban !== '329-3') return <></>;

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Login</Label>
                <Input
                  type="text"
                  placeholder=""
                  value={userDigitation.login ?? ''}
                  onChange={(ev) => {
                    setUserDigitation({
                      ...userDigitation,
                      login: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Senha</Label>
                <Input
                  type="text"
                  placeholder=""
                  value={userDigitation.password ?? ''}
                  onChange={(ev) => {
                    setUserDigitation({
                      ...userDigitation,
                      password: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={() => {
              handleSubmit(userDigitation);
            }}
          >
            SALVAR
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default UserDigitationPrataConfigComponent;
