import {
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import MyTablesComponent from '../myTablesComponent';
import { useEffect, useState } from 'react';
import MyTablesConsignedComponent from '../myTablesConsignedComponent';
import Swal from 'sweetalert2';
import { listAllTablesService } from 'services/AccessBank/AccessBankService';

const AccessBankPanForm = ({ febrabanId, data, setData }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [tables, setTables] = useState([]);

  const handleList = () => {
    listAllTablesService(febrabanId, {
      serviceId: 2,
    })
      .then(({ data }) => {
        setTables(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (!febrabanId || febrabanId !== '623') return;
    handleList();
  }, []);

  if (!febrabanId || febrabanId !== '623') return <></>;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados de acesso - Pan Consig</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>
                Promotora <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                value={data.promoterIntegration}
                onChange={(ev) => {
                  setData({
                    ...data,
                    promoterIntegration: ev.target.value,
                    apiKey: ev.target.value === 'OTHER' ? '' : data.apiKey,
                    token: ev.target.value === 'OTHER' ? '' : data.token,
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                <option value="LEV">LEV</option>
                <option value="LEV_WL">LEV WL</option>
                <option value="ADPROMOTORA">AD PROMOTORA</option>
                <option value="CONSIGACRED">CONSIGA CRED</option>
                <option value="OTHER">OUTRA</option>
              </select>
            </FormGroup>
          </Col>

          <Col md="4">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value),
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      {(data?.promoterIntegration === 'OTHER' ||
        data?.promoterIntegration === null) && (
        <div className="bg-secondary border p-4 rounded mt-4">
          <h3>Dados de acesso - API</h3>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>
                  ApiKey <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="false"
                  value={data.apiKey}
                  className=""
                  onChange={(ev) => {
                    setData({
                      ...data,
                      apiKey: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>
                  SecretKey <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="false"
                  value={data.token}
                  onChange={(ev) => {
                    setData({
                      ...data,
                      token: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}

      <div className="mt-4">
        <h3>Configurações</h3>
        <Nav tabs className="mt-3">
          <NavItem>
            <NavLink
              className={
                activeTab === '1' ? 'active cursor-pointer' : 'cursor-pointer'
              }
              onClick={() => {
                toggleTab('1');
              }}
            >
              FGTS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                activeTab === '2' ? 'active cursor-pointer' : 'cursor-pointer'
              }
              onClick={() => {
                toggleTab('2');
              }}
            >
              Consignado
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="border">
          <TabPane tabId="1">
            <Row className="px-3 py-4">
              <Row className="w-100 mt-0 mx-3 bg-secondary border rounded p-2">
                <h3 className="col-12 my-3">Configurar - FGTS</h3>
                <Col md="6">
                  <FormGroup>
                    <Label>
                      Tabela FGTS <span className="text-danger">*</span>
                    </Label>
                    <select
                      type="text"
                      autoComplete="false"
                      value={data.tableAmmount}
                      placeholder="Ex: 000.000.000-00"
                      className="form-control"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          tableAmmount: ev.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        Selecione...
                      </option>
                      {tables.map((table) => (
                        <option value={table.tableId} key={table.tableId}>
                          {table.tableName}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>
                      Com seguro <span className="text-danger">*</span>
                    </Label>
                    <select
                      type="text"
                      autoComplete="false"
                      value={data.useSafe}
                      placeholder="Ex: 000.000.000-00"
                      className="form-control"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          useSafe: ev.target.value,
                        });
                      }}
                    >
                      <option value="0">NÃO</option>
                      <option value="1">SIM</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>
                      Valor Mínimo liberado FGTS{' '}
                      <i
                        className="fas fa-question-circle"
                        id="minValueLibertyFgts"
                      />
                    </Label>
                    <Input
                      type="text"
                      autoComplete="false"
                      value={data.minValueLibertyFgts}
                      placeholder="Ex: 0,00"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          minValueLibertyFgts: moneyMask(ev.target.value),
                        });
                      }}
                    />
                    <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                      Se for preenchido este campo, a simulação será realizada a
                      partir do valor informado. Caso o valor liberado do FGTS
                      esteja abaixo do valor informado, gerará erro!
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>
                      Valor Máximo liberado FGTS{' '}
                      <i
                        className="fas fa-question-circle"
                        id="maxValueLibertyFgts"
                      />
                    </Label>
                    <Input
                      type="text"
                      autoComplete="false"
                      value={data.maxValueLibertyFgts}
                      placeholder="Ex: 0,00"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          maxValueLibertyFgts: moneyMask(ev.target.value),
                        });
                      }}
                    />
                    <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                      Se for preenchido este campo, a simulação será realizada
                      até o valor informado. Caso o valor liberado do FGTS
                      ultrapasse o informado, gerará erro!
                    </UncontrolledTooltip>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Ativar Consulta FGTS</Label>
                    <select
                      type="text"
                      autoComplete="false"
                      value={data.useFgts}
                      placeholder="Ex: 000.000.000-00"
                      className="form-control"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          useFgts: ev.target.value,
                        });
                      }}
                    >
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Ativar Simulador MultiBancos</Label>
                    <select
                      type="text"
                      autoComplete="false"
                      value={data.useMultBankFgts}
                      placeholder="Ex: 000.000.000-00"
                      className="form-control"
                      onChange={(ev) => {
                        setData({
                          ...data,
                          useMultBankFgts: ev.target.value,
                        });
                      }}
                    >
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <Col>
                <MyTablesComponent febrabanId={febrabanId} serviceId={2} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className="px-3 py-4">
              <Col>
                <MyTablesConsignedComponent febrabanId={febrabanId} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </CardBody>
  );
};

export default AccessBankPanForm;
