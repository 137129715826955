import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Container = styled.div`
  label {
    font-size: 13px;

    @media ${device.desktopLG} {
      font-size: 11px;
    }

    @media ${device.desktopXL} {
      font-size: 13px;
    }
  }
`;

export const SmallAlert = styled.small`
  font-size: 10px;

  margin-top: 4px;
  margin-bottom: 0;

  strong {
    font-weight: 600;
  }

  @media ${device.tablet} {
    font-size: 13px;
  }
`;
