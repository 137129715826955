import { Col, Row, Alert, FormGroup, Label, Input } from 'reactstrap';
import { viaCEP } from 'services/cepApi';
import { brasilApiCep } from 'services/cepApi';
import Swal from 'sweetalert2';
import { cepMask } from 'utils/mask';

const Address = ({ setDigitation, digitation }) => {
  const consultCEP = async (value) => {
    await brasilApiCep(value)
      .then((res) => {
        const address = res.data;
        setDigitation({
          ...digitation,
          cep: cepMask(address.cep),
          district: address.neighborhood,
          state: address.state,
          city: address.city,
          address: address.street,
        });
      })
      .catch(async () => {
        await viaCEP(value)
          .then((res) => {
            const viaCep = res.data;
            setDigitation({
              ...digitation,
              cep: cepMask(viaCep.cep),
              district: viaCep.bairro,
              state: viaCep.uf,
              city: viaCep.localidade,
              address: viaCep.logradouro,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`,
            });
            setDigitation({
              ...digitation,
              district: null,
              uf: null,
              city: null,
              address: null,
            });
          });
      });
  };

  return (
    <>
      <Alert color="secondary" className="mt-5 border">
        <h2 className="m-0">Endereço</h2>
      </Alert>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label>CEP</Label>
            <Input
              name="cep"
              id="cep"
              placeholder="00000-000"
              value={digitation.cep}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  cep: cepMask(ev.target.value),
                });
              }}
              onBlur={(ev) => {
                consultCEP(ev.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>Endereço</Label>
            <Input
              name="address"
              id="address"
              value={digitation.address}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  address: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label>Número</Label>
            <Input
              name="numberAddress"
              id="numberAddress"
              value={digitation.numberAddress}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  numberAddress: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Bairro</Label>
            <Input
              name="district"
              id="district"
              value={digitation.district}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  district: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Cidade</Label>
            <Input
              readOnly
              name="city"
              id="city"
              value={digitation.city}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  city: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>Estado</Label>
            <Input
              readOnly
              name="state"
              id="state"
              value={digitation.state}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  state: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Address;
