import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from 'reactstrap';
import { insertNote } from 'services/User/Note';
import { getAllNote } from 'services/User/Note';
import Swal from 'sweetalert2';

const Note = (props) => {
  const { className, isModal, state, data } = props;
  const [modal, setModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [dataNote, setDataNote] = useState([]);
  const [dataPost, setDataPost] = useState({
    simulationId: null,
    message: null
  });

  const handleSetValue = async (event) => {
    setIsBlocked(true);

    if (event.target.id === 'message') {
      setDataPost({
        ...dataPost,
        message: event.target.value
      });
    }

    if (dataPost.simulationId && dataPost.message) {
      setIsBlocked(false);
    }
  };

  const listNote = async () => {
    if (!data?.id) {
      return;
    }

    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    setDataPost({
      ...dataPost,
      simulationId: data.id
    });
    getAllNote(data?.id ?? 0)
      .then((result) => {
        Swal.close();
        setDataNote(result.data.resultSet);
      })
      .catch(() => {
        Swal.close();
      });
  };

  const handleSubmit = async () => {
    setIsBlocked(true);

    if (!dataPost.simulationId) {
      Swal.fire({
        icon: 'danger',
        title: 'Erro!',
        text: `Não foi possível atualizar o registro, por favor entre em contato com suporte`
      });
      return;
    }

    await insertNote(dataPost)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Parabens!',
          text: `O registro foi inserido com sucesso`
        });
        setIsBlocked(false);
        listNote();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  useEffect(() => {
    setDataNote([]);
    setModal(isModal);
    listNote();
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={closeModal}>Anotações / Observações</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label htmlFor="email">Nome Client</label>
                <input
                  id="name"
                  name="name"
                  className="form-control"
                  autoComplete="off"
                  type="text"
                  value={data.name}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label htmlFor="cpf">CPF</label>
                <input
                  id="document"
                  name="document"
                  className="form-control"
                  autoComplete="off"
                  value={data.cpf}
                  readOnly={true}
                />
              </div>
            </Col>
            <Col lg="12">
              <div className="form-group">
                <label htmlFor="cpf">Observação</label>
                <textarea
                  id="message"
                  name="message"
                  className="form-control"
                  autoComplete="off"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
          </Row>
          {dataNote.length > 0 ? (
            <div>
              <h3>Anotações</h3>
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {dataNote.map((note, key) => (
                  <>
                    <div key={key} className="alert alert-secondary">
                      <h4 className="font-weight-bold">
                        {note.name} - {note.createdAt}
                      </h4>
                      <p>{note.message}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isBlocked} onClick={handleSubmit}>
            SALVAR
          </Button>
          <Button color="secondary" onClick={closeModal}>
            SAIR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Note;
