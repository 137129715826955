import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

const CardConversionComponent = ({
  conversion = {
    qtdSimulation: 0,
    qtdProposal: 0,
    qtdConversion: 0
  }
}) => {
  return (
    <>
      <Card className="mb-4">
        <CardHeader>
          <h3>Conversão</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Card className="shadow card-status mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0">
                        Simulações
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {conversion.qtdSimulation}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="fas fa-search-dollar" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="shadow card-status mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0">
                        Propostas
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {conversion.qtdProposal}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                        <i className="fas fa-file-contract" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="shadow card-status mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0">
                        % de Conversão
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {conversion.qtdConversion}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="fas fa-percentage" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CardConversionComponent;
