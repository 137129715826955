import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-height: 110px;
  width: 100%;

  background: white;
  border-radius: 8px;
  padding: 16px;

  & + & {
    margin-top: 24px;
  }

  @media ${device.desktopXL} {
    min-height: 126.5px;
    justify-content: space-between;
  }

  @media ${device.desktopXXL} {
    min-height: 110px;
    justify-content: flex-start;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;

  @media ${device.desktopXL} {
    align-items: flex-start;
    margin-bottom: 8px;
  }
`;

export const Title = styled.h3`
  color: #8898aa;
  text-transform: uppercase;
  font-size: 13px;

  margin-bottom: 0;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DisplayInfo = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #32325d;

  margin-bottom: 0;

  @media ${device.desktopXL} {
    line-height: 20px;
  }
`;
