import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Alert,
  Label,
  Input,
  FormGroup,
  ModalFooter,
  Button
} from 'reactstrap';
import {
  updateStyles,
  getPromoter,
  getStyles
} from 'services/Promoter/PromoterService';
import Swal from 'sweetalert2';

const ModalStep2 = (props) => {
  const { className, isModal, state, handleSteps } = props;

  const [modal, setModal] = useState(false);
  const [logo, setLogo] = useState({
    name: '',
    base64: '',
    url: ''
  });
  const [bgColorPrimary, setBgColorPrimary] = useState('');
  const [bgColorSecondary, setBgColorSecondary] = useState('');
  const [colorPrimary, setColorPrimary] = useState('');
  const [urlSimulator, setUrlSimulator] = useState('');

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve({
          base64: baseURL,
          file: file
        });
      };
    });
  };

  const handleChangeStyleSimulator = (event) => {
    if (event.target.name === 'logo' && event.target.value !== '') {
      const logo = event.target.files[0] || null;

      getBase64(logo).then((result) => {
        setLogo({
          name: event.target.files[0].name,
          base64: result.base64
        });
      });
    }

    if (event.target.name === 'bgColorPrimary') {
      setBgColorPrimary(event.target.value);
    }

    if (event.target.name === 'bgColorSecondary') {
      setBgColorSecondary(event.target.value);
    }

    if (event.target.name === 'colorPrimary') {
      setColorPrimary(event.target.value);
    }
  };

  const styles = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      // text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await getStyles().then((res) => {
      const result = res.data;
      setBgColorPrimary(result.resultSet.bgColorPrimary);
      setBgColorSecondary(result.resultSet.bgColorSecondary);
      setColorPrimary(result.resultSet.colorPrimary);
      setLogo({
        name: '',
        base64: '',
        url: result.resultSet.logo
      });

      Swal.close();
    });
  };

  const promoter = async () => {
    await getPromoter().then((res) => {
      setUrlSimulator(
        `https://simulador.izidev.com.br/${res.data.resultSet.nameSlug}`
      );
    });
  };

  const handleSubmitStyleSimulator = async () => {
    await updateStyles({
      logo,
      bgColorPrimary,
      bgColorSecondary,
      colorPrimary
    })
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Registro alterado com sucesso, deseja visualizar o simulador?',
          icon: 'success',
          showCancelButton: true,
          cancelButtonText: 'NÃO, SAIR',
          confirmButtonText: 'SIM, SALVAR'
        }).then((res) => {
          closeModal();
          handleSteps(null);

          if (res.isConfirmed) {
            window.open(urlSimulator, '_blank').focus();
          }
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  useEffect(() => {
    setModal(isModal);
    promoter();
    styles();
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>Personalizar Simulador</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Alert color="success">
                <i className="fas fa-question-circle" /> Personalize seu
                simulador, alterando a logo e as cores.
              </Alert>
              <FormGroup>
                <Label>
                  Logo{' '}
                  <span className="badge badge-primary">
                    Tamanho da imagem recomendado:{' '}
                    <strong>150x40 pixels</strong>
                  </span>
                </Label>
                <Input
                  className="form-control form-control-md"
                  type="file"
                  name="logo"
                  id="logo"
                  onChange={handleChangeStyleSimulator}
                />
              </FormGroup>
              <Row>
                <Col xs="12">
                  <Col xs="12">
                    <h2>Cores</h2>
                  </Col>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Cor Botão</Label>
                        <br />
                        <Input
                          className="form-control form-control-md"
                          type="color"
                          name="colorPrimary"
                          id="colorPrimary"
                          value={colorPrimary}
                          onChange={handleChangeStyleSimulator}
                          onBlur={handleChangeStyleSimulator}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Cor Primária</Label>
                        <br />
                        <Input
                          className="form-control form-control-md"
                          type="color"
                          name="bgColorPrimary"
                          id="bgColorPrimary"
                          value={bgColorPrimary}
                          onChange={handleChangeStyleSimulator}
                          onBlur={handleChangeStyleSimulator}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Cor Secundária</Label>
                        <br />
                        <Input
                          className="form-control form-control-md"
                          type="color"
                          value={bgColorSecondary}
                          name="bgColorSecondary"
                          id="bgColorSecondary"
                          onChange={handleChangeStyleSimulator}
                          onBlur={handleChangeStyleSimulator}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button
            onClick={handleSubmitStyleSimulator}
            className="btn btn-success btn-md"
          >
            Salvar alterações
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalStep2;
