import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import BenefitItemComponent from './BenefitItemComponent';

const BenefitsComponent = ({
  benefits,
  setBenefits = () => {},
  links,
  setLinks = () => {},
}) => {
  const handleAdvantageAdd = () => {
    setBenefits([
      ...benefits,
      {
        title: 'Título',
        description: '',
      },
    ]);
  };

  const handleAdvantageRemove = (key) => {
    if (benefits.length <= 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops...',
        text: 'Você não pode remover todas as Vantagens',
      });
      return;
    }

    setBenefits(
      benefits.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  return (
    <>
      {benefits.length > 0 && (
        <Card className="shadow mt-3">
          <CardHeader>
            <Row>
              <Col>
                <h3 className="m-0">
                  <i className="fas fa-link" /> Vantagens
                </h3>
              </Col>
              <Col className="text-right">
                <Button color="primary" onClick={handleAdvantageAdd}>
                  <i className="fas fa-plus" /> Adicionar Nova Vantagem
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {benefits.map((advantage, key) => (
              <BenefitItemComponent
                key={key}
                item={advantage}
                setItem={(e) => {
                  console.log(e);
                  const newLink = [...links];
                  benefits[key] = e;
                  setLinks(newLink);
                }}
                benefits={benefits}
                links={links}
                setLinks={setLinks}
                handleAdvantageRemove={handleAdvantageRemove}
              />
            ))}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default BenefitsComponent;
