import InputComponent from 'components/Form/Input/InputComponent';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Label,
  Row,
} from 'reactstrap';
import { ProposalService } from 'services/Consigned/IcredConsignedService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';

const IcredFormClientConsignedComponent = ({
  stage = null,
  setStage = () => {},
  simulationSelected = {},
  resultSimulation = {},
}) => {
  const [form, setForm] = useState({
    name: resultSimulation.name,
    cpf: resultSimulation.cpf,
    whatsappNumber: resultSimulation.whatsappNumber,
    documentRg: null,
    motherName: null,
    cep: null,
    address: null,
    numberAddress: null,
    city: null,
    state: null,
    district: null,
  });

  const handleSubmit = () => {
    try {
      if (!form?.cpf) throw new Error('CPF não informado');
      if (!form?.name) throw new Error('Nome não informado');
      if (!form?.whatsappNumber) throw new Error('WhatsApp não informado');
      if (!form?.documentRg)
        throw new Error('Nº do documento (RG) não informado');
      if (!form?.motherName) throw new Error('Nome da mãe não informado');
      if (!form?.cep) throw new Error('CEP não informado');
      if (!form?.address) throw new Error('Endereço não informado');
      if (!form?.numberAddress) throw new Error('Nº do endereço não informado');
      if (!form?.district) throw new Error('Bairro não informado');
      if (!form?.city) throw new Error('Cidade não identificada');
      if (!form?.state) throw new Error('Estado não identificado');

      Swal.fire({
        title: 'Enviando proposta para icred',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const payload = {
        ...form,
        valueContract: simulationSelected.loanAmount,
        valueLiberty: simulationSelected.netAmount,
        numberProposal: simulationSelected.uuid,
        birthDate: resultSimulation.birthDate,
      };

      ProposalService(payload)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            message: 'Proposta cadastrada com sucesso!',
          }).then(() => {
            setStage(1);
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      });
    }
  };

  useEffect(() => {
    console.log(simulationSelected);
    setForm({
      ...form,
      numberProposal: simulationSelected.uuid,
      name: resultSimulation.name,
      cpf: resultSimulation.cpf,
      whatsappNumber: resultSimulation.whatsappNumber,
      birthDate: resultSimulation.birthDate,
    });
  }, [stage]);

  if (stage !== 3) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow mb-4">
        <Alert color="secondary">
          {simulationSelected.salaryIncrease && (
            <Badge color="success" className="font-12 mr-2">
              AUMENTO DE MARGEM
            </Badge>
          )}
          {simulationSelected.insurances.length > 0 && (
            <Badge color="warning" className="font-12 mr-2">
              COM SEGURO
            </Badge>
          )}
        </Alert>
        <CardBody>
          <Row>
            <Col md="3">
              <Label>Liberado</Label>
              <p>R$ {simulationSelected.netAmount.toFixed(2)}</p>
            </Col>
            <Col md="3">
              <Label>Valor da Parcela</Label>
              {/* <Input type="text" size="sm" /> */}
              <p>R$ {simulationSelected.installmentsAmount.toFixed(2)}</p>
            </Col>
            <Col md="3">
              <Label>Prazo</Label>
              <p>{simulationSelected.numberOfInstallments}x</p>
            </Col>
            <Col md="3">
              <Label>Taxa</Label>
              <p>
                {(simulationSelected.monthlyInterestRate * 100).toFixed(2)}%
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="shadow">
        <CardHeader>
          <h2>iCred - Informações do cliente</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <InputComponent
                label="Nome"
                value={form.name}
                onChange={(ev) => {
                  setForm({ ...form, name: ev.target.value });
                }}
                isRequired
              />
            </Col>
            <Col md="3">
              <InputComponent
                label="CPF"
                value={form.cpf}
                disabled
                isRequired
              />
            </Col>
            <Col md="3">
              <InputComponent
                label="Whatsapp"
                value={form.whatsappNumber}
                onChange={(ev) => {
                  setForm({
                    ...form,
                    whatsappNumber: phoneMask(ev.target.value),
                  });
                }}
                isRequired
              />
            </Col>
            <Col md="6">
              <InputComponent
                label="RG"
                value={form.documentRg}
                onChange={(ev) => {
                  setForm({ ...form, documentRg: ev.target.value });
                }}
                isRequired
              />
            </Col>
            <Col md="6">
              <InputComponent
                label="Nome da mãe"
                value={form.motherName}
                onChange={(ev) => {
                  setForm({ ...form, motherName: ev.target.value });
                }}
                isRequired
              />
            </Col>
          </Row>

          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-map-marked-alt" /> Endereço
              </h3>
            </CardHeader>
            <CardBody>
              <FormRegisterAddressComponent
                register={form}
                setRegister={setForm}
                isRequired={true}
              />
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            size="lg"
            className="w-100"
            onClick={handleSubmit}
          >
            <i className="fas fa-paper-plane" /> Cadastrar Proposta
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default IcredFormClientConsignedComponent;
