import api from 'services/api';

export const SimulationInssPAN = async (data) => {
  return await api.post(`v1/inss/pan/digitation/simulation`, data);
};

export const ProposalInssPAN = async (simulationId, data) => {
  return await api.post(
    `v1/inss/pan/digitation/proposal/${simulationId}`,
    data
  );
};

export const listINSS = async () => {
  return await api.get(`v1/report/simulation/inss`);
};

export const reSendLinkFormalizationMercantil = async (simulationId) => {
  return await api.post(
    `v1/digitation/proposal/link-formalization/mercantil/resend/${simulationId}`
  );
};

export const cancelProposalICredService = async (simulationId, history) => {
  return await api.put(`v1/digitation/proposal/icred/cancel/${simulationId}`, {
    history,
  });
};

export const reSendProposalICredService = async (
  simulationId,
  phone,
  numberProposal = null
) => {
  return await api.post(
    `v1/digitation/proposal/icred/reSendSMS/${simulationId}`,
    {
      phone,
      numberProposal,
    }
  );
};

export const simuleInssService = async (
  data = {
    cpf: null,
    registry: null,
    valueParc: null,
    parc: null,
    agreement: null,
  }
) => {
  return await api.post(`v2/digitation/inss/simule`, data);
};

export const digitationService = async (
  data = {
    name: null,
    cpf: null,
    birthDate: null,
    email: null,
    whatsappNumber: null,
    documentRG: null,
    dateEmissionRG: null,
    stateRG: null,
    sex: null,
    cep: null,
    address: null,
    district: null,
    number: null,
    city: null,
    state: null,
    registry: null,
    pix: null,
    bank: null,
    numberAccount: null,
    agency: null,
    typeAccount: null,
    simulationInss: null,
    febrabanId: null,
    nameMother: null,
    stateCivil: null,
  }
) => {
  return await api.post(`v2/digitation`, data);
};

export const digitationReportService = async (
  data = {
    dateStart: null,
    dateEnd: null,
    cpf: null,
    numberProposal: null,
  }
) => {
  return await api.get(`v2/digitation`, {
    params: data,
  });
};

export const getDigitationServiceByUuid = async (uuid) => {
  return await api.get(`v2/digitation/${uuid}`);
};

export const getDigitationHistoryServiceByUuid = async (uuid) => {
  return await api.get(`v2/digitation/history/${uuid}`);
};

export const getAllStatusAttendant = async () => {
  return await api.get(`v2/status-attendant`);
};

export const updateDigitationStatusAttendant = async (
  data = {
    digitationId: null,
    statusAttendantId: null,
  }
) => {
  return await api.put(`v2/digitation/status`, data);
};

export const showLinkFormalize = async (numberProposal, febrabanId) => {
  return await api.get(
    `v2/fgts/link-formalize/${numberProposal}/${febrabanId}`
  );
};

export const insertProposalManualService = async (data, serviceId) => {
  return await api.post(`v2/digitation/operation/manual/${serviceId}`, data);
};

export const resendPaymentFgts = async (
  data = {
    simulationId: null,
    proposalId: null,
    bank: null,
    agencyBank: null,
    numberAccountBank: null,
    digitAccountBank: null,
    typeAccount: null,
    febrabanId: null,
  }
) => {
  let url = `v2/fgts/resend-payment`;

  if (data.febrabanId === '329-5') {
    url = `v2/fgts/resend-payment-grandino`;
  }

  return await api.post(url, data);
};

export const listDigitationQueueService = async (
  serviceId,
  filter = {
    dateStart: null,
    dateEnd: null,
    cpf: null,
    statusAttendantId: null,
  }
) => {
  return await api.get(`v3/digitation/${serviceId}`, {
    params: filter,
  });
};

export const listAllDocuments = async (clientId) => {
  return await api.get(`v3/client/document/${clientId}`);
};