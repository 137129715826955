export const FactaTable = [
  { code: 53210, name: 'FGTS PLUS RB' },
  { code: 53201, name: 'FGTS PLUS + RB' },
  { code: 53287, name: 'FGTS GOLD + RB' },
  { code: 53236, name: 'FGTS GOLD RN RB' },
  { code: 53252, name: 'FGTS SMART FLEX RB' },
  { code: 53244, name: 'FGTS SMART LIGHT RB' },
  { code: 53228, name: 'FGTS TOP RB' },
  { code: 53260, name: 'FGTS SMART VIP RB' },
  { code: 50288, name: 'FGTS SMART TURBO FR' },
  { code: 50407, name: 'FGTS LIGHT RB' },
  { code: 53279, name: 'FGTS SMART TURBO RB' },
  { code: 53708, name: 'FGTS GOLD SMART SELECT' },
  { code: 48305, name: 'FGTS SMART' },
  { code: 56073, name: 'FGTS GOLD POWER RB' },
  { code: 53686, name: 'FGTS GOLD SPEED' },
  { code: 53694, name: 'FGTS GOLD PRIME' },
  { code: 53279, name: 'FGTS GOLD SMART TURBO RB' },
  { code: 54216, name: 'GOLD MONEY RB' },
  { code: 53252, name: 'FGTS GOLD SMART FLEX RB' },
  { code: 53244, name: 'FGTS GOLD SMART LIGHT RB' },
  { code: 56090, name: 'FGTS GOLD ULTRA POWER RB' },
  { code: 56081, name: 'FGTS GOLD MEGA POWER RB' },
];

export const GrandinoBankTable = [
  { code: '000010', name: 'FGTS NORMAL' },
  { code: '000001', name: 'POWER | FGTS TX 1,80 | ATÉ R$ 30.000,00 - J17' },
  {
    code: '000002',
    name: 'OMEGA | FGTS TX 1,80 | R$ 30,00 ATÉ R$ 30.000,00 - J17',
  },
  { code: '50', name: 'ALPHA | FGTS TX 1,80 | DE R$ 30,00 A R$ 8.000,00' },
  {
    code: '810',
    name: 'LIGHT | FGTS 1,80% | DE R$ 30,00 A R$ 30.000,00 | COM SEGURO',
  },
  { code: '990', name: 'LIGHT | FGTS 1,80% | DE R$ 400,00 A R$ 30.000,00' },
];

export const ICredTable = [
  { code: 1, name: 'FGTS | FLEX 1' },
  { code: 2, name: 'FGTS | FLEX 2' },
  { code: 3, name: 'FGTS | FLEX 3' },
  { code: 17, name: 'FGTS | FLEX 4' },
  { code: 21, name: 'FGTS | FLEX 5' },
  { code: 30, name: 'FGTS | Light 90' },
  { code: 13, name: 'FGTS | Light 180' },
  { code: 14, name: 'FGTS | TKT 20K+' },
  { code: 15, name: 'FGTS | FLAT 50' },
  { code: 16, name: 'FGTS | FLAT 30' },
  { code: 19, name: 'FGTS | VIP' },
  { code: 20, name: 'FGTS | TOP' },
  { code: 22, name: 'FGTS | OURO' },
  { code: 28, name: 'FGTS | VIP FLEX' },
  { code: 29, name: 'FGTS | DIAMANTE' },
];

export const V8Digital = [
  { code: 'cb563029-ba93-4b53-8d53-4ac145087212', name: 'NORMAL' },
  { code: 'f6d779ed-52bf-42f2-9dbc-3125fe6491ba', name: 'ACELERA 2.0' },
  { code: '13c4c16a-0ace-4cca-af52-323ddf2c0894', name: 'MILHAS' },
  { code: '8beaa78b-b7ba-4f48-853e-de9f322be42f', name: 'TURBO' },
  { code: 'cd67cb02-c49d-457e-bfa9-a99316eb9dfe', name: 'PITSTOP' },
];

export const PrataDigitalTable = [
  { code: '17', name: 'Shake de morango - TX 1,80 am' },
  { code: '16', name: 'Vem todo mundo - TX 1,80 am' },
  { code: '15', name: 'Pense em mim - TX 1,80 am' },
  { code: '14', name: 'O Prata é King - TX 1,80 am' },
];

export const VCTexTable = [
  { code: '0', name: 'Tabela Exponencial' },
  { code: '1', name: 'Tabela 1 - Master' },
  { code: '2', name: 'Tabela 2 - Prime' },
  { code: '3', name: 'Tabela 3 - Linear' },
  { code: '5', name: 'Tabela Flex' },
  { code: '6', name: 'Tabela Exponencial com Seguro' },
  { code: '8', name: 'Tabela Master com Seguro' },
  { code: '9', name: 'Tabela Prime com Seguro' },
  { code: '10', name: 'Tabela Linear com Seguro' },
];
