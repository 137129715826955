import QrCodeComponent from 'components/Form/QrCodeComponent';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink
} from 'reactstrap';
import {
  indexSafraService,
  indexTableConsigned
} from 'services/Digitation/IntegrationService';
import Swal from 'sweetalert2';

const FormPageComponentView = ({ register, setRegister, handleSubmit }) => {
  const [listSafraService, setListSafraService] = useState([]);
  const [listSafraTable, setListSafraTable] = useState([]);
  const [installmentSelected, setInstallmentSelected] = useState(84);

  const handleListTable = (productId, installment = 84, useSafe = 0) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    indexTableConsigned(productId, installment, useSafe)
      .then(({ data }) => {
        setListSafraTable(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    indexSafraService(setListSafraService);
    handleListTable(
      register.infoPage.condition.productId,
      installmentSelected,
      0
    );
  }, []);

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h2>
            <i className="fas fa-cogs" /> Configurar - Auto Contratação
            Consignado
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="8">
              <Card className="shadow">
                <CardHeader>
                  <h3 className="m-0">
                    <i className="fas fa-cog" /> Configurar Financeira
                  </h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Financeira</Label>
                        <select
                          className="form-control"
                          value={register.preferenceBank}
                          onChange={(ev) => {
                            setRegister({
                              ...register,
                              preferenceBank: ev.target.value
                            });
                          }}
                        >
                          <option value="">SELECIONE...</option>
                          <option value="074">SAFRA</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Convênio</Label>
                        {listSafraService.length > 0 &&
                        register.preferenceBank === '074' ? (
                          <select
                            value={register?.infoPage?.condition?.productId}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                infoPage: {
                                  ...register?.infoPage,
                                  condition: {
                                    ...register?.infoPage?.condition,
                                    productId: ev.target.value
                                  }
                                }
                              });

                              handleListTable(
                                ev.target.value,
                                installmentSelected,
                                0
                              );
                            }}
                            className="form-control"
                          >
                            <option value="">Selecione...</option>
                            {listSafraService.map((register, key) => (
                              <option value={register.idConvenio} key={key}>
                                {register.idConvenio} - {register.nome}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>
                            <h3 className="text-danger">
                              Não foram identificados convênios, verifique a API
                            </h3>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>% Base cálculo</Label>
                        <Input
                          type="number"
                          value={Number(
                            register?.infoPage?.condition?.percValueBase
                          )}
                          onChange={(ev) => {
                            setRegister({
                              ...register,
                              infoPage: {
                                ...register?.infoPage,
                                condition: {
                                  ...register?.infoPage?.condition,
                                  percValueBase: ev.target.value
                                }
                              }
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h3>
                    <i className="fas fa-table" /> Tabela
                  </h3>
                  {register.preferenceBank &&
                  register?.infoPage?.condition?.productId ? (
                    <>
                      <Nav tabs>
                        <NavItem
                          onClick={() => {
                            setInstallmentSelected(84);
                            handleListTable(
                              register.infoPage.condition.productId,
                              84,
                              0
                            );
                          }}
                        >
                          <NavLink className="active">84x</NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab="1" className="p-4 border">
                        <TabPane tabId="1">
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Selecionar Tabela</Label>
                                <select
                                  className="form-control form-control-md"
                                  value={
                                    register?.infoPage?.condition[84]?.tableId
                                  }
                                  onChange={(ev) => {
                                    setRegister({
                                      ...register,
                                      infoPage: {
                                        ...register.infoPage,
                                        condition: {
                                          ...register.infoPage.condition,
                                          84: {
                                            ...register.infoPage.condition[84],
                                            tableId: ev.target.value
                                          }
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  {listSafraTable.map((table, key) => (
                                    <option key={key} value={table.id}>
                                      {table.id} - {table.name}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Aumento de Margem</Label>
                                <select
                                  className="form-control"
                                  value={
                                    register?.infoPage?.condition[84]
                                      ?.marginIncrease
                                  }
                                  onChange={(ev) => {
                                    setRegister({
                                      ...register,
                                      infoPage: {
                                        ...register.infoPage,
                                        condition: {
                                          ...register.infoPage.condition,
                                          84: {
                                            ...register.infoPage.condition[84],
                                            marginIncrease: ev.target.value
                                          }
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <option value={0}>Não</option>
                                  <option value={1}>Sim</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </>
                  ) : (
                    <>
                      <Alert color="warning">
                        <i className="fas fa-exclamation-triangle" /> Selecione
                        a <strong>Financeira</strong> e <strong>Produto</strong>
                      </Alert>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <QrCodeComponent url={register.url} className="shadow" />
            </Col>
          </Row>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3 className="m-0">
                <i className="fas fa-star" /> Marketing
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>ID PIXEL</Label>
                    <Input
                      value={register?.infoPage?.pixel}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          infoPage: {
                            ...register?.infoPage,
                            pixel: ev.target.value
                          }
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>ID VERIFICAÇÃO PIXEL</Label>
                    <Input
                      value={register?.infoPage?.pixelDomainVerification}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          infoPage: {
                            ...register?.infoPage,
                            pixelDomainVerification: ev.target.value
                          }
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>ID TAGMANAGER</Label>
                    <Input
                      value={register?.infoPage?.tagManager}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          infoPage: {
                            ...register?.infoPage,
                            tagManager: ev.target.value
                          }
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {/* <Card className="shadow mt-4">
              <CardHeader>
                <h3 className="m-0">
                  <i className="fas fa-link" /> Botões após finalização da
                  digitação
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right"
                    onClick={handleAddBtn}
                  >
                    <i className="fas fa-plus" /> ADICIONAR BOTÃO
                  </Button>
                </h3>
              </CardHeader>
              <CardBody>
                {buttonsAfterFinishProposal?.map((btn, key) => (
                  <Card className="shadow mb-3" key={key}>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label>Nome</Label>
                            <Input
                              type="text"
                              placeholder="Exemplo: Nome do botão"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="8">
                          <FormGroup>
                            <Label>URL</Label>
                            <InputGroup>
                              <Input
                                type="url"
                                placeholder="Exemplo: https://seusite.com.br"
                              />
                              <select className="form-control col-4 border">
                                <option value="_self">
                                  Abrir link na mesma aba
                                </option>
                                <option value="_blank">
                                  Abrir link em nova aba
                                </option>
                              </select>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      {key === buttonsAfterFinishProposal.length - 1 && (
                        <>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={handleAddBtn}
                          >
                            <i className="fas fa-plus" /> NOVO BOTÃO
                          </Button>
                        </>
                      )}
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleRemoveBtn(key)}
                      >
                        <i className="fas fa-trash-alt" /> Remover
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              </CardBody>
            </Card> */}
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            disabled={
              !register.preferenceBank ||
              !register?.infoPage?.condition?.productId
            }
            onClick={handleSubmit}
          >
            <i className="fas fa-save" /> SALVAR
          </Button>
          <Button
            color="warning"
            onClick={() => {
              setRegister({});
            }}
          >
            VOLTAR
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default FormPageComponentView;
