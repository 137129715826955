import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { maskCNPJ, phoneMask, maskCPF, cepMask } from 'utils/mask';
import { brasilApiCep, viaCEP } from 'services/cepApi';
import Swal from 'sweetalert2';
import { insertStoreService } from 'services/Store/StoreService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CreateStoreView = () => {
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState([]);
  const [store, setStore] = useState({
    corporativeName: '',
    fantasyName: '',
    document: '',
    phone: '',
    cep: '',
    address: '',
    district: '',
    numberAddress: '',
    city: '',
    state: '',
    complement: '',
    sellerName: '',
    sellerDocument: '',
    sellerPhone: '',
    email: '',
  });

  const validator = (store) => {
    const missingFields = [];

    if (!store.corporativeName) {
      missingFields.push('Razão Social não informada.');
    }

    if (!store.fantasyName) {
      missingFields.push('O Nome Fantasia não foi informado.');
    }

    if (!store.document) {
      missingFields.push('O CNPJ não foi informado.');
    }

    if (!store.phone) {
      missingFields.push('O Número de Contato não foi informado.');
    }

    if (!store.cep) {
      missingFields.push('O CEP não foi informado.');
    }

    if (!store.address) {
      missingFields.push('O Endereço não foi informado.');
    }

    if (!store.numberAddress) {
      missingFields.push('O Número da residência não foi informado.');
    }

    if (!store.district) {
      missingFields.push('O Bairro não foi informado.');
    }

    if (!store.city) {
      missingFields.push('A Cidade não foi informada.');
    }

    if (!store.state) {
      missingFields.push('O Estado não foi informado.');
    }

    if (!store.complement) {
      missingFields.push('O Complemento não foi informado.');
    }

    if (!store.sellerName) {
      missingFields.push('Nome do Sócio não informado.');
    }

    if (!store.sellerDocument) {
      missingFields.push('CPF não informado.');
    }

    if (!store.sellerPhone) {
      missingFields.push('WhatsApp não informado.');
    }

    if (!store.email) {
      missingFields.push('Email não informado.');
    }

    return missingFields;
  };

  const handleSave = () => {
    const missingFields = validator(store);

    if (missingFields.length > 0) {
      const messages = missingFields.map((field) => `- ${field}`).join('<br>');
      Swal.fire({
        icon: 'error',
        title: 'Ops',
        text: 'Existem campos obrigatórios que não foram preenchidos',
      });

      setErrorMessages(messages);
      return;
    }

    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    insertStoreService(store)
      .then((response) => {
        console.log('API Response:', response);
        const password = response.data.result.password;
        const email = store.email;

        Swal.fire({
          title: 'Sucesso',
          html: `
                        <h2>Cadastro realizado com sucesso.</h2>
                        <p>Informações de acesso:</p>
                        <p>EMAIL: <strong>${email}</strong> </p>
                        <p>SENHA: <strong>${password}</strong></p>
                        <div class="alert bg-success text-white mb-0"><strong>OBS: A senha só será visível neste momento, por favor salve e informe ao usuário, para que o mesmo possa realizar a alteração.</strong> </div>
                    `,
          icon: 'success',
        });

        setStore({
          corporativeName: '',
          fantasyName: '',
          document: '',
          phone: '',
          cep: '',
          address: '',
          district: '',
          numberAddress: '',
          city: '',
          state: '',
          complement: '',
          sellerName: '',
          sellerDocument: '',
          sellerPhone: '',
          email: '',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStore((prevStore) => ({
      ...prevStore,
      [name]: value,
    }));
  };

  const handleIdentifyAddressUserByCEP = async (ev) => {
    setStore({
      ...store,
      cep: cepMask(ev.target.value),
    });

    Swal.fire({
      title: 'Consultando CEP',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await brasilApiCep(ev.target.value)
      .then((res) => {
        Swal.close();
        const address = res.data;
        setStore({
          ...store,
          address: address.street,
          district: address.neighborhood,
          city: address.city,
          state: address.state,
        });
      })
      .catch(async () => {
        viaCEP(ev.target.value)
          .then((res) => {
            Swal.close();
            const address = res.data;
            setStore({
              ...store,
              address: address.logradouro,
              district: address.bairro,
              city: address.localidade,
              state: address.uf,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`,
            });
            setStore({
              ...store,
              address: null,
              district: null,
              city: null,
              state: null,
            });
          });
      });
  };

  useEffect(() => {
    const missingFields = validator(store);

    if (missingFields.length === 0) {
      setErrorMessages([]);
    }
  }, [store]);

  return (
    <>
      <Header titlePage="Cadastro de Lojas" />
      <Container className="mt--9" fluid>
        <Card>
          <CardHeader className="d-md-flex justify-content-between align-items-center">
            <h1>
              <i className="far fa-sticky-note" /> Lojas - Cadastro de Loja
            </h1>
            <Button
              color="success"
              onClick={() => {
                history.push(`/admin/store/list`);
              }}
            >
              <i className="fas fa-arrow-left mr-2" />
              Voltar
            </Button>
          </CardHeader>

          <CardBody>
            <Row>
              <Card className="w-100 shadow">
                <CardHeader>
                  <strong>Informações da Empresa</strong>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Razão Social: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="corporativeName"
                          placeholder="Digite a Razão Social"
                          value={store.corporativeName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Nome Fantasia: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="fantasyName"
                          placeholder="Digite o Nome Fantasia"
                          value={store.fantasyName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          CNPJ: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="document"
                          placeholder="CNPJ"
                          value={maskCNPJ(store.document)}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Tel. Contato: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="phone"
                          placeholder="(00) 0 0000-000"
                          value={phoneMask(store.phone)}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="w-100 mt-3 shadow">
                <CardHeader>
                  <strong>Endereço</strong>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          CEP: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="cep"
                          placeholder="00000-000"
                          value={cepMask(store.cep)}
                          onChange={handleChange}
                          onBlur={handleIdentifyAddressUserByCEP}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Rua: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="address"
                          value={store.address}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Número: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="numberAddress"
                          value={store.numberAddress}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Bairro: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="district"
                          value={store.district}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Cidade: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="city"
                          value={store.city}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Estado: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="state"
                          value={store.state}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>Complemento:</Label>
                        <Input
                          type="text"
                          name="complement"
                          value={store.complement}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="w-100 mt-3 shadow">
                <CardHeader>
                  <strong>Dados do Contato</strong>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Nome do Sócio: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="sellerName"
                          placeholder="Digite o Nome do Sócio"
                          value={store.sellerName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          CPF: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="sellerDocument"
                          placeholder="000.000.000-00"
                          value={maskCPF(store.sellerDocument)}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>WhatsApp:</Label>
                        <Input
                          type="text"
                          name="sellerPhone"
                          placeholder="(00) 0 0000-0000"
                          value={phoneMask(store.sellerPhone)}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12">
                      <FormGroup>
                        <Label>
                          Email: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Digite o Email"
                          value={store.email}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleSave}>
              Salvar
            </Button>
          </CardFooter>
        </Card>

        <Alert className="my-2" color="secondary">
          <p className="m-0">
            - Campos com <strong className="text-danger">*</strong> são
            obrigatórios
          </p>
        </Alert>

        {errorMessages.length > 0 && (
          <Alert color="warning">
            <h2 className="m-0 text-white">
              <i className="fas fa-times" /> Erro
            </h2>
            <div dangerouslySetInnerHTML={{ __html: errorMessages }} />
          </Alert>
        )}
      </Container>
    </>
  );
};

export default CreateStoreView;
