import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'chart.js';
import Swal from 'sweetalert2';
import {
  Card,
  Row,
  Col,
  Alert,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
} from 'reactstrap';
import { scheduleExcel } from 'services/Report/Schedule';

import Header from 'components/Headers/Header.js';
import PaginationSelector from './component/PaginationSelector';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData,
} from 'components/Table';
import { chartOptions, parseOptions } from 'variables/charts.js';
import {
  CardHeader,
  Title,
  CaretRight,
  ContentWrapper,
  MobileTitle,
} from './styles';
import { reportSalePerIndication } from 'services/Promoter/IndicationService';
import { moneyMask } from 'utils/mask';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import InputFebrabanView from 'components/Form/Input/InputFebrabanView';

const ReportSalePerIndication = () => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const history = useHistory();
  const [tableDetails, setTableDetails] = useState(null);
  const [listData, setListData] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    dateStart: null,
    dateEnd: null,
    bank: null,
    page: 0,
    indicationId: null,
    status: null,
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleList = async (pageSelected = 0) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setListData([]);
    if (typeof pageSelected == 'number') {
      setPage(pageSelected);
    } else {
      pageSelected = 0;
    }

    await reportSalePerIndication({
      dateStart: data.dateStart,
      dateEnd: data.dateEnd,
      bank: data.bank,
      page: pageSelected,
      indicationId: data.indicationId,
      status: data.status,
    }).then(async (res) => {
      if (res.data.resultSet.data && res.data.resultSet.data.length > 0) {
        setListData(res.data.resultSet.data);
        setPages(res.data.resultSet.pagination.result.pages);
      }
      Swal.close();
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      <section className="mt--9 container-fluid">
        <Card className="mb-2 shadow">
          <CardHeader>
            <Title>Filtro</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label>Periodo Cadastro</Label>
                  <Row>
                    <Col xl="6">
                      <Input
                        type="date"
                        name="dateStart"
                        className="form-control form-control-sm"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            dateStart: ev.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xl="6">
                      <Input
                        type="date"
                        name="dateEnd"
                        className="form-control form-control-sm"
                        onChange={(ev) => {
                          setData({
                            ...data,
                            dateEnd: ev.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col xl="6">
                <InputIndicationView filter={data} setFilter={setData} />
              </Col>
              <Col md="6">
                <InputFebrabanView
                  setFilter={(e) => {
                    setData({ ...data, bank: e.febraban });
                  }}
                />
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    id="statusProposal"
                    name="statusProposal"
                    className="form-control form-control-sm"
                    onChange={(ev) => {
                      setData({
                        ...data,
                        status: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="2">Pago</option>
                    <option value="3">Em Análise</option>
                    <option value="1">Em Andamento</option>
                    <option value="4">Com Pendência</option>
                    <option value="5">Reprovado</option>
                    <option value="6">Cancelado</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleList}>
              <i className="fas fa-search" /> Buscar
            </Button>
            {userDataConfig?.canExportExcel && (
              <Button
                color="success"
                onClick={() => {
                  scheduleExcel(10, data, history);
                }}
              >
                <i className="fas fa-file-excel" /> Exportar Excel
              </Button>
            )}
          </CardFooter>
        </Card>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader>
                <Title>Vendas</Title>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Indicado</TableHead>
                        <TableHead>Qtd. Contratos</TableHead>
                        <TableHead>Total</TableHead>
                        <TableHead className="d-none d-lg-table-cell">
                          Ticket Médio
                        </TableHead>
                        <TableHead className="d-none d-lg-table-cell">
                          Comissionamento
                        </TableHead>
                        <TableHead className="d-none d-xl-table-cell" />
                        <TableHead className="d-xl-none" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {listData.map((data, index) => (
                        <>
                          <TableRow key={index}>
                            <TableData>
                              <p>{data.indicationName}</p>
                            </TableData>
                            <TableData>
                              <p>{data.qtd}</p>
                            </TableData>
                            <TableData>
                              <p className="font-weight-bold">
                                R$ {moneyMask(data.sumValueLiberty)}
                              </p>
                            </TableData>
                            <TableData className="d-none d-lg-table-cell">
                              <p className="font-weight-bold">
                                R$ {moneyMask(data.avgValueLiberty)}
                              </p>
                            </TableData>
                            <TableData className="d-none d-lg-table-cell">
                              <p className="font-weight-bold">
                                R$ {moneyMask(data.valueComissionSeller ?? 0.0)}
                              </p>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell" />
                            <TableData className="d-xl-none">
                              <CaretRight
                                active={tableDetails === index}
                                onClick={() => handleTableDetails(index)}
                              />
                            </TableData>
                          </TableRow>
                          {tableDetails === index && (
                            <TableRow className="d-xl-none">
                              <TableData colSpan={10}>
                                <ContentWrapper className="d-lg-none">
                                  <MobileTitle>Ticket Médio</MobileTitle>
                                  <p className="font-weight-bold">
                                    R$ {moneyMask(data.avgValueLiberty)}
                                  </p>
                                </ContentWrapper>
                                <ContentWrapper className="d-lg-none">
                                  <MobileTitle>Comissionamento</MobileTitle>
                                  <p className="font-weight-bold">
                                    R${' '}
                                    {moneyMask(data.valueComissionSeller ?? 0)}
                                  </p>
                                </ContentWrapper>
                                <ContentWrapper />
                              </TableData>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possuem registros no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ReportSalePerIndication;
