import { Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';

const DescriptionLandingPageComponent = ({ form, setForm = () => {} }) => {
  return (
    <>
      <Card className="shadow mt-4">
        <CardHeader>
          <h3>Descrição</h3>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Quem somos</Label>
            <textarea
              className="form-control"
              value={form?.infoPage?.whoWeAre}
              onChange={(ev) => {
                setForm({
                  ...form,
                  infoPage: {
                    ...form?.infoPage,
                    whoWeAre: ev.target.value
                  }
                });
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
};

export default DescriptionLandingPageComponent;
