import { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  CardFooter,
  Alert
} from 'reactstrap';
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import { createPromoter } from 'services/Promoter/PromoterService';
import Swal from 'sweetalert2';
import { cepMask, phoneMask, maskCNPJ } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import { getCepService } from 'services/Utils/CepService';

const Register = () => {
  const history = useHistory();
  const mainContent = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const [affiliate, setAffiliate] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [register, setRegister] = useState({
    name: null,
    email: null,
    password: null,
    corporativeName: null,
    document: null,
    cep: null,
    address: null,
    district: null,
    city: null,
    number: null,
    complement: null,
    phone: null,
    uf: null,
    affiliate: null
  });

  const [showMsgPassword, setShowMsgPassword] = useState(false);

  const handleIdentifyAddressUserByCEP = async (event) => {
    setRegister({
      ...register,
      cep: cepMask(event.target.value)
    });

    if (event?.target?.value?.length < 9) {
      return;
    }

    await getCepService(event.target.value)
      .then((res) => {
        const address = res.data;
        setRegister({
          ...register,
          cep: cepMask(address?.cep),
          city: address?.city,
          district: address?.district,
          state: address?.state,
          address: address?.address
        });
      })
      .catch(async () => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `CEP Não encontrado`
        });
      });
  };

  const handleSubmit = async (event) => {
    setDisableBtn(true);

    event.preventDefault();

    Swal.fire({
      title: 'Realizando cadastro',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await createPromoter({
      ...register,
      affiliate
    })
      .then(async () => {
        setDisableBtn(false);
        setRegister({
          name: null,
          email: null,
          password: null,
          corporativeName: null,
          document: null,
          cep: null,
          address: null,
          district: null,
          city: null,
          number: null,
          complement: null,
          phone: null,
          uf: null,
          affiliate: null
        });
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Seu registro foi realizado com sucesso`
        }).then((confirm) => {
          if (!confirm.isConfirmed) return;
          history.push('/auth');
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const validateRequired = () => {
    setDisableBtn(false);

    const requiredInputs = [
      'name',
      'corporativeName',
      'document',
      'phone',
      'movelPhone',
      'cep',
      'address',
      'number',
      'city',
      'state',
      'district',
      'sellerName',
      'sellerDocument',
      'sellerPhone',
      'password',
      'email',
      'repeatPassword',
      'recommendation'
    ];

    for (const key in requiredInputs) {
      if (Object.hasOwnProperty.call(requiredInputs, key)) {
        const element = requiredInputs[key];
        if (register[element] === '') setDisableBtn(true);
      }
    }
  };

  useEffect(() => {
    setAffiliate(pathname.replace('/register/', ''));

    document.body.classList.add('bg-default');
    return () => {
      document.body.classList.remove('bg-default');
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    validateRequired();
  }, [location, register]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        {/* Page content */}
        <Container className="mt-5 pb-5">
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2">
                    <h1>Realize seu cadastro</h1>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-4">
                  <Form role="form" onSubmit={handleSubmit}>
                    <Alert color="warning">
                      Todos os campos com (*) são obrigatórios
                    </Alert>
                    <Card className="shadow">
                      <CardHeader>
                        <h3>Informações da Empresa</h3>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="name">
                                Nome do Responsável do cadastro{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="name"
                                value={register.name}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    name: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="corporativeName">
                                Razão Social{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="corporativeName"
                                value={register.corporativeName}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    corporativeName: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="document">
                                CNPJ <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="document"
                                value={register.document}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    document: maskCNPJ(ev.target.value)
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="phone">
                                Celular Contato{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                placeholder="(xx) x xxxx-xxxx"
                                id="phone"
                                value={register.phone}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    phone: phoneMask(ev.target.value)
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="movelPhone">
                                Celular WhatsApp{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                placeholder="(xx) x xxxx-xxxx"
                                id="movelPhone"
                                value={register.movelPhone}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    movelPhone: phoneMask(ev.target.value)
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="recommendation">
                                Como Conheceu a izidev?
                                <strong className="text-danger">*</strong>
                              </Label>
                              <select
                                id="recommendation"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    recommendation: ev.target.value
                                  });
                                }}
                                className="form-control"
                              >
                                <option value="">Selecione...</option>
                                <option value="INSTAGRAM">INSTAGRAM</option>
                                <option value="FACEBOOK">FACEBOOK</option>
                                <option value="GOOGLE">GOOGLE</option>
                                <option value="GVN">GVN</option>
                                <option value="AD">AD PROMOTORA</option>
                                <option value="BEVICRED">BEVI</option>
                                <option value="LEV">LEV</option>
                                <option value="ALCIF+">ALCIF+</option>
                                <option value="FONTES">FONTES</option>
                                <option value="TNPROMOTORA">
                                  TN PROMOTORA
                                </option>
                                <option value="NOVAPROMOTORA">
                                  NOVA PROMOTORA
                                </option>
                                <option value="ICRED">ICRED</option>
                                <option value="GRANDINOBANK">
                                  GRANDINOBANK
                                </option>
                                <option value="NOVOSAQUE">NOVO SAQUE</option>
                                <option value="LOTUSMAIS">LOTUS MAIS</option>
                                <option value="UPP">UPP</option>
                                <option value="C6BANK">
                                  C6 BANK / C6 CONSIGNADO
                                </option>
                                <option value="PAN">BANCO PAN</option>
                                <option value="SAFRA">SAFRA FINANCEIRA</option>
                                <option value="FACTA">FACTA</option>
                                <option value="MERCANTIL">MERCANTIL</option>
                                <option value="CREFAZ">CREFAZ</option>
                                <option value="CONSIGA">CONSIGA</option>
                                <option value="OUTROS">OUTROS</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="shadow mt-4">
                      <CardHeader>
                        <h3>Endereço da empresa</h3>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label for="cep">
                                CEP <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="cep"
                                value={register.cep}
                                onChange={handleIdentifyAddressUserByCEP}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="address">
                                Rua <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="address"
                                value={register.address}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    address: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="number">
                                Número{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="number"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    number: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label for="district">
                                Bairro{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="district"
                                value={register.district}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    district: ev.target.value
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label for="city">
                                Cidade{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="city"
                                disabled
                                value={register.city}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label for="state">
                                Estado{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="state"
                                disabled
                                value={register.state}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label for="complement">Complemento</Label>
                              <Input
                                type="text"
                                id="complement"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    complement: ev.target.value
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="shadow mt-4">
                      <CardHeader>
                        <h3>Informações do responsável da empresa</h3>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="seller">
                                Nome Completo{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="sellerName"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    sellerName: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="sellerDocument">
                                CPF <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="sellerDocument"
                                value={register.sellerDocument}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    sellerDocument: maskCPF(ev.target.value)
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="sellerPhone">
                                Celular WhatsApp{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                placeholder="(xx) x xxxx-xxxx"
                                id="sellerPhone"
                                value={register.sellerPhone}
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    sellerPhone: phoneMask(ev.target.value)
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="shadow mt-4">
                      <CardHeader>
                        <h3>Dados de acesso</h3>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="email">
                                E-mail{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="text"
                                id="email"
                                value={register.email}
                                autocomplete="off"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    email: ev.target.value
                                  });
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="password">
                                Senha <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    password: ev.target.value
                                  });

                                  setShowMsgPassword(false);
                                  if (
                                    ev.target.value != register.repeatPassword
                                  ) {
                                    setShowMsgPassword(true);
                                  }
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label for="repeatPassword">
                                Repetir senha{' '}
                                <strong className="text-danger">*</strong>
                              </Label>
                              <Input
                                type="password"
                                id="repeatPassword"
                                autoComplete="new-password"
                                onChange={(ev) => {
                                  setRegister({
                                    ...register,
                                    repeatPassword: ev.target.value
                                  });

                                  setShowMsgPassword(false);
                                  if (ev.target.value != register.password) {
                                    setShowMsgPassword(true);
                                  }
                                }}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {showMsgPassword && (
                          <Alert color="danger">
                            As senhas devem ser iguais, por favor verifique
                          </Alert>
                        )}

                        <FormGroup>
                          <Label className="ml-3">
                            <Input type="checkbox" required />
                            Aceito os{' '}
                            <a
                              href="https://storage.googleapis.com/simulador-fgts/contrato/TERMOS%20GERAIS%20E%20CONDI%C3%87%C3%95ES%20DE%20USO%20IZIDEV%20DESENVOLVIMENTO%20DE%20SISTEMAS.pdf"
                              target="_blank"
                              className=""
                            >
                              termos de uso e condições
                            </a>{' '}
                            da izidev
                          </Label>
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button color="success" disabled={disableBtn}>
                          <i className="fas fa-save" /> Concluir Cadastro
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Register;
