import { useEffect } from 'react';
import {
  Alert,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import RuleTableSimulationComponent from '../RuleTableSimulationComponent';
import { ICredTable } from 'utils/EnumTablesBank';
import MyTablesComponent from '../myTablesComponent';

const AccessBankiCredForm = ({ febrabanId, data, setData }) => {
  useEffect(() => {
    setData({
      ...data,
      febrabanId: febrabanId
    });
  }, []);

  if (!febrabanId || febrabanId !== '329') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas FGTS
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Acompanhamento de Propostas
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Reenvio de link de formalização
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>
                Promotora <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                value={data.promoterIntegration}
                onChange={(ev) => {
                  setData({
                    ...data,
                    promoterIntegration: ev.target.value,
                    login: ev.target.value === 'OTHER' ? '' : data.login
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                {/* <option value="GVN">GVN PROMOTORA</option> */}
                <option value="LEV">LEV PROMOTORA</option>
                <option value="AD">AD PROMOTORA</option>
                {/* <option value="ROCHACRED">ROCHA CRED</option>
                <option value="HIPERCRED">HIPERCRED</option> */}
                <option value="TNPROMOTORA">TN PROMOTORA</option>
                <option value="NOVAPROMOTORA">NOVA PROMOTORA</option>
                <option value="OTHER">OUTRA</option>
              </select>
            </FormGroup>
          </Col>
          {data?.promoterIntegration === 'OTHER' && (
            <Col md="4">
              <FormGroup>
                <Label>
                  LOGIN (clientId) <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>izidev-</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    autoComplete="false"
                    value={data.login}
                    placeholder="Ex: 00000000000"
                    className="border"
                    onChange={(ev) => {
                      setData({
                        ...data,
                        login: ev.target.value
                      });
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          )}
          <Col md="4">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value)
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar Tabela de Crédito - FGTS</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                Tabela FGTS <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.tableAmmount}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    tableAmmount: ev.target.value
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                {ICredTable.map((icredTab, keyIcred) => (
                  <option value={icredTab.code} key={keyIcred}>{icredTab.name}</option>
                ))}
              </select>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Com seguro <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useSafe}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useSafe: ev.target.value
                  });
                }}
              >
                <option value="0">NÃO</option>
                <option value="1">SIM</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Mínimo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="minValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.minValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    minValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada a
                partir do valor informado. Caso o valor liberado do FGTS esteja
                abaixo do valor informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Simulador MultiBancos</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useMultBankFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useMultBankFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Condição tabela: FGTS | LIGHT 90</h3>
        <Alert color="primary">
          <p className="m-0 text-white">
            <strong>Atenção: </strong> Esta condição foi implementada, para que
            seja possível realizar a configuração da tabela LIGHT, que entrou em
            vigor no dia 06/05/2024 para simulações RESAQUE na icred. Problemas
            ou sugestões,{' '}
            <strong>
              <a
                href="https://wa.me/551150439783?text=Ol%C3%A1%20gostaria%20de%20tirar%20uma%20d%C3%BAvida%20sobre%20a%20regra%20de%20simula%C3%A7%C3%A3o"
                target="_blank"
                className="text-secondary"
              >
                favor entrar em contato no suporte.
              </a>
            </strong>
          </p>
        </Alert>
        <Row>
          <Col md="6">
            <FormGroup>
              <select
                type="text"
                autoComplete="false"
                value={data.useAutomation}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useAutomation: ev.target.value
                  });
                }}
              >
                <option value="0">SEM SEGURO</option>
                <option value="1">COM SEGURO</option>
                <option value="2">IGNORAR TABELA</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <RuleTableSimulationComponent febrabanId={febrabanId} />
      <MyTablesComponent febrabanId={febrabanId} />
    </CardBody>
  );
};

export default AccessBankiCredForm;
