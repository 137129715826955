import { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Button,
  CardFooter,
  Badge,
  Alert,
  Table
} from 'reactstrap';
import Header from 'components/Headers/Header';
import SmsTemplateComponent from './Component/SmsTemplateComponent';
import { listTemplateService } from 'services/Register/SmsService';
import Swal from 'sweetalert2';
import { createCampaignSmsService } from 'services/Register/SmsService';
import { listCampaignSmsService } from 'services/Register/SmsService';
import SmsCardResumeComponent from './Component/SmsCardResumeComponent';

const SmsCampaignView = () => {
  const [register, setRegister] = useState({
    title: '',
    message: '',
    numbers: ''
  });
  const [showTemplate, setShowTemplate] = useState(false);
  const [listTemplate, setListTemplate] = useState([]);
  const [list, setList] = useState([]);

  const handleListTemplate = () => {
    listTemplateService()
      .then(({ data }) => {
        setListTemplate(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSubmit = () => {
    if (register.title === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Título não informado'
      });
    }

    if (register.message === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Mensagem não informada'
      });
    }

    if (register.message.length > 144) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A mensagem informada possui mais que 144 caracteres'
      });
    }

    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja cadastrar esta campanha?',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, cadastrar campanha'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Carregando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      createCampaignSmsService(register)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Campanha cadastrada com sucesso, aguarde o envio.'
          }).then(() => {
            setRegister({
              title: '',
              message: '',
              numbers: ''
            });
            handleList();
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listCampaignSmsService()
      .then(({ data }) => {
        Swal.close();
        setList(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList();
    handleListTemplate();
  }, []);

  return (
    <>
      <Header titlePage="SMS - Relatório de envios" />
      <Container className="mt--9" fluid>
        <SmsCardResumeComponent />
        <Card className="shadow">
          <CardHeader>
            <h2>SMS - Campanha</h2>
          </CardHeader>
          <CardBody>
            <Card className="shadow mb-3">
              <CardBody>
                <FormGroup>
                  <Label>Modelo padrão</Label>
                  <InputGroup>
                    <select
                      className="form-control"
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          title: listTemplate[ev.target.value]?.title ?? '',
                          message: listTemplate[ev.target.value]?.message ?? ''
                        });
                      }}
                    >
                      <option value="">Selecione...</option>
                      {listTemplate.map((template, key) => (
                        <option value={key} key={key}>
                          {template.title}
                        </option>
                      ))}
                    </select>
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowTemplate(true);
                        }}
                      >
                        Adicionar modelo
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </CardBody>
            </Card>
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for="title">Título da campanha</Label>
                      <Input
                        type="text"
                        id="title"
                        value={register.title}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            title: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="numbers">
                        Números{' '}
                        <Badge color="secondary">
                          (Você pode adicionar até 1000 números)
                        </Badge>
                      </Label>
                      <textarea
                        id="numbers"
                        className="form-control"
                        value={register.numbers}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            numbers: ev.target.value.replace(/[^0-9;]/g, '')
                          });
                        }}
                      />
                    </FormGroup>
                    <Alert color="secondary">
                      Insira seus contatos no formato DDD+Número, exemplo:
                      <strong>11988887777</strong> e coloque o sinal{' '}
                      <strong>; (ponto e vírgula)</strong> ao final de cada
                      número de contato.
                    </Alert>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label for="message">
                        Mensagem a ser enviada{' '}
                        <Badge color="info">{register.message.length}</Badge>
                      </Label>
                      <textarea
                        id="message"
                        className="form-control"
                        value={register.message}
                        maxLength={144}
                        style={{ height: 100 }}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            message: ev.target.value
                          });
                        }}
                      />
                    </FormGroup>
                    <Alert color="secondary">
                      A mensagem de texto deve conter no máximo 144 caracteres.
                    </Alert>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={() => handleSubmit()}>
              Salvar e enviar campanha
            </Button>
          </CardFooter>
        </Card>
        {list.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>Últimas campanhas</CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th>DATA CADASTRO</th>
                  <th>TITULO DA CAMPANHA</th>
                  <th>MENSAGEM ENVIADA</th>
                  <th>QTD. NÚMEROS</th>
                  <th>QTD. AGUARDANDO</th>
                  <th>QTD. ENVIADOS</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key}>
                    <td>{item.createdAt}</td>
                    <td>{item.title}</td>
                    <td>{item.message}</td>
                    <td>{item.qtd}</td>
                    <td>{item?.qtdWait ?? 0}</td>
                    <td>{item?.qtdSend ?? 0}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container>

      <SmsTemplateComponent
        isOpen={showTemplate}
        setIsOpen={(e) => {
          setShowTemplate(e);
          handleListTemplate();
        }}
      />
    </>
  );
};

export default SmsCampaignView;
