export const BanksEnum = [
  {
    name: 'BMG',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/bmg.png'
  },
  {
    name: 'CREFAZ',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/crefaz.png'
  },
  {
    name: 'MERCANTIL',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/mercantil.png'
  },
  {
    name: 'C6 CONSIG',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/c6.png'
  },
  {
    name: 'ICRED',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/icred.png'
  },
  {
    name: 'BANCO MASTER',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/bancomaster.png'
  },
  {
    name: 'NOVO SAQUE',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/novosaque.png'
  },
  {
    name: 'QUERO+CREDITO',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/queromaiscredito.png'
  },
  {
    name: 'SAFRA',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/safra.png'
  },
  {
    name: 'UPP',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/upp.png'
  },
  {
    name: 'BANCO PAN',
    icon: 'https://storage.googleapis.com/simulador-fgts/img/banks/pan.png'
  }
];
