import Header from 'components/Headers/Header';
import { TableData } from 'components/Table';
import { TableHeader } from 'components/Table';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableBody } from 'components/Table';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table
} from 'reactstrap';
import { showSmsCreditsByPromoter } from 'services/Register/SmsService';
import { IndexSmsReportService } from 'services/Register/SmsService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';

const SmsReportView = () => {
  const [registers, setRegisters] = useState([]);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [credits, setCredits] = useState({
    id: null,
    amount: 0,
    dateLastsendSms: 0,
    valueAmount: 0
  });
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null
  });

  const handleFilter = async (isMore = false) => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let filterSearch = {
      ...filter
    };

    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0
      });

      filterSearch = {
        ...filter,
        previusLimit: 0
      };
    }

    IndexSmsReportService(filterSearch)
      .then(({ data }) => {
        Swal.close();
        setFilter({
          ...filter,
          previusLimit: data.resultSet.limit
        });
        if (isMore) {
          setRegisters([...registers, ...data.resultSet.result]);
          setShowBtnMoreRegisters(data?.resultSet?.result?.length > 0);
        } else {
          setRegisters(data.resultSet.result);
          setShowBtnMoreRegisters(data?.resultSet?.result?.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleShowCredits = async () => {
    showSmsCreditsByPromoter().then(({ data }) => {
      console.log(data.resultSet);
      setCredits(data.resultSet);
    });
  };

  useEffect(() => {
    handleFilter();
    handleShowCredits();
  }, []);

  return (
    <>
      <Header titlePage="SMS - Relatório de envios" />
      <Container className="mt--9" fluid>
        {credits.id > 0 && (
          <Row>
            <Col md="6">
              <Card className="shadow mb-2">
                <CardBody>
                  Qtd. SMS Restantes:{' '}
                  <Badge color="success" className="font-12">
                    {credits.amount}
                  </Badge>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="shadow mb-2">
                <CardBody>
                  Data último envio:{' '}
                  <Badge color="primary" className="font-12">
                    {credits.dateLastsendSms}
                  </Badge>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Card className="shadow">
          <CardHeader>
            <h2>SMS - Relatório de envios</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Nº Celular</Label>
                  <Input
                    id="number"
                    name="number"
                    className="border"
                    value={filter.number}
                    placeholder="(00) 0 0000-0000"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        number: phoneMask(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              onClick={() => {
                handleFilter();
              }}
            >
              Buscar
            </Button>
          </CardFooter>
        </Card>

        {registers.length <= 0 && (
          <>
            <Alert color="secondary shadow mt-3">
              Não foram identificados registros para esta consulta
            </Alert>
          </>
        )}

        {registers.length > 0 && (
          <>
            <Card className="mt-3">
              <CardHeader>Registros</CardHeader>
              <div className="table-responsive">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Data Cadastro</TableHead>
                      <TableHead>Nº Celular</TableHead>
                      <TableHead>Mensagem Enviada</TableHead>
                      <TableHead>Status</TableHead>
                      {/* <TableHead>Opções</TableHead> */}
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {registers.map((register, key) => (
                      <>
                        <TableRow key={key}>
                          <TableData>{register.createdAt}</TableData>
                          <TableData>
                            <h2>{phoneMask(register.number)}</h2>
                          </TableData>
                          <TableData>
                            <h3>{register.title}</h3>
                            <p>{register.message}</p>
                          </TableData>
                          <TableData>
                            <Badge color="info">{register.status}</Badge>
                          </TableData>
                          {/* <TableData className="d-none d-xl-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  href={register.urlSign}
                                  target="_blank"
                                >
                                  <i className="fas fa-file-signature" /> Link
                                  para assinatura
                                </DropdownItem>
                                {register.urlContractSigned && (
                                  <DropdownItem
                                    href={register.urlContractSigned}
                                    target="_blank"
                                  >
                                    <i className="fas fa-signature" /> Contrato
                                    Assinado
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData> */}
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                  {showBtnMoreRegisters && (
                    <TableBody>
                      <TableRow>
                        <td colSpan={5}>
                          <Button
                            className="w-100"
                            onClick={() => {
                              setFilter({ ...filter });
                              handleFilter(true);
                            }}
                          >
                            Carregar mais...
                          </Button>
                        </td>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default SmsReportView;
