import { useEffect } from 'react';
import { useState } from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import { showSmsCreditsByPromoter } from 'services/Register/SmsService';

const SmsCardResumeComponent = () => {
  const [credits, setCredits] = useState({});

  const handleShowCredits = async () => {
    showSmsCreditsByPromoter().then(({ data }) => {
      console.log(data.resultSet);
      setCredits(data.resultSet);
    });
  };

  useEffect(() => {
    handleShowCredits();
  }, []);

  return (
    <>
      {credits.id > 0 && (
        <Row>
          <Col md="6">
            <Card className="shadow mb-2">
              <CardBody>
                Qtd. SMS Restantes:{' '}
                <Badge color="success" className="font-12">
                  {credits.amount}
                </Badge>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="shadow mb-2">
              <CardBody>
                Data último envio:{' '}
                <Badge color="primary" className="font-12">
                  {credits.dateLastsendSms}
                </Badge>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SmsCardResumeComponent;
