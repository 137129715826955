import { useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { viaCEP } from 'services/cepApi';
import { brasilApiCep } from 'services/cepApi';
import Swal from 'sweetalert2';
import { cepMask } from 'utils/mask';

const FormRegisterAddressComponent = ({
  register,
  setRegister,
  isRequired = true,
  cepIsReadOnly = false
}) => {
  const handleIdentifyAddressUserByCEP = async (ev) => {
    setRegister({
      ...register,
      cep: cepMask(ev.target.value)
    });

    Swal.fire({
      title: 'Consultando CEP',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    await brasilApiCep(ev.target.value)
      .then((res) => {
        Swal.close();
        const address = res.data;
        setRegister({
          ...register,
          address: address.street,
          district: address.neighborhood,
          city: address.city,
          state: address.state
        });
      })
      .catch(async () => {
        viaCEP(ev.target.value)
          .then((res) => {
            Swal.close();
            const address = res.data;
            setRegister({
              ...register,
              address: address.logradouro,
              district: address.bairro,
              city: address.localidade,
              state: address.uf
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`
              // footer: '<a href="">Why do I have this issue?</a>'
            });
            setRegister({
              ...register,
              address: null,
              district: null,
              city: null,
              state: null
            });
          });
      });
  };

  const handleSearchCEP = async (register) => {
    await brasilApiCep(register.cep)
      .then((res) => {
        Swal.close();
        const address = res.data;
        setRegister({
          ...register,
          address: address.street,
          district: address.neighborhood,
          city: address.city,
          state: address.state
        });
      })
      .catch(async () => {
        viaCEP(register.cep)
          .then((res) => {
            Swal.close();
            const address = res.data;
            setRegister({
              ...register,
              address: address.logradouro,
              district: address.bairro,
              city: address.localidade,
              state: address.uf
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`
            });
            setRegister({
              ...register,
              address: null,
              district: null,
              city: null,
              state: null
            });
          });
      });
  };

  useEffect(async () => {
    if (cepIsReadOnly) {
      handleSearchCEP(register);
    }
  }, []);

  return (
    <>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label>
              CEP {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.cep}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  cep: cepMask(ev.target.value)
                });
              }}
              onBlur={handleIdentifyAddressUserByCEP}
              readOnly={cepIsReadOnly}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              Endereço {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.address}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  address: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Número {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.numberAddress}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  numberAddress: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              Bairro {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.district}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  district: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              Cidade {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input value={register.city} readOnly />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label>
              Estado {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input value={register.state} readOnly />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormRegisterAddressComponent;
