import FormAccountBankComponent from 'components/Form/DigitationProposal/FormAccountBankComponent';
import FormPersonDataComponent from 'components/Form/Register/FormPersonDataComponent';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import Header from 'components/Headers/Header';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  indexSimuleService,
  storeProposalService,
} from 'services/Consigned/InssService';
import { indexSafraService } from 'services/Digitation/IntegrationService';
import { ShowClientProposalService } from 'services/Register/ClientService';
import Swal from 'sweetalert2';
import { PAN_SERVICES } from 'utils/EnumServicesINSS';
import { maskDate, maskCPF, phoneMask, moneyMask } from 'utils/mask';
import { emailIsValid, isEmpty, dateIsValid } from 'utils/ValidateField';

const DigitationManualView = () => {
  const history = useHistory();
  const [listSafraService, setListSafraService] = useState([]);
  const [resultSimulation, setResultSimulation] = useState({
    status: false,
    message: '',
    result: [],
  });
  const [simulationSelected, setSimulationSelected] = useState({
    valueLiberty: 0,
    valueFinally: 0,
    installment: 0,
    valueInstallment: 0,
    default: [],
    table: null,
    tableCode: null,
  });
  const [tableSimulationSelected, setTableSimulationSelected] = useState(-1);
  const [stage, setStage] = useState(1);
  const [navItem, setNavItem] = useState(1);
  const [digitation, setDigitation] = useState({
    cpf: null,
    birthDate: null,
    name: null,
    phone: null,
    registry: null,
    febrabanId: '074',
    simulation: {
      serviceId: 50000,
      marginIncrease: false,
      valueInstallment: 0,
      installment: 84,
      result: [],
    },
    email: null,
    documentRG: null,
    dateEmissionRG: null,
    ufRG: null,
    sex: null,
    stateCivil: null,
    nameMother: null,
    cep: null,
    address: null,
    numberAddress: null,
    district: null,
    state: null,
    city: null,
    complementAddress: null,
    bank: null,
    agencyBank: null,
    numberAccountBank: null,
    digitAccountBank: null,
    typeAccount: null,
  });

  const handleCheckRequiredValueSimulation = () => {
    if (
      !digitation.cpf ||
      !digitation.name ||
      !digitation.birthDate ||
      !digitation.phone ||
      !digitation.registry
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Para continuar é preciso preencher todos os campos',
      });
      return false;
    }
    return true;
  };

  const handleNextStage = (valueStage) => {
    if (valueStage < 1) return;
    if (!handleCheckRequiredValueSimulation()) return;
    setStage(parseInt(valueStage));
  };

  const handleSimule = () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setResultSimulation({
      status: false,
      message: '',
      result: [],
    });
    indexSimuleService({
      serviceId: digitation.simulation.serviceId,
      registry: digitation.registry,
      valueInstallment: digitation.simulation.valueInstallment,
      installment: digitation.simulation.installment,
      febrabanId: digitation.febrabanId,
      cpf: digitation.cpf,
      birthDate: digitation.birthDate,
      name: digitation.name,
    })
      .then(({ data }) => {
        Swal.close();
        const { status, message, resultSet } = data;

        if (digitation.febrabanId === '074') {
          setResultSimulation({
            status,
            message,
            result: resultSet.simulacoes,
          });
        }

        if (digitation.febrabanId === '623') {
          setResultSimulation({
            status,
            message,
            result: resultSet.condicoes_credito,
          });
        }

        if (!status) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: message,
          });
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDigitation = () => {
    try {
      console.log(digitation);

      dateIsValid(digitation.birthDate, 'Data de Nascimento', true);
      isEmpty(digitation.email, 'Campo: E-mail é obrigatório');
      emailIsValid(digitation.email);
      isEmpty(digitation.documentRG, 'Campo: RG é obrigatório');
      isEmpty(
        digitation.dateEmissionRG,
        'Campo: Data Emissão RG é obrigatório'
      );
      isEmpty(digitation.ufRG, 'Campo: UF Rg é obrigatório');
      isEmpty(digitation.sex, 'Campo: Sexo é obrigatório');
      isEmpty(digitation.nameMother, 'Campo: Nome da mãe');
      isEmpty(digitation.cep, 'Campo: CEP é obrigatório');
      isEmpty(digitation.address, 'Campo: Endereço é obrigatório');
      isEmpty(digitation.numberAddress, 'Campo: Número é obrigatório');
      isEmpty(digitation.district, 'Campo: Bairro é obrigatório');
      isEmpty(digitation.city, 'Campo: Cidade é obrigatória');
      isEmpty(digitation.state, 'Campo: Estado é obrigatório');
      isEmpty(digitation.bank, 'Campo: Banco é obrigatório');
      isEmpty(digitation.agencyBank, 'Campo: Agência é obrigatório');
      isEmpty(
        digitation.numberAccountBank,
        'Campo: Número da conta é obrigatório'
      );
      isEmpty(
        digitation.digitAccountBank,
        'Campo: Digito da conta é obrigatório'
      );
      isEmpty(
        digitation.typeAccount,
        'Campo: Tipo de conta da conta é obrigatório'
      );

      Swal.fire({
        title: 'Digitando proposta',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      storeProposalService({
        febrabanId: digitation.febrabanId,
        serviceId: digitation.simulation.serviceId,
        marginIncrease: digitation.simulation.marginIncrease,
        simulation: simulationSelected.default,
        phone: digitation.phone,
        name: digitation.name,
        cpf: digitation.cpf,
        birthDate: digitation.birthDate,
        registry: digitation.registry,
        sex: digitation.sex,
        documentRG: digitation.documentRG,
        dateEmissionRG: digitation.dateEmissionRG,
        ufRG: digitation.ufRG,
        nameMother: digitation.nameMother,
        email: digitation.email,
        cep: digitation.cep,
        address: digitation.address,
        numberAddress: digitation.numberAddress,
        district: digitation.district,
        city: digitation.city,
        state: digitation.state,
        complement: digitation.complementAddress,
        bank: digitation.bank,
        agencyBank: digitation.agencyBank,
        numberAccountBank: digitation.numberAccountBank,
        digitAccountBank: digitation.digitAccountBank,
        typeAccount: digitation.typeAccount,
        valueLiberty: simulationSelected.valueLiberty,
        valueFinally: simulationSelected.valueFinally,
        valueParc: simulationSelected.valueInstallment,
        parc: simulationSelected.installment,
      })
        .then(({ data }) => {
          Swal.close();
          const { status, message, resultSet } = data;

          if (!status) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `[000000] ${message}`,
            });
            return;
          }

          let html = `
          <h3>Proposta Digitada com sucesso</h3>
          <p>Nº da Proposta: <strong>${resultSet.numberProposal}</strong></p>
          `;

          if (resultSet.urlFormalize !== '') {
            html += `<div>
            <a class="btn btn-info btn-md" href="${resultSet.urlFormalize}" target="blank">Formalizar Proposta</a>
            </div>`;
          }

          Swal.fire({
            icon: 'success',
            html: html,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Digitar novo contrato',
            showCancelButton: true,
            cancelButtonText: 'Ok, Finalizar',
            showDenyButton: true,
            denyButtonText: 'Digitar mesmo contrato',
            denyButtonColor: '#17a2b8',
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              window.location.reload();
              return;
            }

            if (confirm.isDenied) {
              handleNextStage(2);
              return;
            }

            history.push('/admin/inss/proposal/report');
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      });
    }
  };

  const handleGetClientByDocument = async (document) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Deseja buscar propostas digitadas, para este cliente, em seu cadastro?',
      showDenyButton: true,
      denyButtonText: 'Sair',
      confirmButtonText: 'Ok, continuar',
    })
      .then(async (confirm) => {
        if (!confirm.isConfirmed) return;

        Swal.fire({
          title: 'Consultando',
          text: 'Por favor, aguarde...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await ShowClientProposalService(
          document.replace(/[^0-9]/g, ''),
          1
        ).then(({ data }) => {
          Swal.close();

          const { resultSet } = data;
          if (resultSet.length <= 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Ops!',
              text: 'Não foram identificados registros para este cliente',
            });

            return;
          }

          console.log(resultSet);

          setDigitation({
            ...digitation,
            email: resultSet?.email,
            cep: resultSet?.CEP,
            dateEmissionRG: dayjs(resultSet?.dateEmissionRG).format(
              'DD/MM/YYYY'
            ),
            sex: resultSet?.sex,
            nameMother: resultSet?.nameMother,
            documentRG: resultSet?.documentRG,
            ufRG: resultSet?.ufRG,
            address: resultSet?.address,
            numberAddress: resultSet?.numberAddress,
            state: resultSet?.state,
            district: resultSet?.district,
            city: resultSet?.city,
            bank: resultSet?.bank,
            numberAccountBank: resultSet?.numberAccountBank,
            agencyBank: resultSet?.agencyBank,
            digitAccountBank: resultSet?.digitAccountBank,
            typeAccount: resultSet?.typeAccount,
          });
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    indexSafraService(setListSafraService);
  }, []);

  return (
    <>
      <Header titlePage="INSS - Realizar digitação" />
      <Container className="mt--9" fluid>
        {stage === 1 && (
          <>
            <Alert color="primary">
              <p className="m-0">
                <i className="fas fa-info-circle" /> Nesta página você pode
                digitar os produtos do Consignado nos bancos{' '}
                <strong>PAN</strong> e <strong>SAFRA</strong>, a integração é
                feita por API.
              </p>
            </Alert>
            <Card className="shadow">
              <CardHeader>
                <i className="fas fa-user" /> Dados do cliente
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        CPF <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={digitation.cpf}
                        placeholder="xxx.xxx.xxx-xx"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            cpf: maskCPF(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        Nome do cliente <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={digitation.name}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            name: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        Data de Nascimento{' '}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={digitation.birthDate}
                        placeholder="--/--/----"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            birthDate: maskDate(ev.target.value),
                          });
                        }}
                        maxLength="10"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        WhatsApp <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={digitation.phone}
                        placeholder="(xx) x xxxx-xxxx"
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            phone: phoneMask(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>
                        Matricula <span className="text-danger">*</span>
                      </Label>
                      <Input
                        value={digitation.registry}
                        onChange={(ev) => {
                          setDigitation({
                            ...digitation,
                            registry: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="success"
                  onClick={() => {
                    handleNextStage(2);
                  }}
                >
                  <i className="fas fa-arrow-right" /> Continuar
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
        {stage === 2 && (
          <>
            <Button
              className="btn btn-warning"
              onClick={() => {
                handleNextStage(1);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </Button>
            <Card className="shadow mt-3">
              <CardHeader>
                <i className="fas fa-poll-h" /> Simulação de crédito
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={navItem === 1 ? 'active' : null}
                      onClick={() => {
                        setNavItem(1);
                        setDigitation({
                          ...digitation,
                          febrabanId: '074',
                        });
                        setResultSimulation([]);
                        setSimulationSelected({});
                        setTableSimulationSelected(-1);
                      }}
                    >
                      SAFRA
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={navItem === 2 ? 'active' : null}
                      onClick={() => {
                        setNavItem(2);
                        setDigitation({
                          ...digitation,
                          febrabanId: '623',
                        });
                        setResultSimulation([]);
                        setSimulationSelected({});
                        setTableSimulationSelected(-1);
                      }}
                    >
                      PAN
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={navItem} className="p-4 border">
                  <TabPane tabId={1}>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label>Aumento de Margem</Label>
                          <select
                            value={digitation.simulation.marginIncrease}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  marginIncrease: ev.target.value,
                                },
                              });
                            }}
                            className="form-control"
                          >
                            <option value={0}>Não</option>
                            <option value={1}>Sim</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Convênio</Label>
                          {listSafraService.length > 0 &&
                          digitation.febrabanId === '074' ? (
                            <select
                              value={digitation.simulation.serviceId}
                              onChange={(ev) => {
                                setDigitation({
                                  ...digitation,
                                  simulation: {
                                    ...digitation.simulation,
                                    serviceId: ev.target.value,
                                  },
                                });
                              }}
                              className="form-control"
                            >
                              <option value="">Selecione...</option>
                              {listSafraService.map((register, key) => (
                                <option value={register.idConvenio} key={key}>
                                  {register.idConvenio} - {register.nome}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <>
                              <h3 className="text-danger">
                                Não foram identificados convênios, verifique a
                                API
                              </h3>
                            </>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Prazo</Label>
                          <Input
                            value={digitation.simulation.installment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  installment: ev.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Valor da Parcela</Label>
                          <Input
                            value={digitation.simulation.valueInstallment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  valueInstallment: moneyMask(ev.target.value),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" onClick={handleSimule}>
                      <i className="fas fa-pray" /> Simular condições de crédito
                    </Button>
                    {resultSimulation.status &&
                      resultSimulation.result.length > 0 &&
                      digitation.febrabanId === '074' && (
                        <Card className="mt-5 shadow">
                          <CardHeader>Resultado Simulação</CardHeader>
                          <CardBody>
                            <h3 className="mb-4 text-primary">
                              Selecione abaixo a tabela que deseja utilizar na
                              digitação do contrato
                            </h3>
                            {resultSimulation.result.map((register, key) => (
                              <Label
                                key={key}
                                className="d-block mt-2 mb-2"
                                style={{ cursor: 'pointer' }}
                              >
                                <Card
                                  className={
                                    key == tableSimulationSelected
                                      ? 'bg-success text-white'
                                      : ''
                                  }
                                >
                                  <CardBody>
                                    <Row>
                                      <Col md="3">
                                        <Input
                                          type="radio"
                                          name="simulationSelected"
                                          value={key}
                                          onChange={(ev) => {
                                            setTableSimulationSelected(
                                              ev.target.value
                                            );
                                            setSimulationSelected({
                                              tableCode: register.idTabelaJuros,
                                              table: register.dsTabelaJuros,
                                              installment: register.prazo,
                                              valueInstallment:
                                                register.valorParcela,
                                              valueLiberty:
                                                register.valorPrincipal,
                                              valueFinally:
                                                register.valorFinanciado,
                                              default: register,
                                            });
                                          }}
                                          hidden
                                        />
                                        <p className="m-0">Tabela</p>
                                        <h3 className="m-0">
                                          {register.idTabelaJuros} -{' '}
                                          {register.dsTabelaJuros} -{' '}
                                          {register.prazo}x
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Parcela</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valorParcela.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Valor Principal</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valorPrincipal.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Valor Financiado</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valorFinanciado.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Label>
                            ))}
                          </CardBody>
                        </Card>
                      )}
                  </TabPane>
                  <TabPane tabId={2}>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Convênio</Label>
                          <select
                            value={digitation.simulation.serviceId}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  serviceId: ev.target.value,
                                },
                              });
                            }}
                            className="form-control"
                          >
                            <option value="">Selecione...</option>
                            {PAN_SERVICES.map((register, key) => (
                              <option key={key} value={register.codigo}>
                                {register.codigo} - {register.nome}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Prazo</Label>
                          <Input
                            value={digitation.simulation.installment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  installment: ev.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Valor da Parcela</Label>
                          <Input
                            value={digitation.simulation.valueInstallment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                simulation: {
                                  ...digitation.simulation,
                                  valueInstallment: moneyMask(ev.target.value),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" onClick={handleSimule}>
                      <i className="fas fa-pray" /> Simular condições de crédito
                    </Button>

                    {resultSimulation.status &&
                      resultSimulation.result.length > 0 &&
                      digitation.febrabanId === '623' && (
                        <Card className="mt-5 shadow">
                          <CardHeader>Resultado Simulação</CardHeader>
                          <CardBody>
                            <h3 className="mb-4 text-primary">
                              Selecione abaixo a tabela que deseja utilizar na
                              digitação do contrato
                            </h3>
                            {resultSimulation.result.map((register, key) => (
                              <Label
                                key={key}
                                className="d-block mt-2 mb-2"
                                style={{ cursor: 'pointer' }}
                              >
                                <Card
                                  className={
                                    key == tableSimulationSelected
                                      ? 'bg-success text-white'
                                      : ''
                                  }
                                >
                                  <CardBody>
                                    <Row>
                                      <Col md="3">
                                        <Input
                                          type="radio"
                                          name="simulationSelected"
                                          value={key}
                                          onChange={(ev) => {
                                            setTableSimulationSelected(
                                              ev.target.value
                                            );
                                            setSimulationSelected({
                                              tableCode:
                                                register.codigo_tabela_financiamento,
                                              table: register.descricao_produto,
                                              installment: register.prazo,
                                              valueInstallment:
                                                register.valor_parcela,
                                              valueLiberty:
                                                register.valor_cliente,
                                              valueFinally:
                                                register.valor_financiado,
                                              default: register,
                                            });
                                          }}
                                          hidden
                                        />
                                        <p className="m-0">Tabela</p>
                                        <h3 className="m-0">
                                          {register.codigo_tabela_financiamento}{' '}
                                          - {register.descricao_produto} -{' '}
                                          {
                                            register.descricao_tabela_financiamento
                                          }{' '}
                                          - {register.prazo}x
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Parcela</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valor_parcela.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Valor Principal</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valor_cliente.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                      <Col md="3">
                                        <p className="m-0">Valor Financiado</p>
                                        <h3 className="m-0">
                                          R${' '}
                                          {moneyMask(
                                            register.valor_financiado.toFixed(2)
                                          )}
                                        </h3>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Label>
                            ))}
                          </CardBody>
                        </Card>
                      )}
                  </TabPane>
                </TabContent>
              </CardBody>
              {tableSimulationSelected > -1 && (
                <CardFooter>
                  <Button
                    color="success"
                    onClick={() => {
                      handleNextStage(3);
                    }}
                  >
                    <i className="fas fa-arrow-right" /> Continuar
                  </Button>
                </CardFooter>
              )}
            </Card>
          </>
        )}
        {stage === 3 && (
          <>
            <Button
              className="btn btn-warning"
              onClick={() => {
                handleNextStage(2);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </Button>
            <Card className="shadow mt-3">
              <CardHeader>
                <i className="fas fa-wallet" /> Informações da Simulação
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <p className="m-0">Nome</p>
                      <h3 className="m-0 text-primary">{digitation.name}</h3>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <p className="m-0">CPF</p>
                      <h3 className="m-0 text-primary">{digitation.cpf}</h3>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <p className="m-0">Data de Nascimento</p>
                      <h3 className="m-0 text-primary">
                        {digitation.birthDate}
                      </h3>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <p className="m-0">WhatsApp</p>
                      <h3 className="m-0 text-primary">{digitation.phone}</h3>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <p className="m-0">Matricula</p>
                      <h3 className="m-0 text-primary">{digitation.phone}</h3>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="bg-secondary p-4 rounded border">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <p className="m-0">Tabela Selecionada</p>
                        <h3 className="m-0 text-primary">
                          {simulationSelected.tableCode} -{' '}
                          {simulationSelected.table} -{' '}
                          {simulationSelected.installment}x
                        </h3>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Parcela</p>
                      <h3 className="m-0">
                        R${' '}
                        {moneyMask(
                          simulationSelected.valueInstallment.toFixed(2)
                        )}
                      </h3>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Valor Principal</p>
                      <h3 className="m-0">
                        R${' '}
                        {moneyMask(simulationSelected.valueLiberty.toFixed(2))}
                      </h3>
                    </Col>
                    <Col md="3">
                      <p className="m-0">Valor Financiado</p>
                      <h3 className="m-0">
                        R${' '}
                        {moneyMask(simulationSelected.valueFinally.toFixed(2))}
                      </h3>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Alert color="warning" className="shadow mt-4">
              Todos os campos com * são obrigatórios
            </Alert>
            <Card className="shadow mt-4">
              <CardHeader>
                <Button
                  color="info"
                  className="float-right"
                  onClick={() => handleGetClientByDocument(digitation.cpf)}
                >
                  <i className="fas fa-user-tag" /> Atualizar dados do cliente
                </Button>
                <i className="far fa-address-book" /> Dados Pessoais do cliente
              </CardHeader>
              <CardBody>
                <FormPersonDataComponent
                  register={digitation}
                  setRegister={setDigitation}
                />
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader>
                <i className="fas fa-map-marked-alt" /> Endereço
              </CardHeader>
              <CardBody>
                <FormRegisterAddressComponent
                  register={digitation}
                  setRegister={setDigitation}
                />
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader>
                <i className="fas fa-piggy-bank" /> Dados Bancários
              </CardHeader>
              <CardBody>
                <FormAccountBankComponent
                  digitation={digitation}
                  setDigitation={setDigitation}
                />
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={handleDigitation}>
                  Salvar e Enviar para a financeira
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default DigitationManualView;
