import Header from 'components/Headers/Header';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  FormText,
  Input,
} from 'reactstrap';
import banner1 from './componentes/banner1/banner1.jpg';
import * as htmlToImage from 'html-to-image';

import './componentes/banner1/banner1.css';
import { useState } from 'react';

const BannerGenerator = () => {
  const [logo, setLogo] = useState('');

  return (
    <>
      <Header showCards={false} titlePage="iziBanners" />
      <section className="mt--8 container-fluid">
        <Card>
          <CardBody>
            <Alert color="success">
              <h2 className="m-0">
                Você foi selecionado para testar a mais nova funcionalidade da
                izidev, o iziBanner
              </h2>
              <h1>Em breve novos modelos e melhorias</h1>
            </Alert>
            <hr />
            <FormGroup>
              <Input
                type="file"
                onChange={(ev) => {
                  setLogo(URL.createObjectURL(ev.target.files[0]));
                }}
              />
              <FormText>
                Selecione a Logo que deseja utilizar na imagem abaixo
              </FormText>
            </FormGroup>
            <div>
              <div
                id="teste"
                className="text-center"
                style={{
                  width: '600px',
                  height: '600px',
                  backgroundImage: `url(${banner1})`,
                  backgroundSize: 'cover',
                }}
              >
                <img
                  id="logo1"
                  src={logo}
                  alt="SELECIONE SUA LOGO"
                  height={80}
                  className="mt-5"
                />
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              onClick={() => {
                htmlToImage
                  .toJpeg(document.getElementById('teste'), {
                    canvasHeight: '1080',
                    canvasWidth: '1080',
                  })
                  .then(function (dataUrl) {
                    var link = document.createElement('a');
                    link.download = 'banner.jpeg';
                    link.href = dataUrl;
                    link.click();
                  });
              }}
            >
              GERAR
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  );
};

export default BannerGenerator;
