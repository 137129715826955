import { useEffect, useState } from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

import {
  getTypeService,
  getProductService,
  listAllTablesService,
  saveSelectedTablesService,
  listTablesService,
  activeInactiveTableService,
} from 'services/AccessBank/AccessBankService';

import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';

const MyTablesConsignedComponent = ({ febrabanId }) => {
  const [tables, setTables] = useState([]);
  const [list, setList] = useState([]);
  const [tableComissionBankId, setTableComissionBankId] = useState('');
  const [installment, setInstallment] = useState('84');
  const [typeList, setTypeList] = useState([]);
  const [productBankGroupId, setProductBankGroupId] = useState('');
  const [productBankId, setProductBankId] = useState('');
  const [productList, setProductList] = useState([]);
  const [typeSelected, setTypeSelected] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const serviceId = '1';

  const handleList = () => {
    listTablesService(febrabanId, serviceId)
      .then(({ data }) => {
        setList(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const validateFields = () => {
    if (
      !tableComissionBankId ||
      !installment ||
      !productBankGroupId ||
      !productBankId
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos obrigatórios',
        text: 'Por favor, preencha todos os campos obrigatórios.',
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    try {
      const confirm = await Swal.fire({
        icon: 'question',
        title: 'Confirmação',
        text: 'Tem certeza que deseja cadastrar esta tabela?',
        showDenyButton: true,
        denyButtonText: 'Não, sair',
        confirmButtonText: 'Sim, salvar',
      });

      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        tableComissionBankId: tableComissionBankId,
        installment: installment,
      };

      await saveSelectedTablesService(data);

      Swal.close();

      setTableComissionBankId('');
      setInstallment('84');

      handleList();
    } catch (err) {
      Swal.close();

      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleActiveInactive = (id, registerInstallment) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      showDenyButton: true,
      denyButtonText: 'Não, sair',
      confirmButtonText: 'Sim, salvar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      activeInactiveTableService(id, registerInstallment)
        .then(() => {
          Swal.close();
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const handleListAllTables = async (productBank) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const filter = {
        productBankId: productBank,
      };

      const { data } = await listAllTablesService(febrabanId, filter);

      if (data?.resultSet.length > 0) {
        setTables(data.resultSet);
        setProductSelected(true);
      } else {
        setTables([]);
        setProductSelected(true);
      }

      Swal.close();
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleGetType = async () => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await getTypeService();

      if (data?.resultSet.length > 0) {
        setTypeList(data.resultSet);
      } else {
        setTypeList([]);
      }

      Swal.close();
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleGetProducts = async (productBanckGroup) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const filter = {
        productBankGroupId: productBanckGroup,
        serviceId: 1,
        febrabanId: febrabanId,
      };
      const { data } = await getProductService(filter);

      if (data?.resultSet.length > 0) {
        setProductList(data.resultSet);
        setTypeSelected(true);
      } else {
        setProductList([]);
        setTypeSelected(true);
      }

      Swal.close();
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  useEffect(() => {
    handleList();
    handleGetType();
  }, []);

  return (
    <>
      <div className="bg-secondary border p-4 rounded mt-0">
        <h3>Minhas Tabelas</h3>
        <Alert color="primary">
          <h2 className="mt-0">Informações importantes</h2>
          <ul>
            <li>
              <strong>Atenção: </strong>Esta configuração não é obrigatória.
            </li>
            <li>
              {' '}
              Ao selecionar uma ou mais tabelas, o sistema só irá exibir as
              tabelas configuradas.
            </li>
          </ul>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <Title>Cadastrar Tabelas</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="3" md="6">
                <FormGroup>
                  <Label>Tipo</Label>
                  <select
                    type="text"
                    autoComplete="false"
                    className="form-control"
                    isLoading={typeList.length <= 0}
                    value={productBankGroupId}
                    onChange={(ev) => {
                      setProductBankId('');
                      setTableComissionBankId('');
                      setProductBankGroupId(ev.target.value);
                      handleGetProducts(ev.target.value);
                      setProductSelected(false);
                    }}
                  >
                    <option selected disabled value="">
                      Selecione...
                    </option>
                    {typeList.length > 0 &&
                      typeList.map((type, key) => (
                        <option key={key} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>

              <Col lg="3" md="6">
                <FormGroup>
                  <Label>Produto</Label>
                  {productList.length <= 0 && typeSelected ? (
                    <Input
                      type="text"
                      disabled
                      placeholder="Nenhum produto encontrado"
                    />
                  ) : (
                    <select
                      type="text"
                      autoComplete="false"
                      className="form-control"
                      disabled={!typeSelected}
                      value={productBankId}
                      onChange={(ev) => {
                        setTableComissionBankId('');
                        setProductBankId(ev.target.value);
                        handleListAllTables(ev.target.value);
                        setProductSelected(false);
                      }}
                    >
                      <option value="" disabled selected>
                        Selecione...
                      </option>
                      {productList.map((product, key) => (
                        <option key={key} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  )}
                </FormGroup>
              </Col>

              <Col lg="3" md="6">
                <FormGroup>
                  <Label>Tabela</Label>
                  {tables.length === 0 && productSelected ? (
                    <Input
                      type="text"
                      disabled
                      placeholder="Nenhuma tabela encontrada"
                    />
                  ) : (
                    <select
                      type="text"
                      autoComplete="false"
                      className="form-control"
                      disabled={!productSelected}
                      value={tableComissionBankId}
                      onChange={(ev) => {
                        setTableComissionBankId(ev.target.value);
                      }}
                    >
                      <option value="" disabled selected>
                        Selecione...
                      </option>
                      {tables.map((table, key) => (
                        <option key={key} value={table.id}>
                          {table.tableName}
                        </option>
                      ))}
                    </select>
                  )}
                </FormGroup>
              </Col>

              <Col lg="3" md="6">
                <FormGroup>
                  <Label>Prazo</Label>
                  <select
                    type="text"
                    autoComplete="false"
                    value={installment}
                    className="form-control"
                    defaultValue={{ value: 84, label: '84 parcelas' }}
                    onChange={(ev) => {
                      setInstallment(ev.target.value);
                    }}
                  >
                    <option value="84">84 parcelas</option>
                    <option value="72">72 parcelas</option>
                    <option value="60">60 parcelas</option>
                    <option value="48">48 parcelas</option>
                    <option value="36">36 parcelas</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              Adicionar
            </Button>
          </CardFooter>
        </Card>

        {list.length > 0 && (
          <Card className="shadow mt-2">
            <CardHeader>
              <Title>Tabelas cadastradas</Title>
            </CardHeader>
            <Table responsive>
              <thead>
                <tr className="bg-secondary">
                  <th>TIPO</th>
                  <th>PRODUTO</th>
                  <th>TABELA</th>
                  <th>PRAZO</th>
                  <th>STATUS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.map((rule, key) => (
                  <tr key={key}>
                    <td>
                      <strong>{rule.type}</strong>
                    </td>
                    <td>
                      <strong>{rule.product}</strong>
                    </td>
                    <td>
                      <strong>{rule.tableName}</strong>
                    </td>
                    <td>
                      <strong>{rule.installment}</strong>
                    </td>
                    <td>
                      <Badge
                        color={rule.active == 1 ? 'success' : 'danger'}
                        className="font-16"
                      >
                        {rule.active == 1 ? 'ATIVO' : 'INATIVO'}
                      </Badge>
                    </td>
                    <td className="text-right">
                      <Button
                        color={rule.active == 0 ? 'success' : 'danger'}
                        size="sm"
                        onClick={() => {
                          handleActiveInactive(rule.id, rule.installment);
                        }}
                      >
                        {rule.active == 1 ? (
                          <>
                            <i className="fas fa-minus-circle" /> INATIVAR
                          </>
                        ) : (
                          <>
                            <i className="fas fa-check-circle" /> ATIVAR
                          </>
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default MyTablesConsignedComponent;
