import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import { createScheduleSimulationService } from 'services/Digitation/ReDigitationService';
import { inactiveScheduleSimulationService } from 'services/Digitation/ReDigitationService';
import { listScheduleSimulationService } from 'services/Digitation/ReDigitationService';
import Swal from 'sweetalert2';

const ScheduleSimulationView = () => {
  const [data, setData] = useState({
    dateStart: null,
    dateEnd: null
  });
  const [registers, setRegisters] = useState([]);

  const handleSchedule = () => {
    if (!data?.dateStart || !data.dateEnd) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `É preciso informar a data inicial e final da simulação`
      });
      return;
    }

    Swal.fire({
      title: 'Salvando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    createScheduleSimulationService(data)
      .then(() => {
        Swal.close();
        handleList();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listScheduleSimulationService()
      .then(({ data }) => {
        console.log(data);
        setRegisters(data?.resultSet);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleInactiveSchedule = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja inativar a consulta? sera irreversível!',
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: 'Ok, continuar'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Inativando',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      inactiveScheduleSimulationService(id)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Consulta inativa!'
          }).then(() => {
            handleList();
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="FGTS RETRABALHAR LEAD - AGENDAR SIMULAÇÃO" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h3>FGTS RETRABALHAR LEAD - Agendar Simulação</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Data Inicial</Label>
                  <Input
                    type="date"
                    id="dateStart"
                    name="dateStart"
                    onChange={(ev) => {
                      setData({
                        ...data,
                        dateStart: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Data Final</Label>
                  <Input
                    type="date"
                    id="dateEnd"
                    name="dateEnd"
                    onChange={(ev) => {
                      setData({
                        ...data,
                        dateEnd: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSchedule}>
              <i className="far fa-clock" /> Agendar rotina
            </Button>
          </CardFooter>
        </Card>
        <Alert color="secondary" className="shadow mt-4">
          <p className="m-0">
            - Enquanto houver registros no período informado, será utilizada a
            consulta.
          </p>
          <p className="m-0">
            - Caso não queira mais utilizar o período, basta inativar a
            consulta.
          </p>
          <p className="m-0">
            - A ordem de execução será da mais antiga para a mais recente.
          </p>
          <p className="m-0">
            - A consulta será inativada automáticamente quando não houver mais
            registros no período.
          </p>
        </Alert>
        {registers.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>
              <h4>Lista de Agendamentos</h4>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>DATA CADASTRO</th>
                      <th>DATA INICIAL AGENDAMENTO</th>
                      <th>DATA FINAL AGENDAMENTO</th>
                      <th>STATUS</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {registers.map((register, key) => (
                      <tr key={key}>
                        <td>{register.createdAt}</td>
                        <td className="font-weight-bold font-14">
                          {register.dateStartSimulation}
                        </td>
                        <td className="font-weight-bold font-14">
                          {register.dateEndSimulation}
                        </td>
                        <td>
                          {register.active > 0 ? (
                            <Badge color="success" className="font-12">
                              CONSULTA ATIVO
                            </Badge>
                          ) : (
                            <Badge color="warning" className="font-12">
                              CONSULTA INATIVA
                            </Badge>
                          )}
                        </td>
                        <td>
                          {register.active > 0 && (
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() =>
                                handleInactiveSchedule(register.id)
                              }
                            >
                              <i className="far fa-calendar-times" /> Inativar
                              Rotina
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ScheduleSimulationView;
