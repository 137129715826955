import { useEffect } from 'react';
import { useState } from 'react';
import { resendPaymentFgts } from 'services/Digitation/DigitationService';
import { showProposalDetails } from 'services/Digitation/ProposalService';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';

const {
  default: SampleModalView
} = require('components/Modals/SampleModalView');
const {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Alert,
  Badge
} = require('reactstrap');

const ResendPaymentComponent = ({
  isOpen = true,
  setIsOpen = () => {},
  simulationId = null,
  proposalId = null,
  febrabanId = null
}) => {
  const [proposal, setProposal] = useState({});
  const banks = [
    {
      febraban: '001',
      label: 'BANCO DO BRASIL'
    },
    {
      febraban: '104',
      label: 'CAIXA ECONOMICA FEDERAL'
    },
    {
      febraban: '341',
      label: 'ITA\u00da'
    },
    {
      febraban: '237',
      label: 'BRADESCO'
    },
    {
      febraban: '033',
      label: 'SANTANDER'
    },
    {
      febraban: '041',
      label: 'BANRISUL'
    },
    {
      febraban: '003',
      label: 'BANCO DA AMAZ\u00d4NIA'
    },
    {
      febraban: '389',
      label: 'MERCANTIL DO BRASIL'
    },
    {
      febraban: '748',
      label: 'SICREDI'
    },
    {
      febraban: '756',
      label: 'SICOOB'
    },
    {
      febraban: '077',
      label: 'Inter'
    },
    {
      febraban: '237',
      label: 'Next'
    },
    {
      febraban: '208',
      label: 'BTG'
    },
    {
      febraban: '260',
      label: 'NUBANK'
    },
    {
      febraban: '070',
      label: 'BRB - BANCO DE BRASILIA'
    },
    {
      febraban: '084',
      label: 'UNIPRIME NORTE DO PARAN\u00c1'
    },
    {
      febraban: '085',
      label: 'AILOS'
    },
    {
      febraban: '074',
      label: 'SAFRA'
    },
    {
      febraban: '655',
      label: 'BANCO VOTORANTIM S.A.'
    },
    {
      febraban: '707',
      label: 'BANCO DAYCOVAL S.A.'
    },
    {
      febraban: '318',
      label: 'BANCO BMG S.A.'
    },
    {
      febraban: '336',
      label: 'BANCO C6 S.A.'
    },
    {
      febraban: '037',
      label: 'BANCO ESTADO DO PAR\u00c1 S.A.'
    },
    {
      febraban: '212',
      label: 'BANCO ORIGINAL'
    },
    {
      febraban: '623',
      label: 'BANCO PAN S.A.'
    },
    {
      febraban: '102',
      label: 'BANCO XP'
    },
    {
      febraban: '042',
      label: 'BANCO DO SERGIPE'
    },
    {
      febraban: '281',
      label: 'COOPAVEL '
    },
    {
      febraban: '174',
      label: 'PEFISA'
    }
  ];

  const handleShowProposal = () => {
    setProposal({});
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    showProposalDetails(proposalId)
      .then(({ data }) => {
        Swal.close();
        setProposal(data?.resultSet ?? []);

        if (
          data?.resultSet.status !== 'CANCELADO' &&
          data?.resultSet.status !== 'PENDENCIA'
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Ops',
            text: 'Operação não pode ser reapresentada'
          });
          setIsOpen(false);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando',
      message: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    resendPaymentFgts({
      febrabanId: febrabanId,
      simulationId: simulationId,
      proposalId: proposalId,
      bank: proposal.bank,
      agencyBank: proposal.agencyBank,
      numberAccountBank: proposal.numberAccountBank,
      digitAccountBank: proposal.digitAccountBank,
      typeAccount: proposal.typeAccount
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Aguardar analise'
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    if (simulationId > 0 && proposalId > 0 && isOpen) {
      handleShowProposal();
    }
  }, [isOpen]);

  if (!proposalId || !simulationId || !proposal?.id) {
    return <></>;
  }

  return (
    <>
      <SampleModalView
        labelModal="Reapresentar proposta - Dados bancários"
        icon="far fa-window-restore"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {
          handleSubmit();
        }}
        showBtnSubmit={true}
        size="xl"
      >
        <Card className="shadow">
          <CardHeader>
            <h3>Cliente</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <p>Cliente</p>
                <p className="font-weight-bold">{proposal.name}</p>
              </Col>
              <Col md="4">
                <p>CPF</p>
                <p className="font-weight-bold">{maskCPF(proposal.cpf)}</p>
              </Col>
              <Col md="4">
                <p>Nº Proposta</p>
                <Badge className="font-14" color="success">
                  {proposal.numberProposal}
                </Badge>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mt-4">
          <CardHeader>
            <h3>Dados bancários</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Banco</Label>
                  <select
                    className="form-control"
                    value={proposal.bank}
                    onChange={(ev) => {
                      setProposal({ ...proposal, bank: ev.target.value });
                    }}
                  >
                    <option>Selecione...</option>
                    {banks.map((bank, key) => (
                      <option key={key} value={bank.febraban}>
                        {bank.febraban} - {bank.label}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Agência</Label>
                  <Input
                    type="text"
                    value={proposal.agencyBank}
                    onChange={(ev) => {
                      setProposal({ ...proposal, agencyBank: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Conta</Label>
                  <Input
                    type="text"
                    value={proposal.numberAccountBank}
                    onChange={(ev) => {
                      setProposal({
                        ...proposal,
                        numberAccountBank: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Dígito</Label>
                  <Input
                    type="text"
                    value={proposal.digitAccountBank}
                    onChange={(ev) => {
                      setProposal({
                        ...proposal,
                        digitAccountBank: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Tipo de conta</Label>
                  <select
                    className="form-control"
                    name="typeAccount"
                    value={proposal.typeAccount}
                    onChange={(ev) => {
                      setProposal({
                        ...proposal,
                        typeAccount: ev.target.value
                      });
                    }}
                  >
                    <option value="" selected disabled>
                      Selecione...
                    </option>
                    <option value="01">Conta Corrente</option>
                    <option value="02">Conta Poupança</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Alert color="warning" className="mt-4">
          <p className="m-0">
            Atenção, os dados bancários devem ser do titular da proposta
          </p>
        </Alert>
      </SampleModalView>
    </>
  );
};

export default ResendPaymentComponent;
