import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const CardAddressComponent = ({ proposal }) => {

    return (
        <Card className="shadow mt-3">
            <CardHeader className="border-0">
                <h2 className="m-0">
                    <i className="fas fa-map-marked-alt" /> Endereço
                </h2>
            </CardHeader>
            <CardBody>
                <div>
                    <Row>
                        <Col md="3" className="p-3">
                            <p className="m-0">CEP</p>
                            <h2 className="m-0">{proposal.CEP || proposal.cep}</h2>
                        </Col>
                        <Col md="9">
                            <Row>
                                <Col md="9" className="p-3">
                                    <p className="m-0">Rua</p>
                                    <h2 className="m-0">{proposal.address}</h2>
                                </Col>
                                <Col md="3" className="p-3">
                                    <p className="m-0">Número</p>
                                    <h2 className="m-0">{proposal.numberAddress || proposal.number}</h2>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" className="p-3">
                            <p className="m-0">Bairro</p>
                            <h2 className="m-0">{proposal.district}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Cidade</p>
                            <h2 className="m-0">{proposal.city}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Estado</p>
                            <h2 className="m-0">{proposal.state}</h2>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
};


export default CardAddressComponent;