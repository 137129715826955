import SampleModalView from 'components/Modals/SampleModalView';
import { Button, Card, CardHeader, Table } from 'reactstrap';
import SaveIndicationGroupComponent from './SaveIndicationGroupComponent';
import { useState } from 'react';
import { listIndicationGroupService } from 'services/Promoter/IndicationService';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const IndicationGroupComponent = ({ isOpen, setIsOpen = () => {} }) => {
  const [modalSave, setModalSave] = useState(false);
  const [indicationGroupId, setIndicationGroupId] = useState(null);
  const [list, setList] = useState([]);

  const handleList = async () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const result = await listIndicationGroupService();
    if (!result) return;

    Swal.close();

    setList(result.resultSet);
  };

  useEffect(() => {
    if (!isOpen) return;

    handleList();
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <>
      <SampleModalView
        labelModal="Grupos de Indicação"
        icon="far fa-window-restore"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitAction={() => {}}
        showBtnSubmit={false}
        size="md"
      >
        <Card className="shadow">
          <CardHeader>
            <Button
              color="primary"
              onClick={() => {
                setIndicationGroupId(null);
                setModalSave(true);
              }}
            >
              <i className="fas fa-plus" /> NOVO REGISTRO
            </Button>
          </CardHeader>
          {list.length > 0 && (
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <Table responsive>
                <thead className="bg-secondary">
                  <tr>
                    <th>NOME</th>
                    <th>DATA CADASTRO</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {list.map((register) => (
                    <tr key={register.id}>
                      <td>{register.name}</td>
                      <td>{register.createdAt}</td>
                      <td>
                        <Button
                          color="secondary"
                          size="sm"
                          className="rounded-pill"
                          onClick={() => {
                            setIndicationGroupId(register.id);
                            setModalSave(true);
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Card>
      </SampleModalView>

      <SaveIndicationGroupComponent
        isOpen={modalSave}
        setIsOpen={setModalSave}
        id={indicationGroupId}
        handleListGroup={handleList}
      />
    </>
  );
};

export default IndicationGroupComponent;
