import Header from 'components/Headers/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row
} from 'reactstrap';
import { showSimulationDetails } from 'services/Digitation/ProposalService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import { moneyMask } from 'utils/mask';

const SimulationDetails = () => {
  let { id } = useParams();
  const [proposal, setProposal] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const handleShow = async (proposalId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    showSimulationDetails(proposalId)
      .then((result) => {
        Swal.close();
        setProposal(result.data.resultSet);
        setShowLoading(false);
      })
      .catch((err) => {
        setShowLoading(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const closePage = () => {
    window.close();
  };

  useEffect(() => {
    handleShow(id);
  }, []);

  if (showLoading) {
    return (
      <>
        <Header />
        <Container className="mt--9" fluid>
          <Card>
            <CardBody className="text-center text-info">
              <i className="fas fa-spinner fa-4x fa-spin" />
              <h1>Carregando</h1>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }

  if (!proposal) {
    return (
      <>
        <Header />
        <Container className="mt--9" fluid>
          <Card>
            <CardBody className="text-center text-danger">
              <i className="fas fa-exclamation-triangle fa-4x" />
              <h1>Não foram identificados registros para esta consulta</h1>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h2 className="m-0">
              <i className="fas fa-receipt" /> Detalhes da simulação
              <div className="float-right">
                <Button color="danger" onClick={closePage}>
                  <i className="fas fa-times" /> Sair
                </Button>
              </div>
            </h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Card
                  className={
                    parseInt(proposal.status) === 1 ||
                    parseInt(proposal.status) === 5
                      ? 'bg-warning text-white'
                      : ''
                  }
                >
                  <CardBody className="text-center">
                    <i className="fas fa-play fa-3x " />
                    <h3 className="mt-2">INICIOU SIMULAÇÃO</h3>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card
                  className={
                    parseInt(proposal.status) === 4 ? 'bg-info text-white' : ''
                  }
                >
                  <CardBody className="text-center">
                    <i className="fas fa-hand-paper fa-3x" />
                    <h3 className="mt-2">SIMULAÇÃO NÃO FINALIZADA</h3>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card
                  className={
                    parseInt(proposal.status) === 3
                      ? 'bg-danger text-white'
                      : ''
                  }
                >
                  <CardBody className="text-center">
                    <i className="fas fa-times fa-3x" />
                    <h3 className="mt-2">DIGITAÇÃO NÃO FINALIZADA</h3>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card
                  className={
                    parseInt(proposal.status) === 2
                      ? 'bg-success text-white'
                      : ''
                  }
                >
                  <CardBody className="text-center">
                    <i className="fas fa-check fa-3x" />
                    <h3 className="mt-2">DIGITAÇÃO REALIZADA</h3>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {proposal.messageReturnService && (
              <Alert color="danger" className="mt-4">
                <strong>Erro:</strong> {proposal.messageReturnService}
              </Alert>
            )}
            <div className="mt-4">
              <Row>
                <Col md="3" className="p-3">
                  <p className="m-0">Produto</p>
                  <h2 className="m-0">{proposal.service}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Banco Selecionado</p>
                  <h2 className="m-0">{proposal.nameFebraban}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Valor Liberado</p>
                  <h2 className="m-0 text-success">
                    R$ {moneyMask(proposal.valueLiberty)}
                  </h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Valor Contrato</p>
                  <h2 className="m-0 text-success">
                    R$ {moneyMask(proposal.valueFinally)}
                  </h2>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        {proposal.multBank && proposal.multBank.length > 0 && (
          <Card className="shadow mt-3">
            <CardHeader className="border-0">
              <h2 className="m-0">
                <i className="fas fa-user-check" /> Simulação MultiBancos
              </h2>
            </CardHeader>
            <CardBody>
              {proposal.multBank.map((register, key) => (
                <div key={key}>
                  <Row>
                    <Col md="4" className="p-3">
                      <p className="m-0">Banco</p>
                      <h2 className="m-0">{register.nameBank}</h2>
                    </Col>
                    {register.error ? (
                      <>
                        <Col md="8" className="p-3">
                          <p className="m-0">Erro</p>
                          <h2 className="m-0 text-danger">{register.error}</h2>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md="4" className="p-3">
                          <p className="m-0">Valor Liberado</p>
                          <h2 className="m-0 text-success">
                            R$ {moneyMask(register.totalCreditLiberty)}
                          </h2>
                        </Col>
                        <Col md="4" className="p-3">
                          <p className="m-0">Valor Contrato</p>
                          <h2 className="m-0 text-success">
                            R$ {moneyMask(register.totalCreditAccountFGTS)}
                          </h2>
                        </Col>
                      </>
                    )}
                  </Row>
                  <hr />
                </div>
              ))}
            </CardBody>
          </Card>
        )}

        {proposal.indication && proposal.indication.uuid && (
          <Card className="shadow mt-3">
            <CardHeader className="border-0">
              <h2 className="m-0">
                <i className="fas fa-user-check" /> Link de indicação / Venda
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" className="p-3">
                  <p className="m-0">Código</p>
                  <h2 className="m-0 text-warning">
                    {proposal.indication.uuid}
                  </h2>
                </Col>
                <Col md="6" className="p-3">
                  <p className="m-0">Nome</p>
                  <h2 className="m-0 text-warning">
                    {proposal.indication.name}
                  </h2>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        <Card className="shadow mt-3">
          <CardHeader className="border-0">
            <h2 className="m-0">
              <i className="fas fa-user-check" /> Cliente
            </h2>
          </CardHeader>
          <CardBody>
            <div>
              <Row>
                <Col md="3" className="p-3">
                  <p className="m-0">CPF</p>
                  <h2 className="m-0">{maskCPF(proposal.cpf)}</h2>
                </Col>
                <Col md="6" className="p-3">
                  <p className="m-0">Nome</p>
                  <h2 className="m-0">{proposal.name}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Data Nascimento</p>
                  <h2 className="m-0">{proposal.birthDate}</h2>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="p-3">
                  <p className="m-0">WhatsApp</p>
                  <h2 className="m-0">{phoneMask(proposal.whatsappNumber)}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">E-mail</p>
                  <h2 className="m-0">{proposal.email ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Matricula</p>
                  <h2 className="m-0">{proposal.registry ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">RG</p>
                  <h2 className="m-0">{proposal.documentRG ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Data Emissão</p>
                  <h2 className="m-0">{proposal.dateEmissionRG ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Orgão Emissor</p>
                  <h2 className="m-0">{proposal.emissionRG ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Estado Emissor</p>
                  <h2 className="m-0">{proposal.ufRG ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Sexo</p>
                  <h2 className="m-0">{proposal.sex ?? '--'}</h2>
                </Col>
                <Col md="3" className="p-3">
                  <p className="m-0">Estado Civil</p>
                  <h2 className="m-0">{proposal.stateCivil ?? '--'}</h2>
                </Col>
                <Col md="6" className="p-3">
                  <p className="m-0">Nome da mãe</p>
                  <h2 className="m-0">{proposal.nameMother ?? '--'}</h2>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

        {proposal.CEP && (
          <Card className="shadow mt-3">
            <CardHeader className="border-0">
              <h2 className="m-0">
                <i className="fas fa-map-marked-alt" /> Endereço
              </h2>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  <Col md="3" className="p-3">
                    <p className="m-0">CEP</p>
                    <h2 className="m-0">{proposal.CEP ?? '--'}</h2>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="9" className="p-3">
                        <p className="m-0">Rua</p>
                        <h2 className="m-0">{proposal.address ?? '--'}</h2>
                      </Col>
                      <Col md="3" className="p-3">
                        <p className="m-0">Número</p>
                        <h2 className="m-0">
                          {proposal.numberAddress ?? '--'}
                        </h2>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md="3" className="p-3">
                    <p className="m-0">Bairro</p>
                    <h2 className="m-0">{proposal.district ?? '--'}</h2>
                  </Col>
                  <Col md="3" className="p-3">
                    <p className="m-0">Cidade</p>
                    <h2 className="m-0">{proposal.city ?? '--'}</h2>
                  </Col>
                  <Col md="3" className="p-3">
                    <p className="m-0">Estado</p>
                    <h2 className="m-0">{proposal.state ?? '--'}</h2>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        )}

        {proposal?.multBank?.febrabanId &&
          proposal?.multBank?.febrabanId === '321' && (
            <Card className="shadow mt-3">
              <CardHeader className="border-0">
                <h2 className="m-0">
                  <i className="fas fa-map-marked-alt" /> Endereço utilizado na
                  simulação
                </h2>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col md="4" className="p-3">
                      <p className="m-0">CEP</p>
                      <h2 className="m-0">
                        {proposal?.multBank?.resultSimulation?.cep ?? '--'}
                      </h2>
                    </Col>
                    <Col md="4" className="p-3">
                      <p className="m-0">CIDADE</p>
                      <h2 className="m-0">
                        {proposal?.multBank?.resultSimulation?.city ?? '--'}
                      </h2>
                    </Col>
                    <Col md="4" className="p-3">
                      <p className="m-0">ESTADO</p>
                      <h2 className="m-0">
                        {proposal?.multBank?.resultSimulation?.state ?? '--'}
                      </h2>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          )}

        {proposal.bank && (
          <Card className="shadow mt-3">
            <CardHeader className="border-0">
              <h2 className="m-0">
                <i className="fas fa-piggy-bank" /> Dados Bancários
              </h2>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  {proposal?.bank && (
                    <>
                      <Col md="3" className="p-3">
                        <p className="m-0">Banco</p>
                        <h2 className="m-0">{proposal.bank ?? '--'}</h2>
                      </Col>
                      <Col md="3" className="p-3">
                        <p className="m-0">Agência</p>
                        <h2 className="m-0">{proposal.agencyBank ?? '--'}</h2>
                      </Col>
                      <Col md="3" className="p-3">
                        <p className="m-0">Conta</p>
                        <h2 className="m-0">
                          {proposal.numberAccountBank ?? '--'}
                        </h2>
                      </Col>
                      <Col md="3" className="p-3">
                        <p className="m-0">Tipo de conta</p>
                        {proposal.typeAccount && (
                          <h2 className="m-0">
                            {proposal.typeAccount && (
                              <>
                                {proposal.typeAccount == '01'
                                  ? 'CONTA CORRENTE'
                                  : 'CONTA POUPANÇA'}
                              </>
                            )}
                          </h2>
                        )}
                      </Col>
                    </>
                  )}

                  {proposal?.PIX && (
                    <Col md="12" className="p-3">
                      <p className="m-0">PIX</p>
                      <h2 className="m-0">{proposal.PIX ?? '--'}</h2>
                    </Col>
                  )}
                </Row>
              </div>
            </CardBody>
          </Card>
        )}

        <Card className="mt-3">
          <CardFooter>
            <Button color="danger" onClick={closePage}>
              <i className="fas fa-times" /> Sair
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default SimulationDetails;
