import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const FormAccountBankComponent = ({
  digitation = {
    bank: null,
    agencyBank: null,
    numberAccountBank: null,
    digitAccountBank: null,
    typeAccount: null
  },
  setDigitation
}) => {
  return (
    <>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label>
              Banco <span className="text-danger">*</span>
            </Label>
            <select
              id="bank"
              name="bank"
              className="form-control"
              value={digitation.bank}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  bank: ev.target.value
                });
              }}
            >
              <option value="" disabled selected>
                SELECIONE
              </option>
              <option value="104">CAIXA ECONOMICA FEDERAL</option>
              <option value="001">BANCO DO BRASIL S.A.</option>
              <option value="237">BANCO BRADESCO</option>
              <option value="341">BANCO ITAÚ S.A.</option>
              <option value="336">BANCO C6 S.A.</option>
              <option value="033">BANCO SANTANDER S.A.</option>
              <option value="151">BANCO NOSSA CAIXA S.A.</option>
              <option value="745">BANCO CITIBANK S.A.</option>
              <option value="041">
                BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
              </option>
              <option value="756">BANCO SICOOB</option>
              <option value="748">BANCO SICREDI</option>
              <option value="085">
                COOPERATIVA CENTRAL DE CRÉDITO - AILOS
              </option>
              <option value="399">HSBC BANK BRASIL S.A</option>
              <option value="756">
                BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
              </option>
              <option value="041">BANRISUL</option>
              <option value="212">BANCO ORIGINAL</option>
              <option value="260">NU BANK PAGAMENTOS S.A.</option>
              <option value="318">BANCO BMG S.A.</option>
              <option value="336">BANCO C6 S.A. (C6 BANK)</option>
              <option value="077">BANCO INTER S.A.</option>
              <option value="623">BANCO PAN</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Agência <span className="text-danger">*</span>
            </Label>
            <Input
              value={digitation.agencyBank}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  agencyBank: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Conta Bancária <span className="text-danger">*</span>
            </Label>
            <Input
              value={digitation.numberAccountBank}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  numberAccountBank: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Dígito da conta <span className="text-danger">*</span>
            </Label>
            <Input
              value={digitation.digitAccountBank}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  digitAccountBank: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Tipo de conta <span className="text-danger">*</span>
            </Label>
            <select
              id="typeAccount"
              name="typeAccount"
              className="form-control"
              value={digitation.typeAccount}
              onChange={(ev) => {
                setDigitation({
                  ...digitation,
                  typeAccount: ev.target.value
                });
              }}
            >
              <option value="" disabled selected>
                SELECIONE
              </option>
              <option value="1">CONTA CORRENTE</option>
              <option value="2">POUPANÇA</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormAccountBankComponent;
