import SampleModalView from 'components/Modals/SampleModalView';
import { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label
} from 'reactstrap';
import { sendSmsService } from 'services/Register/SmsService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import SmsTemplateComponent from './SmsTemplateComponent';
import { listTemplateService } from 'services/Register/SmsService';

const SmsSendMessageComponent = ({
  numberDefault,
  messageDefault,
  isOpen,
  setIsOpen
}) => {
  const [register, setRegister] = useState({
    number: phoneMask(numberDefault ?? ''),
    message: messageDefault ?? ''
  });
  const [showTemplate, setShowTemplate] = useState(false);
  const [listTemplate, setListTemplate] = useState([]);

  const handleSubmit = () => {
    if (!register?.number || register.number === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'O Nº de celular é obrigatório'
      });
      return;
    }
    if (!register?.message || register.message === '') {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A mensagem é obrigatória'
      });
      return;
    }
    if (register.message.length > 144) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'A mensagem pode conter no maximo 144 caracteres'
      });
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Confirmação de envio',
      html: `
        <div class="text-left">
            <h2 class="mb-3 text-dark">Ao realizar o envio, será consumido 1(um) crédito.</h2>
            <h4>Detalhes da mensagem: </h4>
            <p class="m-0"><strong>Nº de celular:</strong> ${register.number}</p>
            <p class="m-0"><strong>Mensagem:</strong></p>
            <p class="m-0">${register.message}</p>
        </div>
        `,
      showCancelButton: true,
      cancelButtonText: 'Não enviar, sair',
      confirmButtonText: 'Sim, enviar SMS',
      confirmButtonColor: '#2dce89'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Realizando envio',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      sendSmsService({
        title: 'ENVIO MANUAL',
        number: register.number,
        message: register.message
      })
        .then(() => {
          setIsOpen(false);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'SMS enviada com sucesso!'
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    });
  };

  const handleListTemplate = () => {
    listTemplateService()
      .then(({ data }) => {
        setListTemplate(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    setRegister({
      number: phoneMask(numberDefault ?? ''),
      message: messageDefault ?? ''
    });
    handleListTemplate();
  }, [numberDefault]);

  return (
    <>
      <SampleModalView
        labelModal="Enviar mensagem por SMS"
        icon="fas fa-paper-plane"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showBtnSubmit={true}
        btnSubmitLabel="Enviar mensagem"
        btnSubmitAction={handleSubmit}
      >
        <Card className="shadow mb-3">
          <CardBody>
            <FormGroup>
              <Label>Modelo Padrão</Label>
              <InputGroup>
                <select
                  className="form-control"
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      message: listTemplate[ev.target.value]
                        ? listTemplate[ev.target.value]?.message
                        : ''
                    });
                  }}
                >
                  <option value="">Selecione</option>
                  {listTemplate.map((template, key) => (
                    <option value={key} key={key}>
                      {template.title}
                    </option>
                  ))}
                </select>
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowTemplate(true);
                    }}
                  >
                    Adicionar modelo
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </CardBody>
        </Card>
        <Card className="shadow">
          <CardBody>
            <FormGroup>
              <Label>Nº de celular</Label>
              <Input
                value={register.number}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    number: phoneMask(ev.target.value)
                  });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Mensagem <Badge color="info">{register.message.length}</Badge>
              </Label>
              <textarea
                className="form-control"
                value={register.message}
                maxLength={144}
                style={{ height: 100 }}
                onChange={(ev) => {
                  setRegister({
                    ...register,
                    message: ev.target.value
                  });
                }}
              />
            </FormGroup>
            <Alert color="secondary">
              <h3>
                <strong className="text-danger">Atenção:</strong>
              </h3>
              <p className="m-0">
                - A mensagem deve conter no{' '}
                <strong>maximo 144 caracteres.</strong>
              </p>
              <p className="m-0">
                - Se um link for enviado, ele poderá passar por análise pela
                operadora, o que pode resultar em um aumento no tempo de entrega
                da mensagem.
              </p>
            </Alert>
          </CardBody>
        </Card>
      </SampleModalView>
      <SmsTemplateComponent
        isOpen={showTemplate}
        setIsOpen={(e) => {
          setShowTemplate(e);
          handleListTemplate();
        }}
      />
    </>
  );
};

export default SmsSendMessageComponent;
