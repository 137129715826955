import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  ModalFooter,
  Button,
  Alert,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
} from 'reactstrap';
import { maskCPF, phoneMask, cepMask } from 'utils/mask';
import Swal from 'sweetalert2';
import { brasilApiCep } from 'services/cepApi';
import { ReDigitationService } from 'services/Digitation/ReDigitationService';
import { moneyMask } from 'utils/mask';
import { ProposalAct } from 'services/Digitation/SimulationService';
import { CloseProposalAct } from 'services/Digitation/SimulationService';
import { viaCEP } from 'services/cepApi';
import { ShowClientProposalService } from 'services/Register/ClientService';

const InfoSimulationDashboard = (props) => {
  const { className, isModal, state, data } = props;

  const [modal, setModal] = useState(false);
  const [simulation, setSimulation] = useState({
    id: '',
    qtd: '',
    name: '',
    cpf: '',
    birthDate: null,
    whatsappNumber: '',
    status: '',
    numberProposal: '',
    valueFinally: 0,
    valueLiberty: 0,
    valueSolicited: 0,
    rate: null,
    createdAt: '',
    messageReturnService: null,
    statusBank: null,
    documentRG: '',
    emissionRG: null,
    dateEmissionRG: null,
    ufRG: null,
    nameMother: '',
    sex: null,
    stateCivil: null,
    email: '',
    cep: null,
    city: null,
    district: null,
    address: null,
    numberAddress: null,
    complementAddress: null,
    bank: null,
    agencyBank: null,
    numberAccountBank: null,
    digitAccountBank: null,
    typeAccount: null,
    uf: null,
    pix: null,
  });

  const [proposalAct, setProposalAct] = useState({
    status: false,
    name: '',
  });

  const ProposalAction = async (id) => {
    setProposalAct({
      status: false,
      name: '',
    });

    const result = await ProposalAct(id);

    setProposalAct(result.data);
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };

  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const resimulation = async () => {
    const valueSolicited =
      simulation.febrabanId === '626'
        ? simulation.valueSolicited
        : simulation.valueLiberty;

    if (valueSolicited === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Valor solicitado não informado. Por favor informar`,
      });
      return;
    }

    Swal.fire({
      title: 'Realizando Digitação...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ReDigitationService(simulation.id, {
      birthDate: simulation.birthDate,
      valueSolicited: valueSolicited,
      rg: simulation.documentRG,
      emissionRG: simulation.emissionRG,
      dateEmissionRg: simulation.dateEmissionRG,
      ufRg: simulation.ufRG,
      nameMother: simulation.nameMother,
      sex: simulation.sex,
      stateCivil: simulation.stateCivil,
      email: simulation.email,
      valueIncome: simulation.valueIncome,
      cep: simulation.cep,
      numberAddress: simulation.numberAddress,
      complementAddress: simulation.complementAddress,
      bank: simulation.bank,
      agencyBank: simulation.agencyBank,
      numberAccountBank: simulation.numberAccountBank,
      digitAccountBank: simulation.digitAccountBank,
      typeAccount: simulation.typeAccount,
      name: simulation.name,
      address: simulation.address,
      city: simulation.city,
      district: simulation.district,
      febrabanId: simulation.febrabanId,
      pix: simulation.pix,
      uf: simulation.uf,
      valueLiberty: simulation.valueLiberty,
      valueFinally: simulation.valueFinally,
      parc: simulation.parc,
      table: simulation.table,
      useSafe: simulation.useSafe,
      whatsappNumber: simulation.whatsappNumber,
    })
      .then((res) => {
        const result = res.data;
        let btnFormalization = '';
        if (result.resultSet.numberProposal === '626') {
          btnFormalization = `<a class="btn btn-success" href="${result.urlWhatsappBanco}" target="_blank">FORMALIZAR</a>`;
        }

        const whatsappNumber = simulation.whatsappNumber.replace(/[^0-9]/g, '');

        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `
          <h3>Proposta digitada com sucesso!</h3>
          <p>Nº da Proposa: <strong>${result.resultSet.numberProposal}</strong></p>
          ${btnFormalization}

          <hr />
          
          <p class="mb-0">WhatsApp Cliente:</p>
          <p class="mt-0">
            ${simulation.whatsappNumber} 
            <a
              class="btn btn-success btn-sm"
              href="https://wa.me/55${whatsappNumber}"
              target="_blank"
            >
              <i class="fab fa-whatsapp" />
            </a>
          </p>
        `,
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            closeModal();
          }
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const closeAnalyse = async () => {
    await CloseProposalAct(simulation.id).then(() => {
      closeModal();
    });
  };

  const handleGetClientByDocument = async (document) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Deseja buscar propostas digitadas, para este cliente, em seu cadastro?',
      showDenyButton: true,
      denyButtonText: 'Sair',
      confirmButtonText: 'Ok, continuar',
    })
      .then(async (confirm) => {
        if (!confirm.isConfirmed) return;

        Swal.fire({
          title: 'Consultando',
          text: 'Por favor, aguarde...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await ShowClientProposalService(
          document.replace(/[^0-9]/g, ''),
          1
        ).then(({ data }) => {
          Swal.close();

          const { resultSet } = data;
          if (resultSet.length <= 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Ops!',
              text: 'Não foram identificados registros para este cliente',
            });

            return;
          }

          setSimulation({
            ...simulation,
            cep: resultSet?.CEP,
            address: resultSet?.address,
            agencyBank: resultSet?.agencyBank,
            bank: resultSet?.bank,
            city: resultSet?.city,
            complementAddress: resultSet?.complementAddress,
            dateEmissionRG: resultSet?.dateEmissionRG,
            digitAccountBank: resultSet?.digitAccountBank,
            district: resultSet?.district,
            documentRG: resultSet?.documentRG,
            email: resultSet?.email,
            emissionRG: resultSet?.emissionRG,
            nameMother: resultSet?.nameMother,
            numberAccountBank: resultSet?.numberAccountBank,
            numberAddress: resultSet?.numberAddress,
            sex: resultSet?.sex,
            uf: resultSet?.state,
            stateCivil: resultSet?.stateCivil,
            typeAccount: resultSet?.typeAccount,
            ufRG: resultSet?.ufRG,
            birthDate: resultSet?.birthDate,
            name: resultSet?.name,
          });
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (isModal) {
      setSimulation({
        ...data,
        cpf: data.cpf ? maskCPF(data.cpf) : null,
        cep: data.cep ? cepMask(data.cep) : null,
        valueSolicited: data.valueSolicited
          ? moneyMask(data.valueSolicited)
          : null,
        whatsappNumber: data.whatsappNumber
          ? phoneMask(data.whatsappNumber)
          : null,
      });

      setModal(isModal);

      if (data) {
        ProposalAction(data.id);
      }
    }
  }, [isModal]);

  const handleChange = async (event) => {
    if (event.target.name === 'address') {
      setSimulation({ ...simulation, address: event.target.value });
    }
    if (event.target.name === 'agencyBank') {
      setSimulation({ ...simulation, agencyBank: event.target.value });
    }
    if (event.target.name === 'bank') {
      setSimulation({ ...simulation, bank: event.target.value });
    }
    if (event.target.name === 'birthDate') {
      setSimulation({ ...simulation, birthDate: event.target.value });
    }
    if (event.target.name === 'city') {
      setSimulation({ ...simulation, city: event.target.value });
    }
    if (event.target.name === 'complementAddress') {
      setSimulation({ ...simulation, complementAddress: event.target.value });
    }
    if (event.target.name === 'cpf') {
      setSimulation({ ...simulation, cpf: event.target.value });
    }
    if (event.target.name === 'dateEmissionRG') {
      setSimulation({ ...simulation, dateEmissionRG: event.target.value });
    }
    if (event.target.name === 'digitAccountBank') {
      setSimulation({ ...simulation, digitAccountBank: event.target.value });
    }
    if (event.target.name === 'district') {
      setSimulation({ ...simulation, district: event.target.value });
    }
    if (event.target.name === 'documentRG') {
      setSimulation({ ...simulation, documentRG: event.target.value });
    }
    if (event.target.name === 'email') {
      setSimulation({ ...simulation, email: event.target.value });
    }
    if (event.target.name === 'emissionRG') {
      setSimulation({ ...simulation, emissionRG: event.target.value });
    }
    if (event.target.name === 'messageReturnService') {
      setSimulation({
        ...simulation,
        messageReturnService: event.target.value,
      });
    }
    if (event.target.name === 'name') {
      setSimulation({ ...simulation, name: event.target.value });
    }
    if (event.target.name === 'nameMother') {
      setSimulation({ ...simulation, nameMother: event.target.value });
    }
    if (event.target.name === 'numberAccountBank') {
      setSimulation({ ...simulation, numberAccountBank: event.target.value });
    }
    if (event.target.name === 'numberAddress') {
      setSimulation({ ...simulation, numberAddress: event.target.value });
    }
    if (event.target.name === 'sex') {
      setSimulation({ ...simulation, sex: event.target.value });
    }
    if (event.target.name === 'stateCivil') {
      setSimulation({ ...simulation, stateCivil: event.target.value });
    }
    if (event.target.name === 'typeAccount') {
      setSimulation({ ...simulation, typeAccount: event.target.value });
    }
    if (event.target.name === 'ufRG') {
      setSimulation({ ...simulation, ufRG: event.target.value });
    }
    if (event.target.name === 'valueSolicited') {
      setSimulation({
        ...simulation,
        valueSolicited: moneyMask(event.target.value),
      });
    }

    if (event.target.name === 'whatsappNumber') {
      setSimulation({
        ...simulation,
        whatsappNumber: phoneMask(event.target.value),
      });
    }

    if (event.target.name === 'cep') {
      setSimulation({ ...simulation, cep: cepMask(event.target.value) });

      if (event.target.value.length >= 9) {
        await consultCEP(event.target.value);
      }
    }

    if (event.target.name === 'PIX') {
      setSimulation({ ...simulation, pix: event.target.value });
    }
  };

  const consultCEP = async (value) => {
    await brasilApiCep(value)
      .then((res) => {
        const address = res.data;
        setSimulation({
          ...simulation,
          cep: cepMask(address.cep),
          district: address.neighborhood,
          uf: address.state,
          city: address.city,
          address: address.street,
        });
      })
      .catch(async () => {
        await viaCEP(value)
          .then((res) => {
            const viaCep = res.data;
            setSimulation({
              ...simulation,
              cep: cepMask(viaCep.cep),
              district: viaCep.bairro,
              uf: viaCep.uf,
              city: viaCep.localidade,
              address: viaCep.logradouro,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `CEP Não encontrado`,
            });
            setSimulation({
              ...simulation,
              district: null,
              uf: null,
              city: null,
              address: null,
            });
          });
      });
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        size="xl"
      >
        <ModalHeader toggle={closeModal}>
          <h1 className="w-100">Simulação</h1>
        </ModalHeader>
        <ModalBody>
          {proposalAct.name && (
            <Alert
              color={
                proposalAct.status
                  ? 'success text-center'
                  : 'danger text-center'
              }
            >
              <h3 className="m-0">Proposta está sendo analisada por:</h3>
              <h1 className="m-0 text-white">{proposalAct.name}</h1>
            </Alert>
          )}
          <Alert color="secondary">
            <Row>
              <Col>
                <div className="rounded rounded-2 bg-info text-center text-white p-3">
                  <p className="m-0">Total FGTS</p>
                  <h1 className="font-weight-bold m-0">
                    R$ {moneyMask(simulation.valueFinally)}
                  </h1>
                </div>
              </Col>
              <Col>
                <div className="rounded rounded-2 bg-success text-center text-white p-3">
                  <p className="m-0">Total Liberado Saque</p>
                  <h1 className="font-weight-bold m-0">
                    R$ {moneyMask(simulation.valueLiberty)}
                  </h1>
                </div>
              </Col>
              {simulation.febrabanId === '626' && (
                <Col>
                  <div className="rounded rounded-2 text-center p-3">
                    <h2 className="m-0">Valor Solicitado</h2>
                    <Input
                      type="text"
                      name="valueSolicited"
                      className="form-control form-control-md"
                      value={simulation.valueSolicited}
                      onChange={handleChange}
                      onBlur={handleChange}
                      placeholder="0,00"
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Alert>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader>
                  <Button
                    color="info"
                    className="float-right"
                    onClick={() => handleGetClientByDocument(simulation.cpf)}
                  >
                    <i className="fas fa-user-tag" /> Atualizar dados do cliente
                  </Button>
                  <h1>
                    <i className="fas fa-user-check" /> Informações do cliente
                  </h1>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Nome</Label>
                        <Input
                          type="text"
                          name="name"
                          className="form-control form-control-sm"
                          value={simulation.name}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>CPF</Label>
                        <Input
                          type="text"
                          name="cpf"
                          className="form-control form-control-sm"
                          value={simulation.cpf}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="8">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input
                          type="email"
                          name="email"
                          className="form-control form-control-sm"
                          value={simulation.email}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>WhatsApp</Label>
                        <InputGroup>
                          <Input
                            type="text"
                            name="whatsappNumber"
                            className="form-control form-control-sm"
                            value={simulation.whatsappNumber}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />

                          {simulation.whatsappNumber && (
                            <a
                              className="btn btn-success btn-sm"
                              href={`https://wa.me/55${simulation.whatsappNumber.replace(
                                /[^0-9]/g,
                                ''
                              )}`}
                              target="_blank"
                            >
                              <i className="fab fa-whatsapp" />
                            </a>
                          )}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3">
                      <FormGroup>
                        <Label>RG</Label>
                        <Input
                          type="text"
                          name="documentRG"
                          className="form-control form-control-sm"
                          value={simulation.documentRG}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>Data Emissão</Label>
                        <Input
                          type="date"
                          name="dateEmissionRG"
                          className="form-control form-control-sm"
                          value={simulation.dateEmissionRG}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>Orgão Emissor</Label>
                        <Input
                          type="text"
                          name="emissionRG"
                          className="form-control form-control-sm"
                          value={simulation.emissionRG}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>Estado Emissor</Label>
                        <select
                          name="ufRG"
                          className="form-control form-control-sm"
                          value={simulation.ufRG}
                          onChange={handleChange}
                          onBlur={handleChange}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="4">
                      <FormGroup>
                        <Label>Data de nascimento</Label>
                        <Input
                          type="date"
                          name="birthDate"
                          className="form-control form-control-sm"
                          value={simulation.birthDate}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>Sexo</Label>
                        <select
                          name="sex"
                          className="form-control form-control-sm"
                          value={simulation.sex}
                          onChange={handleChange}
                          onBlur={handleChange}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>Estado Civil</Label>
                        <select
                          name="stateCivil"
                          className="form-control form-control-sm"
                          value={simulation.stateCivil}
                          onChange={handleChange}
                          onBlur={handleChange}
                        >
                          <option value="" disabled="">
                            Selecione...
                          </option>
                          <option value="Casado">Casado</option>
                          <option value="Divorciado">Divorciado</option>
                          <option value="Separado Judicialmente">
                            Separado Judicialmente
                          </option>
                          <option value="Solteiro">Solteiro</option>
                          <option value="União Estável">União Estável</option>
                          <option value="Viúvo">Viúvo</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <FormGroup>
                        <Label>Nome da Mãe</Label>
                        <Input
                          type="text"
                          name="nameMother"
                          className="form-control form-control-sm"
                          value={simulation.nameMother}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="shadow mt-3">
                <CardHeader>
                  <h1>
                    <i className="fas fa-map-marked-alt" /> Endereço
                  </h1>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="4">
                      <FormGroup>
                        <Label>CEP</Label>
                        <Input
                          type="text"
                          name="cep"
                          className="form-control form-control-sm"
                          value={simulation.cep}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Endereço</Label>
                        <Input
                          type="text"
                          name="address"
                          className="form-control form-control-sm"
                          value={simulation.address}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="2">
                      <FormGroup>
                        <Label>Número</Label>
                        <Input
                          type="text"
                          name="numberAddress"
                          className="form-control form-control-sm"
                          value={simulation.numberAddress}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>Bairro</Label>
                        <Input
                          type="text"
                          name="district"
                          className="form-control form-control-sm"
                          value={simulation.district}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>Cidade</Label>
                        <Input
                          type="text"
                          name="city"
                          className="form-control form-control-sm"
                          value={simulation.city}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label>UF</Label>
                        <Input
                          type="text"
                          name="uf"
                          className="form-control form-control-sm"
                          value={simulation.uf}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <FormGroup>
                        <Label>Complemento</Label>
                        <Input
                          type="text"
                          name="complementAddress"
                          className="form-control form-control-sm"
                          value={simulation.complementAddress}
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="shadow mt-3">
                <CardHeader>
                  <h1>
                    <i className="fas fa-university" /> Dados bancários
                  </h1>
                </CardHeader>
                <CardBody>
                  {simulation.febrabanId != '329' &&
                  simulation.febrabanId != '329-4' ? (
                    <>
                      <Row>
                        <Col xl="12">
                          <FormGroup>
                            <Label>Banco</Label>
                            <select
                              name="bank"
                              onChange={handleChange}
                              onBlur={handleChange}
                              value={simulation.bank}
                              className="form-control form-control-sm"
                            >
                              <option value="">SELECIONE</option>
                              <option value="070">
                                BRB - BANCO DE BRASÍLIA
                              </option>
                              <option value="104">
                                CAIXA ECONOMICA FEDERAL
                              </option>
                              <option value="001">BANCO DO BRASIL S.A.</option>
                              <option value="237">BANCO BRADESCO</option>
                              <option value="341">BANCO ITAÚ S.A.</option>
                              <option value="336">BANCO C6 S.A.</option>
                              <option value="033">BANCO SANTANDER S.A.</option>
                              <option value="151">
                                BANCO NOSSA CAIXA S.A.
                              </option>
                              <option value="745">BANCO CITIBANK S.A.</option>
                              <option value="041">
                                BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                              </option>
                              <option value="756">BANCO SICOOB</option>
                              <option value="748">BANCO SICREDI</option>
                              <option value="085">
                                COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                              </option>
                              <option value="399">HSBC BANK BRASIL S.A</option>
                              <option value="756">
                                BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                              </option>
                              <option value="041">BANRISUL</option>
                              <option value="212">BANCO ORIGINAL</option>
                              <option value="260">
                                NU BANK PAGAMENTOS S.A.
                              </option>
                              <option value="318">BANCO BMG S.A.</option>
                              <option value="336">
                                BANCO C6 S.A. (C6 BANK)
                              </option>
                              <option value="077">BANCO INTER S.A.</option>
                              <option value="133">CRESOL</option>
                              {simulation.febrabanId == '074' ? (
                                <>
                                  <option value="077">INTERMEDIUM S.A.</option>
                                  <option value="290">
                                    PAGSEGURO INTERNET S.A (PAGBANK)
                                  </option>
                                  <option value="413">
                                    BANCO VOTORANTIM S.A
                                  </option>
                                  <option value="197">
                                    STONE PAGAMENTOS S.A
                                  </option>
                                  <option value="169">
                                    BANCO OLÉ BONSUCESSO CONSIGNADO S.A
                                  </option>
                                  <option value="323">
                                    MERCADO PAGO (CARTEIRA DIGITAL)
                                  </option>
                                  <option value="655">
                                    NEON PAGAMENTOS S.A (FINTECH DO BANCO
                                    VOTORANTIM)
                                  </option>
                                  <option value="637">BANCO SOFISA S.A</option>
                                  <option value="336">
                                    BANCO C6 S.A – C6 BANK
                                  </option>
                                </>
                              ) : (
                                <></>
                              )}
                              <option value="074">BANCO SAFRA S.A</option>
                              <option value="623">BANCO PAN</option>
                              <option value="380">PICPAY SERVICOS S.A</option>
                              <option value="037">BANCO DO PARÁ</option>
                              <option value="121">AGIBANK</option>
                              <option value="335">BANCO DIGIO</option>
                              <option value="389">BANCO MERCANTIL</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="4">
                          <FormGroup>
                            <Label>Agência</Label>
                            <Input
                              type="text"
                              name="agencyBank"
                              className="form-control form-control-sm"
                              value={simulation.agencyBank}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl="4">
                          <FormGroup>
                            <Label>Conta</Label>
                            <Input
                              type="text"
                              name="numberAccountBank"
                              className="form-control form-control-sm"
                              value={simulation.numberAccountBank}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl="4">
                          <FormGroup>
                            <Label>Dígito Conta</Label>
                            <Input
                              type="text"
                              name="digitAccountBank"
                              className="form-control form-control-sm"
                              value={simulation.digitAccountBank}
                              onChange={handleChange}
                              onBlur={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl="12">
                          <FormGroup>
                            <Label>Tipo da conta</Label>
                            <select
                              name="typeAccount"
                              className="form-control form-control-sm"
                              value={simulation.typeAccount}
                              onChange={handleChange}
                              onBlur={handleChange}
                            >
                              <option value="" disabled="">
                                Selecione...
                              </option>
                              <option value="01">Conta Corrente</option>
                              <option value="02">Conta Poupança</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col xl="12">
                        <FormGroup>
                          <Label>PIX</Label>
                          <Input
                            type="text"
                            name="PIX"
                            className="form-control form-control-sm"
                            value={simulation.pix}
                            placeholder="Informe o PIX, e-mail, celular, cpf, cnpj ou chave aleatória"
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-right">
          {proposalAct.name !== '' && proposalAct.status === true && (
            <>
              <Button className="btn btn-danger btn-md" onClick={closeAnalyse}>
                FECHAR ANÁLISE
              </Button>
            </>
          )}
          <Button className="btn btn-success btn-md" onClick={resimulation}>
            <i className="fas fa-clipboard-check" /> DIGITAR PROPOSTA
          </Button>
          <Button className="btn btn-danger btn-md" onClick={closeModal}>
            <i className="fas fa-times" /> SAIR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default InfoSimulationDashboard;
