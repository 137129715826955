import api from 'services/api';

const getAllNote = async (id) => {
  return await api.get(`v2/client/note/${id}`);
};

const getAllNoteByClientId = async (id) => {
  return await api.get(`v2/client/note/list/${id}`);
};

const insertNote = async (data) => {
  return await api.post('v2/client/note', data);
};

export { getAllNote, insertNote, getAllNoteByClientId };
