import styled from 'styled-components';

import { device } from 'layouts/breakpoints';

export const Container = styled.td`
  font-size: 10px;
  padding: 12px 8px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  @media ${device.desktopLG} {
    font-size: 11px;
  }

  @media ${device.desktopXL} {
    font-size: 13px;
    &:nth-last-child(2) {
      padding-right: 16px;
    }
  }
`;
