import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
  Alert,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import {
  listRegister,
  listLeadCsv
} from 'services/Consigned/ClientSearchService';
import { Title } from 'views/Dashboard/styles';
import { useHistory } from 'react-router-dom';

const SearchMargem = () => {
  const [search, setSearch] = useState('');
  const [listData, setListData] = useState([]);
  const history = useHistory();

  const handleList = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    await listRegister(search).then((result) => {
      Swal.close();
      setListData(result.data.resultSet);
    });
  };

  const handleCsv = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    await listLeadCsv({ ...search, isCsv: true }).then((res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message
      }).then((result) => {
        if (!result.isConfirmed) return;
        history.push('/admin/report-schedule');
      });
    });
  };

  const handleChange = async (ev) => {
    if (ev.target.name === 'dateStart') {
      setSearch({
        ...search,
        dateStart: ev.target.value
      });
    }
    if (ev.target.name === 'dateEnd') {
      setSearch({
        ...search,
        dateEnd: ev.target.value
      });
    }
    if (ev.target.name === 'status') {
      setSearch({
        ...search,
        status: ev.target.value
      });
    }
    if (ev.target.name === 'cpf') {
      setSearch({
        ...search,
        cpf: maskCPF(ev.target.value)
      });
    }
    if (ev.target.name === 'typeDate') {
      setSearch({
        ...search,
        typeDate: ev.target.value
      });
    }
  };

  const changeStatus = (status) => {
    if (status === 'WAIT') return 'AGUARDANDO';
    if (status === 'ERROR') return 'ERRO';
    if (status === 'SUCCESS') return 'FINALIZADO';
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="mb-2 shadow">
          <CardHeader>
            <Title>Filtro</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label>Periodo Cadastro</Label>
                  <div className="input-group">
                    <Input
                      type="date"
                      name="dateStart"
                      className="form-control form-control-sm"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    <Input
                      type="date"
                      name="dateEnd"
                      className="form-control form-control-sm"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />

                    <select
                      className="form-control form-control-sm border pl-2"
                      id="typeDate"
                      name="typeDate"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="createdAt">DATA DE CADASTRO</option>
                      <option value="updatedAt">DATA DE ATUALIZAÇÃO</option>
                    </select>
                  </div>
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>CPF</Label>
                  <Input
                    type="text"
                    name="cpf"
                    className="form-control form-control-sm"
                    value={search.cpf}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control form-control-sm"
                    name="status"
                    onChange={handleChange}
                    onBlur={handleChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="WAIT">AGUARDANDO</option>
                    <option value="ERROR">ERRO</option>
                    <option value="SUCCESS">SUCCESS</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button className="btn btn-success" onClick={handleList}>
              Buscar
            </Button>

            <a href="/admin/import-margem" className="btn btn-primary btn-md">
              Inserir CPFs para consulta
            </a>

            <Button className="btn btn-success" onClick={handleCsv}>
              Exportar em excel
            </Button>
          </CardFooter>
        </Card>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-md-6">
                    <h3 className="mb-0">Consulta Margem</h3>
                  </div>
                  <div className="col-md-6 text-right" />
                </Row>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">CLIENTE</th>
                        <th scope="col">DADOS CONTRATO</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">DATA</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {listData.map((register, key) => (
                        <>
                          <tr key={key}>
                            <td>
                              <p className="m-1">
                                <strong>NOME:</strong> <>{register.name}</>
                              </p>
                              <p className="m-1">
                                <strong>CPF:</strong>{' '}
                                <>{maskCPF(register.cpf)}</>
                              </p>
                              <p className="m-1">
                                <strong>Matricula:</strong>{' '}
                                <>{register.registry}</>
                              </p>
                            </td>
                            <td>
                              <p className="m-1">
                                <strong>Tipo Servidor:</strong>{' '}
                                <>{register.typeService}</>
                              </p>
                              <p className="m-1">
                                <strong>Orgão:</strong> <>{register.organ}</>
                              </p>
                              <p className="m-1">
                                <strong>Situação:</strong>{' '}
                                <>{register.situation}</>
                              </p>
                            </td>
                            <td>
                              <p className="m-1">
                                {changeStatus(register.status)}
                              </p>
                            </td>
                            <td>
                              <p className="m-1">
                                <strong>Data Cadastro:</strong>{' '}
                                {register.createdAt}
                              </p>
                              <p className="m-1">
                                <strong>Data Atualização:</strong>{' '}
                                {register.updatedAt}
                              </p>
                            </td>
                            <td>
                              {register.status === 'SUCCESS' ? (
                                <button
                                  className="btn btn-info"
                                  onClick={() => {
                                    history.push(
                                      `/admin/details-margem/${register.id}`
                                    );
                                  }}
                                >
                                  Detalhes
                                </button>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possui indicados cadastrados no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SearchMargem;
