import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { TagStageEnum } from './EnumStage';
import { TagSaveService } from 'services/Register/TagService';
import Swal from 'sweetalert2';

const FormModalTagComponent = ({
  isForm = true,
  setStage,
  handleList,
  tag = {
    id: null,
    title: null,
    color: null,
  },
  setTag,
}) => {
  const colors = [
    'verde-claro',
    'amarelo-claro',
    'laranja-claro',
    'vermelho-claro',
    'roxo-claro',
    'azul-claro',
    'azulCeu-claro',
    'lima-claro',
    'rosa-claro',
    'preto-claro',
  ];

  const handleSave = async () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await TagSaveService(tag)
      .then(() => {
        Swal.close();
        handleList();
        setTag({
          id: null,
          title: null,
          color: null,
        });
        setStage(TagStageEnum.LIST);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  if (!isForm) {
    return <></>;
  }

  return (
    <>
      <h3>Criar Etiqueta</h3>
      <Card className="shadow">
        <CardBody>
          {(tag.color || tag.title) && (
            <div className={`mb-3 p-3 shadow rounded ${tag.color}`}>
              {tag.title}
            </div>
          )}
          <FormGroup>
            <Label>Titulo</Label>
            <Input
              type="text"
              value={tag.title}
              onChange={(ev) => {
                setTag({ ...tag, title: ev.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Selecionar uma cor</Label>
            <div className="p-2">
              <Row>
                {colors.map((color, key) => (
                  <Col md="2" className="p-1" key={key}>
                    <Button
                      size="lg"
                      className={`w-100 ${color}`}
                      onClick={() => {
                        setTag({ ...tag, color: color });
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <div className="m-1">
            <Button color="success" className="w-100" onClick={handleSave}>
              Salvar
            </Button>
          </div>
          <div className="m-1">
            <Button
              onClick={() => setStage(TagStageEnum.LIST)}
              color="secondary"
              className="w-100"
            >
              Sair
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default FormModalTagComponent;
