import { useState, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Header from 'components/Headers/Header';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { maskCPF } from 'utils/mask';
import { searchMarginService } from 'services/Consigned/ConsignedService';
import Swal from 'sweetalert2';
import MarginResultComponent from 'views/Consigned/Component/MarginResultComponent';
import { moneyMask } from 'utils/mask';

const MarginSearchView = () => {
    const resultRef = useRef(null);
    const [cpf, setCpf] = useState('');
    const [resultMargin, setResultMargin] = useState({});

    const handleSearchMargin = async () => {
        setResultMargin({});

        const formattedCpf = cpf.replace(/[^0-9]/g, '');

        if (!formattedCpf) {
            Swal.fire({
                icon: 'error',
                title: 'CPF obrigatório',
                text: 'Por favor, informe o CPF antes de continuar.',
                confirmButtonText: 'OK',
            });
            return;
        }

        Swal.fire({
            title: 'Carregando',
            text: 'Por favor aguarde...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const result = await searchMarginService(formattedCpf);

        if (!result) {
            return;
        }

        const { resultSet } = result;
        setResultMargin(resultSet);

        Swal.close();
    };

    const handleExportPDF = () => {
        Swal.fire({
            title: 'Salvando',
            text: 'Por favor aguarde...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.text('Resultado da Simulação', 10, 10);

        doc.setFontSize(16);
        doc.text('Benefícios', 10, 30);

        const benefits = resultMargin.benefit;

        const benefitTableData = benefits.map((benefit) => [
            benefit.code,
            benefit.description,
            benefit.number,
            moneyMask(benefit.value),
            moneyMask(benefit.loanAvailableRMC),
            moneyMask(benefit.loanAvailableRCC)
        ]);

        doc.autoTable({
            startY: 40,
            head: [['Código', 'Descrição', 'Matricula', 'Benefício', 'Margem RMC', 'Margem RCC']],
            body: benefitTableData
        });

        doc.setFontSize(16);
        doc.text('Informações Pessoais', 10, doc.lastAutoTable.finalY + 30);
        doc.setFontSize(12);
        doc.text(`Nome: ${resultMargin.name}`, 10, doc.lastAutoTable.finalY + 40);
        doc.text(`CPF: ${resultMargin.cpf}`, 10, doc.lastAutoTable.finalY + 50);
        doc.text(`Dt. Nascimento: ${resultMargin.birthDate}`, 10, doc.lastAutoTable.finalY + 60);
        doc.text(`RG: ${resultMargin.documentRG}`, 10, doc.lastAutoTable.finalY + 70);
        doc.text(`Nome da Mãe: ${resultMargin.motherName}`, 10, doc.lastAutoTable.finalY + 80);

        doc.setFontSize(16);
        doc.text('Endereço', 10, doc.lastAutoTable.finalY + 100);
        doc.setFontSize(12);
        doc.text(`CEP: ${resultMargin.zipCode}`, 10, doc.lastAutoTable.finalY + 110);
        doc.text(`Endereço: ${resultMargin.street}`, 10, doc.lastAutoTable.finalY + 120);
        doc.text(`Bairro: ${resultMargin.district}`, 10, doc.lastAutoTable.finalY + 130);
        doc.text(`Estado: ${resultMargin.state}`, 10, doc.lastAutoTable.finalY + 140);

        setTimeout(() => {
            Swal.close();
            doc.save('resultado_simulacao.pdf');
        }, 1000);
    };

    return (
        <>
            <Header titlePage="Consulta de Margem" />
            <Container className="mt--9" fluid>
                <Card className="mt-3">
                    <CardHeader>
                        <h2>
                            <i className="fa fa-solid fa-signal" /> Consultar Margem
                        </h2>
                    </CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label>Digite seu CPF: </Label>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType="prepend"
                                    onClick={handleSearchMargin}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <InputGroupText>
                                        <i className="fas fa-search" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder="000.000.000-00"
                                    type="text"
                                    value={cpf}
                                    onChange={(ev) => setCpf(maskCPF(ev.target.value))}
                                />
                            </InputGroup>
                        </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button
                            onClick={handleSearchMargin}
                            color="success"
                        >
                            Buscar
                        </Button>
                    </CardFooter>
                </Card>

                {resultMargin?.name ? (
                    <>
                        <div ref={resultRef}>
                            <Card className='mt-3'>
                                <CardBody>
                                    <Button color='success' className='mb-3 float-md-right' onClick={handleExportPDF}>
                                        Exportar como PDF
                                    </Button>
                                    <MarginResultComponent
                                        result={resultMargin}
                                        renderSelectButton={false}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                ) : (
                    <Alert color="secondary" className="text-center">
                        Não possui dados no momento
                    </Alert>
                )}
            </Container>
        </>
    );
};

export default MarginSearchView;
