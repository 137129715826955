import { Card, CardHeader, Progress, Table } from 'reactstrap';

const CardConversionBankComponent = ({ conversionBank = [] }) => {
  return (
    <>
      {conversionBank.length > 0 && (
        <Card className="shadow mb-4">
          <CardHeader>
            <h3>Conversão por financeira</h3>
          </CardHeader>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th>FINANCEIRA</th>
                <th>SIMULAÇÕES</th>
                <th>PROPOSTAS</th>
                <th>TAXA DE CONVERSÃO</th>
              </tr>
            </thead>
            <tbody>
              {conversionBank.map((register, key) => (
                <tr key={key}>
                  <td className="text-lg font-weight-bold">
                    {register.febrabanId} - {register.nameBank}
                  </td>
                  <td className="text-lg">{register.qtdSimulation}</td>
                  <td className="text-lg">{register.qtdProposal}</td>
                  <td className="text-lg">
                    <div className="d-flex align-items-center">
                      <span className="mr-2">{register.qtdConversion}%</span>
                      <div>
                        <Progress
                          max="100"
                          value={register.qtdConversion}
                          barClassName="bg-danger"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </>
  );
};

export default CardConversionBankComponent;
