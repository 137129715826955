import { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import SampleModalView from 'components/Modals/SampleModalView';
import { showLinkIndicationUserService } from 'services/User/User';
import Swal from 'sweetalert2';

const ModalLinkIndicationComponent = ({
  isOpen = false,
  setIsOpen = () => {},
  userId = 0
}) => {
  const [registers, setRegisters] = useState(null);

  const handleListLinksIndication = (userId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    showLinkIndicationUserService(userId)
      .then(({ data }) => {
        Swal.close();
        setRegisters(data?.resultSet ?? null);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        }).then(() => {
          setIsOpen(false);
        });
      });
  };

  useEffect(() => {
    if (userId <= 0) return;
    setRegisters(null);
    handleListLinksIndication(userId);
  }, [userId]);

  if (userId <= 0 || !registers) return <></>;

  return (
    <>
      <SampleModalView
        labelModal="Link(s) de indicação / vendedor"
        icon="fas fa-link"
        isOpen={isOpen}
        setIsOpen={(e) => {
          setIsOpen(e);
        }}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {}}
        showBtnSubmit={false}
        size="xl"
      >
        <Card className="shadow">
          <CardHeader>
            <h3>Registro</h3>
          </CardHeader>
          <CardBody>
            <Row className="mb-5">
              <Col md="4">
                <p>Usuário</p>
                <h3>{registers.name}</h3>
              </Col>
              <Col md="4">
                <p>Código Indicação</p>
                <h3>{registers.uuidIndication}</h3>
              </Col>
              <Col md="4">
                <p>Nome Link Indicação / Vendedor</p>
                <h3>{registers.nameIndication}</h3>
              </Col>
            </Row>
            {registers?.urls?.length > 0 && (
              <>
                <h2>Links</h2>
                {registers.urls.map((register, key) => (
                  <Card key={key} className="shadow mb-2">
                    <CardHeader className="border-0">
                      {register?.name}
                    </CardHeader>
                    <CardBody>
                      <Input type="text" value={register.url} readOnly />
                    </CardBody>
                  </Card>
                ))}
              </>
            )}
          </CardBody>
        </Card>
      </SampleModalView>
    </>
  );
};

export default ModalLinkIndicationComponent;
