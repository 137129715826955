import Header from 'components/Headers/Header';
import { CaretRight } from 'phosphor-react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import Swal from 'sweetalert2';
import {
  ImportProposalService,
  listFilesImportedService,
} from 'services/Import/ImportProposalService';
import { scheduleExcel } from 'services/Report/Schedule';
import { useHistory } from 'react-router-dom';

const ImportOptinFgtsView = () => {
  const history = useHistory();
  const [form, setForm] = useState({
    febrabanId: '',
    serviceId: '',
  });
  const [file, setFile] = useState(null);
  const [result, setResult] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const upload = (file, appendData = [], onUploadProgress) => {
    let formData = new FormData();

    formData.append('upload', file);
    if (appendData.length > 0) {
      appendData.map((register) => {
        formData.append(register.name, register.code);
      });
    }

    return ImportProposalService(formData, onUploadProgress);
  };

  const handleUploadFile = () => {
    if (!form?.febrabanId) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Existem campos obrigatórios que não foram preenchidos',
      });
      return;
    }

    Swal.fire({
      title: 'Enviando arquivo',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    upload(
      file,
      [
        { name: 'serviceId', code: 2 },
        { name: 'febrabanId', code: form.febrabanId },
        { name: 'typeFile', code: 'OPTIN' },
      ],
      () => { }
    )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Arquivo enviado com sucesso, agora basta aguardar processamento da rotina!',
        }).then(() => {
          handleListImportedFiles();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListImportedFiles = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setResult([]);
    listFilesImportedService('OPTIN')
      .then((res) => {
        Swal.close();
        setResult(res?.data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleExportCsv = (fileImportedId) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja exportar o relatório?',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, exportar'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      scheduleExcel(
        20,
        { fileImportedId: fileImportedId },
        history
      );
    })
  };

  useEffect(() => {
    handleListImportedFiles();
  }, []);

  return (
    <>
      <Header titlePage="IMPORTAÇÃO - CONSULTA BASE OFFLINE BANCO(s)" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-file-csv" /> IMPORTAÇÃO - CONSULTA BASE
              OFFLINE BANCO(s)
            </h2>
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <i className="far fa-question-circle" /> Modelo de planilha para
              importação -{' '}
              <a
                href="https://storage.googleapis.com/simulador-fgts/modelo/MODELO%20PLANILHA%20-%20OPTIN.csv"
                download
              >
                <strong>BAIXAR</strong>
              </a>
            </Alert>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Arquivo CSV <Badge color="info">Tamanho máximo 1MB</Badge>{' '}
                    <Badge color="warning">Obrigatório</Badge>
                  </Label>
                  <Input
                    type="file"
                    accept=".csv"
                    className="form-control"
                    onChange={(ev) => {
                      const file = ev.target.files[0];

                      if (!file) return;

                      const sizeFile = file.size / 1024 / 1024;

                      if (sizeFile > 1) {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Error',
                          text: 'O arquivo não pode ser importado. Por favor, reduza a quantidade de registros e importe novamente!',
                        });
                        return;
                      }

                      setFile(file);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Financeira <Badge color="warning">Obrigatório</Badge>
                  </Label>
                  <select
                    className="form-control"
                    value={form.febrabanId}
                    onChange={(ev) => {
                      setForm({
                        ...form,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Selecione...
                    </option>
                    <option value="149">FACTA</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleUploadFile}>
              <i className="fas fa-file-import" /> Importar
            </Button>
          </CardFooter>
        </Card>

        {result.length > 0 && (
          <Card className="mt-3">
            <CardHeader>Últimas Importações</CardHeader>
            <CardBody className="p-2 m-0">
              <Alert color="secondary">
                <h1>
                  <i className="fas fa-exclamation-circle" /> Atenção
                </h1>
                <p>
                  - Os arquivos importados ficarão disponíveis por 1(um) dia.
                  Após esse prazo, por segurança, serão excluídos,
                  automaticamente, pelo sistema.
                </p>
                <p>
                  - Realize a importação no período das{' '}
                  <strong>07h às 18h</strong>.
                </p>
              </Alert>
            </CardBody>
            <Table responsive hover>
              <thead>
                <tr className='bg-secondary'>
                  <th>DATA IMPORTAÇÃO</th>
                  <th>DETALHES DA IMPORTAÇÃO</th>
                  <th>PROCESSAMENTO</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {result.map((register, key) => (
                  <tr key={key}>
                    <td className='align-middle'>{register.createdAt}</td>
                    <td className='align-middle'>
                      <p className="m-0">
                        Status processamento planilha:{' '}
                        <strong>{register.status}</strong>
                      </p>
                      <p className="m-0">
                        Data atualização: <strong>{register.updateAt}</strong>
                      </p>

                      <a
                        href={register.url}
                        className="btn btn-secondary btn-sm"
                        target="blank"
                      >
                        <i className="fas fa-link" /> Arquivo Importado
                      </a>
                    </td>
                    <td className='align-middle'>
                      {register?.proccess?.length > 0 &&
                        register?.proccess.map((registerProccess, key) => (
                          <p
                            className="m-0"
                            key={`${registerProccess.fileImportedId}-${key}`}
                          >
                            {registerProccess.name}:{' '}
                            <strong>
                              <Badge color="info" className="font-12">
                                {registerProccess.qtd}
                              </Badge>
                            </strong>
                          </p>
                        ))}
                    </td>
                    <td className='align-middle text-right'>
                      <Button color='success' onClick={() => handleExportCsv(register.id)}><i className="fas fa-file-csv"></i> EXPORTAR CSV</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </Container >
    </>
  );
};

export default ImportOptinFgtsView;
