import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { userListOptionService } from 'services/User/User';

const InputOperatorView = ({ filter, setFilter, isMulti = false }) => {
  const [dataList, setDataList] = useState([]);

  const handleIndexIndication = async () => {
    await userListOptionService()
      .then((result) => {
        if (!result?.data?.resultSet) {
          return;
        }
        setDataList(
          result?.data?.resultSet?.map((res) => {
            return {
              value: res.id,
              label: `${res.name}`
            };
          })
        );
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleIndexIndication();
  }, []);

  return (
    <FormGroup>
      <Label>Usuário / Operador</Label>
      <Select
        isMulti={isMulti}
        className="basic-single"
        classNamePrefix="select"
        options={dataList}
        isLoading={dataList.length <= 0}
        placeholder={`Selecione...`}
        onChange={(res) => {
          setFilter({
            ...filter,
            userId: isMulti === true ? res : res.value
          });
        }}
      />
    </FormGroup>
  );
};

export default InputOperatorView;
