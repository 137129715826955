import api from 'services/api';

export const tableTaxService = async () => {
  return await api.get(`v2/integration/safra/table-tax`);
};

export const tableTaxLotusMaisService = async () => {
  return await api.get(`v2/integration/lotusmais/table-tax`);
};

export const indexTableConsigned = async (productId, installment, useSafe) => {
  return await api.get(`v2/integration/safra/table`, {
    params: {
      productId,
      installment,
      useSafe
    }
  });
};

export const indexTableSabemiFgts = async () => {
  return await api.get(`v2/integration/sabemi/table-tax`);
};

export const indexSafraService = async (setList) => {
  await api
    .get(`v2/integration/safra/service`)
    .then((res) => {
      setList(res.data.resultSet);
    })
    .catch(() => {
      setList([]);
    });
};

export const cancelProposalGrandinoService = async (numberProposal) => {
  return await api.post(`v2/integration/grandino/cancel`, {
    numberProposal
  });
};
