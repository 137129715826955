import { useState } from 'react';
import Header from 'components/Headers/Header';
import { Container } from 'reactstrap';
import IcredFormSimulationConsignedComponent from './component/IcredFormSimulationConsignedComponent';
import IcredResultSimulationConsignedComponent from './component/IcredResultSimulationConsignedComponent';
import IcredFormClientConsignedComponent from './component/IcredFormClientConsignedComponent';

const DigitationManualiCredView = () => {
  const [stage, setStage] = useState(1);
  const [result, setResult] = useState([]);
  const [simulationSelected, setSimulationSelected] = useState({});

  return (
    <>
      <Header titlePage="INSS - Realizar digitação" />
      <Container className="mt--9 container-sm">
        <IcredFormSimulationConsignedComponent
          stage={stage}
          setStage={setStage}
          resultSimulation={(e) => {
            setResult(e);
          }}
        />
        <IcredResultSimulationConsignedComponent
          stage={stage}
          setStage={setStage}
          resultSimulation={result}
          simulationSelected={(e) => {
            setSimulationSelected(e);
          }}
        />
        <IcredFormClientConsignedComponent
          stage={stage}
          setStage={setStage}
          simulationSelected={simulationSelected}
          resultSimulation={result}
        />
      </Container>
    </>
  );
};

export default DigitationManualiCredView;
