import api from 'services/api';

export const indexSimuleService = async ({
  serviceId,
  registry,
  valueInstallment,
  installment,
  febrabanId,
  cpf,
  birthDate,
  name
}) => {
  return await api.post(`v2/consigned/inss/simule`, {
    serviceId,
    registry,
    valueInstallment,
    installment,
    febrabanId,
    cpf,
    birthDate,
    name
  });
};

export const storeProposalService = async ({
  febrabanId,
  serviceId,
  marginIncrease,
  simulation,
  phone,
  name,
  cpf,
  birthDate,
  registry,
  sex,
  documentRG,
  dateEmissionRG,
  ufRG,
  nameMother,
  email,
  cep,
  address,
  numberAddress,
  district,
  city,
  state,
  complement,
  bank,
  agencyBank,
  numberAccountBank,
  digitAccountBank,
  typeAccount,
  valueLiberty,
  valueFinally,
  valueParc,
  parc
}) => {
  return await api.post(`v2/consigned/inss/proposal`, {
    febrabanId,
    serviceId,
    marginIncrease,
    simulation,
    phone,
    name,
    cpf,
    birthDate,
    registry,
    sex,
    documentRG,
    ufRG,
    dateEmissionRG,
    nameMother,
    email,
    cep,
    address,
    numberAddress,
    district,
    city,
    state,
    complement,
    bank,
    agencyBank,
    numberAccountBank,
    digitAccountBank,
    typeAccount,
    valueLiberty,
    valueFinally,
    valueParc,
    parc
  });
};
