import QRCode from 'qrcode.react';
import { QRWrapper } from 'views/Profile/component/AccountForm/styles';
import { Input as InputCopy } from 'components/Form';
import { Card, CardBody } from 'reactstrap';

const QrCodeComponent = ({ url, className }) => {
  if (!url) {
    return <></>;
  }

  return (
    <>
      <Card className={className}>
        <CardBody className="pb-0">
          <QRWrapper>
            <h3 className="d-lg-none">QRCode</h3>
            <QRCode value={url} size={200} />
            <InputCopy
              label="URL"
              placeholder="URL"
              name="QrCodeURL"
              value={url}
              clipboard={url}
              readOnly
            />
          </QRWrapper>
        </CardBody>
      </Card>
    </>
  );
};

export default QrCodeComponent;
