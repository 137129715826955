import api from 'services/api';

export const listPayments = async (data) => {
  return await api.get('v1/payment', { params: data });
};

export const newPayment = async () => {
  return await api.post('v1/payment');
};

export const getInvoice = async () => {
  return await api.get('v1/payment/invoice');
};

export const getProductService = async (typeId) => {
  return await api.get('v2/license/product', {
    params: {
      typeId: typeId
    }
  });
};

export const genereteInvoiceProductService = async (productID) => {
  return await api.post('v2/license/product', { productId: productID });
};
