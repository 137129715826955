import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { scheduleExcel } from 'services/Report/Schedule';
import { indexLinkIndication } from 'services/User/LandingPage';
import Swal from 'sweetalert2';

const IndicationListComponent = ({ landingPageId, url }) => {
  const [listLinks, setListLinks] = useState([]);
  const history = useHistory();
  const [filter, setFilter] = useState({
    name: null,
    uuid: null,
  });

  const onCopyText = () => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Campo copiado com sucesso',
    });
  };

  const handleList = () => {
    setListLinks([]);
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    indexLinkIndication(landingPageId, url, filter)
      .then(({ data }) => {
        Swal.close();
        setListLinks(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleExportCsv = () => {
    scheduleExcel(
      17,
      {
        ...filter,
        landingPageId: landingPageId,
        url: url,
      },
      history
    );
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h3>Filtro</h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Nome</Label>
                <Input
                  value={filter.name}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Código Indicação</Label>
                <Input
                  value={filter.uuid}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      uuid: e.target.value,
                    });
                  }}
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={handleList}>
            Buscar
          </Button>
          <Button color="success" onClick={handleExportCsv}>
            Exportar CSV
          </Button>
        </CardFooter>
      </Card>
      {listLinks?.length > 0 ? (
        <>
          <Card className="shadow mt-3">
            <CardHeader>
              <h3>Registros</h3>
            </CardHeader>
            <div className="table-responsive">
              <Table bordered striped>
                <thead>
                  <tr className="bg-primary text-white">
                    <th>NOME</th>
                    <th>Código Indicação</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  {listLinks.map((link, key) => (
                    <tr key={key}>
                      <td>{link.name}</td>
                      <td>{link.uuid}</td>
                      <td width={400}>
                        <a
                          href={link.url}
                          className="btn btn-success w-50 m-0 mr-2"
                          target="_blank"
                        >
                          Acessar
                        </a>
                        <CopyToClipboard
                          text={link.url}
                          onCopy={onCopyText}
                          className="w-50 btn btn-primary m-0"
                        >
                          <button
                            type="button"
                            className={`btn btn-md btn-primary w-100`}
                          >
                            Copiar
                          </button>
                        </CopyToClipboard>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Alert color="secondary" className="mt-3">
            ...
          </Alert>
        </>
      )}
    </>
  );
};

export default IndicationListComponent;
