import api from 'services/api';

export const saveProposalCrefisaService = async (
  serviceId,
  formData,
  onUploadProgress = () => {}
) => {
  return await api.post(`v3/personal-credit/crefisa/${serviceId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
