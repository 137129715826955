import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { SelectOfferPersonalCreditCrefazService } from 'services/Digitation/PersonalCreditService';
import { InstallmentPersonalCreditCrefazService } from 'services/Digitation/PersonalCreditService';
import Swal from 'sweetalert2';

const FormSelectOfferCrefazComponent = ({
  form,
  setForm = () => {},
  offers = [],
  result = {},
  stage,
  setStage = () => {}
}) => {
  const [service, setService] = useState(-1);
  const [serviceValue, setServiceValue] = useState('');
  const [serviceSelected, setServiceSelected] = useState({});
  const [installments, setInstallments] = useState([]);

  const handleGetInstallment = (tableId, value) => {
    setInstallments([]);
    Swal.fire({
      title: 'Consultando ofertas',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const dataSend = {
      numberProposal: result.numberProposal,
      productId: offers[service].id,
      serviceId: serviceSelected.id,
      tableId: tableId,
      value: value,
      dateExpire: result.dateExpire,
      valueIncome: 2500
    };

    InstallmentPersonalCreditCrefazService(dataSend)
      .then(({ data }) => {
        Swal.close();
        setInstallments(data?.resultSet?.prazoValor ?? []);
        setForm({
          ...form,
          productId: offers[service].id,
          serviceId: serviceSelected.id,
          tableId: tableId,
          valueLiberty: value,
          dateExpire: result.dateExpire,
          numberProposal: result.numberProposal
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleSelectOffer = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja selecionar esta oferta? não será possível alterar!',
      showDenyButton: true,
      denyButtonText: 'Não, sair',
      confirmButtonText: 'Sim, continuar'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Selecionando oferta',
        text: 'Por favor, aguarde...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const products = result.offers.produtos.filter((register) => {
        return register.id == form.productId;
      });
      const services = products[0].convenio.filter((register) => {
        return register.id == form.serviceId;
      });

      const payload = {
        cep: form.cep,
        city: form.city,
        state: form.state,
        valueLiberty: form.valueLiberty,
        installment: form.qtdInstallments,
        productId: form.productId,
        serviceId: form.serviceId,
        tableId: form.tableId,
        valueInstallment: form.valueInstallment,
        valueIncome: result.offers.proposta.valorRendaPresumida,
        dateExpire: form.dateExpire,
        adicional: services[0].convenioDados.map((register) => {
          register.valor =
            Number(register.tipo) === 4 ? '2023-01-01' : '1111111';
          return register;
        })
      };

      SelectOfferPersonalCreditCrefazService(form.simulationId, payload)
        .then(({ data }) => {
          Swal.close();
          setStage(data.stage ?? 1);
          setForm({
            ...form,
            numberProposal: data.resultSet.propostaId
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });

      // console.log(form.simulationId, payload, result);
    });
  };

  if (Number(stage) !== 5) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow mt-4">
        <CardHeader>
          <h2>
            <i className="fas fa-user-tag" /> Selecione a oferta
          </h2>
        </CardHeader>
        <CardBody>
          <Card className="bg-secondary">
            <CardBody>
              <Row>
                <Col md="3">
                  <h4>Nome</h4>
                  <p>{form.name}</p>
                </Col>
                <Col md="3">
                  <h4>CPF</h4>
                  <p>{form.cpf}</p>
                </Col>
                <Col md="3">
                  <h4>Data de Nascimento</h4>
                  <p>{form.birthDate}</p>
                </Col>
                <Col md="3">
                  <h4>WhatsApp</h4>
                  <p>{form.phone}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>Oferta</CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label>Produto</Label>
                    <select
                      className="form-control"
                      onChange={(ev) => {
                        setService(ev.target.value);
                      }}
                    >
                      <option>Selecione...</option>
                      {offers.length > 0 &&
                        offers.map((register, key) => (
                          <option value={key} key={key}>
                            {register.nome}
                          </option>
                        ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Empresa - Convênio</Label>
                    <select
                      className="form-control"
                      onChange={(ev) => {
                        if (ev.target.value === '') return;
                        setInstallments([]);
                        setServiceValue('');
                        setServiceSelected(
                          offers[service].convenio[ev.target.value]
                        );
                      }}
                    >
                      <option value="" selected disabled>
                        Selecione...
                      </option>
                      {service > -1 &&
                        offers[service].convenio.map((register, key) => (
                          <option value={key} key={key}>
                            {register.nome}
                          </option>
                        ))}
                    </select>
                  </FormGroup>
                </Col>
                {serviceSelected?.id > 0 && (
                  <Col md="3">
                    <FormGroup>
                      <Label>Valor do Empréstimo</Label>
                      <select
                        value={serviceValue}
                        className="form-control"
                        onChange={(ev) => {
                          if (ev.target.value === '') return;
                          setServiceValue(ev.target.value);
                          handleGetInstallment(
                            serviceSelected.tabelaJuros[0].id,
                            ev.target.value
                          );
                        }}
                      >
                        <option value="" selected disabled>
                          Selecione...
                        </option>
                        {serviceSelected.tabelaJuros.length > 0 &&
                          serviceSelected.tabelaJuros[0].tabelaJurosValores.map(
                            (register, key) => (
                              <option value={register.valor} key={key}>
                                R$ {register.valor.toFixed(2)}
                              </option>
                            )
                          )}
                      </select>
                    </FormGroup>
                  </Col>
                )}
                {installments.length > 0 && (
                  <Col md="3">
                    <FormGroup>
                      <Label>Parcelas</Label>
                      <select
                        className="form-control"
                        onChange={(ev) => {
                          if (ev.target.value === '') return;

                          setForm({
                            ...form,
                            qtdInstallments:
                              installments[ev.target.value].prazo,
                            valueInstallment:
                              installments[ev.target.value].valor
                          });
                        }}
                      >
                        <option value="" selected disabled>
                          Selecione...
                        </option>
                        {installments.map((register, key) => (
                          <option value={key} key={key}>
                            {register.prazo}x - R$ {register.valor.toFixed(2)}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={handleSelectOffer}>
            Confirmar oferta
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default FormSelectOfferCrefazComponent;
