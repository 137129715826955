import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { SimulePersonalCreditCrefazService } from 'services/Digitation/PersonalCreditService';
import { handleSearchCepService } from 'services/cepApi';
import Swal from 'sweetalert2';
import { phoneMask, maskCPF, maskDate, cepMask } from 'utils/mask';

const FormDigitationClientCrefazComponent = ({
  form,
  setForm = () => {},
  setResult,
  setOffers,
  stage,
  setStage = () => {},
}) => {
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Salvando simulação',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await SimulePersonalCreditCrefazService(form)
      .then(({ data }) => {
        Swal.close();
        setResult(data?.resultSet?.result ?? {});
        setOffers(data?.resultSet?.result?.offers?.produtos ?? []);
        setStage(data?.resultSet?.stage ?? 1);
        setForm({
          ...form,
          simulationId: data?.resultSet?.id ?? 0,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    setBtnDisabled(true);
    if (
      form.name !== '' &&
      form.cpf !== '' &&
      form.birthDate !== '' &&
      form.phone !== '' &&
      form.state !== '' &&
      form.city !== '' &&
      form.cep !== ''
    ) {
      setBtnDisabled(false);
    }
  }, [form]);

  if (Number(stage) !== 1) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow mt-4">
        <CardHeader>
          <h2>
            <i className="fas fa-user-check" /> Informações do cliente
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label>Nome</Label>
                <Input
                  value={form.name}
                  onChange={(ev) => {
                    setForm({ ...form, name: ev.target.value });
                  }}
                  invalid={!form.name}
                />
                <FormFeedback invalid={!form.name}>
                  O nome é obrigatório
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>CPF</Label>
                <Input
                  value={form.cpf}
                  onChange={(ev) => {
                    setForm({ ...form, cpf: maskCPF(ev.target.value) });
                  }}
                  invalid={!form.cpf}
                  placeholder="000.000.000-00"
                />
                <FormFeedback invalid={!form.cpf}>
                  O CPF é obrigatório
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Data de Nascimento</Label>
                <Input
                  value={form.birthDate}
                  onChange={(ev) => {
                    setForm({ ...form, birthDate: maskDate(ev.target.value) });
                  }}
                  invalid={!form.birthDate}
                  placeholder="00/00/0000"
                />
                <FormFeedback invalid={!form.birthDate}>
                  A data de nascimento é obrigatória
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>WhatsApp</Label>
                <Input
                  value={form.phone}
                  onChange={(ev) => {
                    setForm({
                      ...form,
                      phone: phoneMask(ev.target.value),
                    });
                  }}
                  invalid={!form.phone}
                  placeholder="(00) 00000-0000"
                />
                <FormFeedback invalid={!form.phone}>
                  O WhatsApp é obrigatório
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-map-marker-alt" /> Região
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>CEP</Label>
                    <Input
                      value={form.cep}
                      onChange={(ev) => {
                        setForm({
                          ...form,
                          cep: cepMask(ev.target.value),
                        });
                      }}
                      onBlur={(ev) => {
                        handleSearchCepService(form.cep, (cep) => {
                          setForm({
                            ...form,
                            cep: cepMask(ev.target.value),
                            state: cep.state,
                            city: cep.city,
                          });
                        });
                      }}
                      invalid={!form.cep}
                    />
                    <FormFeedback invalid={!form.cep}>
                      O CEP é obrigatório
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Estado</Label>
                    <Input value={form.state} readOnly />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Cidade</Label>
                    <Input value={form.city} readOnly />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Button
            className="btn btn-success mt-3"
            onClick={handleSubmit}
            disabled={btnDisabled}
          >
            <i className="fas fa-fire" /> Consultar oferta de crédito
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default FormDigitationClientCrefazComponent;
