export const PAN_SERVICES = [
  {
    codigo: '007000',
    nome: 'INSS',
  },
  {
    codigo: '000006',
    nome: 'GOV RO',
  },
  {
    codigo: '000014',
    nome: 'GOV RJ',
  },
  {
    codigo: '000017',
    nome: 'GOVERNO DO ESTADO DE ALAGOAS',
  },
  {
    codigo: '000042',
    nome: 'GOV PR',
  },
  {
    codigo: '000050',
    nome: 'PREF RJ',
  },
  {
    codigo: '000052',
    nome: 'GOV GO',
  },
  {
    codigo: '000053',
    nome: 'GOV MG',
  },
  {
    codigo: '000071',
    nome: 'INSTITUTO DE PREV DE SAO PAULO',
  },
  {
    codigo: '000100',
    nome: 'PREFEITURA DE GOIANIA',
  },
  {
    codigo: '000101',
    nome: 'GOV AM',
  },
  {
    codigo: '000127',
    nome: 'PREFEITURA DE SALVADOR',
  },
  {
    codigo: '000128',
    nome: 'TRIBUNAL DE JUSTICA DO ESTADO DA BAHIA',
  },
  {
    codigo: '000154',
    nome: 'SECRETARIA DE ESTADO DA GESTAO ADM AC',
  },
  {
    codigo: '000166',
    nome: 'GOV DO ESPIRITO SANTO  CARTAO',
  },
  {
    codigo: '000178',
    nome: 'PREFEITURA DE MANAUS',
  },
  {
    codigo: '000191',
    nome: 'POL MILITAR MINAS GERAIS',
  },
  {
    codigo: '000192',
    nome: 'IPSEMG',
  },
  {
    codigo: '000196',
    nome: 'MARINHA',
  },
  {
    codigo: '000197',
    nome: 'AERONAUTICA',
  },
  {
    codigo: '000225',
    nome: 'PREF BH',
  },
  {
    codigo: '000242',
    nome: 'GOV SC',
  },
  {
    codigo: '000245',
    nome: 'PREFEITURA MUN DE BELEM',
  },
  {
    codigo: '000265',
    nome: 'AMAZONPREV',
  },
  {
    codigo: '000272',
    nome: 'DEF PUBLICA MG',
  },
  {
    codigo: '000273',
    nome: 'CBMG',
  },
  {
    codigo: '000358',
    nome: 'GOV PE',
  },
  {
    codigo: '000362',
    nome: 'PREFEITURA DE SAO PAULO',
  },
  {
    codigo: '000363',
    nome: 'GOV DO MARANHAO',
  },
  {
    codigo: '000483',
    nome: 'GOVERNO DO ESTADO DA BAHIA',
  },
  {
    codigo: '000485',
    nome: 'GOVERNO DO ESTADO DE SAO PAULO',
  },
  {
    codigo: '000487',
    nome: 'SAO PAULO PREVIDENCIA SPPREV',
  },
  {
    codigo: '000505',
    nome: 'FUNDO UNICO DE PREVIDENCIA SOCIAL DO RJ',
  },
  {
    codigo: '000528',
    nome: 'GOV SP  SEC EDUCACAO',
  },
  {
    codigo: '000533',
    nome: 'GOVERNO DO ESTADO DE SAO PAULO',
  },
  {
    codigo: '000535',
    nome: 'SECRETARIA DE EST DA ADM E PREVIDENCIA',
  },
  {
    codigo: '000536',
    nome: 'SECRETARIA EST FAZENDA DE MINAS GERAIS ',
  },
  {
    codigo: '000537',
    nome: 'SECRETARIA ESTADO DE JUST E SEG PUBLICA',
  },
  {
    codigo: '000539',
    nome: 'MINAS GERAIS GAB MILITAR DO GOVERNADOR',
  },
  {
    codigo: '000540',
    nome: 'SECRETARIA GERAL',
  },
  {
    codigo: '000543',
    nome: 'FUNDO DO REGIME GERAL DE PREVID SOCIAL',
  },
  {
    codigo: '000545',
    nome: 'SECRETARIA MUNICIPAL DE GESTAO - PREF SP',
  },
  {
    codigo: '000548',
    nome: 'GOVERNO DE PERNAMBUCO - CARTÃO C',
  },
  {
    codigo: '005000',
    nome: 'SIAPE  PENSION',
  },
  {
    codigo: '005001',
    nome: 'SIAPE SERVIDOR',
  },
  {
    codigo: '100000',
    nome: 'CAIXA ECONOMICA FEDERAL',
  },
];
export const SAFRA_SERVICES = [
  {
    idConvenio: 10122,
    nome: 'PREF CAMPO GRANDE',
    cnpj: 3501509000106,
    nomeFantasia: 'PREF MUNIC DE CAMPO GRANDE',
    uf: 'MS',
  },
  {
    idConvenio: 10123,
    nome: 'IMPCG',
    cnpj: 3514189000129,
    nomeFantasia: 'IMPCG INST MUN DE PREV CAMPO GRANDE',
    uf: 'MS',
  },
  {
    idConvenio: 10126,
    nome: 'PREF GUARULHOS',
    cnpj: 46319000000150,
    nomeFantasia: 'PREF MUNIC DE GUARULHOS',
    uf: 'SP',
  },
  {
    idConvenio: 10183,
    nome: 'PREF SAO LUIS',
    cnpj: 6307102000130,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE SAO LUIS',
    uf: 'MA',
  },
  {
    idConvenio: 10197,
    nome: 'GOV MA',
    cnpj: 6354468000160,
    nomeFantasia: 'GOVERNO DO ESTADO DO MARANHAO',
    uf: 'MA',
  },
  {
    idConvenio: 10237,
    nome: 'GOV BA',
    cnpj: 13323274000163,
    nomeFantasia: 'GOVERNO DO ESTADO DA BAHIA',
    uf: 'BA',
  },
  {
    idConvenio: 10256,
    nome: 'PREF BARUERI',
    cnpj: 46523015000135,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE BARUERI',
    uf: 'SP',
  },
  {
    idConvenio: 10257,
    nome: 'GOV AM',
    cnpj: 4312369000190,
    nomeFantasia: 'GOVERNO DO ESTADO DO AMAZONAS',
    uf: 'AM',
  },
  {
    idConvenio: 10258,
    nome: 'GOV MT',
    cnpj: 3507415000497,
    nomeFantasia: 'GOVERNO DO ESTADO DO MATO GROSSO',
    uf: 'MT',
  },
  {
    idConvenio: 10312,
    nome: 'GOV MS',
    cnpj: 15412257000128,
    nomeFantasia: 'GOV EST MATO GROSSO DO SUL',
    uf: 'MS',
  },
  {
    idConvenio: 10321,
    nome: 'PREF BELO HORIZONTE',
    cnpj: 18715383000140,
    nomeFantasia: 'PREF MUNIC BELO HORIZONTE',
    uf: 'MG',
  },
  {
    idConvenio: 10322,
    nome: 'TJ MG',
    cnpj: 21154554000113,
    nomeFantasia: 'TJ DO ESTADO  DE MINAS GERAIS',
    uf: 'MG',
  },
  {
    idConvenio: 10324,
    nome: 'AERONAUTICA',
    cnpj: 394429011064,
    nomeFantasia: 'COMANDO DA  AERONAUTICA - GARJ',
    uf: 'RJ',
  },
  {
    idConvenio: 10375,
    nome: 'GOV MG - SEPLAG',
    cnpj: 1446221000150,
    nomeFantasia: 'GOV MINAS GERAIS SEPLAG',
    uf: 'MG',
  },
  {
    idConvenio: 10411,
    nome: 'GOV MG - PMMG',
    cnpj: 2753130000120,
    nomeFantasia: 'PMMG',
    uf: 'MG',
  },
  {
    idConvenio: 10416,
    nome: 'IPASP',
    cnpj: 51327724000185,
    nomeFantasia: 'INSTITUTO DE PREVIDENCIA E ASSIST',
    uf: 'SP',
  },
  {
    idConvenio: 10417,
    nome: 'CAMPREV',
    cnpj: 6916689000185,
    nomeFantasia: 'INST DE PREVID DO MUN DE CAMPINAS',
    uf: 'SP',
  },
  {
    idConvenio: 10422,
    nome: 'PREF CAMPINAS',
    cnpj: 51885242000140,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE CAMPINAS',
    uf: 'SP',
  },
  {
    idConvenio: 10435,
    nome: 'GOV MG - IPSM',
    cnpj: 17444779000137,
    nomeFantasia: 'INST DE PESQ DE SERV MILITAR DE MG',
    uf: 'MG',
  },
  {
    idConvenio: 13355,
    nome: 'SIAPE',
    cnpj: 489828000236,
    nomeFantasia: 'MINISTERIO DO PLANEJ ORC E GESTAO',
    uf: 'DF',
  },
  {
    idConvenio: 13357,
    nome: 'GOV SC',
    cnpj: 82951229000176,
    nomeFantasia: 'GOVERNO DO ESTADO DE SANTA CATARINA',
    uf: 'SC',
  },
  {
    idConvenio: 13358,
    nome: 'GOV PR',
    cnpj: 76416940000128,
    nomeFantasia: 'GOVERNO DO ESTADO DO PARANA',
    uf: 'PR',
  },
  {
    idConvenio: 50000,
    nome: 'INSS',
    cnpj: 29979036000140,
    nomeFantasia: 'INSS',
    uf: 'DF',
  },
  {
    idConvenio: 13360,
    nome: 'GOV MG - CORPO DE BOMBEIROS MG',
    cnpj: 3389126000198,
    nomeFantasia: 'CORPO DE BOMBEIROS MG',
    uf: 'MG',
  },
  {
    idConvenio: 13361,
    nome: 'GOV MG - IPSEMG',
    cnpj: 17217332000125,
    nomeFantasia: 'IPSEMG',
    uf: 'MG',
  },
  {
    idConvenio: 13364,
    nome: 'PREF CURITIBA',
    cnpj: 76417005000186,
    nomeFantasia: 'PREF CURITIBA',
    uf: 'PR',
  },
  {
    idConvenio: 10038,
    nome: 'IPREM ',
    cnpj: 47109087000101,
    nomeFantasia: 'INSTITUTO DE PREV. MUNIC. S. PAULO',
    uf: 'SP',
  },
  {
    idConvenio: 10108,
    nome: 'MARINHA',
    cnpj: 394502001035,
    nomeFantasia: 'MARINHA DO BRASIL',
    uf: 'RJ',
  },
  {
    idConvenio: 10109,
    nome: 'GOV GO',
    cnpj: 3517631000170,
    nomeFantasia: 'GOVERNO DO ESTADO DE GOIAS',
    uf: 'GO',
  },
  {
    idConvenio: 10110,
    nome: 'PREF SAO PAULO',
    cnpj: 46392130000380,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE SAO PAULO',
    uf: 'SP',
  },
  {
    idConvenio: 50010,
    nome: 'GOV SP - SPPREV',
    cnpj: 9041213000136,
    nomeFantasia: 'GOV SP - SPPREV',
    uf: 'SP',
  },
  {
    idConvenio: 50099,
    nome: 'AUXILIO BRASIL',
    cnpj: 5526783000165,
    nomeFantasia: 'AUXILIO BRASIL',
    uf: 'DF',
  },
  {
    idConvenio: 50116,
    nome: 'PREF JOAO PESSOA',
    cnpj: 8778326000156,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE JOAO PESSOA',
    uf: 'PB',
  },
  {
    idConvenio: 50086,
    nome: 'PREF MANAUS',
    cnpj: 4365326000173,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE MANAUS',
    uf: 'AM',
  },
  {
    idConvenio: 50111,
    nome: 'GOV AL',
    cnpj: 12200176000176,
    nomeFantasia: 'GOVERNO DE ALAGOAS',
    uf: 'AL',
  },
  {
    idConvenio: 50006,
    nome: 'UNICAMP',
    cnpj: 46068425000133,
    nomeFantasia: 'UNICAMP',
    uf: 'SP',
  },
  {
    idConvenio: 50057,
    nome: 'FGTS',
    cnpj: 360305000104,
    nomeFantasia: 'CEF MATRIZ',
    uf: 'DF',
  },
  {
    idConvenio: 50023,
    nome: 'PREF PIRACICABA',
    cnpj: 46341038000129,
    nomeFantasia: 'PREF PIRACICABA',
    uf: 'SP',
  },
  {
    idConvenio: 50073,
    nome: 'PREF RECIFE',
    cnpj: 10565000000192,
    nomeFantasia: 'PREFEITURA DE RECIFE',
    uf: 'PE',
  },
  {
    idConvenio: 50089,
    nome: 'GOV PB',
    cnpj: 8761140000194,
    nomeFantasia: 'GOVERNO DO ESTADO DA PARAÍBA',
    uf: 'PB',
  },
  {
    idConvenio: 50008,
    nome: 'GOV SP - SEFAZ',
    cnpj: 46377222000129,
    nomeFantasia: 'GOV SP - SEFAZ',
    uf: 'SP',
  },
  {
    idConvenio: 50009,
    nome: 'GOV SP - POLICIA MILITAR',
    cnpj: 4198514000154,
    nomeFantasia: 'GOV SP - PM',
    uf: 'SP',
  },
  {
    idConvenio: 50113,
    nome: 'MANAUSPREV',
    cnpj: 7637990000112,
    nomeFantasia: 'MANAUSPREV',
    uf: 'AM',
  },
  {
    idConvenio: 50062,
    nome: 'ISAPA IMPORTAÇÃO E COMERCIO',
    cnpj: 61327045000160,
    nomeFantasia: 'ISAPA IMPORTAÇÃO E COMERCIO',
    uf: 'SP',
  },
  {
    idConvenio: 50088,
    nome: 'PREF ARACAJU',
    cnpj: 13128780000100,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE ARACAJU',
    uf: 'SP',
  },
  {
    idConvenio: 50098,
    nome: 'GOV RO',
    cnpj: 394585000171,
    nomeFantasia: 'GOVERNO DO ESTADO DE RONDÔNIA',
    uf: 'RO',
  },
  {
    idConvenio: 50131,
    nome: 'PREF CONTAGEM',
    cnpj: 18715508000131,
    nomeFantasia: 'PREFEITURA MUNICIPAL DE CONTAGEM',
    uf: 'MG',
  },
  {
    idConvenio: 50128,
    nome: 'PREF PORTO VELHO',
    cnpj: 5903125000145,
    nomeFantasia: 'PREF PORTO VELHO',
    uf: 'RO',
  },
];
export const FACTA_SERVICES = [
  {
    id: 13,
    name: 'NOVO DIGITAL'
  },
  {
    id: 35,
    name: 'NOVO DIGITAL (REPR LEGAL)'
  },
  {
    id: 27,
    name: 'MARGEM COMPLEMENTAR DIGITAL'
  },
  {
    id: 37,
    name: 'MARGEM COMPL DIGITAL (REPR LEGAL)'
  }
];
