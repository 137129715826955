import api from 'services/api';

export const TagSaveService = (
  data = {
    id: null,
    title: null,
    color: null
  }
) => {
  return api.post(`v2/tag`, data);
};

export const listTagsService = () => {
  return api.get(`v2/tag`);
};

export const TagIndexService = ({ clientId, serviceId }) => {
  return api.get(`v2/tag/${clientId}/${serviceId}`);
};

export const TagClientSaveService = (
  data = {
    tagId: null,
    clientId: null,
    serviceId: null,
    active: null
  }
) => {
  return api.post(`v2/tag/client`, data);
};

export const TagInactiveService = (tagId) => {
  return api.delete(`v2/tag/${tagId}`);
};
