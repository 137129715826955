import api from 'services/api';
import Swal from 'sweetalert2';

export const insertStoreService = async (data) => {
  return await api.post(`v3/store/create`, data);
};

export const getAllStores = async (filter) => {
  return await api.get(`v3/store`, {
    params: filter,
  });
};

export const listStoreToFilter = async () => {
  return await api
    .get(`v3/store/filter`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};

export const toggleStoreStatus = async (storeId) => {
  return await api.put(`v3/store/active-inactive/${storeId}`);
};
