export const PersonImgEnum = [
  'https://izidev.temfgts.com.br/v1/image/peoplesmartphone.png',
  'https://izidev.temfgts.com.br/v1/image/peoplesmartphone2.png',
  'https://izidev.temfgts.com.br/v1/image/peoplesmartphone3.png',
  'https://izidev.temfgts.com.br/v1/image/peoplesmartphone4.png',
  'https://izidev.temfgts.com.br/v1/image/peopleoldwoman1.png',
  'https://izidev.temfgts.com.br/v1/image/peopleoldwoman2.png',
  'https://izidev.temfgts.com.br/v1/image/peopleoldman1.png',
  'https://izidev.temfgts.com.br/v1/image/peopleoldman2.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-1.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-3.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-4.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-5.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-6.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-7.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-8.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-9.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-10.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-11.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-12.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-13.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-14.png',
  'https://storage.googleapis.com/simulador-fgts/img/people/people-15.png'
];
