import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { simuleInssService } from 'services/Digitation/DigitationService';
import { indexSafraService } from 'services/Digitation/IntegrationService';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import safra from '../../../../assets/img/banks/074.png';

const SimulationBanks = ({
  simulation,
  setSimulation,
  digitation,
  setDigitation
}) => {
  const [resultSafra, setResultSafra] = useState(null);
  const [listSafraService, setListSafraService] = useState([]);

  useEffect(() => {}, [digitation]);
  useEffect(() => {
    indexSafraService(setListSafraService);
  }, []);

  const handleSimule = () => {
    Swal.fire({
      title: 'Carregando',
      message: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    simuleInssService({
      registry: digitation.registry,
      cpf: digitation.cpf,
      valueParc: simulation.value,
      parc: simulation.parc,
      agreement: digitation.agreement
    })
      .then((res) => {
        Swal.close();
        setResultSafra(res.data.resultSet.simulation.safra);
      })
      .catch(() => {
        Swal.close();
        setResultSafra(null);
      });
  };

  return (
    <>
      <Col className="mb-5 mb-xl-0 mt-5" xs="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Simulação</h3>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {listSafraService.length > 0 && (
                <Col md="12">
                  <FormGroup>
                    <Label>Convênio</Label>
                    <select
                      className="form-control"
                      value={digitation.agreement ?? 50000}
                      onChange={(ev) => {
                        setDigitation({
                          ...digitation,
                          agreement: ev.target.value
                        });
                      }}
                    >
                      {listSafraService.map((option, key) => (
                        <option value={option.idConvenio} key={key}>
                          {option.nome}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              )}

              <Col md="4">
                <FormGroup>
                  <Label>Aumento de Margem</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setDigitation({
                        ...digitation,
                        marginIncrease: ev.target.value
                      });
                    }}
                  >
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </select>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Valor da Parcela</Label>
                  <Input
                    name="valueParc"
                    id="valueParc"
                    value={simulation.value}
                    onChange={(ev) => {
                      setSimulation({
                        ...simulation,
                        value: moneyMask(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Prazo</Label>
                  <div className="input-group">
                    <Input
                      name="parc"
                      id="parc"
                      value={simulation.parc}
                      type="number"
                      onChange={(ev) => {
                        setSimulation({
                          ...simulation,
                          parc: ev.target.value
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <Button color="success" onClick={handleSimule}>
                        Simular
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <h4>Resultado</h4>
            <div>
              {resultSafra ? (
                <>
                  <img alt="Safra" src={safra} />
                  <div>
                    {resultSafra && resultSafra.criticas.length > 0 ? (
                      <Alert color="danger">
                        <h2 className="m-0 text-white">
                          {resultSafra.criticas[0]}
                        </h2>
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ maxHeight: '800px', overflow: 'auto' }}>
                    {resultSafra.simulacoes.map((data, key) => (
                      <div key={key} className="border p-2 mb-2">
                        <Alert>
                          <Label className="m-0" style={{ cursor: 'pointer' }}>
                            <Input
                              type="radio"
                              name="bank"
                              className="ml"
                              onChange={() => {
                                setDigitation({
                                  ...digitation,
                                  febrabanId: '074',
                                  simulationInss: data
                                });
                              }}
                            />
                            {data.idTabelaJuros} - {data.dsTabelaJuros}
                          </Label>
                        </Alert>
                        <Row>
                          <Col md="3">
                            <p className="m-0">VLR. PARCELA</p>
                            <h3 className="m-0">
                              R$ {moneyMask(data.valorParcela.toFixed(2))}
                            </h3>
                          </Col>
                          <Col md="3">
                            <p className="m-0">VLR. FINANCIADO</p>
                            <h3 className="m-0">
                              R$ {moneyMask(data.valorFinanciado.toFixed(2))}
                            </h3>
                          </Col>
                          <Col md="3">
                            <p className="m-0">VLR. PRINCIPAL</p>
                            <h3 className="m-0">
                              R$ {moneyMask(data.valorPrincipal.toFixed(2))}
                            </h3>
                          </Col>
                          <Col md="3">
                            <p className="m-0">PRAZO</p>
                            <h3 className="m-0">{data.prazo}</h3>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <h3 className="text-danger">
                    {resultSafra && resultSafra.criticas
                      ? resultSafra.criticas[0]
                      : 'Não possuem resultados no momento'}
                  </h3>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SimulationBanks;
