import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';

import { Container, SmallAlert } from './styles';

export const Input = ({
  label,
  name,
  placeholder,
  type = 'text',
  value,
  onChange,
  onBlur,
  clipboard,
  clipboardTheme = 'primary',
  small,
  smallTheme = 'primary',
  ...rest
}) => {
  const onCopyText = () => {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Campo copiado com sucesso'
    });
  };

  return (
    <Container className="form-group">
      <label className="form-control-label" htmlFor={`input-${name}`}>
        {label}
      </label>
      <div className={clipboard ? 'input-group' : null}>
        <input
          className="form-control form-control-alternative"
          id={`input-${name}`}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
        {clipboard && (
          <div className="input-group-append">
            <CopyToClipboard text={clipboard} onCopy={onCopyText}>
              <button
                type="button"
                className={`btn btn-md btn-${clipboardTheme}`}
              >
                Copiar
              </button>
            </CopyToClipboard>
          </div>
        )}
      </div>
      {small && (
        <SmallAlert className={`text-${smallTheme}`}>{small}</SmallAlert>
      )}
    </Container>
  );
};
