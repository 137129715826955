import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
} from 'reactstrap';
import {
  listAllTablesService,
  saveSelectedTablesService,
  listTablesService,
  activeInactiveTableService,
} from 'services/AccessBank/AccessBankService';
import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';

const MyTablesComponent = ({ febrabanId, serviceId = 2 }) => {
  const [tables, setTables] = useState([]);
  const [list, setList] = useState([]);
  const [tableComissionBankId, setTableComissionBankId] = useState('');

  const handleList = () => {
    listTablesService(febrabanId, serviceId)
      .then(({ data }) => {
        setList(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = async () => {
    if (!tableComissionBankId) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos obrigatórios',
        text: 'Por favor, preencha todos os campos obrigatórios.',
      });
      return;
    }

    try {
      const confirm = await Swal.fire({
        icon: 'question',
        title: 'Confirmação',
        text: 'Tem certeza que deseja cadastrar esta tabela?',
        showDenyButton: true,
        denyButtonText: 'Não, sair',
        confirmButtonText: 'Sim, salvar',
      });

      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        tableComissionBankId: tableComissionBankId,
      };

      await saveSelectedTablesService(data);

      Swal.close();

      setTableComissionBankId('');
      handleList();
    } catch (err) {
      Swal.close();

      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleActiveInactive = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      showDenyButton: true,
      denyButtonText: 'Não, sair',
      confirmButtonText: 'Sim, salvar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      activeInactiveTableService(id)
        .then(() => {
          Swal.close();
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const handleListAllTables = async () => {
    try {
      const { data } = await listAllTablesService(febrabanId, {
        serviceId: serviceId,
      });
      setTables(data.resultSet ?? []);
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  useEffect(() => {
    handleListAllTables();
    handleList();
  }, []);

  return (
    <>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Minhas Tabelas</h3>
        <Alert color="primary">
          <h2 className="mt-0">Informações importantes</h2>
          <ul>
            <li>
              <strong>Atenção: </strong>Esta configuração não é obrigatória.
            </li>
            <li>
              {' '}
              Ao selecionar uma ou mais tabelas, o sistema só irá exibir as
              tabelas configuradas.
            </li>
          </ul>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <Title>Cadastrar Tabelas</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Tabela</Label>
                  <select
                    type="text"
                    autoComplete="false"
                    value={tableComissionBankId}
                    className="form-control"
                    onChange={(ev) => {
                      setTableComissionBankId(ev.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Selecione...
                    </option>
                    {tables.length > 0 &&
                      tables.map((table, key) => (
                        <option key={key} value={table.id}>
                          {table.tableName}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSubmit}>
              Adicionar
            </Button>
          </CardFooter>
        </Card>
        {list.length > 0 && (
          <Card className="shadow mt-2">
            <CardHeader>
              <Title>Tabelas cadastradas</Title>
            </CardHeader>
            <Table responsive>
              <thead>
                <tr className="bg-secondary">
                  <th>TIPO</th>
                  <th>PRODUTO</th>
                  <th>TABELA</th>
                  <th>PRAZO</th>
                  <th>STATUS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.map((rule, key) => (
                  <tr key={key}>
                    <td>
                      <strong>{rule.type}</strong>
                    </td>
                    <td>
                      <strong>{rule.product}</strong>
                    </td>
                    <td>
                      <strong>{rule.tableName}</strong>
                    </td>
                    <td>
                      <strong>{rule.installment}</strong>
                    </td>
                    <td>
                      <Badge
                        color={rule.active == 1 ? 'success' : 'danger'}
                        className="font-16"
                      >
                        {rule.active == 1 ? 'ATIVO' : 'INATIVO'}
                      </Badge>
                    </td>
                    <td className="text-right">
                      <Button
                        color={rule.active == 0 ? 'success' : 'danger'}
                        size="sm"
                        onClick={() => {
                          handleActiveInactive(rule.id);
                        }}
                      >
                        {rule.active == 1 ? (
                          <>
                            <i className="fas fa-minus-circle" /> INATIVAR
                          </>
                        ) : (
                          <>
                            <i className="fas fa-check-circle" /> ATIVAR
                          </>
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )}
      </div>
    </>
  );
};

export default MyTablesComponent;
