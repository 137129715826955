import api from 'services/api';
import Swal from 'sweetalert2';

const ListSimulation = async ({
  dateStart = null,
  dateEnd = null,
  document = null,
  status = null,
  page = 0,
  error = null,
  numberProposal = null,
  statusBank = null,
  statusBankId = null,
  indicationId = null,
  bank = null,
  userId = null,
  hasBalance = null,
  typeDate = null,
  whatsappNumber = null,
  tag = null,
}) => {
  return await api.get('v1/digitation/simulation', {
    params: {
      dateStart,
      dateEnd,
      document,
      status,
      page,
      error,
      numberProposal,
      statusBank,
      statusBankId,
      indicationId,
      bank,
      userId,
      hasBalance,
      typeDate,
      whatsappNumber,
      tag,
    },
  });
};

const ListSimulationCsv = async ({
  dateStart = null,
  dateEnd = null,
  document = null,
  status = null,
  error = null,
  numberProposal = null,
  statusBank = null,
  statusBankId = null,
  indicationId = null,
  bank = null,
  hasBalance = null,
  typeDate = null,
  whatsappNumber = null,
  tag = null,
}) => {
  return await api.get('v1/digitation/simulation/csv', {
    params: {
      dateStart,
      dateEnd,
      document,
      status,
      page: 0,
      error,
      numberProposal,
      statusBank,
      statusBankId,
      indicationId,
      bank,
      hasBalance,
      typeDate,
      whatsappNumber,
      tag,
    },
  });
};

const ProposalAct = async (simulationId) => {
  return await api.post(`v1/proposal/action-user/${simulationId}`);
};

const CloseProposalAct = async (simulationId) => {
  return await api.put(`v1/proposal/action-user/close/${simulationId}`);
};

const SimulationFgts = async (data) => {
  return await api.post(`v2/digitation/fgts/simulation/manual`, data);
};

const MiniSimulationFgts = async (simulationId) => {
  return await api.post(`v1/digitation/mini-simulation/${simulationId}`);
};

const IndexSimulationReport = async (serviceId, data) => {
  return await api.get(`v2/report/simulation/${serviceId}`, {
    params: data,
  });
};

const showResumeDigitationDay = async (serviceId) => {
  return await api.get(`v2/report/digitation/resume/${serviceId}`);
};

const showCardResumeDigitationService = async (serviceId, days) => {
  return await api.get(`v2/report/card-resume/${serviceId}/${days}`);
};

const IndexMiniSimulationReport = async (filter) => {
  return await api.get(`v2/report/mini-simulation`, {
    params: filter,
  });
};

const IndexMiniSimulationReportCsv = async (filter) => {
  return await api.get(`v2/report/mini-simulation/csv`, {
    params: filter,
  });
};

const OnlySimulationFgtsService = async (client) => {
  return await api.post(`v2/fgts/only/simulation`, client);
};

const SimulationFgtsService = async (client) => {
  return await api.post(`v2/fgts/simulation`, client);
};

const SaveSimulationFgtsService = async (client) => {
  return await api.post(`v2/fgts/simulation/save`, client);
};

const ReportSimulationErrorService = async (data) => {
  return await api.get(`v3/fgts/report/simulation/error`, {
    params: data,
  });
};

const ChangeIndicationSimulationService = async (simulationId, indicationId) => {
  return await api.put(`v3/simulation/change/indication/${simulationId}/${indicationId}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
}

export {
  ListSimulation,
  ListSimulationCsv,
  ProposalAct,
  CloseProposalAct,
  SimulationFgts,
  MiniSimulationFgts,
  IndexSimulationReport,
  showResumeDigitationDay,
  IndexMiniSimulationReport,
  IndexMiniSimulationReportCsv,
  OnlySimulationFgtsService,
  SimulationFgtsService,
  SaveSimulationFgtsService,
  ReportSimulationErrorService,
  ChangeIndicationSimulationService,
  showCardResumeDigitationService
};
