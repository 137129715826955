import QrCodeComponent from 'components/Form/QrCodeComponent';
import Header from 'components/Headers/Header.js';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { updateLandingPage } from 'services/User/LandingPage';
import { getLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import { BodyTitle } from 'views/Profile/styles';

const LandingPageConsigned = () => {
  const [landingPage, setLandingPage] = useState({
    contactPhone: null,
    textContactPhone: null,
    image: null,
    bgPrimary: null,
    bgSecondary: null,
    colorPrimary: null,
  });

  const [header, setHeader] = useState({
    title: null,
    text: null,
  });

  const [infoPage1, setInfoPage1] = useState({
    title: null,
    text: null,
  });

  const [infoPage2, setInfoPage2] = useState({
    title: null,
    text: null,
  });

  const [infoPage3, setInfoPage3] = useState({
    title: null,
    text: null,
  });

  const handleGetLandingPage = () => {
    Swal.close();
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLandingPage('consigned').then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setLandingPage(result);
      setHeader(result.header);
      setInfoPage1(result.infoPage[0]);
      setInfoPage2(result.infoPage[1]);
      setInfoPage3(result.infoPage[2]);
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...landingPage,
      header: header,
      infoPage: [].concat(infoPage1, infoPage2, infoPage3),
    };
    updateLandingPage('consigned', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <section className="mt--8 container-fluid">
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>Landing page Consignado - Analytics</Col>
              <Col className="text-right">
                <a
                  href={landingPage.url}
                  target="_blank"
                  className="btn btn-primary"
                  size="sm"
                >
                  <i className="fas fa-external-link-alt" />
                  {` `}
                  Ver Landing Page
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              <h3 className="m-0 text-white">
                Atenção: A izidev não realiza configuração no píxel e
                tagmanager. Não nos responsábilizamos pelo trabalho realizado
                pela equipe de marketing ou terceiros.
              </h3>
            </Alert>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID Pixel</Label>
                      <Input
                        value={landingPage.pixel}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            pixel: ev.target.value.replace(/[^0-9]/g, ''),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID TAG MANAGER</Label>
                      <Input
                        value={landingPage.tagManager}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            tagManager: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodeComponent url={landingPage.url} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>Informações da página</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Telefone Suporte com DDD</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.contactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        contactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Texto Padrão WhatsApp</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.textContactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        textContactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>Landing Page - Personalizar textos</Col>
            </Row>
          </CardHeader>
          <CardBody>
            <BodyTitle>TEXTO PRINCIPAL</BodyTitle>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Titulo</Label>
                  <Input
                    value={header.title}
                    onChange={(ev) => {
                      setHeader({
                        ...header,
                        title: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Texto</Label>
                  <Input
                    value={header.text}
                    onChange={(ev) => {
                      setHeader({
                        ...header,
                        text: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <BodyTitle>INFORMAÇÕES DA EMPRESA</BodyTitle>
            <Alert color="secondary">
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Titulo 1</Label>
                    <Input
                      value={infoPage1.title}
                      onChange={(ev) => {
                        setInfoPage1({
                          ...infoPage1,
                          title: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Texto 1</Label>
                    <Input
                      value={infoPage1.text}
                      onChange={(ev) => {
                        setInfoPage1({
                          ...infoPage1,
                          text: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Titulo 2</Label>
                    <Input
                      value={infoPage2.title}
                      onChange={(ev) => {
                        setInfoPage2({
                          ...infoPage2,
                          title: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Texto 2</Label>
                    <Input
                      value={infoPage2.text}
                      onChange={(ev) => {
                        setInfoPage2({
                          ...infoPage2,
                          text: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Titulo 3</Label>
                    <Input
                      value={infoPage3.title}
                      onChange={(ev) => {
                        setInfoPage3({
                          ...infoPage3,
                          title: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Texto 3</Label>
                    <Input
                      value={infoPage3.text}
                      onChange={(ev) => {
                        setInfoPage3({
                          ...infoPage3,
                          text: ev.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Alert>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>Cores</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Cor Primária</Label>
                  <Input
                    label="Cor Botão"
                    placeholder="Cor Botão"
                    name="colorPrimary"
                    type="color"
                    value={landingPage.bgPrimary}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        bgPrimary: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Cor Secundária</Label>
                  <Input
                    label="Cor Botão"
                    placeholder="Cor Botão"
                    name="colorPrimary"
                    type="color"
                    value={landingPage.bgSecondary}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        bgSecondary: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Botão</Label>
                  <Input
                    label="Cor Botão"
                    placeholder="Cor Botão"
                    name="colorPrimary"
                    type="color"
                    value={landingPage.colorPrimary}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        colorPrimary: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Titulo Informações Empresa</Label>
                  <Input
                    label="Cor Botão"
                    placeholder="Cor Botão"
                    name="colorPrimary"
                    type="color"
                    value={landingPage.colorSecondary}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        colorSecondary: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mb-4">
          <CardHeader>Imagem</CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone3.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone3.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone4.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peoplesmartphone4.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman1.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldwoman1.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldwoman2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldwoman2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldman1.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldman1.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
              <Col md="3">
                <Label>
                  <div className="border rounded bg-white">
                    <img
                      className="w-100"
                      width={300}
                      alt="peoplesmartphone.png"
                      src="https://izidev.temfgts.com.br/v1/image/peopleoldman2.png"
                    />
                    <Alert color="secondary" className="m-0 text-center">
                      <Input
                        type="radio"
                        name="img-girl-lp"
                        value="peopleoldman2.png"
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            image: ev.target.value,
                          });
                        }}
                      />
                      Selecionar
                    </Alert>
                  </div>
                </Label>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button color="warning" size="lg" onClick={handleSubmit}>
              SALVAR ALTERAÇÃO
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  );
};

export default LandingPageConsigned;
