const getBase64 = (file) => {
  return new Promise((resolve) => {
    if (!file) {
      return;
    }

    let baseURL = '';
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve({
        base64: baseURL,
        file: file
      });
    };
  });
};

export default getBase64;
