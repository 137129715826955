const { Col, Button } = require('reactstrap');

const ShortcutButtonComponent = ({
  icon = 'fas fa-list',
  md = '12',
  xl = '12',
  title = '',
  color = 'outline-secondary'
}) => {
  return (
    <>
      <Col md={md} xl={xl} className="pt-3 pb-3 d-flex align-items-stretch">
        <Button
          className="bg-white w-100 shadow p-0 pt-4 pb-4"
          size="lg"
          color={color}
        >
          <i className={`${icon}`} /> {title}
        </Button>
      </Col>
    </>
  );
};

export default ShortcutButtonComponent;
