import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ListModalTagComponent from './ListModalTagComponent';
import FormModalTagComponent from './FormModalTagComponent';
import { useState } from 'react';
import { TagStageEnum } from './EnumStage';
import { TagIndexService } from 'services/Register/TagService';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const ModalTagComponent = ({ tagDataModal, setTagDataModal, serviceId }) => {
  const [stage, setStage] = useState('list');

  const [tag, setTag] = useState({
    id: null,
    title: null,
    color: null,
  });
  const [tags, setTags] = useState([]);

  const handleList = async () => {
    setTags([]);
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await TagIndexService({
      clientId: tagDataModal.clientId,
      serviceId,
    })
      .then(({ data }) => {
        Swal.close();
        setTags(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(async () => {
    if (!tagDataModal.isOpen) return;
    await handleList();
  }, [tagDataModal.isOpen]);

  return (
    <Modal isOpen={tagDataModal.isOpen} centered>
      <ModalHeader
        toggle={() => setTagDataModal({ ...tagDataModal, isOpen: false })}
      >
        <h2>
          <i className="fas fa-tags" /> Etiquetas
        </h2>
      </ModalHeader>
      <ModalBody>
        <ListModalTagComponent
          isList={stage === TagStageEnum.LIST}
          setStage={setStage}
          tags={tags}
          setTag={setTag}
          setTags={setTags}
          clientId={tagDataModal.clientId}
          serviceId={serviceId}
          handleList={handleList}
        />
        <FormModalTagComponent
          isForm={stage === TagStageEnum.FORM}
          setStage={setStage}
          handleList={handleList}
          tag={tag}
          setTag={setTag}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalTagComponent;
