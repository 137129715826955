import api from 'services/api';

export const IndexSmsReportService = (filter) => {
  return api.get(`v2/sms/report`, {
    params: filter
  });
};

export const showSmsCreditsByPromoter = () => {
  return api.get(`v2/sms/credits`);
};

export const sendSmsService = (data) => {
  return api.post(`v2/sms/send-message`, data);
};

export const createTemplateService = (data) => {
  return api.post(`v2/sms/template`, data);
};

export const listTemplateService = () => {
  return api.get(`v2/sms/template`);
};

export const inactiveTemplateService = (id) => {
  return api.delete(`v2/sms/template/${id}`);
};

export const createCampaignSmsService = (data) => {
  return api.post(`v2/sms/campaign`, data);
};

export const listCampaignSmsService = () => {
  return api.get(`v2/sms/campaign`);
};
