import { useEffect, useState } from 'react';

import { getInvoice } from 'services/Finance/Payment/PaymentService';

import { Card, CardHeader } from './styles';
import { indexScheduleUserTodayService } from 'services/Register/ScheduleUserService';
import { CardBody, Collapse } from 'reactstrap';

const Header = ({
  showCards = true,
  titlePage = 'IZIDEV - SOMOS SUA TECNOLOGIA',
}) => {
  const [resumePayment, setResumePayment] = useState({});
  const [collapse, setCollapse] = useState(false);
  const isIndication = localStorage.getItem('IZIDEV_AUTH_ISINDICATION');

  const toggle = () => {
    setCollapse(!collapse);
  };

  const [scheduleUser, setScheduleUser] = useState([]);

  const handleListScheduleUser = () => {
    indexScheduleUserTodayService().then(({ data }) => {
      setScheduleUser(data.resultSet);
    });
  };

  useEffect(() => {
    document.title = titlePage;

    if (showCards) {
      const getResultInvoice = async () => {
        const {
          data: { resultSet },
        } = await getInvoice();
        setResumePayment(resultSet);
      };
      getResultInvoice();
    }

    handleListScheduleUser();
  }, []);

  return (
    <section className="header bg-dark-blue pb-8 pt-5 pt-sm-8">
      {scheduleUser.length > 0 && (
        <>
          <div className="mt--4 m-5">
            <button className="btn btn-secondary btn-lg w-100" onClick={toggle}>
              <i className="fas fa-calendar-day" /> MINHA AGENDA - HOJE
            </button>
            <Collapse className="w-100" isOpen={collapse}>
              <Card className="p-0 shadow mt-2 w-100">
                <CardBody
                  className="w-100 rounded bg-secondary"
                  style={{ maxHeight: '600px', overflow: 'auto' }}
                >
                  {scheduleUser.map((res, key) => (
                    <Card className="shadow w-100" key={key}>
                      <CardHeader>
                        <h2 className="m-0" style={{ color: res.color }}>
                          <i className="fas fa-stream" /> {res.title}
                        </h2>
                      </CardHeader>
                      <CardBody className="w-100 p-0">
                        {res.description}
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </>
      )}

      <div className="">
        {resumePayment.qtd == 0 && isIndication === 'false' ? (
          <div className="alert alert-warning mt--3 m-5">
            <h1 className="m-0 text-white">
              <i className="fas fa-exclamation-circle" /> Sua licença izidev
              vence hoje!{' '}
            </h1>
            <p className="m-0">
              Esse é apenas um lembrete de que sua licença expirará hoje.{' '}
              <strong>Se você já efetuou o pagamento</strong>, por favor,
              desconsidere essa mensagem.
            </p>
            <p className="m-0">
              Se o pagamento ainda não foi efetuado, para evitar o cancelamento,
              você pode gerar a fatura no menu: <strong>Pagamento</strong>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="">
        {resumePayment.qtd < 0 && isIndication === 'false' ? (
          <div className="alert alert-danger mt--3 m-5">
            <h1 className="m-0 text-white">
              <i className="fas fa-exclamation-circle" /> Sua licença izidev
              venceu
            </h1>
            <p className="m-0">
              Para reativar seu cadastro, basta realizar o pagamento da fatura.
              Se o pagamento for feito via PIX, a liberação do cadastro ocorrerá
              em até 15 minutos.
            </p>
            <p className="m-0">
              Se o pagamento ainda não foi efetuado, você pode gerar a fatura no
              menu: <strong>Pagamento</strong>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Header;
