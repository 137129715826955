import styled from 'styled-components';

export const StyleWrapper = styled.div``;

export const StyleTitle = styled.h3`
  margin-bottom: 4px;
`;

export const TagInfo = styled.p`
  font-size: 13px;
  margin-bottom: 4px;
`;

export const InfoCard = styled.div`
  margin-top: 28px;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);

  p {
    font-size: 13px;

    strong {
      font-weight: 600;
    }
  }
`;
