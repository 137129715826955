import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';
import { indexLandingPage } from 'services/User/LandingPage';
import { updateLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import FormPageComponentView from './Component/FormPageComponentView';

const AutoContractionView = () => {
  const [list, setList] = useState([]);
  const [register, setRegister] = useState({
    id: null,
    url: 'https://simulador.izidev.com.br',
    febrabanId: '074',
    infoPage: {
      condition: {
        installmentDefault: 84,
        percValueBase: 35,
        productId: null,
      },
    },
  });

  const buttonDefault = {
    name: 'Nome do botão',
    url: 'nameUrl',
    target: '_blank',
  };

  const [buttonsAfterFinishProposal, setButtonsAfterFinishProposal] = useState([
    buttonDefault,
  ]);

  const handleGetLandingPage = () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    indexLandingPage('simulator-consigned')
      .then((res) => {
        Swal.close();
        const result = res.data.resultSet;

        setList(result);

        if (result?.length === 1) {
          setRegister(result[0]);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...register,
      infoPage: {
        ...register?.infoPage,
        buttons: buttonsAfterFinishProposal,
      },
    };

    updateLandingPage('simulator-consigned', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        });
        handleGetLandingPage();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  return (
    <>
      <Header titlePage="Auto Contratação Consignado" />
      <Container className="mt--9" fluid>
        {register.id > 0 ? (
          <FormPageComponentView
            register={register}
            setRegister={setRegister}
            handleSubmit={handleSubmit}
          />
        ) : (
          <>
            <Card className="shadow">
              <CardHeader>
                <h2>
                  <i className="fas fa-cogs" /> Configurar - Auto Contratação
                  Consignado
                </h2>
              </CardHeader>
              <CardBody>
                <table className="table table-bordered">
                  <thead className="bg-secondary">
                    <tr>
                      <th>ID</th>
                      <th>CHAVE</th>
                      <th>NOME</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((lp, key) => (
                      <tr key={key}>
                        <td>{lp.id}</td>
                        <td>{lp.uuid}</td>
                        <td>{lp.name}</td>
                        <td>
                          <Button
                            className="btn btn-success btn-sm"
                            onClick={() => {
                              setRegister(list[key]);
                            }}
                          >
                            DETALHES
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default AutoContractionView;
