import axios from 'axios';
import { getConfigCrm } from 'utils/GetConfigCrm';

const api = axios.create({
  // baseURL: `http://localhost:8000/api/`,
  baseURL: `https://api.izidev.com.br/api/`,
  // baseURL: `https://coreapi.izidev.com.br/api/`
});

api.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('IZIDEV_AUTH_TOKEN');
    if (
      !token &&
      config.url !== 'v1/token' &&
      config.url !== 'v1/reset-password' &&
      config.url !== 'v1/promoter'
    ) {
      const configCrm = getConfigCrm();

      window.location.href = configCrm?.pathLogin ?? '/auth';
    }
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = async (error) => {
  const originalConfig = error.config;

  if (error.response?.status === 401) {
    try {
      const configCrm = getConfigCrm();
      window.location.href = configCrm?.pathLogin ?? '/auth';

      return api(originalConfig);
    } catch (_error) {
      if (_error.response && _error.response.data) {
        return Promise.reject(_error.response.data);
      }

      return Promise.reject(_error);
    }
  }

  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export default api;
