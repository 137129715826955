import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { phoneMask, maskDate, maskCPF } from 'utils/mask';
import { ICredTable } from 'utils/EnumTablesBank';

const Stage1View = ({
  digitation,
  setDigitation,
  optionsSelectFebraban,
  handleSimulation,
  stage,
}) => {
  const configTableFebrabanDefault = {
    tableAmmount: null,
    useSafe: 0,
    installment: null,
  };
  const [icredConfig, setIcredConfig] = useState(configTableFebrabanDefault);
  const [safraConfig, setSafraConfig] = useState(configTableFebrabanDefault);
  const [panConfig, setPanConfig] = useState(configTableFebrabanDefault);

  const animatedComponents = makeAnimated();
  const isIndication = localStorage.getItem('IZIDEV_AUTH_ISINDICATION');
  const [collapse, setCollapse] = useState(false);
  const [navItem, setNavItem] = useState(1);

  const toggle = () => {
    setCollapse(!collapse);
  };

  if (stage !== 1) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <i className="fas fa-user" /> Dados do cliente
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label>
                  CPF <span className="text-danger">*</span>
                </Label>
                <Input
                  value={digitation.cpf}
                  placeholder="xxx.xxx.xxx-xx"
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      cpf: maskCPF(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Nome do cliente <span className="text-danger">*</span>
                </Label>
                <Input
                  value={digitation.name}
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      name: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  Data de Nascimento <span className="text-danger">*</span>
                </Label>
                <Input
                  value={digitation.birthDate}
                  placeholder="--/--/----"
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      birthDate: maskDate(ev.target.value),
                    });
                  }}
                  maxLength="10"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>
                  WhatsApp <span className="text-danger">*</span>
                </Label>
                <Input
                  value={digitation.phone}
                  placeholder="(xx) x xxxx-xxxx"
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      phone: phoneMask(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <Label>Financeiras para a simulação</Label>
              <Select
                components={animatedComponents}
                options={optionsSelectFebraban}
                defaultValue={optionsSelectFebraban}
                placeholder="Selecione..."
                isMulti
                onChange={(select) => {
                  setDigitation({
                    ...digitation,
                    febraban: select,
                  });
                }}
              />
            </Col>
          </Row>
          {isIndication === 'false' ? (
            <>
              <div className="mt-4">
                <button
                  className="btn btn-secondary btn-lg w-100"
                  onClick={toggle}
                >
                  CONFIGURAÇÃO AVANÇADA
                </button>
                <Collapse isOpen={collapse}>
                  <Card className="shadow">
                    <CardBody>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={navItem === 1 ? 'active' : null}
                            onClick={() => {
                              setNavItem(1);
                            }}
                          >
                            iCred
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={navItem === 2 ? 'active' : null}
                            onClick={() => {
                              setNavItem(2);
                            }}
                          >
                            SAFRA
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={navItem === 3 ? 'active' : null}
                            onClick={() => {
                              setNavItem(3);
                            }}
                          >
                            PAN
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={navItem} className="p-4 border">
                        <TabPane tabId={1}>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label>Tabela</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setIcredConfig({
                                      ...icredConfig,
                                      tableAmmount: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  {ICredTable.map((icredTab, keyIcred) => (
                                    <option
                                      value={icredTab.code}
                                      key={keyIcred}
                                    >
                                      {icredTab.name}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label>Prazo</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setIcredConfig({
                                      ...icredConfig,
                                      installment: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  <option value="1">1 Parcela</option>
                                  <option value="2">2 Parcelas</option>
                                  <option value="3">3 Parcelas</option>
                                  <option value="4">4 Parcelas</option>
                                  <option value="5">5 Parcelas</option>
                                  <option value="6">6 Parcelas</option>
                                  <option value="7">7 Parcelas</option>
                                  <option value="8">8 Parcelas</option>
                                  <option value="9">9 Parcelas</option>
                                  <option value="10">10 Parcelas</option>
                                  <option value="11">11 Parcelas</option>
                                  <option value="12">12 Parcelas</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label>Seguro</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setIcredConfig({
                                      ...icredConfig,
                                      useSafe: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="0">NÃO</option>
                                  <option value="1">SIM</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Row>
                            <Col md="8">
                              <FormGroup>
                                <Label>Tabela</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setSafraConfig({
                                      ...safraConfig,
                                      tableAmmount: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label>Seguro</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setSafraConfig({
                                      ...safraConfig,
                                      useSafe: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="0">NÃO</option>
                                  <option value="1">SIM</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label>Tabela</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setPanConfig({
                                      ...panConfig,
                                      tableAmmount: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  <option value="900001">FGTS_CORBAN</option>
                                  <option value="900006">
                                    FGTS_CORBAN_FLEX1
                                  </option>
                                  <option value="900007">
                                    FGTS_CORBAN_FLEX2
                                  </option>
                                  <option value="900008">
                                    FGTS_CORBAN_FLEX3
                                  </option>
                                  <option value="900009">
                                    FGTS_CORBAN_FLEX4
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label>Prazo</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setPanConfig({
                                      ...panConfig,
                                      installment: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  <option value="1">1 Parcela</option>
                                  <option value="2">2 Parcelas</option>
                                  <option value="3">3 Parcelas</option>
                                  <option value="4">4 Parcelas</option>
                                  <option value="5">5 Parcelas</option>
                                  <option value="6">6 Parcelas</option>
                                  <option value="7">7 Parcelas</option>
                                  <option value="8">8 Parcelas</option>
                                  <option value="9">9 Parcelas</option>
                                  <option value="10">10 Parcelas</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label>Seguro</Label>
                                <select
                                  className="form-control form-control-sm"
                                  onChange={(ev) => {
                                    setPanConfig({
                                      ...panConfig,
                                      useSafe: ev.target.value,
                                    });
                                  }}
                                >
                                  <option value="0">NÃO</option>
                                  <option value="1">SIM</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </>
          ) : (
            <></>
          )}
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={() => {
              handleSimulation([icredConfig, safraConfig, panConfig]);
            }}
          >
            <i className="fas fa-arrow-right" /> Continuar - Realizar simulação
            de crédito
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Stage1View;
