import InputBankView from 'components/Form/Input/InputBankView';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import SampleModalView from 'components/Modals/SampleModalView';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { getIndicationGroupByIdService } from 'services/Promoter/IndicationService';
import { saveIndicationGroupService } from 'services/Promoter/IndicationService';
import Swal from 'sweetalert2';

const SaveIndicationGroupComponent = ({
  id = null,
  isOpen,
  setIsOpen = () => {},
  handleListGroup = () => {}
}) => {
  const [form, setForm] = useState({
    id: null,
    name: null,
    indication: [],
    bank: []
  });

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const newForm = {
      id: form?.id,
      name: form.name,
      indication: form.indication.map((e) => {
        return { id: e.value, name: e.label };
      }),
      bank: form.bank.map((e) => {
        return { id: e.value, name: e.label };
      })
    };

    const result = await saveIndicationGroupService(newForm);
    if (!result) return;

    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Registro salvo com sucesso'
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      setIsOpen(false);
      setForm({});
      handleListGroup();
    });
  };

  const handleGetRegister = async (id) => {
    if (id <= 0) return;

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const result = await getIndicationGroupByIdService(id);

    if (!result) return;
    const newResult = {
      ...result.resultSet,
      indication: result.resultSet.indication.map((e) => {
        return { label: e.name, value: e.id };
      }),
      bank: result.resultSet.bank.map((e) => {
        return { label: e.name, value: e.id };
      })
    };
    setForm(newResult);
    Swal.close();
  };

  useEffect(() => {
    if (!isOpen) return;

    handleGetRegister(id);
  }, [isOpen]);

  if (!isOpen) return <></>;
  if (id > 0 && !form?.id) return <></>;

  return (
    <>
      <SampleModalView
        labelModal="Registro - Grupos de Indicação"
        icon="far fa-window-restore"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={handleSubmit}
        showBtnSubmit={true}
        size="xl"
      >
        <Card className="shadow">
          <CardBody>
            <FormGroup>
              <Label>Nome: </Label>
              <Input
                type="text"
                value={form.name}
                onChange={(ev) => {
                  setForm({
                    ...form,
                    name: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <Row>
          <Col md="12">
            <Card className="shadow mt-3">
              <CardHeader>Indicação</CardHeader>
              <CardBody>
                <Alert color="secondary">
                  Informe os links de indicação que você deseja vincular no
                  grupo
                </Alert>
                <InputIndicationView
                  defaultValue={form.indication}
                  setFilter={(e) => {
                    setForm({
                      ...form,
                      indication: e
                    });
                  }}
                  isMultSelect={true}
                  isClearable={true}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card className="shadow mt-3">
              <CardHeader>Bancos / Fintechs</CardHeader>
              <CardBody>
                <Alert color="secondary">
                  Ao selecionar um ou mais bancos, o simulador multibancos, irá
                  utilizar estas financeiras para realizar a simulação.
                </Alert>
                <InputBankView
                  defaultValue={form.bank}
                  setFilter={(e) => {
                    setForm({
                      ...form,
                      bank: e
                    });
                  }}
                  isMultSelect={true}
                  isClearable={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </SampleModalView>
    </>
  );
};

export default SaveIndicationGroupComponent;
