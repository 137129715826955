import { Pie } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { moneyMask } from 'utils/mask';

const ChartPie = (
  props = {
    title: '',
    subtitle: '',
    data: [],
    background: 'bg-white'
  }
) => {
  if (props.data.length <= 0 || props.data.labels.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Card className={props.background}>
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h6 className="text-uppercase ls-1 mb-1">{props.subtitle}</h6>
              <h2 className="mb-0">{props.title}</h2>
            </div>
            <div className="col" />
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Pie
              data={props.data}
              options={{
                options: {
                  showAllTooltips: true
                },
                legend: {
                  display: true,
                  position: 'left'
                },
                tooltips: {
                  callbacks: {
                    label: function (item, data) {
                      let label = data.labels[item.index] || '';
                      let value =
                        data.datasets[item.datasetIndex].data[item.index] || '';
                      return label + ' | R$ ' + moneyMask(value);
                    }
                  }
                }
              }}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ChartPie;
