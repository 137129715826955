import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { MaxSimulationService } from 'services/Consigned/IcredConsignedService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import { maskDate } from 'utils/mask';
import { maskCPF } from 'utils/mask';

const IcredFormSimulationConsignedComponent = ({
  stage = null,
  setStage = () => {},
  resultSimulation = () => {},
}) => {
  const [simulation, setSimulation] = useState({
    name: null,
    cpf: null,
    birthDate: null,
    whatsappNumber: null,
    haveMargin: 1,
  });

  if (stage !== 1) {
    return <></>;
  }

  const handleSubmit = () => {
    try {
      if (!simulation?.cpf) throw new Error('CPF não informado');
      if (!simulation?.name) throw new Error('Nome não informado');
      if (!simulation?.whatsappNumber)
        throw new Error('WhatsApp não informado');
      if (!simulation?.birthDate)
        throw new Error('Data de nascimento não informada');

      Swal.fire({
        title: 'Realizando simulação',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      MaxSimulationService(simulation)
        .then(({ data }) => {
          setStage(2);
          resultSimulation({
            ...simulation,
            benefits: data?.resultSet ?? [],
          });
          Swal.close();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      });
    }
  };

  return (
    <>
      <Alert color="secondary" className="shadow">
        <i className="fas fa-gift fa-3x" />
        <p className="mt-2">
          Você foi escolhido(a) pela izidev, para testar, em primeira mão, nossa
          funcionalidade beta na icred, para digitação de operações de INSS,
          diretamente, em nossa plataforma!
        </p>
        <p className="mb-0">
          Como toda inovação beta, é possível que aconteçam alguns erros. Por
          isso, pedimos que teste e compartilhe suas sugestões e melhorias,
          diretamente, com nosso gerente comercial ou suporte. Sua colaboração é
          crucial para aprimorarmos, ainda mais, essa inovação.
        </p>
      </Alert>
      <Card className="shadow">
        <CardHeader>
          <h2>iCred - Simular INSS</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>CPF</Label>
                <Input
                  placeholder="000.000.000-00"
                  value={simulation.cpf}
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      cpf: maskCPF(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Nome</Label>
                <Input
                  value={simulation.name}
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      name: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Data de Nascimento</Label>
                <Input
                  placeholder="00/00/0000"
                  value={simulation.birthDate}
                  maxLength={10}
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      birthDate: maskDate(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Nº WhatsApp</Label>
                <Input
                  placeholder="(00) 0 0000-0000"
                  value={simulation.whatsappNumber}
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      whatsappNumber: phoneMask(ev.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Aumento de Margem</Label>
                <select
                  className="form-control"
                  value={simulation.haveMargin}
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      haveMargin: ev.target.value,
                    });
                  }}
                >
                  <option value={1}>SIM</option>
                  <option value={0}>NÃO</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Tabela</Label>
                <select
                  type="text"
                  autoComplete="false"
                  value={simulation.tableAmmount}
                  className="form-control"
                  onChange={(ev) => {
                    setSimulation({
                      ...simulation,
                      tableAmmount: ev.target.value,
                    });
                  }}
                >
                  <option value="" selected disabled>
                    Selecione...
                  </option>
                  <option value="-5">
                    INSS | BPC Loas | Normal | Novo ou Aumento
                  </option>
                  <option value="-8">INSS | Flex 1 | Novo ou Aumento</option>
                  <option value="-9">INSS | Flex 2 | Novo ou Aumento</option>
                  <option value="-10">INSS | Flex 3 | Novo ou Aumento</option>
                  <option value="-11">INSS | Flex 4 | Novo ou Aumento</option>
                  <option value="-12">INSS | Flex 5 | Novo ou Aumento</option>
                  <option value="-13">INSS | Flex 6 | Novo ou Aumento</option>
                  <option value="-4">INSS | Normal | Novo ou Aumento</option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            size="lg"
            className="w-100 font-16"
            onClick={handleSubmit}
          >
            <i className="fas fa-stream" /> Realizar simulação
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default IcredFormSimulationConsignedComponent;
