import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { indexTableSabemiFgts } from 'services/Digitation/IntegrationService';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import MyTablesComponent from '../myTablesComponent';

const AccessBankSabemiForm = ({ febrabanId, data, setData }) => {
  const [tableSafra, setTableSafra] = useState([]);

  const handleGetTableTaxSafra = async () => {
    await indexTableSabemiFgts()
      .then((res) => {
        setTableSafra(res.data.resultSet);
      })
      .catch(() => {
        setTableSafra([]);
      });
  };

  useEffect(() => {
    if (!febrabanId || febrabanId !== '329-2') return;
    handleGetTableTaxSafra();
  }, []);

  if (!febrabanId || febrabanId !== '329-2') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="info">
        <strong className="font-weight-bold">ATENÇÃO!</strong> A API fornecida
        pelo Sabemi não possui as funcionalidades: acompanhamento de status de
        propostas e link de formalização. Em caso de dúvidas, favor entrar em
        contato com seu comercial Sabemi.
      </Alert>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas FGTS
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Seleção de Tabela
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login de API (client_id) <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha da API (access_token){' '}
                <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do Representante</h3>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>
                CPF do Vendedor <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value)
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                Código do Vendedor <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.idBankUser}
                placeholder=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    idBankUser: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                Código Promotora <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.aditional}
                placeholder=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    aditional: ev.target.value
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar - FGTS</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>Tabela FGTS</Label>
              {tableSafra.length > 0 ? (
                <select
                  className="form-control"
                  value={data.tableAmmount}
                  onChange={(ev) => {
                    setData({
                      ...data,
                      tableAmmount: ev.target.value
                    });
                  }}
                >
                  <option value="">Selecione...</option>
                  {tableSafra.length > 0 ? (
                    tableSafra.map((register, key) => (
                      <option value={register.Codigo} key={key}>
                        {register.Plano} - Taxa: {register.Taxa} - Prazo:{' '}
                        {register.Prazo}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              ) : (
                <p className="text-danger">
                  Não foram identificadas tabelas...
                </p>
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Mínimo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="minValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.minValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    minValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada a
                partir do valor informado. Caso o valor liberado do FGTS esteja
                abaixo do valor informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Simulador MultiBancos</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useMultBankFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useMultBankFgts: ev.target.value
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <MyTablesComponent febrabanId={febrabanId}  />
    </CardBody>
  );
};

export default AccessBankSabemiForm;
