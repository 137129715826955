import api from 'services/api';

export const bulkInsert = async (cpfs) => {
  return await api.post(`v2/consigned/client-search`, cpfs);
};

export const listRegister = async (data) => {
  return await api.get(`v2/consigned/client-search`, {
    params: data
  });
};

export const showRegister = async (id) => {
  return await api.get(`v2/consigned/client-search/${id}`);
};

export const listLeadCsv = async (data) => {
  return await api.get('v2/consigned/client-search/csv', {
    params: data
  });
};
