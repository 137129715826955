import api from 'services/api';

export const ReportSimulationAbandonedService = async (
  filter = {
    dateStart: null,
    dateEnd: null,
    indicationId: null,
    previusLimit: 0
  }
) => {
  return await api.get('v2/report/simulation-client/abandoned', {
    params: filter
  });
};
