export const RemoveCharEspecial = (value) => {
  value = value.replace(/[áàãâä]/ui, 'a');
  value = value.replace(/[éèêë]/ui, 'e');
  value = value.replace(/[íìîï]/ui, 'i');
  value = value.replace(/[óòõôö]/ui, 'o');
  value = value.replace(/[úùûü]/ui, 'u');
  value = value.replace(/[.]/ui, '');
  value = value.replace(/[ç]/ui, 'c');
  value = value.replace(/[^a-z0-9]/i, '-');
  return value;
}