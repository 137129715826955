import Header from 'components/Headers/Header';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row
} from 'reactstrap';
import ShortcutButtonComponent from './component/ShortcutButtonComponent';

const DashboardView = () => {
  return (
    <>
      <Header titlePage="Home" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <Button className="float-right" color="outline-primary">
              <i className="fas fa-cogs" /> Configurar
            </Button>
            <h2>
              <i className="fas fa-share" /> Atalhos
            </h2>
          </CardHeader>
          <CardBody className="bg-secondary pt-2 pb-2">
            <Row>
              <ShortcutButtonComponent md="4" xl="3" title="FGTS" />
              <ShortcutButtonComponent md="4" xl="3" title="CONSIGNADO" />
              <ShortcutButtonComponent md="4" xl="3" title="CREDITO PESSOAL" />
              <ShortcutButtonComponent md="4" xl="3" title="RESUMO DE VENDAS" />
              {/* <ShortcutButtonComponent
                md="4"
                xl="4"
                color="outline-primary"
                title=""
                icon="fas fa-plus"
              /> */}
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col md="6">
            <Card className="shadow mt-4">
              <CardHeader className="border-0">
                <h2>
                  <i className="far fa-bell" /> Últimas notificações do sistema
                </h2>
              </CardHeader>
              <CardBody>
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h2 className="m-0">
                      <i className="fas fa-stream" /> Teste -{' '}
                      <Badge color="secondary">30/11/2023</Badge>
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <p>Mensagem da Agênda</p>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="shadow mt-4">
              <CardHeader className="border-0">
                <h2>
                  <i className="fas fa-calendar-alt" /> Agênda
                </h2>
              </CardHeader>
              <CardBody>
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h2 className="m-0">
                      <i className="fas fa-stream" /> Teste -{' '}
                      <Badge color="secondary">30/11/2023</Badge>
                    </h2>
                  </CardHeader>
                  <CardBody>
                    <p>Mensagem da Agênda</p>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardView;
