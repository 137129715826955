import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { phoneMask, maskCPF } from "utils/mask";

const CardClientComponent = ({ proposal }) => {
    
    return (
        <Card className="shadow mt-3">
            <CardHeader className="border-0">
                <h2 className="m-0">
                    <i className="fas fa-user-check" /> Cliente
                </h2>
            </CardHeader>
            <CardBody>
                <div>
                    <Row>
                        <Col md="3" className="p-3">
                            <p className="m-0">CPF</p>
                            <h2 className="m-0">{maskCPF(proposal.cpf)}</h2>
                        </Col>
                        <Col md="6" className="p-3">
                            <p className="m-0">Nome</p>
                            <h2 className="m-0">{proposal.name}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Data Nascimento</p>
                            <h2 className="m-0">{proposal.birthDate}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" className="p-3">
                            <p className="m-0">WhatsApp</p>
                            <h2 className="m-0">{phoneMask(proposal.whatsappNumber)}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">E-mail</p>
                            <h2 className="m-0">{proposal.email}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Matricula</p>
                            <h2 className="m-0">{proposal.registry}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">RG</p>
                            <h2 className="m-0">{proposal.documentRG}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Data Emissão</p>
                            <h2 className="m-0">{proposal.dateEmissionRG}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Orgão Emissor</p>
                            <h2 className="m-0">{proposal.emissionRG || proposal.organRg}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Estado Emissor</p>
                            <h2 className="m-0">{proposal.ufRG || proposal.stateRG}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Sexo</p>
                            <h2 className="m-0">{proposal.sex}</h2>
                        </Col>
                        <Col md="3" className="p-3">
                            <p className="m-0">Estado Civil</p>
                            <h2 className="m-0">{proposal.stateCivil}</h2>
                        </Col>
                        <Col md="6" className="p-3">
                            <p className="m-0">Nome da mãe</p>
                            <h2 className="m-0">{proposal.nameMother}</h2>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
};


export default CardClientComponent;