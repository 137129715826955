import { Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PrivacyPolicyComponent = ({ form = {}, setForm = () => {} }) => {
  return (
    <>
      <Card className="shadow mt-3">
        <CardHeader>
          <h3>
            <i className="fas fa-user-shield" /> Politica de privacidade
          </h3>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Exibir</Label>
            <select
              className="form-control"
              value={form?.infoPage?.usePolicy ?? 0}
              onChange={(ev) => {
                setForm({
                  ...form,
                  infoPage: {
                    ...form?.infoPage,
                    usePolicy: ev.target.value ?? 0
                  }
                });
              }}
            >
              <option value={1}>SIM</option>
              <option value={0}>NÃO</option>
            </select>
          </FormGroup>
          <Card className="shadow mb-2">
            <CardBody>
              <FormGroup>
                <Label>Informar politica de privacidade</Label>
                <ReactQuill
                  value={form?.infoPage?.policy ?? ''}
                  style={{ height: 350 }}
                  className="mb-5"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' }
                      ],
                      [],
                      ['clean']
                    ]
                  }}
                  formats={[
                    'header',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video'
                  ]}
                  onChange={(ev) => {
                    setForm({
                      ...form,
                      infoPage: {
                        ...form?.infoPage,
                        policy: ev ?? ''
                      }
                    });
                  }}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default PrivacyPolicyComponent;
