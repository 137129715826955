import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

const OptionUserComponent = ({
  register,
  setUserSelected,
  setOpenLinkIndication,
  removeUser,
  handleDeleteUser,
  handleListUpdateUserByUserId,
  setOpenUserDigitationComponent
}) => {
  const history = useHistory();

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color="secondary">
          Opções
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              history.push(`/admin/user/register/${register.id}`);
            }}
          >
            <i className="fas fa-pencil-alt" /> Alterar
          </DropdownItem>
          {register?.indicationId > 0 && (
            <>
              <DropdownItem
                onClick={() => {
                  setUserSelected(register?.id);
                  setOpenLinkIndication(true);
                }}
              >
                <i className="fas fa-link" /> Link Indicação / Vendedor
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setUserSelected(register?.id);
                  setOpenUserDigitationComponent(true);
                }}
              >
                <i className="fas fa-user-tie" /> Usuário digitador Banco
              </DropdownItem>
            </>
          )}

          <DropdownItem
            onClick={() => {
              removeUser(register.id);
            }}
          >
            <i className="fas fa-users-cog" />
            {Number(register.active) ? 'Inativar' : 'Ativar'}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleDeleteUser(register.id);
            }}
          >
            <i className="fas fa-user-times" />
            Excluir
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleListUpdateUserByUserId();
            }}
          >
            <i className="far fa-list-alt" />
            Atualização Cadastro
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default OptionUserComponent;
