import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { listAllTableService } from 'services/Comission/TableComissionService';
const ConditionSimulationFGTSLotusMais = ({
  bankId,
  setTableSelected,
  installmentSelected = 12,
  setInstallmentSelected = () => {},
  useSafe = 0,
  setUseSafe = () => {},
}) => {
  const [table, setTable] = useState([]);

  const handleListTable = async () => {
    const result = await listAllTableService('324');
    if (!result) return;
    setTable(result.resultSet);
  };

  useEffect(() => {
    if (bankId !== 17) return;
    handleListTable();
  }, []);

  if (bankId !== 17) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>Tabela(s)</Label>
            <Select
              isMulti
              className="basic-single"
              classNamePrefix="select"
              isClearable
              placeholder="Selecione..."
              options={table.map((res) => {
                return {
                  value: res.tableId,
                  label: res.tableName,
                };
              })}
              onChange={(res) => {
                const values = res.map((res) => {
                  return res.value;
                });
                setTableSelected(values);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>Parcela(s)</Label>
            <select
              className="form-control"
              value={installmentSelected}
              onChange={(ev) => {
                setInstallmentSelected(ev.target.value);
              }}
            >
              <option value={1}>1 - Parcela</option>
              <option value={2}>2 - Parcelas</option>
              <option value={3}>3 - Parcelas</option>
              <option value={4}>4 - Parcelas</option>
              <option value={5}>5 - Parcelas</option>
              <option value={6}>6 - Parcelas</option>
              <option value={7}>7 - Parcelas</option>
              <option value={8}>8 - Parcelas</option>
              <option value={9}>9 - Parcelas</option>
              <option value={10}>10 - Parcelas</option>
              <option value={11}>11 - Parcelas</option>
              <option value={12}>12 - Parcelas</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>Seguro</Label>
            <select
              className="form-control"
              value={useSafe}
              onChange={(ev) => {
                setUseSafe(ev.target.value);
              }}
            >
              <option value={1}>SIM</option>
              <option value={0}>NÃO</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ConditionSimulationFGTSLotusMais;
