import Header from 'components/Headers/Header';
import { TableRow } from 'components/Table';
import { TableBody } from 'components/Table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { ReportSimulationErrorService } from 'services/Digitation/SimulationService';
import { scheduleExcel } from 'services/Report/Schedule';
import Swal from 'sweetalert2';
import { EnumBanksIntegration } from 'utils/EnumBanks';
import { maskCPF, phoneMask, maskDate } from 'utils/mask';
import { Title } from 'views/Dashboard/styles';

const SimulationErrorReportView = () => {
  const history = useHistory();
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [filter, setFilter] = useState({
    dateStart: '',
    dateEnd: '',
    febrabanId: '',
    messageError: [],
    pageActual: 0,
    limit: 15,
  });
  const [list, setList] = useState([]);

  const handleSetMessage = () => {
    if (!messageError || messageError === '') return;

    setFilter({
      ...filter,
      messageError: [...filter.messageError, messageError],
    });
    setMessageError('');
  };

  const handleSubmit = (isMore = false) => {
    if (!isMore) {
      setFilter({
        ...filter,
        pageActual: 0,
      });
      setList([]);
    }

    try {
      if (filter.dateStart === '') {
        throw new Error('Data inicial não informada');
      }

      if (filter.dateEnd === '') {
        throw new Error('Data final não informada');
      }

      if (filter.febrabanId === '') {
        throw new Error('Banco não informado');
      }

      if (filter.messageError.length <= 0) {
        throw new Error('Mensagem de erro não informado');
      }

      Swal.fire({
        title: 'Carregando.',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      ReportSimulationErrorService(filter)
        .then(({ data }) => {
          Swal.close();
          if (!data?.resultSet?.data) {
            setShowBtnMoreRegisters(false);
            return;
          }

          setList(data?.resultSet?.data);

          setFilter({
            ...filter,
            pageActual: data.resultSet.pagination,
          });

          if (isMore) {
            setList([...list, ...data?.resultSet?.data]);
            setShowBtnMoreRegisters(data?.resultSet?.data?.length > 0);
          } else {
            setList(data?.resultSet?.data ?? []);
            setShowBtnMoreRegisters(data?.resultSet?.data?.length >= 15);
          }
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e.message,
      });
    }
  };

  const handleExportCsv = () => {
    scheduleExcel(
      18,
      {
        ...filter,
        pageActual: 0,
      },
      history
    );
  };

  const handleRemoveMessageError = (index) => {
    const messages = filter.messageError.filter(
      (e) => e !== filter.messageError[index]
    );
    setFilter({
      ...filter,
      messageError: messages,
    });
  };

  return (
    <>
      <Header titlePage="FGTS: Clientes com erro de simulação" />
      <Container className="mt--9" fluid>
        <Card>
          <CardHeader>
            <h1 className="m-0">FGTS: Clientes com erro de simulação</h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <Label>
                  Período - Data cadastro <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <Input
                    placeholder="--/--/----"
                    className="border"
                    value={filter.dateStart}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateStart: maskDate(ev.target.value),
                      });
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>à</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="--/--/----"
                    className="border pl-2"
                    value={filter.dateEnd}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateEnd: maskDate(ev.target.value),
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md="3">
                <Label>
                  Banco <span className="text-danger">*</span>
                </Label>
                <select
                  className="form-control"
                  value={filter.febrabanId}
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      febrabanId: ev.target.value,
                    });
                  }}
                >
                  <option value="">SELECIONE...</option>
                  {EnumBanksIntegration.map((bank, key) => (
                    <option value={bank.code} key={key}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md="5">
                <Label>
                  Mensagem de erro <span className="text-danger">*</span>
                </Label>
                <InputGroup>
                  <Input
                    value={messageError}
                    onChange={(ev) => {
                      setMessageError(ev.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSetMessage();
                      }
                    }}
                  />

                  <InputGroupAddon addonType="append">
                    <Button
                      color="success"
                      onClick={() => {
                        handleSetMessage();
                      }}
                    >
                      <i className="fas fa-plus" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>

            {filter.messageError.length > 0 && (
              <div className="bg-secondary rounded p-4 mt-3">
                <h3 className="m-0">Mensagens de erro para consulta</h3>
                <p className="mt-0">
                  Os termos abaixo, serão utilizados na consulta.
                </p>
                <div>
                  {filter.messageError.map((message, key) => (
                    <Badge color="primary" className="mr-2" key={key}>
                      {message}{' '}
                      <Button
                        color="primary"
                        size="sm"
                        className="border-0 shadow-none"
                        onClick={() => {
                          handleRemoveMessageError(key);
                        }}
                      >
                        <i className="fas fa-times" />
                      </Button>
                    </Badge>
                  ))}
                </div>
              </div>
            )}
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              <i className="fas fa-search" /> Listar
            </Button>
            <Button
              color="success"
              onClick={() => {
                handleExportCsv();
              }}
            >
              <i className="fas fa-file-csv" /> Exportar excel
            </Button>
          </CardFooter>
        </Card>
        <Alert className="mt-3 shadow" color="secondary">
          <p className="m-0">
            - Todos os campos com <span className="text-danger">*</span> são
            obrigatórios
          </p>
        </Alert>
        {list?.length > 0 && (
          <Card>
            <CardHeader>
              <Title>Registros</Title>
            </CardHeader>
            <Table responsive>
              <thead>
                <tr className="bg-secondary">
                  <th>NOME</th>
                  <th>CPF</th>
                  <th>WHATSAPP</th>
                  <th>DATA CADASTRO</th>
                </tr>
              </thead>
              <tbody>
                {list.map((register, key) => (
                  <>
                    <tr key={key}>
                      <td className="border-bottom-0">
                        <p className="m-0">{register.name}</p>
                      </td>
                      <td>
                        <p className="m-0">{maskCPF(register.cpf)}</p>
                      </td>
                      <td>
                        <p className="m-0">
                          {phoneMask(register.whatsappNumber)}{' '}
                          <a
                            className="btn btn-success btn-sm rounded-pill"
                            target="_blank"
                            href={`https://wa.me/${register.whatsappNumber.replace(
                              /[^0-9]/g,
                              ''
                            )}`}
                          >
                            <i className="fab fa-whatsapp" />
                          </a>
                        </p>
                      </td>
                      <td>
                        <p className="m-0">{register.createdAt}</p>
                      </td>
                    </tr>
                    <tr key={`message-${key}`}>
                      <td colSpan={4} className="border-top-0">
                        <p className="text-danger m-0">
                          Erro: <strong>{register.messageReturnService}</strong>
                        </p>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
              {showBtnMoreRegisters && (
                <TableBody>
                  <TableRow>
                    <td colSpan={5}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          setFilter({ ...filter });
                          handleSubmit(true);
                        }}
                      >
                        Carregar mais...
                      </Button>
                    </td>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default SimulationErrorReportView;
