import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'chart.js';
import Swal from 'sweetalert2';
import {
  Card,
  Row,
  Col,
  Alert,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import Header from 'components/Headers/Header.js';
import PaginationSelector from './component/PaginationSelector';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData,
} from 'components/Table';
import { chartOptions, parseOptions } from 'variables/charts.js';

import { DataCadastro } from './tableData';
import {
  CardHeader,
  Title,
  ButtonsWrapper,
  CaretRight,
  ContentWrapper,
  Button,
  MobileTitle,
  WhatsAppLink,
  ClientName,
} from './styles';
import { ListLead } from 'services/Report/Leads';
import { phoneMask } from 'utils/mask';
import { maskDocument } from 'utils/mask';
import { MiniSimulationFgts } from 'services/Digitation/SimulationService';
import SimulationInfo from './../Dashboard/component/simulationInfo';
import { ReSimulationMessageError } from 'services/Digitation/ReDigitationService';
import { ListLeadCsv } from 'services/Report/Leads';
import { maskCPF } from 'utils/mask';

const Leads = () => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const history = useHistory();
  const [tableDetails, setTableDetails] = useState(null);
  const [listData, setListData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalInfoData, setModalInfoData] = useState(false);

  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    cpf: null,
    status: null,
    page: 0,
    error: '',
    numberProposal: null,
    messageReturnService: null,
  });

  const [document, setDocument] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [numberProposal, setNumberProposal] = useState(null);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [updateList, setUpdateList] = useState(false);
  const [reSimulationMessageError, setReSimulationMessageError] = useState([]);
  const [messageReturnService, setMessageReturnService] = useState(null);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const listMessageReSimulation = () => {
    ReSimulationMessageError().then((res) => {
      setReSimulationMessageError(res.data.resultSet);
    });
  };

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const openModalInfo = async (data) => {
    await setModalInfoData(data);
    await setModalInfo(!modalInfo);
  };

  const openModal = async () => {
    await setModal(!modal);
  };

  const handleList = async (pageSelected = 0, messageReturn = null) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (messageReturn) {
      setMessageReturnService(messageReturn);
    }

    if (!messageReturn && messageReturnService) {
      messageReturn = messageReturnService;
    }

    if (typeof pageSelected == 'number') {
      setPage(pageSelected);
    } else {
      pageSelected = 0;
    }

    await ListLead({
      dateStart: filter.dateStart,
      dateEnd: filter.dateEnd,
      cpf: filter.cpf,
      status,
      page: pageSelected,
      error,
      numberProposal,
      messageReturnService: messageReturn,
    })
      .then(async (res) => {
        Swal.close();
        if (res.data.resultSet.length <= 0) {
          return;
        }

        setListData(res.data.resultSet.data);
        if (res.data.resultSet.data.length > 0) {
          setPages(res.data.resultSet.pagination.pages);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  const handleMiniSimulation = async (simulationId, index) => {
    Swal.fire({
      title: 'Realizando Simulação, Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await MiniSimulationFgts(simulationId)
      .then((res) => {
        Swal.close();
        handleList(page);
        if (!res.data.status) {
          Swal.fire({
            icon: 'error',
            title: 'Simulação não finalizada',
            text: res.data.resultSet.miniSimulation.messageReturnService,
          });
          return;
        }
        openModalInfo(res.data.resultSet.simulation);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  const exportExcell = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await ListLeadCsv({
      dateStart: filter.dateStart,
      dateEnd: filter.dateEnd,
      cpf: document,
      status,
      error,
      numberProposal,
      messageReturnService: messageReturnService,
    }).then(async (res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message,
      }).then((result) => {
        if (!result.isConfirmed) return;
        history.push('/admin/report-schedule');
      });
    });
  };

  useEffect(() => {
    if (!updateList) {
      handleList();
      listMessageReSimulation();
    }
  }, [modalInfo, updateList]);

  return (
    <>
      <Header titlePage="Meus Leads" />
      <section className="mt--9 container-fluid">
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader>
                <h3 className="m-0">Meus Leads - Filtrar</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label>Periodo Cadastro</Label>
                      <Row>
                        <Col xl="6">
                          <Input
                            type="date"
                            name="dateStart"
                            className="form-control"
                            onChange={(ev) => {
                              setFilter({
                                ...filter,
                                dateStart: ev.target.value,
                              });
                            }}
                          />
                        </Col>
                        <Col xl="6">
                          <Input
                            type="date"
                            name="dateEnd"
                            className="form-control"
                            onChange={(ev) => {
                              setFilter({
                                ...filter,
                                dateEnd: ev.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <Label>CPF</Label>
                      <Input
                        type="text"
                        value={maskCPF(filter.cpf ?? '')}
                        name="document"
                        className="form-control"
                        onChange={(ev) => {
                          setFilter({
                            ...filter,
                            cpf: maskCPF(ev.target.value),
                          });
                        }}
                        placeholder="000.000.000-00"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button className="btn btn-info btn-lg-md" onClick={handleList}>
                  <i className="fas fa-search" /> BUSCAR
                </Button>
                {` `}
                {userDataConfig?.canExportExcel > 0 && (
                  <Button
                    className="btn btn-success btn-lg-md"
                    onClick={exportExcell}
                  >
                    <i className="far fa-file-excel" /> EXPORTAR EXCEL
                  </Button>
                )}
              </CardFooter>
            </Card>

            <Card className="shadow mt-4">
              <CardHeader>
                <Title>Lista de Clientes / Leads</Title>
                <ButtonsWrapper />
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>ULTIMA SIMULAÇÃO</TableHead>
                        <TableHead>NOME</TableHead>
                        <TableHead className="d-none d-lg-table-cell">
                          CPF
                        </TableHead>
                        <TableHead className="d-none d-lg-table-cell">
                          DATA DE NASCIMENTO
                        </TableHead>
                        <TableHead className="d-none d-xl-table-cell">
                          WHATSAPP
                        </TableHead>
                        <TableHead className="d-none d-xl-table-cell">
                          ORIGEM
                        </TableHead>
                        <TableHead className="d-none d-xl-table-cell" />
                        <TableHead className="d-xl-none" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {listData.map((data, index) => (
                        <>
                          <TableRow key={index}>
                            <TableData>
                              <DataCadastro data={data} />
                              {data.valueFinally ? (
                                <>
                                  <br />
                                  Financeira:{' '}
                                  <strong className="font-weight-bold">
                                    {data.bank}
                                  </strong>
                                  <br />
                                  Vlr. Contrato:{' '}
                                  <strong className="font-weight-bold">
                                    R$ {data.valueFinally}
                                  </strong>
                                  <br />
                                  Vlr. Liberado:{' '}
                                  <strong className="font-weight-bold">
                                    R$ {data.valueLiberty}
                                  </strong>
                                  <br />
                                  Erro:{' '}
                                  <strong className="text-danger font-weight-bold">
                                    {data.messageReturnService}
                                  </strong>
                                </>
                              ) : (
                                <></>
                              )}
                            </TableData>
                            <TableData>
                              <ClientName>
                                {data.name ? data.name : 'Não identificado =('}
                              </ClientName>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell">
                              <strong className="font-weight-bold">
                                {maskDocument(data.cpf)}
                              </strong>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell">
                              <strong className="font-weight-bold">
                                {data.birthdate}
                              </strong>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell">
                              <WhatsAppLink
                                className="btn btn-link"
                                href={`https://wa.me/55${data.urlWhatsapp.replace(
                                  /[^0-9]/g,
                                  ''
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fab fa-whatsapp" />{' '}
                                {phoneMask(data.whatsappNumber)}
                              </WhatsAppLink>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell">
                              <strong className="font-weight-bold">
                                {data.service} - {data.localeSimulation}
                              </strong>
                            </TableData>
                            <TableData className="d-xl-none">
                              <CaretRight
                                active={tableDetails === index}
                                onClick={() => handleTableDetails(index)}
                              />
                            </TableData>
                          </TableRow>
                          {tableDetails === index && (
                            <TableRow className="d-xl-none">
                              <TableData colSpan={10}>
                                <ContentWrapper>
                                  <MobileTitle>CPF</MobileTitle>
                                  <strong className="font-weight-bold">
                                    {maskDocument(data.cpf)}
                                  </strong>
                                </ContentWrapper>
                                <ContentWrapper>
                                  <MobileTitle>Data de Nascimento</MobileTitle>
                                  <strong className="font-weight-bold">
                                    {data.birthdate}
                                  </strong>
                                </ContentWrapper>
                                <ContentWrapper>
                                  <MobileTitle>Whatsapp</MobileTitle>
                                  <WhatsAppLink
                                    className="btn btn-link"
                                    href={data.urlWhatsapp}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="fab fa-whatsapp" />{' '}
                                    {phoneMask(data.whatsappNumber)}
                                  </WhatsAppLink>
                                </ContentWrapper>
                              </TableData>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possuem registros para esta consulta
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </section>
      <SimulationInfo
        isModal={modalInfo}
        state={setModalInfo}
        data={modalInfoData}
      />
    </>
  );
};

export default Leads;
