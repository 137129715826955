import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { indexUserSupervisor } from 'services/User/User';

const SelectSupervisorComponent = ({
  label = 'Supervisor',
  id = 'select-supervisor',
  onChange = () => {}
}) => {
  const [listSupervisor, setListSupervisor] = useState([]);

  const handleListSupervisor = () => {
    indexUserSupervisor().then((res) => {
      setListSupervisor(res.data.resultSet);
    });
  };

  useEffect(() => {
    handleListSupervisor();
  }, []);

  return (
    <>
      <FormGroup>
        <Label for={id}>{label}</Label>
        <Select
          id={id}
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          isLoading={listSupervisor.length <= 0}
          options={listSupervisor.map((res) => {
            return {
              value: res.id,
              label: res.name
            };
          })}
          onChange={(res) => {
            onChange(res?.value ?? null);
          }}
          placeholder={`Selecione...`}
        />
      </FormGroup>
    </>
  );
};

export default SelectSupervisorComponent;
