import api from 'services/api';
import Swal from 'sweetalert2';

export const simuleConsignedService = async (data) => {
  return await api
    .post(`v3/consigned/simule`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};

export const createProposalConsignedService = async (data) => {
  return await api
    .post(`v3/consigned/proposal`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};

export const searchMarginService = async (cpf) => {
  return await api
    .get(`v3/search/benefit/inss/${cpf}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};
