import SampleModalView from 'components/Modals/SampleModalView';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { saveAutomationMessageService } from 'services/Automation/AutomationMessageService';
import { showAutomationMessageService } from 'services/Automation/AutomationMessageService';
import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';

const NotificationMessageComponent = ({
  automation = null,
  isOpen = false,
  setIsOpen = () => {},
}) => {
  const [data, setData] = useState({
    useFgts: 0,
    useConsigned: 0,
    usePersonalCredit: 0,
    message: null,
    automation: null,
  });

  const [showModal, setShowModal] = useState(false);

  const handleShowMessage = async () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const result = await showAutomationMessageService(automation);
    if (result && result?.status) {
      Swal.close();
      setData({
        ...result?.resultSet,
        automation: automation,
      });
      setShowModal(true);
      return;
    }
    setIsOpen(false);
    Swal.close();
  };

  const handleSave = async () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const result = await saveAutomationMessageService({
      ...data,
      automation: automation,
    });
    if (result && result?.status) {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Salvo atualizado com sucesso',
      });
    }
  };

  useEffect(() => {
    console.log(automation, isOpen);
    if (!isOpen || !automation) {
      setShowModal(false);
      return;
    }
    handleShowMessage();
  }, [isOpen]);

  if (!showModal) {
    return <></>;
  }

  const translateTypeMessage = {
    NEW_PROPOSTAL: 'Nova Proposta',
    CANCEL_PROPOSAL: 'Proposta Cancelada',
    FINISH_PROPOSAL: 'Proposta Paga',
    LINK_FORMALIZATION: 'Link de formalização',
  };

  return (
    <>
      <SampleModalView
        labelModal={`Mensagem de Notificação - ${translateTypeMessage[automation]}`}
        icon="fas fa-file-invoice-dollar"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={handleSave}
        showBtnSubmit={true}
        size="xl"
      >
        <Row>
          <Col md="4">
            <Title>Pré visualização</Title>
            <textarea
              readOnly
              className="bg-secondary p-4 mt-3 border w-100 h-100"
              value={data.message}
            />
          </Col>
          <Col md="8">
            <Card className="shadow">
              <CardHeader>
                <Title>Ativar notificação</Title>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <Label>
                      FGTS{' '}
                      <i
                        className="fas fa-info-circle"
                        title="A notificação será ativa ou inativa para o produto FGTS"
                      />
                    </Label>
                    <div>
                      <Label className="switch">
                        <Input
                          id="status"
                          type="checkbox"
                          checked={data.useFgts}
                          onChange={(ev) => {
                            setData({
                              ...data,
                              useFgts: ev.target.checked,
                            });
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                    </div>
                  </Col>
                  <Col md="3">
                    <Label>
                      Crédito Pessoal{' '}
                      <i
                        className="fas fa-info-circle"
                        title="A notificação será ativa ou inativa para o produto CRÉDITO PESSOAL"
                      />
                    </Label>
                    <div>
                      <Label className="switch">
                        <Input
                          id="status"
                          type="checkbox"
                          checked={data.usePersonalCredit}
                          onChange={(ev) => {
                            setData({
                              ...data,
                              usePersonalCredit: ev.target.checked,
                            });
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                    </div>
                  </Col>
                  <Col md="3">
                    <Label>
                      Consignado{' '}
                      <i
                        className="fas fa-info-circle"
                        title="A notificação será ativa ou inativa para o produto CONSIGNADO"
                      />
                    </Label>
                    <div>
                      <Label className="switch">
                        <Input
                          id="status"
                          type="checkbox"
                          checked={data.useConsigned}
                          onChange={(ev) => {
                            setData({
                              ...data,
                              useConsigned: ev.target.checked,
                            });
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                    </div>
                  </Col>

                  <Col md="3">
                    <Label>Instancia</Label>
                    <select
                      className="form-control"
                      value={data.instanceId}
                      onChange={(ev) => {
                        setData({
                          ...data,
                          instanceId: ev.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Selecione...
                      </option>
                      {data?.instances && data?.instances.length > 0 && (
                        <>
                          {data.instances.map((instance) => (
                            <option value={instance.id} key={instance.id}>
                              {instance.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader>
                <Title>Mensagem</Title>
              </CardHeader>
              <CardBody>
                <Title>Tags</Title>
                <Alert color="secondary">
                  <Badge color="info" className="font-weight-bold mr-2">
                    [nome]
                  </Badge>
                  <Badge color="info" className="font-weight-bold mr-2">
                    [loja]
                  </Badge>
                  <Badge color="info" className="font-weight-bold mr-2">
                    [link]
                  </Badge>
                  <Badge color="info" className="font-weight-bold mr-2">
                    [proposta]
                  </Badge>
                </Alert>
                <textarea
                  className="form-control"
                  style={{ height: '200px' }}
                  value={data.message}
                  onChange={(ev) => {
                    setData({
                      ...data,
                      message: ev.target.value,
                    });
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </SampleModalView>
    </>
  );
};

export default NotificationMessageComponent;
