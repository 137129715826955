const { Row, Col, FormGroup, Label, Input } = require('reactstrap');
const { maskDate } = require('utils/mask');

const FormPersonDataComponent = ({ register, setRegister }) => {
  return (
    <>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label>
              E-mail <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              value={register.email}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  email: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              RG <span className="text-danger">*</span>
            </Label>
            <Input
              value={register.documentRG}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  documentRG: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Data Emissão <span className="text-danger">*</span>
            </Label>
            <Input
              value={register.dateEmissionRG}
              placeholder="--/--/----"
              onChange={(ev) => {
                setRegister({
                  ...register,
                  dateEmissionRG: maskDate(ev.target.value)
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Estado Emissor <span className="text-danger">*</span>
            </Label>
            <select
              className="form-control form-control-md"
              name="stateEmissionRg"
              id="stateEmissionRg"
              value={register.ufRG}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  ufRG: ev.target.value
                });
              }}
            >
              <option name="" disabled selected>
                Selecione
              </option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Sexo <span className="text-danger">*</span>
            </Label>
            <select
              name="sex"
              className="form-control"
              value={register.sex}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  sex: ev.target.value
                });
              }}
            >
              <option value="">Selecione</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Nome da mãe <span className="text-danger">*</span>
            </Label>
            <Input
              value={register.nameMother}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  nameMother: ev.target.value
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormPersonDataComponent;
