export const EnumRateBolsaFamilia = [
  { installment: 1, rate: 0.904528301886793 },
  { installment: 2, rate: 1.65025157232704 },
  { installment: 3, rate: 2.2648427672956 },
  { installment: 4, rate: 2.77157232704403 },
  { installment: 5, rate: 3.18930817610063 },
  { installment: 6, rate: 3.53377358490566 },
  { installment: 7, rate: 3.81767295597484 },
  { installment: 8, rate: 4.05169811320755 },
  { installment: 9, rate: 4.23955974842767 },
  { installment: 10, rate: 4.4037106918239 },
  { installment: 11, rate: 4.53471698113208 },
  { installment: 12, rate: 4.64283018867925 },
];
