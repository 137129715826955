import api from 'services/api';

export const uploadFileImportFgtsService = (formData, onUploadProgress) => {
  return api.post('/v2/import/fgts/lead/simulation', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
};

export const listFileImportedService = () => {
  return api.get('/v2/import/fgts/lead/simulation');
};

export const listBanksImportedLeadService = () => {
  return api.get('/v2/import/fgts/lead/banks');
};

export const listLeadImportedService = (importedId, page) => {
  return api.get(`/v2/import/fgts/lead/list/${importedId}/${page}`);
};

export const listLeadSimulationImportedService = (id) => {
  return api.get(`/v2/import/fgts/lead/list/simulations/${id}`);
};

export const stopProcessImportedService = (importLeadFgtsId) => {
  return api.put(`/v2/import/fgts/lead/stop-process/${importLeadFgtsId}`);
};

export const startProcessImportedService = (importLeadFgtsId) => {
  return api.put(`/v2/import/fgts/lead/start-process/${importLeadFgtsId}`);
};
