import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Badge,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import { maskCNPJ, phoneMask, maskCPF } from 'utils/mask';
import Swal from 'sweetalert2';
import { getAllStores, toggleStoreStatus } from 'services/Store/StoreService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListStoreView = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    email: '',
    corporativeName: '',
    document: '',
    pageActual: 0,
    limit: 15,
  });

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [id]: value,
    }));
  };

  const handleUpdateStatus = async (storeId) => {
    const result = await Swal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja realmente alterar o status desta loja?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, alterar!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: 'Alterando status...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        await toggleStoreStatus(storeId);

        Swal.fire('Sucesso!', 'Status atualizado com sucesso.', 'success');

        handleListStore(false);
      } catch (err) {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      }
    }
  };

  const handleListStore = async (isMore) => {
    Swal.fire({
      title: 'Carregando, por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { data } = await getAllStores(filter);
      Swal.close();

      const fetchedData = data?.resultSet?.data || [];

      if (isMore) {
        setList((prevList) => [...prevList, ...fetchedData]);
      } else {
        setList(fetchedData);
      }

      setShowBtnMoreRegisters(fetchedData.length >= filter.limit);

      setFilter({
        name: '',
        email: '',
        corporativeName: '',
        document: '',
        limit: 15,
        pageActual: data?.resultSet?.pagination ?? 0,
      });
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  useEffect(() => {
    handleListStore();
  }, []);

  return (
    <>
      <Header titlePage="Listagem de Lojas" />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow mt-2">
              <CardHeader>
                <h3>Filtro - Lojas</h3>
              </CardHeader>
              <CardBody className="pb-0">
                <Row>
                  <Col className="col-lg-3 col-md-6 col-12">
                    <FormGroup>
                      <Label for="corporativeName">Razão Social</Label>
                      <Input
                        id="corporativeName"
                        value={filter.corporativeName}
                        onChange={handleFilterChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12">
                    <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input
                        id="name"
                        value={filter.name}
                        onChange={handleFilterChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12">
                    <FormGroup>
                      <Label for="document">CNPJ</Label>
                      <Input
                        id="document"
                        value={maskCNPJ(filter.document)}
                        onChange={handleFilterChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12">
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="email"
                        id="email"
                        value={filter.email}
                        onChange={handleFilterChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="border-0">
                <Button color="success" onClick={() => handleListStore(false)}>
                  <i className="fas fa-search mr-2" />
                  Buscar
                </Button>
                <Button
                  color="success"
                  className="ml-0 mt-sm-0 mt-2"
                  onClick={() => {
                    history.push(`/admin/store/create`);
                  }}
                >
                  <i className="fas fa-store mr-2" />
                  Nova Loja
                </Button>
              </CardFooter>
            </Card>
            <Card className="shadow mt-2">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Lista de Lojas</h3>
                  </div>
                </Row>
              </CardHeader>
              {list.length > 0 ? (
                <div className="table-responsive">
                  <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">DATA DE CADASTRO</th>
                        <th scope="col">CONTATO</th>
                        <th scope="col">LOJA</th>
                        <th scope="col">STATUS</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((store) => (
                        <tr key={store.id}>
                          <td>{store.createdAt}</td>
                          <td>
                            <p className="m-0">
                              Nome: <strong>{store.sellerName}</strong>
                            </p>
                            <p className="m-0">
                              CPF:{' '}
                              <strong>{maskCPF(store.sellerDocument)}</strong>
                            </p>
                          </td>
                          <td>
                            <p className="m-0">
                              Nome: <strong>{store.name}</strong>
                            </p>
                            <p className="m-0">
                              CNPJ: <strong>{maskCNPJ(store.document)}</strong>
                            </p>
                            <p className="m-0">
                              Razão Social:{' '}
                              <strong>{store.corporativeName}</strong>
                            </p>
                            <p className="m-0">
                              Tel. Contato:{' '}
                              <strong>{phoneMask(store.phone)}</strong>
                            </p>
                          </td>
                          <td>
                            <Badge
                              color={
                                Number(store.active) ? 'success' : 'danger'
                              }
                              className="font-14"
                            >
                              {Number(store.active) ? 'ATIVO' : 'INATIVO'}
                            </Badge>
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => handleUpdateStatus(store.id)}
                                >
                                  <i className="fas fa-users-cog" />
                                  {Number(store.active) ? 'Inativar' : 'Ativar'}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                      {showBtnMoreRegisters && (
                        <tr>
                          <td colSpan={12}>
                            <Button
                              className="w-100"
                              onClick={() => handleListStore(true)}
                            >
                              Carregar mais...
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <Alert color="secondary" className="text-center">
                  Não possui registros no momento.
                </Alert>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ListStoreView;
