import styled, { css } from 'styled-components';
import { device } from 'layouts/breakpoints';

export const Main = styled.div`
  @media ${device.desktopLG} {
    .main-content {
      ${({ navbarCollapsed }) =>
        navbarCollapsed &&
        css`
          margin-left: 62px !important;
        `}
    }
  }
`;
