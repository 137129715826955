import { useState } from 'react';
import dayjs from 'dayjs';
import Header from 'components/Headers/Header';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Container,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardFooter,
} from 'reactstrap';

import { moneyMask, cepMask, maskDate, phoneMask } from 'utils/mask';
import { ShowClientProposalService } from 'services/Register/ClientService';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import { saveProposalCrefisaService } from 'services/Digitation/PersonalCreditCrefisaService';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import { useHistory } from 'react-router-dom';
import { EnumOrganEmissionRG } from 'utils/EnumOrganEmissionRG';
import { EnumRateBolsaFamilia } from './Crefisa/EnumRateBolsaFamilia';

const NewProposalCrefisaView = () => {
  const history = useHistory();
  const inputRequired = [
    'name',
    'birthDate',
    'cpf',
    'phone',
    'registry',
    'nameMother',
    'dateEmissionRG',
    'documentRG',
    'emissionRG',
    'stateRG',
    'sex',
    'cep',
    'address',
    'district',
    'numberAddress',
    'city',
    'state',
    'numberAccount',
    'RG_FRONT',
    'RG_BACK',
    'CPF_FRONT',
    'CPF_BACK',
    'DOCUMENT_BANK',
  ];
  const translateInput = [
    { key: 'clientId', name: 'Cliente' },
    { key: 'serviceId', name: 'Serviço' },
    { key: 'name', name: 'Nome' },
    { key: 'birthDate', name: 'Data de nascimento' },
    { key: 'cpf', name: 'CPF' },
    { key: 'phone', name: 'Contato WhatsApp' },
    { key: 'registry', name: 'Matricula' },
    { key: 'nameMother', name: 'Nome da mãe' },
    { key: 'dateEmissionRG', name: 'Data de emissão do RG' },
    { key: 'documentRG', name: 'RG' },
    { key: 'emissionRG', name: 'Orgão emissor RG' },
    { key: 'stateRG', name: 'Estado Emissor RG' },
    { key: 'sex', name: 'Sexo' },
    { key: 'stateCivil', name: 'Estado Civil' },
    { key: 'cep', name: 'CEP' },
    { key: 'address', name: 'Endereço' },
    { key: 'district', name: 'Bairro' },
    { key: 'numberAddress', name: 'Numero' },
    { key: 'city', name: 'Cidade' },
    { key: 'state', name: 'Estado' },
    { key: 'numberAccount', name: 'Número da conta' },
    { key: 'RG_FRONT', name: 'Anexo: RG frente não informado' },
    { key: 'RG_BACK', name: 'Anexo: RG verso não informado' },
    { key: 'CPF_FRONT', name: 'Anexo: CPF frente não informado' },
    { key: 'CPF_BACK', name: 'Anexo CPF verso não informado' },
    { key: 'DOCUMENT_BANK', name: 'Anexo: Extrato bancário não informado' },
  ];
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [simulation, setSimulation] = useState({
    show: false,
    valueInstallment: 0,
    valueClient: 0,
  });
  const [contract, setContract] = useState({
    cpf: '',
    birthDate: '',
    febrabanId: null,
    registry: '',
    value: '',
    installment: 12,
    valueInstallment: '159,00',
    useSafe: false,
  });

  const handleGetClientProposal = async () => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ShowClientProposalService(
      contract.cpf.replace(/[^0-9]/g, ''),
      1
    ).then(({ data }) => {
      if (!data?.resultSet || !data?.resultSet?.name) {
        Swal.fire({
          icon: 'warning',
          title: 'Não identificado',
          text: 'Registro não identificado na base',
        });
        return;
      }

      Swal.close();

      setContract({
        ...contract,
        name: data.resultSet?.name ?? '',
        email: data.resultSet?.email ?? '',
        sex: data.resultSet?.sex ?? '',
        motherName: data.resultSet?.nameMother ?? '',
        cep: cepMask(data.resultSet?.CEP ?? ''),
        address: data.resultSet?.address ?? '',
        numberAddress: data.resultSet?.numberAddress ?? '',
        district: data.resultSet?.district ?? '',
        city: data.resultSet?.city ?? '',
        state: data.resultSet?.state ?? '',
        complement: data.resultSet?.email ?? '',
        documentRg: data.resultSet?.documentRG ?? '',
        dateEmissionRg: dayjs(data.resultSet?.dateEmissionRG ?? '').format(
          'DD/MM/YYYY'
        ),
        organEmissionRg: data.resultSet?.emissionRG ?? '',
        stateRg: data.resultSet?.ufRG ?? '',
      });
    });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === 'cpf') value = maskCPF(value);
    if (name === 'phone') value = phoneMask(value);
    if (name === 'valueIncome' || name === 'valueInstallment')
      value = moneyMask(value);
    if (name === 'birthDate' || name === 'dateEmissionRG')
      value = maskDate(value);

    setContract({
      ...contract,
      [name]: value,
    });
  };

  const handleSetFile = (ev) => {
    const file = ev.target.files[0];
    const name = ev.target.name;

    if (!file) return;

    const sizeFile = file.size / 1024 / 1024;

    if (sizeFile > 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: 'O arquivo não pode ser importado. Por favor, reduza a quantidade de registros e importe novamente!',
      });
      return;
    }

    setContract({
      ...contract,
      [name]: file,
    });
  };

  const handleSave = () => {
    Swal.fire({
      title: 'Salvando proposta',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setErrorMessages([]);
    const errors = [];

    for (let key in inputRequired) {
      const keyInput = inputRequired[key];
      if (contract[keyInput] !== undefined && contract[keyInput] !== '')
        continue;

      const translateName = translateInput.filter((e) => e.key === keyInput)[0];

      errors.push({
        input: keyInput,
        message: `Campo: ${translateName.name}, é obrigatório`,
      });
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      Swal.close();
      return;
    }

    setIsSaving(true);
    const formData = new FormData();

    for (let key in contract) {
      formData.append(key, contract[key]);
    }

    saveProposalCrefisaService(6, formData)
      .then(({ data }) => {
        Swal.close();
        setIsSaving(false);
        if (data?.errors && data?.errors.length > 0) {
          setErrorMessages(data?.errors);
          return;
        }

        setContract({
          ...contract,
          uuid: data.uuid,
        });
        setShowSuccess(true);
      })
      .catch((err) => {
        Swal.close();

        setErrorMessages([
          {
            input: null,
            message: !err.response
              ? 'Ocorreu um erro inesperado'
              : err.response.data.message,
          },
        ]);

        setIsSaving(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSimulate = () => {
    try {
      const installment = Number(contract.installment);
      const valueInstallment = contract?.valueInstallment
        .replace('.', '')
        .replace(',', '.');

      const filterRate = EnumRateBolsaFamilia.filter(
        (e) => Number(e.installment) === Number(installment)
      )[0];

      if (!filterRate) {
        Swal.fire({
          icon: 'warning',
          title: 'Ops',
          text: 'Não foi possível calcular',
        });
        return;
      }

      setSimulation({
        ...simulation,
        show: true,
        installment: installment,
        valueClient: (filterRate.rate * valueInstallment).toFixed(2),
        valueInstallment: moneyMask(valueInstallment),
      });

      setContract({
        ...contract,
        installment: installment,
        valueLiberty: (filterRate.rate * valueInstallment).toFixed(2),
        valueInstallment: moneyMask(valueInstallment),
      });
    } catch (e) {
      setSimulation({
        ...simulation,
        show: false,
        installment: 12,
        valueClient: 0,
        valueInstallment: 0,
      });
    }
  };

  if (showSuccess) {
    return (
      <>
        <Header titlePage="Crédito Pessoal - Crefisa" />
        <Container className="mt--9" fluid>
          <div>
            <Card className="mt-4 shadow" color="success">
              <CardBody className="text-center text-white">
                <span>
                  <i className="fas fa-check fa-3x" />
                </span>
                <h1>Sucesso</h1>
                <p>
                  Agora, basta aguardar o processamento da esteira para o envio
                  ao banco Crefisa.
                </p>
                <Card color="white" className="mt-3 ">
                  <CardBody className="text-dark">
                    <p className="mb-0">Código digitação:</p>
                    <h2 className="m-0" style={{ fontSize: '30px' }}>
                      {contract.uuid}
                    </h2>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
            <div>
              <Row>
                <Col md="6">
                  <Button
                    color="primary"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      history.push('/admin/personal-credit/digitation/queue');
                    }}
                  >
                    ESTEIRA DE PROPOSTAS
                  </Button>
                </Col>
                <Col md="6">
                  <Button
                    color="success"
                    size="lg"
                    className="w-100 mt-3"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    NOVA PROPOSTA
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header titlePage="Crédito Pessoal - Crefisa" />
      <Container className="mt--9" fluid>
        <Alert color="primary">
          <p className="m-0">
            <i className="fas fa-info-circle" /> Nesta página você pode digitar
            os produtos do Crédito pessoal Crefisa
          </p>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <h1>
              <i className="far fa-sticky-note" /> Crédito Pessoal - Crefisa
              <Button
                color="warning"
                className="float-right"
                size="sm"
                onClick={() =>
                  history.push(`/admin/personal-credit/digitation/queue`)
                }
              >
                <i className="fas fa-chevron-left" /> Voltar
              </Button>
            </h1>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label>
                    CPF <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="cpf"
                    value={contract.cpf}
                    onChange={handleInputChange}
                    placeholder="000.000.000-00"
                  />
                </FormGroup>
              </Col>
              <Col lg="4" md="4">
                <FormGroup>
                  <Label>
                    Matrícula <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <Input
                      name="registry"
                      value={contract.registry}
                      placeholder="Matrícula"
                      onChange={handleInputChange}
                    />

                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="bg-secondary border b-5">
                        -
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      name="registryDigit"
                      className="col-3"
                      value={contract.registryDigit}
                      placeholder="Digito"
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg="3" md="4">
                <FormGroup>
                  <Label>
                    Valor da Renda Líquida{' '}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="valueIncome"
                    value={contract.valueIncome}
                    onChange={handleInputChange}
                    placeholder="R$ 0,00"
                  />
                </FormGroup>
              </Col>
              <Col lg="2" md="4">
                <FormGroup>
                  <Label>
                    Tipo de contrato <span className="text-danger">*</span>
                  </Label>
                  <select className="form-control">
                    <option value={1} selected>
                      NOVO
                    </option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="shadow">
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>
                            Valor da Parcela{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="valueInstallment"
                            value={contract.valueInstallment}
                            onChange={handleInputChange}
                            placeholder="R$ 0,00"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>
                            Parcela <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <select
                              className="form-control"
                              name="installment"
                              value={contract.installment}
                              onChange={handleInputChange}
                            >
                              <option value={1}>1x</option>
                              <option value={2}>2x</option>
                              <option value={3}>3x</option>
                              <option value={4}>4x</option>
                              <option value={5}>5x</option>
                              <option value={6}>6x</option>
                              <option value={7}>7x</option>
                              <option value={8}>8x</option>
                              <option value={9}>9x</option>
                              <option value={10}>10x</option>
                              <option value={11}>11x</option>
                              <option value={12}>12x</option>
                            </select>
                            <InputGroupAddon addonType="append">
                              <Button color="success" onClick={handleSimulate}>
                                Simular
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    {simulation.show && (
                      <>
                        <div className="bg-secondary p-3 rounded border">
                          <Row>
                            <Col md="4">
                              <p className="m-0">Prazo</p>
                              <h3 className="m-0">{simulation.installment}</h3>
                            </Col>
                            <Col md="4">
                              <p className="m-0">Valor Parcela</p>
                              <h3 className="m-0">
                                R$ {simulation.valueInstallment}
                              </h3>
                            </Col>
                            <Col md="4">
                              <p className="m-0">Valor Cliente</p>
                              <h3 className="m-0">
                                R$ {simulation.valueClient}
                              </h3>
                            </Col>
                          </Row>
                        </div>
                        <Alert color="info" className="mt-2">
                          <h4 className="m-0">
                            Valor aproximado, poderá sofrer alteração de acordo
                            com as taxas de juros da Crefisa
                          </h4>
                        </Alert>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-2">
          <CardHeader>
            <h3 className="m-0">
              <i className="fas fa-user-check" /> Informações do cliente
              <Button
                color="primary"
                size="sm"
                className="float-right"
                onClick={handleGetClientProposal}
              >
                <i className="fas fa-id-badge" /> Atualizar dados do cliente
              </Button>
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="name"
                    value={contract.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Data de Nascimento <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="birthDate"
                    placeholder="--/--/----"
                    value={contract.birthDate}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Contato WhatsApp <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="phone"
                    placeholder="(xx) x xxxx-xxxx"
                    value={contract.phone}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    RG <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="documentRG"
                    value={contract.documentRG}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Data de Emissão <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="dateEmissionRG"
                    placeholder="--/--/----"
                    value={contract.dateEmissionRG}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Orgão Emissor <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="emissionRG"
                    className="form-control"
                    value={contract.emissionRG}
                    onChange={handleInputChange}
                  >
                    <option value="" selected disabled>
                      SELECIONE...
                    </option>
                    {EnumOrganEmissionRG.map((organEmission, key) => (
                      <option key={key} value={organEmission.code}>
                        {organEmission.code} - {organEmission.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Estado Emissor <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="stateRG"
                    className="form-control"
                    value={contract.stateRG}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled="">
                      Selecione...
                    </option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    E-mail <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="email"
                    value={contract.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>
                    Sexo <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="sex"
                    className="form-control"
                    value={contract.sex}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled="">
                      Selecione...
                    </option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Nome da Mãe <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="nameMother"
                    value={contract.nameMother}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-2">
          <CardHeader>
            <h3>
              <i className="fas fa-map-marked-alt" /> Endereço
            </h3>
          </CardHeader>
          <CardBody>
            <FormRegisterAddressComponent
              register={contract}
              setRegister={setContract}
              isRequired={true}
            />
          </CardBody>
        </Card>

        <Card className="shadow mt-2">
          <CardHeader>
            <h3 className="m-0">
              <i className="fas fa-university" /> Dados bancários
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup>
                  <Label>
                    Banco <span className="text-danger">*</span>
                  </Label>
                  <select className="form-control" readOnly disabled>
                    <option value="104" selected>
                      CAIXA ECONÔMICA FEDERAL
                    </option>
                  </select>
                </FormGroup>
              </Col>
              <Col xl="2">
                <FormGroup>
                  <Label>
                    Agência <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="agencyBank"
                    value="3880"
                    readOnly
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>
                    Conta <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="numberAccount"
                    value={contract.numberAccount}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col xl="2">
                <FormGroup>
                  <Label>
                    Dígito Conta <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="digitAccount"
                    value={contract.digitAccountBank}
                    onChange={handleInputChange}
                    maxLength={1}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="shadow mt-2">
          <CardHeader>
            <h3 className="m-0">
              <i className="fas fa-upload" /> Envio de documentos
            </h3>
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <h3 className="m-0">Informações importantes sobre os arquivos</h3>
              <p className="m-0">Extensões permitidas: PDF, JPG</p>
              <p className="m-0">Tamanho limite: 2Mb por arquivo</p>
            </Alert>

            <Row>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      RG FRENTE <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="RG_FRONT"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      RG VERSO <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="RG_BACK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      CPF FRENTE <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CPF_FRONT"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      CPF VERSO <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="CPF_BACK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>
                      Extrato bancário <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      name="DOCUMENT_BANK"
                      onChange={handleSetFile}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="shadow mb-2">
                  <CardBody>
                    <Label>Outros</Label>
                    <Input type="file" name="OTHERS" onChange={handleSetFile} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleSave} disabled={isSaving}>
              <i className="fas fa-save" /> SALVAR PROPOSTA{' '}
              {isSaving && <i className="fas fa-circle-notch fa-spin" />}
            </Button>
          </CardFooter>
        </Card>

        <Alert className="mt-2" color="secondary">
          <p className="m-0">
            - Campos com <strong className="text-danger">*</strong> são
            obrigatórios
          </p>
        </Alert>

        {errorMessages.length > 0 && (
          <Alert color="warning">
            <h2 className="m-0 text-white">
              <i className="fas fa-times" /> Erro
            </h2>
            {errorMessages.map((errorMessage, key) => (
              <div
                key={key}
                dangerouslySetInnerHTML={{
                  __html: `- ${errorMessage.message}`,
                }}
              />
            ))}
          </Alert>
        )}
      </Container>
    </>
  );
};

export default NewProposalCrefisaView;
