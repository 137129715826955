import Header from 'components/Headers/Header';
import { TableRow } from 'components/Table';
import { TableBody } from 'components/Table';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import { ReportIndicationConversionService } from 'services/Promoter/IndicationService';
import Swal from 'sweetalert2';

const IndicationConversionView = () => {
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    uuidIndication: null,
    emailUser: null,
  });
  const [list, setList] = useState([
    {
      indicationId: 33434,
      nameIndication: 'Katia Susi Alves da Silva',
      uuidIndication: '349ed79e-07b6-4055-bda0-ebd186f95d74',
      qtdSimulation: 867,
      qtdProposal: 14,
      conversion: 1.61,
      nameUser: 'Katia Susi Alves da Silva',
      emailUser: 'katia@gmail.com',
      phoneUser: '44999665533',
    },
    {
      indicationId: 33434,
      nameIndication: 'Katia Susi Alves da Silva',
      uuidIndication: '349ed79e-07b6-4055-bda0-ebd186f95d74',
      qtdSimulation: 867,
      qtdProposal: 14,
      conversion: 30,
      nameUser: 'Katia Susi Alves da Silva',
      emailUser: 'katia@gmail.com',
      phoneUser: '44999665533',
    },
    {
      indicationId: 33434,
      nameIndication: 'Katia Susi Alves da Silva',
      uuidIndication: '349ed79e-07b6-4055-bda0-ebd186f95d74',
      qtdSimulation: 867,
      qtdProposal: 14,
      conversion: 90,
      nameUser: 'Katia Susi Alves da Silva',
      emailUser: 'katia@gmail.com',
      phoneUser: '44999665533',
    },
  ]);

  const handleSubmit = (isMore = false) => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!isMore) {
      setList([]);
    }

    let filterSearch = {
      ...filter,
    };

    if (!isMore) {
      setFilter({
        ...filter,
        pageActual: 0,
      });

      filterSearch = {
        ...filter,
        pageActual: 0,
      };
    }

    ReportIndicationConversionService(filterSearch)
      .then(({ data }) => {
        Swal.close();
        if (!data?.resultSet?.data) {
          setShowBtnMoreRegisters(false);
          return;
        }

        setFilter({
          ...filter,
          pageActual: data.resultSet.pagination,
        });

        if (isMore) {
          setList([...list, ...data?.resultSet?.data]);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length > 0);
        } else {
          setList(data?.resultSet?.data ?? []);
          setShowBtnMoreRegisters(data?.resultSet?.data?.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <Header titlePage="Indicação - Relatório de conversão - simulações x propostas" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>Filtro</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período Simulação</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      value={filter.dateStart}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      value={filter.dateEnd}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />

                    <select
                      className="form-control border pl-2"
                      id="typeDate"
                      name="typeDate"
                      onChange={(ev) => {
                        setFilter({ ...filter, typeDate: ev.target.value });
                      }}
                      onBlur={(ev) => {
                        setFilter({ ...filter, typeDate: ev.target.value });
                      }}
                    >
                      <option value="createdAt">DATA DE CADASTRO</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Código Indicação</Label>
                  <Input
                    name="uuidIndication"
                    value={filter.uuidIndication}
                    onChange={(ev) => {
                      setFilter({ ...filter, uuidIndication: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>E-mail do usuário</Label>
                  <Input
                    name="uuidIndication"
                    value={filter.emailUser}
                    onChange={(ev) => {
                      setFilter({ ...filter, emailUser: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              onClick={() => {
                setList([]);
                handleSubmit();
              }}
            >
              Filtrar
            </Button>
          </CardFooter>
        </Card>
        {list.length <= 0 && (
          <Alert className="text-center mt-4 shadow" color="secondary">
            <p className="m-0">
              Não foram identificados registros para esta consulta
            </p>
          </Alert>
        )}
        {list.length > 0 && (
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>Relatório - Simulações X Propostas</h3>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th>LINK - INDICAÇÃO / VENDEDOR</th>
                  <th>USUÁRIO DE SISTEMA</th>
                  <th>SIMULAÇÕES</th>
                  <th>PROPOSTAS</th>
                  <th>TAXA DE CONVERSÃO</th>
                </tr>
              </thead>
              <tbody>
                {list.map((register, key) => (
                  <tr key={key}>
                    <td className="text-lg font-weight-bold">
                      <p className="m-0">
                        Nome:{' '}
                        <span className="font-weight-bold">
                          {register.nameIndication}
                        </span>
                      </p>
                      <p className="m-0">
                        Código:{' '}
                        <span className="font-weight-bold">
                          {register.uuidIndication}
                        </span>
                      </p>
                    </td>
                    <td className="text-lg">
                      <p className="m-0">
                        Nome:{' '}
                        <span className="font-weight-bold">
                          {register.nameUser}
                        </span>
                      </p>
                      <p className="m-0">
                        E-mail:{' '}
                        <span className="font-weight-bold">
                          {register.emailUser}
                        </span>
                      </p>
                      <p className="m-0">
                        WhatsApp:{' '}
                        <span className="font-weight-bold">
                          {register.phoneUser}
                        </span>
                      </p>
                    </td>
                    <td className="text-lg">{register.qtdSimulation}</td>
                    <td className="text-lg">{register.qtdProposal}</td>
                    <td className="text-lg">
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{register.conversion}%</span>
                        <div>
                          <Progress
                            max="100"
                            value={register.conversion}
                            barClassName={
                              register.conversion > 30
                                ? 'bg-success'
                                : register.conversion > 10
                                ? 'bg-primary'
                                : 'bg-danger'
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {showBtnMoreRegisters && (
                <TableBody>
                  <TableRow>
                    <td colSpan={5}>
                      <Button
                        className="w-100"
                        onClick={() => {
                          setFilter({ ...filter });
                          handleSubmit(true);
                        }}
                      >
                        Carregar mais...
                      </Button>
                    </td>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default IndicationConversionView;
