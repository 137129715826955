import Header from 'components/Headers/Header';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableHeader } from 'components/Table';
import { TableBody } from 'components/Table';
import { TableData } from 'components/Table';
import { Table } from 'components/Table';
import { CaretRight } from 'phosphor-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { MobileTitle } from 'views/Dashboard/styles';
import { ContentWrapper } from 'views/Dashboard/styles';
import {
  listLeadImportedService,
  listLeadSimulationImportedService
} from '../../services/Import/ImportFgtsService';

const LeadImportedView = () => {
  let { importedId } = useParams();
  const [result, setResult] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);
  const [tableSimulations, setTableSimulations] = useState(null);
  const [resultSimulations, setResultSimulations] = useState(null);
  const [pages, setPages] = useState({
    pages: 0
  });

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleShowTableSimulations = (index) => {
    if (tableSimulations === index) return setTableSimulations(null);

    setTableSimulations(index);
  };

  const handleList = (page) => {
    const pageSelected = typeof page == 'number' ? page : 0;

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setResult([]);
    listLeadImportedService(importedId, pageSelected)
      .then((res) => {
        Swal.close();
        const { data, pagination } = res.data.resultSet;
        setResult(data);
        setPages(pagination);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleListSimulations = (id, key) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listLeadSimulationImportedService(id)
      .then((res) => {
        Swal.close();
        setResultSimulations(res.data.resultSet);
        handleShowTableSimulations(key);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList(0);
  }, []);

  return (
    <>
      <Header titlePage="FGTS - CLIENTES IMPORTADOS" />
      <Container className="mt--9" fluid>
        {result.length > 0 && (
          <Card className="mt-3">
            <CardHeader>Clientes Importados</CardHeader>
            <PaginationSelector pages={pages.pages} list={handleList} />
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>DATA CADASTRO</TableHead>
                  <TableHead>CLIENTE</TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    STATUS
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    DATA PROCESSAMENTO
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell" />
                  <TableHead className="d-xl-none" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {result.map((register, key) => (
                  <>
                    <TableRow key={key}>
                      <TableData>{register.createdAt}</TableData>
                      <TableData>
                        <p className="m-0">
                          Nome: <strong>{register.name}</strong>
                        </p>
                        <p className="m-0">
                          CPF: <strong>{maskCPF(register.document)}</strong>
                        </p>
                        <p className="m-0">
                          WHATSAPP: <strong>{phoneMask(register.phone)}</strong>
                        </p>
                        <p className="m-0">
                          DATA DE NASCIMENTO:{' '}
                          <strong>{register.birthDate}</strong>
                        </p>
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        {register.status == 1 ? (
                          <Badge color="success">PROCESSADO</Badge>
                        ) : register.status == 0 ? (
                          <Badge color="warning">AGUARDANDO</Badge>
                        ) : (
                          <Badge color="danger">CANCELADO</Badge>
                        )}
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        <p className="m-0">
                          <strong>{register.updateAt}</strong>
                        </p>
                      </TableData>
                      <TableData>
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            <DropdownItem
                              onClick={() => {
                                handleListSimulations(
                                  register.id,
                                  `simulation-${key}`
                                );
                              }}
                            >
                              <i className="fas fa-edit" /> Simulação
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </TableData>
                      <TableData className="d-xl-none">
                        <CaretRight
                          active={tableDetails === key}
                          onClick={() => handleTableDetails(key)}
                        />
                      </TableData>
                    </TableRow>
                    {tableSimulations === `simulation-${key}` && (
                      <TableRow key={`simulation-${key}`}>
                        <TableData colSpan={5} className="bg-secondary">
                          <h3>Simulações</h3>
                          {resultSimulations.length > 0 ? (
                            <table className="table table-bordered w-100 bg-white">
                              <thead>
                                <tr>
                                  <th>Financeira</th>
                                  <th>Valor Liberado</th>
                                  <th>Erro</th>
                                </tr>
                              </thead>
                              <tbody>
                                {resultSimulations.map((register, key) => (
                                  <tr key={key}>
                                    <td>
                                      {register.febrabanId} -{' '}
                                      {register.febrabanName}
                                    </td>
                                    <td>R$ {register.valueLiberty}</td>
                                    <td className="text-danger">
                                      {register.messageReturnService}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p className="text-danger">
                              Não foram identificados registros
                            </p>
                          )}
                        </TableData>
                      </TableRow>
                    )}
                    {tableDetails === key && (
                      <TableRow className="d-xl-none">
                        <TableData colSpan={10}>
                          <ContentWrapper className="d-lg-none">
                            <MobileTitle>Status</MobileTitle>

                            {register.status == 1 ? (
                              <Badge color="success">PROCESSADO</Badge>
                            ) : register.status == 0 ? (
                              <Badge color="warning">AGUARDANDO</Badge>
                            ) : (
                              <Badge color="danger">CANCELADO</Badge>
                            )}
                          </ContentWrapper>
                          <ContentWrapper>
                            <MobileTitle>Data Processamento</MobileTitle>
                            <p className="m-0">
                              <strong>{register.updateAt}</strong>
                            </p>
                          </ContentWrapper>
                        </TableData>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
            <PaginationSelector pages={pages.pages} list={handleList} />
          </Card>
        )}
      </Container>
    </>
  );
};

export default LeadImportedView;
