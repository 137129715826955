import api from 'services/api';

const getLandingPage = async (type) => {
  return await api.get(`v2/landing-page/${type}`);
};

const indexLinkIndication = async (landingPageId, url, filter) => {
  return await api.get(`v2/landing-page/link-indication/${landingPageId}`, {
    params: {
      url,
      landingPageId,
      ...filter
    }
  });
};

const updateLandingPage = async (type, dataPost) => {
  return await api.put(`v2/landing-page/${type}`, dataPost);
};

const showIziLink = async () => {
  return await api.get(`v2/landing-page/izilink/show`);
};

const storeIziLink = async (
  data = {
    header: '',
    links: []
  }
) => {
  return await api.post(`v2/landing-page/izilink/insert`, data);
};

const indexLandingPage = async (type) => {
  return await api.get(`v2/landing-page/list/${type}`);
};

export {
  getLandingPage,
  updateLandingPage,
  showIziLink,
  storeIziLink,
  indexLandingPage,
  indexLinkIndication
};
