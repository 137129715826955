export const EnumOrganEmissionRG = [
  {
    code: 'ABNC',
    name: 'Academia Brasileira de Neurocirurgia',
  },
  {
    code: 'AGU',
    name: 'Advocacia-Geral da União',
  },
  {
    code: 'ANAC',
    name: 'Agência Nacional de Aviação Civil',
  },
  {
    code: 'CAER',
    name: 'Clube de Aeronáutica',
  },
  {
    code: 'CAU',
    name: 'Conselho de Arquitetura e Urbanismo',
  },
  {
    code: 'CBM',
    name: 'Corpo de Bombeiro Militar',
  },
  {
    code: 'CFA',
    name: 'Conselho Federal Administração',
  },
  {
    code: 'CFB',
    name: 'Conselho Federal de Biblioteconomia',
  },
  {
    code: 'CFBIO',
    name: 'Conselho Federal de Biologia',
  },
  {
    code: 'CFBM',
    name: 'Conselho Federal de Biomedicina',
  },
  {
    code: 'CFC',
    name: 'Conselho Federal de Contabilidade',
  },
  {
    code: 'CFESS',
    name: 'Conselho Federal de Serviço Social',
  },
  {
    code: 'CFF',
    name: 'Conselho Regional de Farmácia',
  },
  {
    code: 'CFFA',
    name: 'Conselho Federal de Fonoaudiologia',
  },
  {
    code: 'CFM',
    name: 'Conselho Federal de Medicina',
  },
  {
    code: 'CFMV',
    name: 'Conselho Federal de Medicina Veterinária',
  },
  {
    code: 'CFN',
    name: 'Conselho Federal de Nutrição',
  },
  {
    code: 'CFO',
    name: 'Conselho Federal de Odontologia',
  },
  {
    code: 'CFP',
    name: 'Conselho Federal de Psicologia',
  },
  {
    code: 'CFQ',
    name: 'Conselho Regional de Química',
  },
  {
    code: 'CFT',
    name: 'Conselho Federal dos Técnicos Industriais',
  },
  {
    code: 'CFTA',
    name: 'Conselho Federal dos Técnicos Agrícolas',
  },
  {
    code: 'CGPI',
    name: 'Coordenação Geral de Privilégios e Imunidades',
  },
  {
    code: 'CGPMAF',
    name: 'Coordenadoria Geral de Polícia Marítima, Aeronáutica e de Fronteiras',
  },
  {
    code: 'CIPC',
    name: 'Centro de Inteligência da Polícia Civil',
  },
  {
    code: 'CNIG',
    name: 'Conselho Nacional de Imigração',
  },
  {
    code: 'CNT',
    name: 'Confederação Nacional do Transporte',
  },
  {
    code: 'CNTV',
    name: 'Confederação Nacional de Vigilantes & Prestadores de Serviços',
  },
  {
    code: 'COFECI',
    name: 'Conselho Federal de Corretores de Imóveis',
  },
  {
    code: 'COFECON',
    name: 'Conselho Federal de Economia',
  },
  {
    code: 'COFEM',
    name: 'Conselho Federal de Museologia',
  },
  {
    code: 'COFEN',
    name: 'Conselho Federal de Enfermagem',
  },
  {
    code: 'COFFITO',
    name: 'Conselho Regional de Fisioterapia e Terapia Ocupacional',
  },
  {
    code: 'COMAER',
    name: 'Comando da Aeronáutica',
  },
  {
    code: 'CONFE',
    name: 'Conselho Federal de Estatística',
  },
  {
    code: 'CONFEA',
    name: 'Conselho Federal de Engenharia e Agronomia',
  },
  {
    code: 'CONFEF',
    name: 'Conselho Federal de Educação Física',
  },
  {
    code: 'CONFERE',
    name: 'Conselho Federal dos Representantes Comerciais',
  },
  {
    code: 'CONRE',
    name: 'Conselho Regional de Estatística',
  },
  {
    code: 'CONRERP',
    name: 'Conselho Federal de Profissionais de Relações Públicas',
  },
  {
    code: 'CORE',
    name: 'Conselho Regional dos Representantes Comerciais',
  },
  {
    code: 'CORECON',
    name: 'Conselho Regional de Economia',
  },
  {
    code: 'COREM',
    name: 'Conselho Regional de Museologia',
  },
  {
    code: 'COREN',
    name: 'Conselho Regional de Enfermagem',
  },
  {
    code: 'CRA',
    name: 'Conselho Regional de Administração',
  },
  {
    code: 'CRAS',
    name: 'Centro de Referência de Assistência Social',
  },
  {
    code: 'CRB',
    name: 'Conselho Regional de Biblioteconomia',
  },
  {
    code: 'CRBIO',
    name: 'Conselho Regional de Biologia',
  },
  {
    code: 'CRBM',
    name: 'Conselho Regional de Biomedicina',
  },
  {
    code: 'CRC',
    name: 'Conselho Regional de Contabilidade',
  },
  {
    code: 'CREA',
    name: 'Conselho Regional de Engenharia e Agronomia',
  },
  {
    code: 'CRECI',
    name: 'Conselho Regional de Corretores de Imóveis',
  },
  {
    code: 'CREF',
    name: 'Conselho Regional de Educação Física',
  },
  {
    code: 'CREFITO',
    name: 'Conselho Regional de Fisioterapia e Terapia Ocupacional',
  },
  {
    code: 'CRESS',
    name: 'Conselho Regional de Serviço Social',
  },
  {
    code: 'CRF',
    name: 'Conselho Regional de Farmácia',
  },
  {
    code: 'CRFA',
    name: 'Conselho Regional de Fonoaudiologia',
  },
  {
    code: 'CRM',
    name: 'Conselho Regional de Medicina',
  },
  {
    code: 'CRMV',
    name: 'Conselho Regional de Medicina Veterinária',
  },
  {
    code: 'CRN',
    name: 'Conselho Regional de Nutrição',
  },
  {
    code: 'CRO',
    name: 'Conselho Regional de Odontologia',
  },
  {
    code: 'CRP',
    name: 'Conselho Regional de Psicologia',
  },
  {
    code: 'CRPRE',
    name: 'Conselho Regional de Profissionais de Relações Públicas',
  },
  {
    code: 'CRQ',
    name: 'Conselho Regional de Química',
  },
  {
    code: 'CRT',
    name: 'Conselho Regional dos Técnicos Industriais',
  },
  {
    code: 'CRTA',
    name: 'Conselho Regional de Técnicos de Administração',
  },
  {
    code: 'CTPS',
    name: 'Carteira de Trabalho e Previdência Social',
  },
  {
    code: 'CV',
    name: 'Cartório Civil',
  },
  {
    code: 'DELEMIG',
    name: 'Delegacia de Polícia de Imigração',
  },
  {
    code: 'DETRAN',
    name: 'Departamento Estadual de Trânsito',
  },
  {
    code: 'DGPC',
    name: 'Diretoria Geral da Polícia Civil',
  },
  {
    code: 'DIC',
    name: 'Diretoria de Identificação Civil',
  },
  {
    code: 'DICC',
    name: 'Diretoria de Identificação Civil e Criminal',
  },
  {
    code: 'DIREX',
    name: 'Diretoria Executiva',
  },
  {
    code: 'DPF',
    name: 'Departamento de Polícia Federal',
  },
  {
    code: 'DPMAF',
    name: 'Divisão de Polícia Marítima, Aérea e de Fronteiras',
  },
  {
    code: 'DPT',
    name: 'Departamento de Polícia Técnica Geral',
  },
  {
    code: 'DPTC',
    name: 'Departamento de Polícia Técnico Científica',
  },
  {
    code: 'DREX',
    name: 'Delegacia Regional Executiva',
  },
  {
    code: 'DRT',
    name: 'Delegacia Regional do Trabalho',
  },
  {
    code: 'EB',
    name: 'Exército Brasileiro',
  },
  {
    code: 'FAB',
    name: 'Força Aérea Brasileira',
  },
  {
    code: 'FENAJ',
    name: 'Federação Nacional dos Jornalistas',
  },
  {
    code: 'FGTS',
    name: 'Fundo de Garantia do Tempo de Serviço',
  },
  {
    code: 'FIPE',
    name: 'Fundação Instituto de Pesquisas Econômicas',
  },
  {
    code: 'FLS',
    name: 'Fundação Lyndolpho Silva',
  },
  {
    code: 'FUNAI',
    name: 'Fundação Nacional do Índio',
  },
  {
    code: 'GEJSP',
    name: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania',
  },
  {
    code: 'GEJSPC',
    name: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania',
  },
  {
    code: 'GEJUSPC',
    name: 'Gerência de Estado de Justiça, Segurança Pública e Cidadania',
  },
  {
    code: 'GESP',
    name: 'Gerência de Estado de Segurança Pública',
  },
  {
    code: 'GOVGO',
    name: 'Governo do Estado de Goiás',
  },
  {
    code: 'I CLA',
    name: 'Carteira de Identidade Classista',
  },
  {
    code: 'ICP',
    name: 'Instituto de Polícia Científica',
  },
  {
    code: 'IDAMP',
    name: 'Instituto de Identificação Dr. Aroldo Mendes Paiva',
  },
  {
    code: 'IFP',
    name: 'Instituto Félix Pacheco',
  },
  {
    code: 'IGP',
    name: 'Instituto Geral de Perícias',
  },
  {
    code: 'IIACM',
    name: 'Instituto de Identificação Aderson Conceição de Melo',
  },
  {
    code: 'IICC',
    name: 'Instituto de Identificação Civil e Criminal',
  },
  {
    code: 'IICCECF',
    name: 'Instituto de Identificação Civil e Criminal Engrácia da Costa Francisco',
  },
  {
    code: 'IICM',
    name: 'Instituto de Identificação Carlos Menezes',
  },
  {
    code: 'IIGP',
    name: 'Instituto de Identificação Gonçalo Pereira',
  },
  {
    code: 'IIJDM',
    name: 'Instituto de Identificação João de Deus Martins',
  },
  {
    code: 'IIPC',
    name: 'Instituto de Identificação da Polícia Civil',
  },
  {
    code: 'IIPC',
    name: 'Instituto de Identificação Pedro Mello',
  },
  {
    code: 'IIRGD',
    name: 'Instituto de Identificação Ricardo Gumbleton Daunt',
  },
  {
    code: 'IIRHM',
    name: 'Instituto de Identificação Raimundo Hermínio de Melo',
  },
  {
    code: 'IITB',
    name: 'Instituto de Identificação Tavares Buril',
  },
  {
    code: 'IML',
    name: 'Instituto Médico-Legal',
  },
  {
    code: 'INI',
    name: 'Instituto Nacional de Identificação',
  },
  {
    code: 'IPF',
    name: 'Instituto Pereira Faustino',
  },
  {
    code: 'ITCP',
    name: 'Instituto Técnico-Científico de Perícia',
  },
  {
    code: 'ITEP',
    name: 'Instituto Técnico-Científico de Perícia',
  },
  {
    code: 'MAER',
    name: 'Ministério da Aeronáutica',
  },
  {
    code: 'MB',
    name: 'Marinha do Brasil',
  },
  {
    code: 'MD',
    name: 'Ministério da Defesa',
  },
  {
    code: 'MDS',
    name: 'Ministério da Cidadania',
  },
  {
    code: 'MEC',
    name: 'Ministério da Educação e Cultura',
  },
  {
    code: 'MEX',
    name: 'Ministério do Exército',
  },
  {
    code: 'MINDEF',
    name: 'Ministério da Defesa',
  },
  {
    code: 'MJ',
    name: 'Ministério da Justiça',
  },
  {
    code: 'MM',
    name: 'Ministério da Marinha',
  },
  {
    code: 'MMA',
    name: 'Ministério da Marinha',
  },
  {
    code: 'MPAS',
    name: 'Ministério da Previdência e Assistência Social',
  },
  {
    code: 'MPE',
    name: 'Ministério Público Estadual',
  },
  {
    code: 'MPF',
    name: 'Ministério Público Federal',
  },
  {
    code: 'MPT',
    name: 'Ministério Público do Trabalho',
  },
  {
    code: 'MRE',
    name: 'Ministério das Relações Exteriores',
  },
  {
    code: 'MT',
    name: 'Ministério do Trabalho',
  },
  {
    code: 'MTE',
    name: 'Ministério da Economia',
  },
  {
    code: 'MTPS',
    name: 'Ministério do Trabalho e Previdência Social',
  },
  {
    code: 'NUMIG',
    name: 'Núcleo de Polícia de Imigração',
  },
  {
    code: 'OAB',
    name: 'Ordem dos Advogados do Brasil',
  },
  {
    code: 'OMB',
    name: 'Ordens dos Músicos do Brasil',
  },
  {
    code: 'PC',
    name: 'Polícia Civil',
  },
  {
    code: 'PF',
    name: 'Polícia Federal',
  },
  {
    code: 'PGFN',
    name: 'Procuradoria Geral da Fazenda Nacional',
  },
  {
    code: 'PM',
    name: 'Polícia Militar',
  },
  {
    code: 'POLITEC',
    name: 'Perícia Oficial e Identificação Técnica',
  },
  {
    code: 'PRF',
    name: 'Polícia Rodoviária Federal',
  },
  {
    code: 'PTC',
    name: 'Polícia Tecnico-Científica',
  },
  {
    code: 'SCC',
    name: 'Secretaria de Estado da Casa Civil',
  },
  {
    code: 'SCJDS',
    name: 'Secretaria Coordenadora de Justiça e Defesa Social',
  },
  {
    code: 'SDS',
    name: 'Secretaria de Defesa Social',
  },
  {
    code: 'SECC',
    name: 'Secretaria de Estado da Casa Civil',
  },
  {
    code: 'SECCDE',
    name: 'Secretaria de Estado da Casa Civil e Desenvolvimento Econômico',
  },
  {
    code: 'SEDS',
    name: 'Secretaria de Estado da Defesa Social',
  },
  {
    code: 'SEGUP',
    name: 'Secretaria de Estado da Segurança Pública e da Defesa Social',
  },
  {
    code: 'SEJSP',
    name: 'Secretaria de Estado de Justiça e Segurança Pública',
  },
  {
    code: 'SEJUC',
    name: 'Secretaria de Estado da Justica',
  },
  {
    code: 'SEJUSP',
    name: 'Secretaria de Estado de Justiça e Segurança Pública',
  },
  {
    code: 'SEPC',
    name: 'Secretaria de Estado da Polícia Civil',
  },
  {
    code: 'SES',
    name: 'Secretaria de Estado da Segurança',
  },
  {
    code: 'SESC',
    name: 'Secretaria de Estado da Segurança e Cidadania',
  },
  {
    code: 'SESDC',
    name: 'Secretaria de Estado da Segurança, Defesa e Cidadania',
  },
  {
    code: 'SESDEC',
    name: 'Secretaria de Estado da Segurança, Defesa e Cidadania',
  },
  {
    code: 'SESEG',
    name: 'Secretaria Estadual de Segurança',
  },
  {
    code: 'SESP',
    name: 'Secretaria de Estado da Segurança Pública',
  },
  {
    code: 'SESPAP',
    name: 'Secretaria de Estado da Segurança Pública e Administração Penitenciária',
  },
  {
    code: 'SESPDC',
    name: 'Secretaria de Estado de Segurança Publica e Defesa do Cidadão',
  },
  {
    code: 'SESPDS',
    name: 'Secretaria de Estado de Segurança Pública e Defesa Social',
  },
  {
    code: 'SGPC',
    name: 'Superintendência Geral de Polícia Civil',
  },
  {
    code: 'SGPJ',
    name: 'Superintendência Geral de Polícia Judiciária',
  },
  {
    code: 'SIM',
    name: 'Serviço de Identificação da Marinha',
  },
  {
    code: 'SJ',
    name: 'Secretaria da Justiça',
  },
  {
    code: 'SJCDH',
    name: 'Secretaria da Justiça e dos Direitos Humanos',
  },
  {
    code: 'SJDS',
    name: 'Secretaria Coordenadora de Justiça e Defesa Social',
  },
  {
    code: 'SJS',
    name: 'Secretaria da Justiça e Segurança',
  },
  {
    code: 'SJTC',
    name: 'Secretaria da Justiça do Trabalho e Cidadania',
  },
  {
    code: 'SJTS',
    name: 'Secretaria da Justiça do Trabalho e Segurança',
  },
  {
    code: 'SNJ',
    name: 'Secretaria Nacional de Justiça / Departamento de Estrangeiros',
  },
  {
    code: 'SPMAF',
    name: 'Serviço de Polícia Marítima, Aérea e de Fronteiras',
  },
  {
    code: 'SPTC',
    name: 'Secretaria de Polícia Técnico-Científica',
  },
  {
    code: 'SRDPF',
    name: 'Superintendência Regional do Departamento de Polícia Federal',
  },
  {
    code: 'SRF',
    name: 'Receita Federal',
  },
  {
    code: 'SRTE',
    name: 'Superintendência Regional do Trabalho',
  },
  {
    code: 'SSDC',
    name: 'Secretaria da Segurança, Defesa e Cidadania',
  },
  {
    code: 'SSDS',
    name: 'Secretaria da Segurança e da Defesa Social',
  },
  {
    code: 'SSI',
    name: 'Secretaria de Segurança e Informações',
  },
  {
    code: 'SSP',
    name: 'Secretaria de Segurança Pública',
  },
  {
    code: 'SSPCGP',
    name: 'Secretaria de Segurança Pública e Coordenadoria Geral de Perícias',
  },
  {
    code: 'SSPDC',
    name: 'Secretaria de Segurança Pública e Defesa do Cidadão',
  },
  {
    code: 'SSPDS',
    name: 'Secretaria de Segurança Pública e Defesa Social',
  },
  {
    code: 'SSPPC',
    name: 'Secretaria de Segurança Pública Polícia Civil',
  },
  {
    code: 'SUSEP',
    name: 'Superintendência de Seguros Privados',
  },
  {
    code: 'SUSEPE',
    name: 'Superintendência dos Serviços Penitenciários',
  },
  {
    code: 'TJ',
    name: 'Tribunal de Justiça',
  },
  {
    code: 'TJAEM',
    name: 'Tribunal Arbitral e Mediação dos Estados Brasileiros',
  },
  {
    code: 'TRE',
    name: 'Tribunal Regional Eleitoral',
  },
  {
    code: 'TRF',
    name: 'Tribunal Regional Federal',
  },
  {
    code: 'TSE',
    name: 'Tribunal Superior Eleitoral',
  },
  {
    code: 'XXX',
    name: 'Orgão Estrangeiro',
  },
  {
    code: 'ZZZ',
    name: 'Outro',
  },
];
