const { default: api } = require('services/api');

export const ListLead = async ({
  dateStart = null,
  dateEnd = null,
  cpf = null,
  page = 0,
  messageReturnService = null
}) => {
  return await api.get('v1/report/lead', {
    params: {
      dateStart,
      dateEnd,
      cpf,
      page,
      messageReturnService
    }
  });
};

export const ListLeadCsv = async ({
  dateStart = null,
  dateEnd = null,
  cpf = null,
  page = 0,
  messageReturnService = null
}) => {
  return await api.get('v1/report/lead/csv', {
    params: {
      dateStart,
      dateEnd,
      cpf,
      page,
      messageReturnService
    }
  });
};
