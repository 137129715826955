import QrCodeComponent from 'components/Form/QrCodeComponent';
import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import IndicationListComponent from './IndicationListComponent';

const FormPageComponentView = ({
  register,
  setRegister,
  handleSubmit,
  landingPageId,
  url,
}) => {
  const [tabActive, setTabActive] = useState('1');

  return (
    <>
      <Card className="shadow mb-2">
        <CardHeader className="p-0">
          <Nav tabs>
            <NavItem
              onClick={() => {
                setTabActive('1');
              }}
            >
              <NavLink className={tabActive == '1' ? 'active' : ''}>
                <h3 className="m-0">
                  <i className="fas fa-cog" /> CONFIGURAÇÃO
                </h3>
              </NavLink>
            </NavItem>
            <NavItem
              onClick={() => {
                setTabActive('2');
              }}
            >
              <NavLink className={tabActive == '2' ? 'active' : ''}>
                <h3 className="m-0">
                  <i className="fas fa-link" /> LINKS DE INDICAÇÃO / VENDEDORES
                </h3>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={tabActive}>
            <TabPane tabId="1" className="p-2">
              <Card className="shadow">
                <CardHeader>
                  <h2>
                    <i className="fas fa-cogs" /> Configurar - Auto Contratação
                    Crefaz
                  </h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <Card className="shadow">
                        <CardHeader>
                          <h3 className="m-0">
                            <i className="fas fa-star" /> Marketing
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>ID PIXEL</Label>
                                <Input
                                  value={register?.pixel}
                                  onChange={(ev) => {
                                    setRegister({
                                      ...register,
                                      pixel: ev.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            {/* <Col md="4">
                      <FormGroup>
                        <Label>ID VERIFICAÇÃO PIXEL</Label>
                        <Input
                          value={register?.domainVerification}
                          onChange={(ev) => {
                            setRegister({
                              ...register,
                              domainVerification: ev.target.value
                            });
                          }}
                        />
                      </FormGroup>
                    </Col> */}
                            <Col md="6">
                              <FormGroup>
                                <Label>ID TAGMANAGER</Label>
                                <Input
                                  value={register?.tagManager}
                                  onChange={(ev) => {
                                    setRegister({
                                      ...register,
                                      tagManager: ev.target.value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <QrCodeComponent url={register.url} className="shadow" />
                    </Col>
                  </Row>
                  <Card className="shadow mt-4">
                    <CardHeader>
                      <h3 className="m-0">
                        <i className="fas fa-cogs" /> Configuração
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label>Exibir Tel. Suporte</Label>
                            <select
                              className="form-control"
                              value={Number(
                                register?.infoPage?.showPhoneSuport
                              )}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  infoPage: {
                                    ...register.infoPage,
                                    showPhoneSuport: ev.target.value,
                                  },
                                });
                              }}
                            >
                              <option value={1}>SIM</option>
                              <option value={0}>NÃO</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Telefone Suporte</Label>
                            <Input
                              value={register?.contactPhone}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  contactPhone: ev.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Texto msg suporte</Label>
                            <Input
                              value={register?.textContactPhone}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  textContactPhone: ev.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Apenas Pré-Análise</Label>
                            <select
                              className="form-control"
                              value={register?.infoPage?.onlySimulation}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  infoPage: {
                                    ...register.infoPage,
                                    onlySimulation: ev.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Selecione...</option>
                              <option value="0">Não</option>
                              <option value="1">Sim</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
                <CardFooter>
                  <Button color="success" onClick={handleSubmit}>
                    <i className="fas fa-save" /> SALVAR
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => {
                      setRegister({});
                    }}
                  >
                    VOLTAR
                  </Button>
                </CardFooter>
              </Card>
            </TabPane>

            <TabPane tabId="2" className="p-2">
              <IndicationListComponent
                landingPageId={landingPageId}
                url={url}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default FormPageComponentView;
