export const BackgroundImgEnum = [
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-1.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-2.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-3.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-4.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-5.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-6.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-7.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-8.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-9.jpeg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-10.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-11.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-12.jpg',
  'https://storage.googleapis.com/simulador-fgts/img/background/bg-13.jpg'
];
