import api from 'services/api';
import Swal from 'sweetalert2';

export const insertIndication = async (data) => {
  return await api.post('v1/promoter/indication', data);
};

export const listIndication = async (data) => {
  return await api.get('v1/promoter/indication', {
    params: data,
  });
};

export const removeIndication = async (id) => {
  return await api.put(`v1/promoter/indication/active-inactive/${id}`);
};

export const filterProposalByIndication = async (data) => {
  return await api.get(`v1/promoter/indication/report`, {
    params: data,
  });
};

export const reportSalePerIndication = async (data) => {
  return await api.get(`v1/promoter/indication/sale`, {
    params: data,
  });
};

export const updateIndication = async (id, data) => {
  return await api.put(`v1/promoter/indication/${id}`, data);
};

export const generateCsv = async (filter) => {
  return await api.get('v1/promoter/indication/csv', { params: filter });
};

export const searchIndicationService = async (filter) => {
  return await api.get('v1/promoter/indication/search', { params: filter });
};

export const ReportIndicationConversionService = async (filter) => {
  return await api.get('v2/indication/report/conversion', { params: filter });
};

export const saveIndicationGroupService = async (data) => {
  return await api
    .post(`v3/indication/group`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};

export const listIndicationGroupService = async () => {
  return await api
    .get(`v3/indication/group`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};

export const getIndicationGroupByIdService = async (id) => {
  return await api
    .get(`v3/indication/group/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    });
};
