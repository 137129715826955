import { useEffect } from 'react';
import { tableComissionService } from 'services/Comission/SellerComissionTableService';
import Header from 'components/Headers/Header';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import { useState } from 'react';
import { maskDate } from 'utils/mask';
import { moneyMask } from 'utils/mask';
import Swal from 'sweetalert2';

const TableComissionView = () => {
  const [filter, setFilter] = useState({
    serviceId: 2,
    dateComission: null
  });
  const [result, setResult] = useState([]);

  const handleList = async () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    tableComissionService(filter)
      .then(({ data }) => {
        setResult(data?.resultSet ?? []);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Tabela de comissionamento" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h3>Tabela de comissionamento</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Data base de comissionamento</Label>
                  <Input
                    placeholder="--/--/----"
                    value={filter.dateComission}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateComission: maskDate(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleList}>
              Listar
            </Button>
          </CardFooter>
        </Card>
        <Card className="shadow mt-4">
          <CardHeader>Listagem</CardHeader>
          <Table responsive>
            <thead>
              <tr className="bg-secondary">
                <th>FINANCEIRA</th>
                <th>PRODUTO</th>
                <th>PERÍODO VALIDADE</th>
                <th>VALOR</th>
                <th>% COMISSIONAMENTO</th>
              </tr>
            </thead>
            <tbody>
              {result.map((register, key) => (
                <>
                  <tr>
                    <td colSpan={5} className="pb-0">
                      <h4 className="m-0">
                        <Alert color="secondary">{register.name}</Alert>
                      </h4>
                    </td>
                  </tr>
                  <tr key={key}>
                    <td className="border-top-0">{register.bankName}</td>
                    <td className="border-top-0">{register.serviceName}</td>
                    <td className="border-top-0">
                      <p className="m-0">
                        Perído inicial: <strong>{register.dateStart}</strong>
                      </p>
                      <p className="m-0">
                        Perído final: <strong>{register.dateEnd}</strong>
                      </p>
                    </td>
                    <td className="border-top-0">
                      {register.valueStart > 0 && (
                        <>
                          <p className="m-0">
                            Valor Inicial:{' '}
                            <strong>R$ {moneyMask(register.valueStart)}</strong>
                          </p>
                          <p className="m-0">
                            Valor final:{' '}
                            <strong>R$ {moneyMask(register.valueEnd)}</strong>
                          </p>
                        </>
                      )}
                    </td>
                    <td className="border-top-0">
                      <p className="font-weight-bold">
                        {register.valueComission} %
                      </p>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default TableComissionView;
