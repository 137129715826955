import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import Swal from 'sweetalert2';

const CommonQuestionsComponentView = ({
  form = {},
  setForm = () => {},
  questions,
  setQuestions = () => {}
}) => {
  const handleAdd = () => {
    console.log(questions);
    setQuestions([
      ...questions,
      {
        title: '',
        text: ''
      }
    ]);
  };

  const handleRemove = (key) => {
    if (questions.length <= 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops...',
        text: 'Você não pode remover todas as dúvidas.'
      });
      return;
    }

    setQuestions(
      questions.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  return (
    <>
      <Card className="shadow mt-3">
        <CardHeader>
          <h3>
            <i className="fas fa-question" /> Perguntas Frequentes
          </h3>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label>Exibir</Label>
            <select
              className="form-control"
              value={form?.infoPage?.useQuestions ?? 0}
              onChange={(ev) => {
                setForm({
                  ...form,
                  infoPage: {
                    ...form?.infoPage,
                    useQuestions: ev.target.value ?? 1
                  }
                });
              }}
            >
              <option value={1}>SIM</option>
              <option value={0}>NÃO</option>
            </select>
          </FormGroup>
          {form?.infoPage?.useQuestions > 0 &&
            questions.map((question, key) => (
              <Card className="shadow mb-2" key={key}>
                <CardBody>
                  <FormGroup>
                    <Label>Titulo</Label>
                    <Input
                      type="text"
                      value={question.title}
                      onChange={(ev) => {
                        const newQuestion = [...questions];
                        questions[key].title = ev.target.value;
                        setQuestions(newQuestion);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Texto</Label>
                    <textarea
                      className="form-control"
                      value={question.text}
                      onChange={(ev) => {
                        const newQuestion = [...questions];
                        questions[key].text = ev.target.value;
                        setQuestions(newQuestion);
                      }}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  {questions.length - 1 === key && (
                    <Button color="primary" size="sm" onClick={handleAdd}>
                      <i className="fas fa-plus" /> Adicionar nova dúvida
                    </Button>
                  )}
                  {questions.length > 1 && (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleRemove(key)}
                    >
                      <i className="fas fa-trash-alt" /> Remover
                    </Button>
                  )}
                </CardFooter>
              </Card>
            ))}
        </CardBody>
      </Card>
    </>
  );
};

export default CommonQuestionsComponentView;
