import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { BodyTitle } from 'views/Profile/styles';
import ModalImgPeopleComponent from './ModalImgPeopleComponent';
import { useState } from 'react';
import ModalImgBackgroundComponent from './ModalImgBackgroundComponent';

const LandingPageColorsComponent = ({
  landingPage,
  setLandingPage,
  isConfigBackground = true
}) => {
  const [openModalImgHeader, setOpenModalImgHeader] = useState(false);
  const [openModalBgHeader, setOpenModalBgHeader] = useState(false);
  return (
    <>
      <Card className="shadow mb-4">
        <CardHeader>
          <Row>
            <Col>
              <h2 className="m-0">Landing Page - Personalizar</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <BodyTitle>TEXTO PRINCIPAL</BodyTitle>
          <Row>
            <Col>
              <FormGroup>
                <Label>Titulo</Label>
                <Input
                  value={landingPage?.infoPage?.title}
                  onChange={(ev) => {
                    setLandingPage({
                      ...landingPage,
                      infoPage: {
                        ...landingPage?.infoPage,
                        title: ev.target.value
                      }
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <BodyTitle>Cores</BodyTitle>
          <Row>
            <Col>
              <FormGroup>
                <Label>Cor Principal</Label>
                <Input
                  value={landingPage?.infoPage?.colorPrimary}
                  type="color"
                  onChange={(ev) => {
                    setLandingPage({
                      ...landingPage,
                      infoPage: {
                        ...landingPage?.infoPage,
                        colorPrimary: ev.target.value
                      }
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Cor Secondaria</Label>
                <Input
                  value={landingPage?.infoPage?.colorSecondary}
                  type="color"
                  onChange={(ev) => {
                    setLandingPage({
                      ...landingPage,
                      infoPage: {
                        ...landingPage?.infoPage,
                        colorSecondary: ev.target.value
                      }
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <BodyTitle>Imagem destaque - Principal</BodyTitle>
              <Button
                color="success"
                onClick={() => {
                  setOpenModalImgHeader(true);
                }}
              >
                SELECIONAR
              </Button>
              {landingPage?.image && (
                <Card className="shadow mt-4">
                  <CardBody>
                    <img
                      src={landingPage.image}
                      alt="imagem de destaque"
                      height={180}
                    />
                  </CardBody>
                </Card>
              )}
              <ModalImgPeopleComponent
                isOpen={openModalImgHeader}
                setIsOpen={setOpenModalImgHeader}
                item={landingPage.image}
                handleSubmit={(e) => {
                  setLandingPage({
                    ...landingPage,
                    image: e
                  });
                }}
              />
            </Col>
            {isConfigBackground && (
              <Col md="6">
                <BodyTitle>Plano de fundo - Principal</BodyTitle>
                <Button
                  color="success"
                  onClick={() => {
                    setOpenModalBgHeader(true);
                  }}
                >
                  SELECIONAR
                </Button>
                {landingPage?.infoPage?.background && (
                  <Card className="shadow mt-4">
                    <CardBody>
                      <img
                        src={landingPage?.infoPage?.background}
                        alt="imagem de destaque"
                        height={180}
                      />
                    </CardBody>
                  </Card>
                )}
                <ModalImgBackgroundComponent
                  isOpen={openModalBgHeader}
                  setIsOpen={setOpenModalBgHeader}
                  item={landingPage?.infoPage?.background}
                  handleSubmit={(e) => {
                    setLandingPage({
                      ...landingPage,
                      infoPage: {
                        ...landingPage.infoPage,
                        background: e
                      }
                    });
                  }}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default LandingPageColorsComponent;
