import Header from 'components/Headers/Header';
import { TableBody } from 'components/Table';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableData } from 'components/Table';
import { TableHeader } from 'components/Table';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
  UncontrolledDropdown
} from 'reactstrap';
import { showStatusContractSignService } from 'services/Register/ContractSign';
import { IndexContractSignService } from 'services/Register/ContractSign';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import { maskCPF } from 'utils/mask';

const ContractSignReportView = () => {
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    document: null,
    limit: 15,
    previusLimit: 0
  });
  const [registers, setRegisters] = useState([]);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);

  const handleFilter = (isMore = false) => {
    Swal.fire({
      title: 'Buscando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const filterSearch = {
      ...filter
    };
    console.log(isMore);
    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0
      });
    }

    IndexContractSignService({
      ...filterSearch
    })
      .then(({ data }) => {
        Swal.close();
        if (isMore) {
          setRegisters([...registers, ...data.resultSet.result]);
          setFilter({
            ...filter,
            previusLimit: data.resultSet.limit
          });
          setShowBtnMoreRegisters(data?.resultSet?.result?.length > 0);
        } else {
          setRegisters(data.resultSet.result);
          setFilter({
            ...filter,
            previusLimit: 0
          });
          setShowBtnMoreRegisters(data?.resultSet?.result?.length >= 15);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleGetStatusContract = (id, key) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    showStatusContractSignService(id)
      .then(({ data }) => {
        const status =
          data?.resultSet?.status == 'signed' ? 'Assinado' : 'Pendente';

        let newRegisters = [...registers];
        registers[key].status = status;
        setRegisters(newRegisters);

        Swal.fire({
          icon: 'info',
          title: 'Status Atual',
          text: `${status}`
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleFilter();
  }, []);

  return (
    <>
      <Header titlePage="IZISIGN - LISTAGEM DE CONTRATOS" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>IZISIGN - LISTAGEM DE CONTRATOS</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>CPF do Cliente</Label>
                  <Input
                    id="cpf"
                    name="cpf"
                    type="text"
                    value={filter.document}
                    placeholder="000.000.000-00"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        document: maskCPF(ev.target.value)
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value
                      });
                    }}
                  >
                    <option value="">TODOS</option>
                    <option value="pending">PENDENTE</option>
                    <option value="signed">ASSINADO</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              onClick={() => {
                handleFilter();
              }}
            >
              Buscar
            </Button>
          </CardFooter>
        </Card>
        {registers.length <= 0 && (
          <>
            <Alert color="secondary shadow mt-3">
              Não foram identificados registros para esta consulta
            </Alert>
          </>
        )}

        {registers.length > 0 && (
          <>
            <Card className="mt-3">
              <CardHeader>Registros</CardHeader>
              <div className="table-responsive">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Data Cadastro</TableHead>
                      <TableHead>Qtd. Token Enviado</TableHead>
                      <TableHead>Custo</TableHead>
                      <TableHead>Cliente</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Opções</TableHead>
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {registers.map((register, key) => (
                      <>
                        <TableRow key={key}>
                          <TableData>{register.createdAt}</TableData>
                          <TableData>
                            <Badge color="success" className="font-12">
                              {register.qtdTokenSend}
                            </Badge>
                          </TableData>
                          <TableData>
                            <h2>R$ {moneyMask(register.valueAmount)}</h2>
                          </TableData>
                          <TableData>
                            <p className="m-0">
                              Nome:{' '}
                              <span className="font-weight-bold">
                                {register.name}
                              </span>
                            </p>
                            <p className="m-0">
                              CPF:{' '}
                              <span className="font-weight-bold">
                                {maskCPF(register.document)}
                              </span>
                            </p>
                          </TableData>
                          <TableData>
                            <Badge
                              className="font-12"
                              color={
                                register.status == 'Assinado'
                                  ? 'success'
                                  : 'warning'
                              }
                            >
                              {register.status}
                            </Badge>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  onClick={() =>
                                    handleGetStatusContract(register.id, key)
                                  }
                                >
                                  <i className="fas fa-signal" /> Buscar Status
                                </DropdownItem>
                                <DropdownItem
                                  href={register.urlSign}
                                  target="_blank"
                                >
                                  <i className="fas fa-file-signature" /> Link
                                  para assinatura
                                </DropdownItem>
                                {register.urlContractSigned && (
                                  <DropdownItem
                                    href={register.urlContractSigned}
                                    target="_blank"
                                  >
                                    <i className="fas fa-signature" /> Contrato
                                    Assinado
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                  {showBtnMoreRegisters && (
                    <TableBody>
                      <TableRow>
                        <td colSpan={5}>
                          <Button
                            className="w-100"
                            onClick={() => {
                              setFilter({ ...filter });
                              handleFilter(true);
                            }}
                          >
                            Carregar mais...
                          </Button>
                        </td>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default ContractSignReportView;
