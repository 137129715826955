import InputComponent from 'components/Form/Input/InputComponent';
import FormPersonDataComponent from 'components/Form/Register/FormPersonDataComponent';
import FormRegisterAddressComponent from 'components/Form/Register/FormRegisterAddressComponent';
import FormRegisterBankComponent from 'components/Form/Register/FormRegisterBankComponent';
import { ProposalPersonalCreditCrefazService } from 'services/Digitation/PersonalCreditService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
const {
  CardFooter,
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Row
} = require('reactstrap');
const { maskDate } = require('utils/mask');

const FormDigitationCompleteRegisterCrefazComponent = ({
  form,
  setForm = () => {},
  stage,
  setStage = () => {}
}) => {
  const handleSubmit = () => {
    Swal.fire({
      title: 'Selecionando oferta',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    ProposalPersonalCreditCrefazService(form.simulationId, form)
      .then(({ data }) => {
        if (!data?.status) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data?.resultSet?.message
          });
          return;
        }

        Swal.fire({
          icon: 'success',
          title: 'Proposta finalizada com sucesso',
          html: `
        <h3><strong>Nº da Proposta: ${data?.resultSet?.numberProposal}</strong>
        `
        }).then(() => {
          setStage(1);
          setForm({});
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  if (Number(stage) !== 6) {
    return <></>;
  }
  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h2>
            <i className="fas fa-user-edit" /> Completar Cadastro
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <InputComponent
                label="Nome completo"
                isRequired
                value={form.name}
                onChange={(ev) => {
                  setForm({ ...form, name: ev.target.value });
                }}
              />
            </Col>
            <Col md="3">
              <InputComponent
                label="Data de nasimento"
                isRequired
                value={form.birthDate}
                placeholder="--/--/----"
                onChange={(ev) => {
                  setForm({ ...form, birthDate: maskDate(ev.target.value) });
                }}
              />
            </Col>
            <Col md="3">
              <InputComponent
                label="WhatsApp"
                isRequired
                type="phone"
                value={form.phone}
                placeholder="(00) 0 0000-0000"
                onChange={(ev) => {
                  setForm({ ...form, phone: ev.target.value });
                }}
              />
            </Col>
          </Row>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-id-card" /> Informações pessoais
              </h3>
            </CardHeader>
            <CardBody>
              <FormPersonDataComponent register={form} setRegister={setForm} />
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-map-marker-alt" /> Endereço
              </h3>
            </CardHeader>
            <CardBody>
              <FormRegisterAddressComponent
                register={form}
                setRegister={setForm}
                isRequired={true}
                cepIsReadOnly={true}
              />
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-university" /> Dados bancários
              </h3>
            </CardHeader>
            <CardBody>
              <FormRegisterBankComponent
                register={form}
                setRegister={setForm}
              />
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardHeader>
              <h3>
                <i className="fas fa-user-friends" /> Referências Pessoais
              </h3>
            </CardHeader>
            <CardBody>
              <Card className="shadow">
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputComponent
                        label="Nome"
                        isRequired
                        value={form.referenceName1}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            referenceName1: ev.target.value
                          });
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <InputComponent
                        label="Contato telefônico"
                        isRequired
                        value={form.referencePhone1}
                        placeholder="(00) 0 0000-0000"
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            referencePhone1: phoneMask(ev.target.value)
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="shadow mt-3">
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputComponent
                        label="Nome"
                        isRequired
                        value={form.referenceName2}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            referenceName2: ev.target.value
                          });
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <InputComponent
                        label="Contato telefônico"
                        isRequired
                        value={form.referencePhone2}
                        placeholder="(00) 0 0000-0000"
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            referencePhone2: phoneMask(ev.target.value)
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={handleSubmit}>
            Atualizar cliente
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};
export default FormDigitationCompleteRegisterCrefazComponent;
