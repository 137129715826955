import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  CardBody,
  Alert,
  Collapse,
  Button
} from 'reactstrap';

import Header from 'components/Headers/Header';
import BankList from './component/bankList';
import { listBanksLibertyService } from 'services/AccessBank/AccessBankService';
import { useEffect } from 'react';
import { useState } from 'react';

const AccessBank = () => {
  const [list, setList] = useState([]);
  const [openIntegration, setOpenIntegration] = useState(false);
  const [filter, setFilter] = useState('');

  const handleList = (filter) => {
    listBanksLibertyService(filter).then(({ data }) => {
      setList(data?.resultSet ?? []);
    });
  };

  useEffect(() => {
    handleList(filter);
  }, [filter]);

  return (
    <>
      <Header showCards={false} titlePage="Marketplace - Integrações Bancos" />
      <Container className="mt--8" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h1 className="mb-0">Marketplace - Integrações Bancos</h1>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className='pt-2'>

                {list.length > 0 && (
                  <>
                    <div className='w-100 align-items-start mb-4'>
                      <Button
                        color={filter === '' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('')}
                      >
                        TODOS
                      </Button>
                      <Button
                        color={filter === 'FGTS' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('FGTS')}
                      >
                        FGTS
                      </Button>
                      <Button
                        color={filter === 'CONSIGNED' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('CONSIGNED')}
                      >
                        CONSIGNADO
                      </Button>
                      <Button
                        color={filter === 'PERSONAL_CREDIT' ? 'primary' : 'secondary'}
                        onClick={() => setFilter('PERSONAL_CREDIT')}
                      >
                        CRÉDITO PESSOAL
                      </Button>
                    </div>
                  </>
                )}

                {list.length <= 0 && (
                  <Alert color="secondary">Carregando...</Alert>
                )}
                {list.length > 0 && (
                  <>
                    <Row>
                      {list.map((bank, key) => (
                        <>
                          {bank.isHide === '0' && (
                            <Col
                              xl="4"
                              md="6"
                              className=" d-flex align-items-stretch"
                              key={key}
                            >
                              <BankList
                                bank={bank.febraban}
                                isNew={bank.isNew > 0}
                                hasFgts={bank.hasFgts > 0}
                                hasPersonalCredit={bank.hasPersonalCredit > 0}
                                hasINSS={bank.hasINSS > 0}
                                isRecommended={bank.isRecommended > 0}
                                hasLinkFormalization={
                                  bank.hasLinkFormalization > 0
                                }
                                updateStatusProposal
                              />
                            </Col>
                          )}
                        </>
                      ))}
                    </Row>
                    <Collapse isOpen={openIntegration}>
                      <Row>
                        {list.map((bank, key) => (
                          <>
                            {bank.isHide === '1' && (
                              <Col
                                xl="4"
                                md="6"
                                className=" d-flex align-items-stretch"
                                key={key}
                              >
                                <BankList
                                  bank={bank.febraban}
                                  isNew={bank.isNew > 0}
                                  hasFgts={bank.hasFgts > 0}
                                  hasPersonalCredit={bank.hasPersonalCredit > 0}
                                  hasINSS={bank.hasINSS > 0}
                                  isRecommended={bank.isRecommended > 0}
                                  hasLinkFormalization={
                                    bank.hasLinkFormalization > 0
                                  }
                                  updateStatusProposal
                                />
                              </Col>
                            )}
                          </>
                        ))}
                      </Row>
                    </Collapse>
                    <Button
                      color="info"
                      className="w-100"
                      onClick={() => {
                        setOpenIntegration(!openIntegration);
                      }}
                    >
                      {!openIntegration ? (
                        <>
                          <i className="fas fa-eye" /> Exibir mais integrações
                        </>
                      ) : (
                        <>
                          <i className="fas fa-eye-slash" /> Ocultar integrações
                        </>
                      )}
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AccessBank;
