import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
  TableHead,
  TableHeader,
  TableBody,
  TableData,
  TableRow,
  Table
} from 'components/Table';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { listService } from 'services/Comission/SellerComissionTableService';
import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';
import { inactiveService } from 'services/Comission/SellerComissionTableService';
import { useHistory } from 'react-router-dom';

const ComissionTableView = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({
    status: 1
  });
  const [result, setResult] = useState([]);

  const handleList = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    listService(filter)
      .then((res) => {
        Swal.close();
        setResult(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  const handleInactiveTableComission = (id) => {
    Swal.fire({
      title: 'Inativando Registro',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    inactiveService(id)
      .then(() => {
        Swal.close();
        handleList();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="COMISSIONAMENTO - TABELA DE COMISSÃO" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <Title>
              <i className="fas fa-percentage" /> Filtro - Tabela de Comissão
            </Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período Cadastro</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Nome da Tabela</Label>
                  <Input
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        name: ev.target.value
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value
                      });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">UY3</option>
                    <option value="329-5">GRANDINOBANK</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Produto</Label>
                  <select className="form-control">
                    <option value="">FGTS</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value
                      });
                    }}
                  >
                    <option value="1" selected>
                      Ativo
                    </option>
                    <option value="0">Inativo</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleList}>
              Buscar
            </Button>
            <Button
              color="success"
              onClick={() => {
                history.push('/admin/comission/table/register');
              }}
            >
              Novo Registro
            </Button>
          </CardFooter>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>
            <Title>Tabelas</Title>
          </CardHeader>
          {result.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Data Cadastro</TableHead>
                  <TableHead>Nome</TableHead>
                  <TableHead>Faixa Período</TableHead>
                  <TableHead>Incentivos</TableHead>
                  <TableHead>Financeira</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {result.map((register, key) => (
                  <TableRow key={key}>
                    <TableData>{register.createdAt}</TableData>
                    <TableData>{register.name}</TableData>
                    <TableData>
                      de <strong>{register.dateStart}</strong> á{' '}
                      <strong>{register.dateEnd}</strong>
                    </TableData>
                    <TableData>
                      <h3>{register.valueComission}%</h3>
                    </TableData>
                    <TableData>
                      {register.febrabanId} - {register.nameFebraban}
                    </TableData>
                    <TableData>
                      {register.active > 0 ? (
                        <>
                          <Badge color="success">ATIVO</Badge>
                        </>
                      ) : (
                        <>
                          <Badge color="error">INATIVO</Badge>
                        </>
                      )}
                    </TableData>
                    <TableData>
                      <UncontrolledDropdown>
                        <DropdownToggle caret color="secondary">
                          Opções
                        </DropdownToggle>
                        <DropdownMenu dark>
                          <DropdownItem
                            onClick={() => {
                              handleInactiveTableComission(register.id);
                            }}
                          >
                            <i className="far fa-eye-slash" /> Desativar Tabela
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert color="secondary">
              Não foram identificados registros para esta consulta
            </Alert>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ComissionTableView;
