import api from 'services/api';
import Swal from 'sweetalert2';

export const showCredentialExternalService = async (name) => {
  return await api
    .get(`v3/credential/external/${name}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
};

export const saveCredentialExternalService = async (
  data = {
    name: null,
    login: null,
    password: null,
    config: null,
    active: null
  }
) => {
  return await api
    .post(`v3/credential/external`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
};

export const showAutomationMessageService = async (name) => {
  return await api
    .get(`v3/automation/message/${name}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
};

export const saveAutomationMessageService = async (
  data = {
    name: null,
    login: null,
    password: null,
    config: null,
    active: null
  }
) => {
  return await api
    .post(`v3/automation/message`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
};
