import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
  TableHead,
  TableHeader,
  TableBody,
  TableData,
  TableRow,
  Table,
} from 'components/Table';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { Title } from 'views/Dashboard/styles';
import { useHistory } from 'react-router-dom';
import { listComissionProposal } from 'services/Comission/SellerComissionTableService';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { moneyMask } from 'utils/mask';
import { scheduleExcel } from 'services/Report/Schedule';
import InputIndicationView from 'components/Form/Input/InputIndicationView';

const ComissionProposalView = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({
    status: 2,
  });
  const [result, setResult] = useState([]);
  const [pages, setPages] = useState({
    pages: 0,
  });

  const handleList = (page = 0) => {
    const newFilter = {
      ...filter,
      page: typeof page == 'number' ? page : 0,
    };

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listComissionProposal(newFilter)
      .then((res) => {
        Swal.close();
        const { data, pagination } = res.data.resultSet;
        setResult(data);
        setPages(pagination);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="COMISSIONAMENTO - Propostas" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <Title>
              <i className="fas fa-percentage" /> Filtro - Comissionamento
              Propostas
            </Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período Cadastro</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Financeira</Label>
                  <select
                    id="bank"
                    name="bank"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        febrabanId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="626">C6 Consignado</option>
                    <option value="149">FACTA Financeira</option>
                    <option value="074">SAFRA</option>
                    <option value="623">PAN</option>
                    <option value="389">BANCO MERCANTIL</option>
                    <option value="329">iCred</option>
                    <option value="465">Quero+Crédito</option>
                    <option value="318">BMG</option>
                    <option value="329-2">SABEMI</option>
                    <option value="324">LOTUS MAIS</option>
                    <option value="457">457 - UY3</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nº Proposta</Label>
                  <Input
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        numberProposal: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Produto</Label>
                  <select className="form-control">
                    <option value="">FGTS</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value,
                      });
                    }}
                  >
                    <option value="2" selected>
                      PAGO
                    </option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <InputIndicationView filter={filter} setFilter={setFilter} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleList}>
              <i className="fas fa-search" /> Buscar
            </Button>
            <Button
              color="success"
              onClick={() => {
                scheduleExcel(8, filter, history);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </Button>
          </CardFooter>
        </Card>

        <Card className="shadow mt-4">
          <CardHeader>
            <Title>Propostas</Title>
          </CardHeader>
          {result.length > 0 ? (
            <>
              <PaginationSelector pages={pages.pages} list={handleList} />
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>DATA CADASTRO</TableHead>
                    <TableHead>PROPOSTA</TableHead>
                    <TableHead>COMISSÃO</TableHead>
                    <TableHead>FINANCEIRA</TableHead>
                    <TableHead>VENDEDOR</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result.map((register, key) => (
                    <TableRow key={key}>
                      <TableData>{register.createdAt}</TableData>
                      <TableData>
                        <p className="m-0">
                          Nº Proposta:{' '}
                          <strong>{register.numberProposal}</strong>
                        </p>
                        {register.numberProposalBank && (
                          <p className="m-0">
                            Nº Proposta Banco:{' '}
                            <strong>{register.numberProposalBank}</strong>
                          </p>
                        )}
                        <p className="m-0">
                          Vlr. Liberado:{' '}
                          <strong>R$ {moneyMask(register.valueLiberty)}</strong>
                        </p>
                        <p className="m-0">
                          Produto: <strong>{register.serviceName}</strong>
                        </p>
                      </TableData>
                      <TableData>
                        <p className="m-0">
                          Tabela: <strong>{register.tableName}</strong>
                        </p>
                        <p className="m-0">
                          Vlr. Comissão:{' '}
                          <strong className="text-success font-18">
                            R$ {moneyMask(register.valueComissionSeller)}
                          </strong>
                        </p>
                      </TableData>
                      <TableData>
                        {register.febrabanId} - {register.nameFebraban}
                      </TableData>
                      <TableData>
                        <p className="m-0">
                          <strong className="text-warning">
                            {register.indicationId} - {register.indicationName}
                          </strong>
                        </p>
                        <p className="m-0">
                          Código:{' '}
                          <strong className="text-warning">
                            {register.indicationUuid}
                          </strong>
                        </p>
                      </TableData>
                      <TableData>
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            <DropdownItem
                              href={`/admin/proposal-details/${
                                register.proposalId ?? 0
                              }`}
                              target="_blank"
                            >
                              <i className="fas fa-edit" /> Detalhes do Contrato
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <PaginationSelector pages={pages.pages} list={handleList} />
            </>
          ) : (
            <Alert color="secondary">
              Não foram identificados registros para esta consulta
            </Alert>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ComissionProposalView;
