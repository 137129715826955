import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  CardBody,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { bulkInsert } from 'services/Consigned/ClientSearchService';

const ImportMargem = () => {
  const [dataSend, setDataSend] = useState([]);

  const handleSubmit = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      showCancelButton: true,
      cancelButtonText: 'NÃO, SAIR',
      confirmButtonText: 'SIM, SALVAR'
    }).then(async (res) => {
      if (!res.isConfirmed) return;

      Swal.fire({
        title: 'Inserindo registros',
        text: 'Inserindo registros',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await bulkInsert(dataSend.cpfs).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Successo',
          text: 'Registro Inserido com sucesso'
        });
      });
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-md-6">
                    <h3 className="mb-0">Enviar contratos</h3>
                  </div>
                  <div className="col-md-6 text-right">
                    <a
                      className="btn btn-md btn-warning"
                      href="/admin/search-margem"
                    >
                      Voltar
                    </a>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Col xl="12">
                  <FormGroup>
                    <Label>CPFs</Label>
                    <textarea
                      className="form-control form-control-md"
                      onBlur={(ev) => {
                        setDataSend({
                          ...dataSend,
                          cpfs: ev.target.value.split(/[,\n;]/)
                        });
                      }}
                    />
                  </FormGroup>
                  <Button color="success" onClick={handleSubmit} block>
                    Importar
                  </Button>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImportMargem;
