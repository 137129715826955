import QrCodeComponent from 'components/Form/QrCodeComponent';
import Header from 'components/Headers/Header.js';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { updateLandingPage } from 'services/User/LandingPage';
import { getLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import LandingPageColorsComponent from './Components/LandingPageColorsComponent';
import CardAfterHeaderComponent from './Components/CardAfterHeaderComponent';
import DescriptionLandingPageComponent from './Components/DescriptionLandingPageComponent';
import BenefitsComponent from './Components/BenefitsComponent';
import { moneyMask } from 'utils/mask';
import CommonQuestionsComponentView from './Components/CommonQuestionsComponentView';
import { phoneMask } from 'utils/mask';
import { BanksEnum } from './Enum/BanksEnum';

const LandingPageMultiProduct2024View = () => {
  const linksDefault = [
    {
      title: 'Antecipação de Saque-aniversário FGTS',
      description: 'Antecipe seu saldo FGTS. A menor taxa do mercado.',
      link: null,
      target: 'blank',
    },
  ];

  const defaultAdvantages = [
    {
      title: 'Crédito',
      description: 'Sem consulta ao SPC e SERASA',
    },
    {
      title: 'Simulação em diversas instituições financeiras',
      description:
        'Receba propostas das melhores instituições financeiras do Brasil',
    },
    {
      title: 'Rapidez no recebimento',
      description: 'Receba a partir de 3 minutos, após a aprovação do crédito',
    },
    {
      title: 'Sem Boletos',
      description: 'Parcelas descontadas direto do seu FGTS',
    },
  ];
  const [links, setLinks] = useState(linksDefault);
  const [advantages, setAdvantages] = useState(defaultAdvantages);
  const [landingPage, setLandingPage] = useState({
    contactPhone: null,
    textContactPhone: null,
    image: null,
    bgPrimary: null,
    bgSecondary: null,
    colorPrimary: null,
    title: null,
    description: null,
    infoPage: {
      useQuestions: 1,
    },
  });
  const [questions, setQuestions] = useState([
    {
      title: '',
      text: '',
    },
  ]);
  const [partners, setPartners] = useState([]);

  const handleGetLandingPage = () => {
    Swal.close();
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLandingPage('LANDINGPAGE_MULTPRODUCT_2024').then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setLandingPage(result);
      setLinks(result?.infoPage?.links ?? linksDefault);
      setAdvantages(result?.infoPage?.advantages ?? defaultAdvantages);
      setPartners(result?.infoPage?.partners ?? []);
      setQuestions(
        result?.infoPage?.questions ?? [
          {
            title: '',
            text: '',
          },
        ]
      );
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...landingPage,
      infoPage: {
        ...landingPage.infoPage,
        links: links,
        advantages: advantages,
        questions: questions,
      },
    };
    updateLandingPage('LANDINGPAGE_MULTPRODUCT_2024', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        }).then(() => {
          handleGetLandingPage();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  useEffect(() => {
    if (
      landingPage.infoPage.partners &&
      landingPage.infoPage.partners.length > 0
    ) {
      setPartners(landingPage.infoPage.partners);
    }
  }, [partners]);

  return (
    <>
      <Header showCards={false} />
      <section className="mt--8 container-fluid">
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h2>Landing Page - MultiProdutos 2024</h2>
              </Col>
              <Col className="text-right">
                <a
                  href={landingPage.url}
                  target="_blank"
                  className="btn btn-link btn-sm"
                  size="sm"
                >
                  <i className="fas fa-external-link-alt" />
                  {` `}
                  Ver Landing Page
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              <h3 className="m-0 text-white">
                Atenção: A izidev não realiza configuração no píxel e
                tagmanager. Não nos responsábilizamos pelo trabalho realizado
                pela equipe de marketing ou terceiros.
              </h3>
            </Alert>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Nome Página</Label>
                      <Input
                        value={landingPage?.nameSlug}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            nameSlug: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID PIXEL</Label>
                      <Input
                        value={landingPage?.infoPage?.pixel}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              pixel: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID VERIFICAÇÃO DOMINIO PIXEL</Label>
                      <Input
                        value={landingPage?.infoPage?.pixelDomainVerification}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              pixelDomainVerification: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID TAG MANAGER</Label>
                      <Input
                        value={landingPage?.infoPage?.tagManager}
                        onChange={(ev) => {
                          setLandingPage({
                            ...landingPage,
                            infoPage: {
                              ...landingPage?.infoPage,
                              tagManager: ev.target.value,
                            },
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodeComponent url={landingPage.url} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2 className="m-0">Informações da página</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Telefone Suporte com DDD</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.contactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        contactPhone: phoneMask(ev.target.value),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Texto Padrão WhatsApp</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={landingPage.textContactPhone}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        textContactPhone: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Instagram</Label>
                  <Input
                    placeholder="Link do instagram"
                    type="url"
                    value={landingPage?.infoPage?.instagram}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          instagram: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Facebook</Label>
                  <Input
                    placeholder="Link do Facebook"
                    type="url"
                    value={landingPage?.infoPage?.facebook}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          facebook: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Link Youtube</Label>
                  <Input
                    placeholder="Link do Youtube"
                    type="url"
                    value={landingPage?.infoPage?.youtube}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          youtube: ev.target.value,
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Crédito total liberado</Label>
                  <Input
                    placeholder=""
                    type="text"
                    value={
                      landingPage?.infoPage?.totalCreditLiberty ?? '500.000,00'
                    }
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          totalCreditLiberty: moneyMask(ev.target.value),
                        },
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Exibir Card mais informações</Label>
                  <select
                    className="form-control"
                    value={landingPage?.infoPage?.showCardInformations ?? 0}
                    onChange={(ev) => {
                      setLandingPage({
                        ...landingPage,
                        infoPage: {
                          ...landingPage?.infoPage,
                          showCardInformations: moneyMask(ev.target.value),
                        },
                      });
                    }}
                  >
                    <option value={1}>SIM</option>
                    <option value={0}>NÃO</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <LandingPageColorsComponent
          landingPage={landingPage}
          setLandingPage={setLandingPage}
        />

        <CardAfterHeaderComponent links={links} setLinks={setLinks} />

        <DescriptionLandingPageComponent
          form={landingPage}
          setForm={setLandingPage}
        />

        <BenefitsComponent
          benefits={advantages}
          setBenefits={setAdvantages}
          links={links}
          setLinks={setLinks}
        />

        <Card className="shadow mt-4">
          <CardHeader>
            <h3>Parceiros</h3>
          </CardHeader>
          <CardBody>
            <Row>
              {BanksEnum.map((bank, key) => (
                <Col key={key} md="2">
                  <Label>
                    <Card className="shadow mg-2 mb-2">
                      <CardBody>
                        <img src={bank.icon} alt={bank.name} width="100%" />
                      </CardBody>
                      <CardFooter>
                        <span className="ml-3">
                          <Input
                            type="checkbox"
                            name="partners"
                            value={key}
                            checked={
                              partners.length > 0 &&
                              partners.some((e) => e.name === bank.name)
                            }
                            onChange={(ev) => {
                              const selectedBank = BanksEnum[ev.target.value];

                              const isBankInList = partners.some(
                                (e) => e.name === selectedBank.name
                              );

                              if (isBankInList) {
                                // Se estiver na lista, remove
                                const updatedPartners = partners.filter(
                                  (e) => e.name !== selectedBank.name
                                );
                                setPartners(updatedPartners);

                                setLandingPage({
                                  ...landingPage,
                                  infoPage: {
                                    ...landingPage.infoPage,
                                    partners: updatedPartners,
                                  },
                                });
                                return;
                              }

                              // Se não estiver na lista, adiciona
                              const updatedPartners = [
                                ...partners,
                                selectedBank,
                              ];
                              setPartners(updatedPartners);

                              setLandingPage({
                                ...landingPage,
                                infoPage: {
                                  ...landingPage.infoPage,
                                  partners: updatedPartners,
                                },
                              });
                            }}
                          />
                          Selecione
                        </span>
                      </CardFooter>
                    </Card>
                  </Label>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>

        <CommonQuestionsComponentView
          form={landingPage}
          setForm={setLandingPage}
          questions={questions}
          setQuestions={setQuestions}
        />

        <Button
          color="success"
          size="lg"
          className="mt-4"
          onClick={handleSubmit}
        >
          SALVAR ALTERAÇÕES
        </Button>
      </section>
    </>
  );
};

export default LandingPageMultiProduct2024View;
