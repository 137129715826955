import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    margin: 16px 0;
  }
`;
