import { Button, Card, CardBody, CardFooter, Input, Label } from 'reactstrap';
import { TagStageEnum } from './EnumStage';
import { TagClientSaveService } from 'services/Register/TagService';
import Swal from 'sweetalert2';
import { TagInactiveService } from 'services/Register/TagService';

const ListModalTagComponent = ({
  isList = true,
  setStage,
  tags = [],
  setTag,
  setTags,
  clientId,
  serviceId,
  handleList,
}) => {
  const handleSelectTag = async (tagId, checked) => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await TagClientSaveService({
      clientId,
      serviceId,
      tagId,
      active: checked ? 1 : 0,
    })
      .then(() => {
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInactive = (tagId) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja excluir? Será irreversível!',
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: 'Sim, continuar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Removendo',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      TagInactiveService(tagId)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Registro removido com sucesso!',
          });
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  if (!isList) {
    return <></>;
  }

  return (
    <>
      <h3>Lista</h3>
      <Card className="shadow">
        <CardBody>
          <div style={{ overflow: 'auto', maxHeight: '400px' }}>
            {tags.length > 0 ? (
              tags.map((tag, key) => (
                <div key={key} className="pl-4 pr-2 mt-1 mb-1">
                  <Label className="w-100">
                    <Input
                      value={tag.id}
                      checked={tag.checked > 0}
                      type="checkbox"
                      className="mt-3 position-relative"
                      style={{ float: 'left' }}
                      onChange={(ev) => {
                        let newTags = [...tags];
                        tags[key].checked = ev.target.checked;
                        setTags(newTags);
                        handleSelectTag(ev.target.value, ev.target.checked);
                      }}
                    />
                    <button
                      className="mt-1 btn btn-sm float-right text-danger"
                      onClick={() => {
                        handleInactive(tag.id);
                      }}
                    >
                      <i className="far fa-trash-alt" />
                    </button>
                    <button
                      className="mt-1 btn btn-sm float-right"
                      onClick={() => {
                        setTag(tag);
                        setStage(TagStageEnum.FORM);
                      }}
                    >
                      <i className="fas fa-edit" />
                    </button>
                    <div className={`cursor-pointer rounded p-2 ${tag.color}`}>
                      {tag.title}
                    </div>
                  </Label>
                </div>
              ))
            ) : (
              <h4 className="text-danger">Não possuem tags cadastradas</h4>
            )}
          </div>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            className="w-100"
            onClick={() => {
              setTag({
                id: null,
                title: null,
                color: null,
              });
              setStage(TagStageEnum.FORM);
            }}
          >
            Criar uma nova etiqueta
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ListModalTagComponent;
