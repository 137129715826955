import { useEffect, useState } from 'react';
import Chart from 'chart.js';
import Swal from 'sweetalert2';
import {
  Card,
  Row,
  Col,
  Alert,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter
} from 'reactstrap';
import { format } from 'date-fns';
import Header from 'components/Headers/Header.js';
import PaginationSelector from './component/PaginationSelector';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableData
} from 'components/Table';
import { chartOptions, parseOptions } from 'variables/charts.js';

import { DataCadastro, Cliente, Valores, Indication } from './tableData';
import {
  CardHeader,
  Title,
  CaretRight,
  ContentWrapper,
  Button,
  MobileTitle,
  Subtitle
} from './styles';
import { filterProposalByIndication } from 'services/Promoter/IndicationService';
import { listIndication } from 'services/Promoter/IndicationService';

const ReportIndication = () => {
  const [tableDetails, setTableDetails] = useState(null);
  const [listData, setListData] = useState([]);

  const [dateStart, setDateStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateEnd, setDateEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [indicationId, setIndicationId] = useState('');
  const [status, setStatus] = useState(2);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const [indicationList, setIndicationList] = useState([]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleList = async (pageSelected = 0) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    setListData([]);
    if (typeof pageSelected == 'number') {
      setPage(pageSelected);
    } else {
      pageSelected = 0;
    }

    await filterProposalByIndication({
      dateStart,
      dateEnd,
      page: pageSelected,
      indicationId: indicationId,
      status
    }).then(async (res) => {
      setListData(res.data.resultSet.data);
      if (res.data.resultSet.data.length > 0) {
        setPages(res.data.resultSet.pagination.pages);
      }
      Swal.close();
    });
  };

  const handleChange = (event) => {
    if (event.target.name === 'dateStart') {
      setDateStart(event.target.value);
    }
    if (event.target.name === 'dateEnd') {
      setDateEnd(event.target.value);
    }
    if (event.target.name === 'indication') {
      setIndicationId(event.target.value);
    }
    if (event.target.name === 'status') {
      setStatus(event.target.value);
    }
  };

  const indication = async () => {
    await listIndication({ limit: 10000, status: 1, simpleHeader: 1 }).then(
      (result) => {
        Swal.close();
        setIndicationList(result?.data?.resultSet?.data ?? []);
      }
    );
  };

  useEffect(() => {
    handleList();
    indication();
  }, []);

  return (
    <>
      <Header />
      <section className="mt--9 container-fluid">
        <Card className="mb-2 shadow">
          <CardHeader>
            <Title>Filtro</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label>Periodo Cadastro</Label>
                  <Row>
                    <Col xl="6">
                      <Input
                        type="date"
                        name="dateStart"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                    </Col>
                    <Col xl="6">
                      <Input
                        type="date"
                        name="dateEnd"
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Parceiro / Indicado</Label>
                  <select
                    className="form-control form-control-sm"
                    name="indication"
                    onChange={handleChange}
                    onBlur={handleChange}
                  >
                    <option value="">Selecione...</option>
                    {indicationList.length
                      ? indicationList.map((register, key) => (
                          <option key={key} value={register.id}>
                            {register.name}
                          </option>
                        ))
                      : ''}
                  </select>
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    id="status"
                    name="status"
                    className="form-control form-control-sm"
                    onChange={handleChange}
                  >
                    <option value="">Todos</option>
                    <option value="2" selected>
                      Pago
                    </option>
                    <option value="3">Em Análise</option>
                    <option value="1">Em Andamento</option>
                    <option value="4">Com Pendência</option>
                    <option value="5">Reprovado</option>
                    <option value="6">Cancelado</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button className="btn btn-success" onClick={handleList}>
              Buscar
            </Button>
          </CardFooter>
        </Card>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader>
                <Title>Vendas</Title>
              </CardHeader>
              {listData.length > 0 ? (
                <>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Data Cadastro</TableHead>
                        <TableHead>Cliente</TableHead>
                        <TableHead>Indicação</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="d-none d-lg-table-cell">
                          Valores
                        </TableHead>
                        <TableHead className="d-none d-xl-table-cell" />
                        <TableHead className="d-xl-none" />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {listData.map((data, index) => (
                        <>
                          <TableRow key={index}>
                            <TableData>
                              <DataCadastro data={data} />
                            </TableData>
                            <TableData>
                              <Cliente data={data} />
                            </TableData>
                            <TableData>
                              <Indication data={data} />
                            </TableData>
                            <TableData>
                              <p>
                                Status:{' '}
                                {data.situation ? (
                                  <strong>{data.situation}</strong>
                                ) : (
                                  'AGUARDANDO ATUALIZAÇÃO'
                                )}
                              </p>
                            </TableData>
                            <TableData className="d-none d-lg-table-cell">
                              <Valores data={data} />
                              <Subtitle>
                                COMISSIONAMENTO:{' '}
                                <strong>
                                  R$ {data.valueComissionSeller ?? 0}
                                </strong>
                              </Subtitle>
                            </TableData>
                            <TableData className="d-none d-xl-table-cell" />
                            <TableData className="d-xl-none">
                              <CaretRight
                                active={tableDetails === index}
                                onClick={() => handleTableDetails(index)}
                              />
                            </TableData>
                          </TableRow>
                          {tableDetails === index && (
                            <TableRow className="d-xl-none">
                              <TableData colSpan={10}>
                                <ContentWrapper className="d-lg-none">
                                  <MobileTitle>Valores</MobileTitle>
                                  <Valores data={data} />
                                  <Subtitle>
                                    COMISSIONAMENTO:{' '}
                                    <strong>
                                      R$ {data.valueComissionSeller ?? 0}
                                    </strong>
                                  </Subtitle>
                                </ContentWrapper>
                                <ContentWrapper />
                              </TableData>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  <PaginationSelector
                    pages={pages}
                    list={handleList}
                    setPage={setPage}
                    page={page}
                  />
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possuem registros no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ReportIndication;
