import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'reactstrap';
import ReportDigitationQueueComponent from 'views/Digitation/Queue/ReportDigitationQueueComponent';

const PersonalCreditDigitationQueueView = () => {
  const history = useHistory();

  return (
    <>
      <ReportDigitationQueueComponent
        serviceId={6}
        buttons={
          <Button
            onClick={() =>
              history.push(`/admin/personal-credit/proposal/crefisa`)
            }
            color="success"
          >
            <i className="fas fa-plus" /> Nova Proposta Crefisa
          </Button>
        }
      />
    </>
  );
};

export default PersonalCreditDigitationQueueView;
