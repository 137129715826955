import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
  Button,
  Alert,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardFooter,
  InputGroup,
  Badge,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import RegisterAccessBank from './component/register';
import Swal from 'sweetalert2';
import {
  listIndication,
  removeIndication,
} from 'services/Promoter/IndicationService';
import DetailsIndication from './component/details';
import { generateCsv } from 'services/Promoter/IndicationService';
import { useHistory } from 'react-router-dom';
import { Title } from 'views/Dashboard/styles';
import PaginationSelector from 'views/ReportIndication/component/PaginationSelector';
import IndicationGroupComponent from './component/IndicationGroupComponent';

const Indication = () => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));
  const [listData, setListData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalIndication, setModalIndication] = useState(false);
  const [dataIndication, setDataIndication] = useState({});
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    name: null,
    uuid: null,
    status: 1,
    typeDate: 'createdAt',
  });
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const [modalIndicationGroup, setModalIndicationGroup] = useState(false);

  const list = async (pageSelected = 0) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (typeof pageSelected == 'number') {
      setPage(pageSelected);
    } else {
      pageSelected = 0;
    }

    await listIndication({
      ...filter,
      page: pageSelected,
    })
      .then(({ data }) => {
        Swal.close();
        setListData(data?.resultSet?.data ?? []);
        setPages(data?.resultSet?.pagination?.pages ?? 0);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const openModal = async () => {
    await setModal(!modal);
  };

  const openIndication = async (data) => {
    await setDataIndication(data);
    await setModalIndication(!modalIndication);
  };

  const exportExcell = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await generateCsv({ ...filter }).then(async (res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message,
      }).then((result) => {
        if (!result.isConfirmed) return;
        history.push('/admin/report-schedule');
      });
    });
  };

  const remove = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'cancelar',
    }).then(async () => {
      await removeIndication(id).then(() => {
        list();
      });
    });
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <>
      <Header showCards={false} titlePage="INDICAÇÃO - Links de Indicação" />
      <Container className="mt--8" fluid>
        <Card className="mb-5">
          <CardHeader>
            <Title>Links de Indicação</Title>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label>Período - Data Cadastro</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                    <select
                      className="form-control border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          typeDate: ev.target.value,
                        });
                      }}
                    >
                      <option value="createdAt">DATA CADASTRO</option>
                      <option value="inactiveAt">DATA INATIVAÇÃO</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        name: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Código indicação</Label>
                  <Input
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        uuid: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xl="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="1" selected>
                      Ativo
                    </option>
                    <option value="0">Inativo</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={openModal}>
              <i className="fas fa-plus" /> Novo registro
            </Button>
            <Button className="btn btn-success" onClick={list}>
              <i className="fas fa-search" /> Buscar
            </Button>
            {userDataConfig?.canExportExcel > 0 && (
              <Button
                className="btn btn-success btn-md btn-lg-md"
                onClick={exportExcell}
              >
                <i className="far fa-file-excel" /> EXPORTAR EXCEL
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => {
                setModalIndicationGroup(true);
              }}
            >
              <i className="fas fa-users" /> Grupos de indicação
            </Button>
          </CardFooter>
        </Card>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Listar Registros</h3>
                  </div>
                </Row>
              </CardHeader>
              {listData?.length > 0 ? (
                <>
                  <PaginationSelector
                    pages={pages}
                    list={list}
                    setPage={setPage}
                    page={page}
                  />
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">INDICADO</th>
                        <th scope="col">DATA CADASTRO</th>
                        <th scope="col">STATUS</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {listData.map((register, key) => (
                        <>
                          <tr key={key}>
                            <td>
                              <p className="m-1">
                                NOME: <strong>{register.name}</strong>
                              </p>
                              <p className="m-1">
                                CODIGO: <strong>{register.uuid}</strong>
                              </p>
                            </td>
                            <td>{register.createdAt}</td>
                            <td>
                              {register.active == 1 ? (
                                <span className="badge badge-success">
                                  ATIVO
                                </span>
                              ) : (
                                <span className="badge badge-danger">
                                  INATIVO
                                </span>
                              )}
                            </td>
                            <td className="text-right">
                              <button
                                className={
                                  Number(register.active)
                                    ? 'btn btn-danger btn-sm'
                                    : 'btn btn-success btn-sm'
                                }
                                onClick={() => {
                                  remove(register.id);
                                }}
                              >
                                {Number(register.active)
                                  ? 'INATIVAR'
                                  : 'ATIVAR'}
                              </button>

                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                  openIndication(register);
                                }}
                              >
                                Detalhes
                              </button>
                            </td>
                          </tr>
                          {register?.proposals?.qtd > 0 && (
                            <tr>
                              <td colSpan={4} className="bg-secondary">
                                <p className="m-0">
                                  Propostas:{' '}
                                  <span>
                                    <Badge color="info" className="font-12">
                                      {register?.proposals?.qtd}
                                    </Badge>
                                  </span>{' '}
                                  - Data ultima proposta:{' '}
                                  <span>
                                    <Badge color="info" className="font-12">
                                      {register?.proposals?.dateLastSale}
                                    </Badge>
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>

                  <PaginationSelector
                    pages={pages}
                    list={list}
                    setPage={setPage}
                    page={page}
                  />
                </>
              ) : (
                <>
                  <Alert color="secondary" className="text-center">
                    Não possui indicados cadastrados no momento
                  </Alert>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <RegisterAccessBank isModal={modal} state={setModal} list={list} />
      <DetailsIndication
        isModal={modalIndication}
        state={setModalIndication}
        data={dataIndication}
        list={list}
      />
      <IndicationGroupComponent
        isOpen={modalIndicationGroup}
        setIsOpen={setModalIndicationGroup}
      />
    </>
  );
};

export default Indication;
