import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Label,
  Row,
} from 'reactstrap';

const IcredResultSimulationConsignedComponent = ({
  stage = null,
  setStage = () => {},
  resultSimulation = [],
  simulationSelected = () => {},
}) => {
  const handleSelected = (result) => {
    console.log(result);
    simulationSelected(result);
    setStage(3);
  };

  if (stage !== 2) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h2>iCred - Resultado simulação INSS</h2>
        </CardHeader>
        <CardBody>
          <Alert color="secondary">
            <p className="m-0">
              Nome:{' '}
              <span className="font-weight-bold">{resultSimulation.name}</span>
            </p>
            <p className="m-0">
              CPF:{' '}
              <span className="font-weight-bold">{resultSimulation.cpf}</span>
            </p>
          </Alert>
          {resultSimulation?.benefits?.length > 0 && (
            <Card className="shadow mt-4">
              <CardHeader>
                <h3>
                  <i className="far fa-list-alt" /> Selecione a melhor oferta
                </h3>
              </CardHeader>
              <CardBody>
                {resultSimulation?.benefits.map((benefit, key) => (
                  <div key={`benefit-${key}`}>
                    <Alert color="primary" className="mt-4">
                      <p className="m-0 font-18">
                        <i className="fas fa-id-card" /> Matricula:{' '}
                        <strong className="font-weight-bold">
                          {benefit.number}
                        </strong>{' '}
                        - Tipo:{' '}
                        <strong className="font-weight-bold">
                          {benefit.benefitTypeCode}
                        </strong>
                      </p>
                    </Alert>
                    {benefit.simulations.map((simulation, simulationKey) => (
                      <Card
                        className="shadow mb-4"
                        key={`simulation-${simulationKey}`}
                      >
                        <Alert color="secondary">
                          {simulation.salaryIncrease && (
                            <Badge color="success" className="font-12 mr-2">
                              AUMENTO DE MARGEM
                            </Badge>
                          )}
                          {simulation.insurances.length > 0 && (
                            <Badge color="warning" className="font-12 mr-2">
                              COM SEGURO
                            </Badge>
                          )}
                        </Alert>
                        <CardBody>
                          <Row>
                            <Col md="3">
                              <Label>Liberado</Label>
                              <p>R$ {simulation.netAmount.toFixed(2)}</p>
                            </Col>
                            <Col md="3">
                              <Label>Valor da Parcela</Label>
                              {/* <Input type="text" size="sm" /> */}
                              <p>
                                R$ {simulation.installmentsAmount.toFixed(2)}
                              </p>
                            </Col>
                            <Col md="3">
                              <Label>Prazo</Label>
                              <p>{simulation.numberOfInstallments}x</p>
                            </Col>
                            <Col md="3">
                              <Label>Taxa</Label>
                              <p>
                                {(simulation.monthlyInterestRate * 100).toFixed(
                                  2
                                )}
                                %
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <Button
                            color="success"
                            onClick={() => {
                              handleSelected(simulation);
                            }}
                          >
                            <i className="fas fa-check" /> SELECIONAR OFERTA
                          </Button>
                          {/* <Button color="secondary">
                            <i className="fas fa-sync-alt" /> RECALCULAR
                          </Button> */}
                        </CardFooter>
                      </Card>
                    ))}
                  </div>
                ))}
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default IcredResultSimulationConsignedComponent;
