import { useEffect } from 'react';
import { useState } from 'react';
import { maskCPF } from 'utils/mask';

const {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
  CardFooter,
  Button,
} = require('reactstrap');

const UserDigitationIcredConfigComponent = ({
  bank,
  handleSubmit = () => {},
}) => {
  const [userDigitation, setUserDigitation] = useState({
    idBankUser: null,
    bankId: null,
  });

  useEffect(() => {
    setUserDigitation({
      idBankUser: bank.userDigitation.idBankUser,
      bankId: bank.id,
    });
  }, []);

  if (bank.febraban !== '329') return <></>;

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <FormGroup>
            <Label>CPF Usuário digitador (sellercode)</Label>
            <Input
              type="text"
              placeholder="000.000.000-00"
              value={userDigitation.idBankUser ?? ''}
              onChange={(ev) => {
                setUserDigitation({
                  ...userDigitation,
                  idBankUser: maskCPF(ev.target.value),
                });
              }}
            />
          </FormGroup>
          <Alert color="warning">
            <h5>
              <i className="fas fa-exclamation-triangle" /> Atenção
            </h5>
            <p>
              - Ao realizar a configuração, toda operação de crédito estará
              vinculada ao usuário digitador cadastrado.
            </p>
            <p>
              - O usuário digitador deve estar vinculado ao usuário configurado
              no menu <strong>Integração bancos</strong>.
            </p>
          </Alert>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={() => {
              handleSubmit(userDigitation);
            }}
          >
            SALVAR
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default UserDigitationIcredConfigComponent;
