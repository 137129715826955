import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const SampleModalView = ({
  children,
  isOpen = false,
  setIsOpen,
  icon = '',
  labelModal = 'Modal',
  btnSubmitLabel = '',
  btnSubmitColor = 'success',
  btnSubmitAction,
  showBtnSubmit = true,
  showBtnClose = true,
  size = '',
  ...className
}) => {
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className ?? ``}
      size={size}
    >
      <ModalHeader toggle={toggle}>
        <h2 className="m-0">
          {icon && (
            <>
              <i className={icon} /> {` `}
            </>
          )}
          {labelModal}
        </h2>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {showBtnSubmit && (
          <>
            <Button
              color={btnSubmitColor}
              onClick={() => {
                btnSubmitAction();
              }}
            >
              {btnSubmitLabel}
            </Button>{' '}
          </>
        )}

        {showBtnClose && (
          <Button color="danger" onClick={toggle}>
            Sair
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SampleModalView;
