import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

const FormRegisterBankComponent = ({
  register,
  setRegister,
  isRequired = true,
}) => {
  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>
              Banco {isRequired && <span className="text-danger">*</span>}
            </Label>
            <select
              className="form-control"
              id="bank"
              value={register.bank}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  bank: ev.target.value,
                });
              }}
            >
              <option value="" selected="" disabled="">
                Selecione...
              </option>
              <option value="104">104 - CAIXA ECONOMICA FEDERAL</option>
              <option value="001">001 - BANCO DO BRASIL S.A.</option>
              <option value="237">237 - BANCO BRADESCO S.A.</option>
              <option value="341">341 - BANCO ITAÚ S.A.</option>
              <option value="336">336 - BANCO C6 S.A.</option>
              <option value="033">033 - BANCO SANTANDER BANESPA S.A.</option>
              <option value="151">151 - BANCO NOSSA CAIXA S.A.</option>
              <option value="745">745 - BANCO CITIBANK S.A.</option>
              <option value="041">
                041 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
              </option>
              <option value="756">756 - BANCO SICOOB</option>
              <option value="748">748 - BANCO SICREDI</option>
              <option value="085">
                085 - COOPERATIVA CENTRAL DE CRÉDITO - AILOS
              </option>
              <option value="399">399 - HSBC BANK BRASIL S.A.</option>
              <option value="756">
                756 - BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
              </option>
              <option value="041">041 - BANRISUL</option>
              <option value="212">212 - BANCO ORIGINAL</option>
              <option value="260">260 - NU BANK PAGAMENTOS S.A.</option>
              <option value="077">077 - BANCO INTER S.A.</option>
              <option value="389">389 - BANCO MERCANTIL DO BRASIL S.A</option>
              <option value="623">623 - BANCO PAN S.A.</option>
            </select>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Agência {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.agency}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  agency: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Nº da conta {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.numberAccount}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  numberAccount: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Dígito da conta{' '}
              {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              value={register.digitAccount}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  digitAccount: ev.target.value,
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label>
              Tipo de Conta{' '}
              {isRequired && <span className="text-danger">*</span>}
            </Label>
            <select
              className="form-control"
              id="typeAccount"
              value={register.typeAccount}
              onChange={(ev) => {
                setRegister({
                  ...register,
                  typeAccount: ev.target.value,
                });
              }}
            >
              <option value="" selected="" disabled="">
                Selecione...
              </option>
              <option value="0">Conta Corrente</option>
              <option value="1">Conta Poupança</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormRegisterBankComponent;
