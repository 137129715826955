import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Label,
  Row
} from 'reactstrap';
import { UploadPersonalCreditCrefazService } from 'services/Digitation/PersonalCreditService';
import Swal from 'sweetalert2';
import getBase64 from 'utils/input-base64';

const FormUploadCrefazComponent = ({
  form,
  setForm = () => {},
  stage,
  setStage = () => {}
}) => {
  const handleSubmit = () => {
    Swal.fire({
      title: 'Enviando documentos',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    UploadPersonalCreditCrefazService(form.simulationId, form)
      .then(({ data }) => {
        setStage(data?.resultSet?.stage ?? 7);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`
        });
      });
  };

  if (Number(stage) !== 7) {
    return <></>;
  }
  return (
    <>
      <Card className="shadow mt-4">
        <CardHeader>
          <h2>
            <i className="fas fa-file-upload" /> Anexar documentos
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <Label>
                Foto do documento de identificação (Frente){' '}
                <span className="text-danger">*</span>
              </Label>
              <Input
                className="form-control"
                type="file"
                onChange={(ev) => {
                  const file = ev.target.files[0] || null;
                  getBase64(file).then((result) => {
                    setForm({
                      ...form,
                      documentIdentification: result.base64
                    });
                  });
                }}
              />
            </Col>
            <Col md="4">
              <Label>
                Foto do documento de identificação (Verso){' '}
                <span className="text-danger">*</span>
              </Label>
              <Input
                className="form-control"
                type="file"
                onChange={(ev) => {
                  const file = ev.target.files[0] || null;
                  getBase64(file).then((result) => {
                    setForm({
                      ...form,
                      documentIdentificationAditional: result.base64
                    });
                  });
                }}
              />
            </Col>
            <Col md="4">
              <Label>
                Foto da fatura de energia <span className="text-danger">*</span>
              </Label>
              <Input
                className="form-control"
                type="file"
                onChange={(ev) => {
                  const file = ev.target.files[0] || null;
                  getBase64(file).then((result) => {
                    setForm({
                      ...form,
                      invoiceEnergy: result.base64
                    });
                  });
                }}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={handleSubmit}>
            Enviar documentos e finalizar
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default FormUploadCrefazComponent;
