import api from 'services/api';
import Swal from 'sweetalert2';

export const listReport = async () => {
  return api.get(`v1/report/csv`);
};

export const scheduleExcel = async (reportId, filter, history) => {
  Swal.fire({
    title: 'Carregando',
    message: 'Por favor, aguarde.',
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  await api
    .post(`v2/report/generate-csv/${reportId}`, filter)
    .then(async (res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message
      })
        .then((result) => {
          if (!result.isConfirmed) return;
          history.push('/admin/report-schedule');
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`
          });
        });
    })
    .catch((err) => {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`
      });
    });
};
