import api from "./api";

export const AuthService = async (data) => {
    return await api.post('v1/token', data)
}

export const resetPassword = async (data) => {
    return await api.put('v1/reset-password', data)
}

export const updatePassword = async (data) => {
    return await api.put('v1/update-password', data)
}

