import { useEffect } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
const ConditionSimulationFGTSDefault = ({
  bankId,
  installmentSelected,
  setInstallmentSelected,
  setTableSelected,
}) => {
  useEffect(() => {
    if (![21].includes(bankId)) return;
  }, []);

  if (![21].includes(bankId)) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>Parcela(s)</Label>
            <select
              className="form-control"
              value={installmentSelected}
              onChange={(ev) => {
                setTableSelected(1);
                setInstallmentSelected(ev.target.value);
              }}
            >
              <option value={1}>1 - Parcela</option>
              <option value={2}>2 - Parcelas</option>
              <option value={3}>3 - Parcelas</option>
              <option value={4}>4 - Parcelas</option>
              <option value={5}>5 - Parcelas</option>
              <option value={6}>6 - Parcelas</option>
              <option value={7}>7 - Parcelas</option>
              <option value={8}>8 - Parcelas</option>
              <option value={9}>9 - Parcelas</option>
              <option value={10}>10 - Parcelas</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ConditionSimulationFGTSDefault;
