import styled, { css } from 'styled-components';
import { device } from 'layouts/breakpoints';
import { CaretDown } from 'phosphor-react';

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;

  height: 72px;

  @media ${device.desktopLG} {
    display: none;
  }
`;

export const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 20px;
  width: 100%;
`;

export const Logo = styled.img`
  width: 115px;

  @media ${device.desktopLG} {
    width: 100%;
    max-width: 160px;
  }
`;

export const Navbar = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;

  width: 260px;
  padding: 16px 0 !important;
  background: #ffffff;
  transition: width 0.2s ease;

  ${({ mobile }) =>
    mobile &&
    css`
      display: block;
    `}

  @media ${device.desktopLG} {
    display: block;

    ${({ collapsed }) =>
      collapsed &&
      css`
        width: 62px !important;

        ${Logo} {
          display: none;
        }
      `}
  }
`;

export const NavbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;
  margin-bottom: 40px;

  svg {
    display: none;
  }

  @media ${device.desktopLG} {
    ${({ navbar }) =>
      navbar &&
      css`
        justify-content: center;
      `}
    svg {
      display: block;
    }
  }
`;

export const Nav = styled.nav`
  a,
  i {
    color: inherit !important;
  }
  .selected {
    > div {
      background: #f6f9fc;
    }
    p {
      color: rgba(0, 0, 0, 0.9);
    }
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: relative;

  position: relative;
  color: inherit;
  padding: 12px 16px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 8px;

  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    transition: color 0.2s ease;
  }

  svg {
    ${({ navbar }) =>
      navbar &&
      css`
        display: none;
      `}
  }

  i {
    text-align: center;
    width: 20px;
  }

  &:hover {
    p {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  @media ${device.desktopLG} {
    ${({ navbar }) =>
      navbar &&
      css`
        justify-content: center;
        padding: 14px 16px;
        p {
          display: none;
        }

        &:hover {
          background: #f6f9fc;
          &::after {
            ${({ name }) =>
              name &&
              css`
                content: ${`'${name}'`};
              `}
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 170px;
            position: fixed;
            background: #f6f9fc;
            z-index: 5;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.9);
            padding: 12px 14px;
            left: 50px;
            border-radius: 0 8px 8px 0;
          }
        }
      `}
  }
`;

export const MobileBackground = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;

  background: rgba(0, 0, 0, 0.5);

  ${({ navbar }) =>
    navbar &&
    css`
      display: block;
    `}

  @media ${device.desktopLG} {
    display: none;
  }
`;

export const Divisor = styled.hr`
  height: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 12px 24px;
`;

export const Caret = styled(CaretDown).attrs({
  size: 20
})`
  transition: transform 0.3s ease;
  ${({ active }) =>
    active &&
    css`
      transform: rotate(-180deg);
    `}
`;
