import Header from 'components/Headers/Header';
import { useState } from 'react';
import { Container } from 'reactstrap';
import Stage1View from './components/Digitation/Stage1View';

const DigitationFgtsManualView = () => {
  const [stage, setStage] = useState(1);

  const optionsSelectFebraban = [
    { value: '074', label: 'SAFRA' },
    { value: '626', label: 'C6 CONSIGNADO' },
    { value: '149', label: 'FACTA FINANCEIRA' },
    { value: '623', label: 'BANCO PAN' },
    { value: '389', label: 'MERCANTIL' },
    { value: '329', label: 'ICRED' },
    { value: '318', label: 'BMG' },
    { value: '465', label: 'QUERO+CREDITO' }
  ];

  const [digitation, setDigitation] = useState({
    cpf: null,
    name: null,
    birthDate: null,
    phone: null,
    febraban: optionsSelectFebraban,
    config: []
  });

  const handleSimulation = (config) => {
    console.log(config);
    setDigitation({
      ...digitation,
      config: config
    });
    setStage(2);
  };

  return (
    <>
      <Header titlePage="FGTS - Realizar digitação" />
      <Container className="mt--9" fluid>
        <Stage1View
          digitation={digitation}
          setDigitation={setDigitation}
          optionsSelectFebraban={optionsSelectFebraban}
          handleSimulation={handleSimulation}
          stage={stage}
        />
      </Container>
    </>
  );
};

export default DigitationFgtsManualView;
