import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Form,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';
import { getInvoice } from 'services/Finance/Payment/PaymentService';
import Swal from 'sweetalert2';

const ModalStep4 = (props) => {
  const { className, isModal, state, handleSteps } = props;

  const [modal, setModal] = useState(false);
  const [resumePayment, setResumePayment] = useState({});

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const getResultInvoice = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    getInvoice().then((res) => {
      setResumePayment(res.data.resultSet);
      Swal.close();
    });
  };

  const handleSubmit = async () => {
    closeModal();
    handleSteps(null);
  };

  const handleDownload = () => {
    window.open(resumePayment.invoiceUrl, '_blank').focus();
  };

  useEffect(() => {
    setModal(isModal);
    getResultInvoice();
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>Informações sobre o pagamento</ModalHeader>
        <ModalBody>
          <Alert color="warning text-white">
            <h1 className="text-white">Atenção</h1>
            <h3 className="text-white">
              Assim que seu pagamento for aprovado, terá acesso a 30 dias do
              serviço, <br />
              Utilize o PIX para que a liberação seja imediata!
            </h3>
          </Alert>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Form>
                <div>
                  <Row>
                    <Col lg="12">
                      <embed
                        src={resumePayment.invoiceUrl}
                        type="application/pdf"
                        width="100%"
                        height="400"
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button onClick={handleDownload} color="info">
            SALVAR BOLETO
          </Button>
          <Button onClick={handleSubmit} color="success">
            FINALIZAR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalStep4;
