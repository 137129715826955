import SampleModalView from 'components/Modals/SampleModalView';
import MarginResultComponent from './MarginResultComponent';

const ModalMarginComponent = ({
  openMarginModal,
  result = {},
  setIsOpen = () => { },
  setMarginSelected = () => { },
}) => {
  return (
    <>
      <SampleModalView
        labelModal="Consulta de Margem"
        icon="fa fa-search"
        isOpen={openMarginModal}
        setIsOpen={setIsOpen}
        showBtnSubmit={false}
        size="xl"
      >
        <MarginResultComponent
          result={result}
          setIsOpen={setIsOpen}
          setMarginSelected={setMarginSelected}
          renderSelectButton={true}
        />
      </SampleModalView>
    </>
  );
};

export default ModalMarginComponent;
