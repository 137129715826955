import api from 'services/api';

export const insertLevelAccessService = async (register) => {
  return await api.post('v2/user/level-access', register);
};

export const listLevelAccessService = async (filter) => {
  return await api.get('v2/user/level-access', {
    params: filter
  });
};

export const getLevelAccessService = async (id) => {
  return await api.get(`v2/user/level-access/${id}`);
};

export const listLevelAccessModulesService = async (filter = {}) => {
  return await api.get(`v2/user/level-access/modules`, { params: filter });
};

export const deleteLevelAccessService = async (id) => {
  return await api.delete(`v2/user/level-access/${id}`);
};
