import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
   Modal,
   ModalHeader,
   ModalBody,
   ModalFooter,
   Button,
   Row,
   Col,
   Card,
   CardBody
} from 'reactstrap';
import { listAllDocuments } from 'services/Digitation/DigitationService';
import Swal from 'sweetalert2';

const ModalClientDocumentComponent = ({
   showModalDocuments,
   closeModal,
   clientId
}) => {
   const [listDocuments, setListDocuments] = useState([]);
   const [selectedDocument, setSelectedDocument] = useState(null);

   const handleDocumentClick = (document) => {
      setSelectedDocument(document);
   };

   const handleListDocuments = async (clientId) => {
      setListDocuments([]);
      Swal.fire({
         title: 'Carregando listagem',
         text: 'Por favor, aguarde.',
         allowOutsideClick: false,
         allowEscapeKey: false,
         didOpen: () => {
            Swal.showLoading();
         },
      });

      listAllDocuments(clientId)
         .then(({ data }) => {
            Swal.close();
            const uniqueDocuments = data.resultSet.filter((doc, index, self) =>
               index === self.findIndex((d) => d.type === doc.type)
            );
            setListDocuments(uniqueDocuments);
            if (uniqueDocuments.length > 0) {
               setSelectedDocument(uniqueDocuments[0]);
            }
         })
         .catch((err) => {
            const code = !err.response ? '00000' : err.response.data.code;
            const message = !err.response
               ? 'Ocorreu um erro inesperado'
               : err.response.data.message;
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `[${code}] ${message}`,
            });
         });
   };

   useEffect(() => {
      setSelectedDocument(null);
      setListDocuments([]);

      if (!showModalDocuments || clientId <= 0) return;
      handleListDocuments(clientId);
   }, [clientId])

   return (
      <Modal
         isOpen={showModalDocuments}
         toggle={closeModal}
         backdrop={true}
         size="lg"
         className="modal-dialog modal-dialog-centered"
      >
         <ModalHeader toggle={closeModal}><h2>Documentos do Cliente</h2></ModalHeader>
         <ModalBody>
            {listDocuments.length > 0 ? (
               <>
                  <Row className='d-flex align-items-start justify-content-center'>
                     <Col xl="4" className="d-flex align-items-center flex-column h-100">
                        {listDocuments.length > 0 &&
                           listDocuments.map((register) => (
                              <Button
                                 key={register.id}
                                 color="secondary"
                                 className="mb-3 w-100"
                                 onClick={() => handleDocumentClick(register)}
                                 active={selectedDocument?.clientId === register.clientId && selectedDocument?.type === register.type}
                              >
                                 {register.typeTranslate}
                              </Button>
                           ))}
                     </Col>
                     <Col xl="8" className='d-flex align-items-center justify-content-center h-100'>
                        {selectedDocument ? (
                           <Card className='w-100 d-flex align-items-center justify-content-center h-100 shadow'>
                              <CardBody>
                                 <img
                                    src={`${selectedDocument.urlDocument}?t=${new Date().getTime()}`}
                                    alt={selectedDocument.typeTranslate}
                                    className="img-fluid"
                                 />
                              </CardBody>
                           </Card>
                        ) : (
                           <p>Selecione um documento para visualizar a imagem.</p>
                        )}
                     </Col>
                  </Row>
               </>
            ) : <>
               <p>Cliente não possui imagens anexadas</p>
            </>}


         </ModalBody>
         <ModalFooter>
            <Button color="secondary" onClick={closeModal}>
               FECHAR
            </Button>
         </ModalFooter>
      </Modal>
   );
};
export default ModalClientDocumentComponent;