import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import {
  updateSimple,
  getStyles,
  getPromoter,
  updateStyles,
} from 'services/Promoter/PromoterService';

import { RemoveCharEspecial } from 'utils/sanitaze';

import { AccountForm } from './component/AccountForm';
import { StyleForm } from './component/StyleForm';
import {
  Header,
  Hello,
  Card,
  CardHeader,
  HeaderTitle,
  LinkToSimulation,
  CardBody,
  BodyTitle,
  CardFooter,
  Button,
} from './styles';
import { phoneMask } from 'utils/mask';
import { Alert, Badge, Col, Input, Label, Row } from 'reactstrap';

const Profile = () => {
  const [dataPromoter, setDataPromoter] = useState({});
  const [contactEmail, setContactEmail] = useState(null);
  const [contactMovelPhone, setContactMovelPhone] = useState(null);
  const [notificationPhone, setNotificationPhone] = useState(null);
  const [preferenceBank, setPreferenceBank] = useState(null);
  const [urlSimulator, setUrlSimulator] = useState('');
  const [nameSlug, setNameSlug] = useState('');
  const [imagePeople, setImagePeople] = useState('');

  const [logo, setLogo] = useState({
    name: '',
    base64: '',
    url: '',
  });
  const [bgColorPrimary, setBgColorPrimary] = useState('');
  const [bgColorSecondary, setBgColorSecondary] = useState('');
  const [colorPrimary, setColorPrimary] = useState('');
  const [tagManager, setTagManager] = useState('');

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve({
          base64: baseURL,
          file: file,
        });
      };
    });
  };

  const styles = async () => {
    await getStyles().then((res) => {
      const result = res.data;
      setBgColorPrimary(result.resultSet.bgColorPrimary);
      setBgColorSecondary(result.resultSet.bgColorSecondary);
      setColorPrimary(result.resultSet.colorPrimary);
      setTagManager(result.resultSet.tagManager);
      setLogo({
        name: '',
        base64: '',
        url: result.resultSet.logo,
      });
    });
  };

  const promoter = async () => {
    await getPromoter().then((res) => {
      setDataPromoter(res.data.resultSet);
      setContactEmail(res.data.resultSet.contactEmail);
      setPreferenceBank(res.data.resultSet.preferenceBank);
      setNameSlug(res.data.resultSet.nameSlug);
      setUrlSimulator(res.data.resultSet.url);
      setContactMovelPhone(phoneMask(res.data.resultSet.contactMovelPhone));
      setNotificationPhone(phoneMask(res.data.resultSet.notificationPhone));
    });
  };

  const handleChange = async (event) => {
    if (event.target.name === 'contactEmail') {
      setContactEmail(event.target.value);
    }
    if (event.target.name === 'contactMovelPhone') {
      setContactMovelPhone(phoneMask(event.target.value));
    }
    if (event.target.name === 'notificationPhone') {
      setNotificationPhone(phoneMask(event.target.value));
    }
    if (event.target.name === 'preferenceBank') {
      setPreferenceBank(event.target.value);
    }
    if (event.target.name === 'nameSlug') {
      setNameSlug(
        RemoveCharEspecial(event.target.value.replace(' ', '-').toLowerCase())
      );
    }
    if (event.target.name === 'tagManager') {
      setTagManager(event.target.value);
    }
  };

  const handleSubmit = async () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta alteração?',
      showCancelButton: true,
      cancelButtonText: 'NÃO, SAIR',
      confirmButtonText: 'SIM, SALVAR',
    }).then((res) => {
      if (!res.isConfirmed) return;

      Swal.fire({
        title: 'Alterando informações',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      updateSimple({
        contactEmail,
        contactMovelPhone,
        preferenceBank,
        nameSlug,
        notificationPhone,
      })
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Atualização realizada',
            icon: 'success',
          });
          promoter();
        })
        .catch(() => {
          Swal.fire({
            title: 'Ops, ocorreu um erro',
            text: 'Ops ocorreu um erro',
            icon: 'error',
          });
        });
    });
  };

  const handleChangeStyleSimulator = (event) => {
    if (event.target.name === 'logo' && event.target.value !== '') {
      const logo = event.target.files[0] || null;

      getBase64(logo).then((result) => {
        setLogo({
          name: event.target.files[0].name,
          base64: result.base64,
        });
      });
    }

    if (event.target.name === 'bgColorPrimary') {
      setBgColorPrimary(event.target.value);
    }

    if (event.target.name === 'bgColorSecondary') {
      setBgColorSecondary(event.target.value);
    }

    if (event.target.name === 'colorPrimary') {
      setColorPrimary(event.target.value);
    }

    if (event.target.name === 'img-girl-lp' && event.target.checked === true) {
      setImagePeople(event.target.value);
    }
  };

  const handleSubmitStyleSimulator = async () => {
    await updateStyles({
      logo,
      bgColorPrimary,
      bgColorSecondary,
      colorPrimary,
      tagManager,
      imagePeople,
    })
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Registro alterado com sucesso',
          icon: 'success',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    promoter();
    styles();
  }, []);

  return (
    <>
      <Header className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
        <div className="mask bg-gradient-default opacity-8" />
        <div className="container-fluid d-flex align-items-center">
          <div className="row">
            <div className="col-12">
              <Hello>Olá, {dataPromoter.name}</Hello>
              <p className="text-white mt-0 mb-5">
                Abaixo, você pode visualizar e personalizar seu perfil de forma
                simples e fácil
              </p>
            </div>
          </div>
        </div>
      </Header>
      <div className="container-fluid mt--9">
        <div className="row">
          <div className="col-12">
            <Card>
              <CardHeader>
                <HeaderTitle>Minha Conta - ID: {dataPromoter.uuid}</HeaderTitle>
                <LinkToSimulation
                  href={urlSimulator}
                  target="_blank"
                  className="btn btn-primary"
                >
                  <i className="fas fa-external-link-alt" /> Ver simulador
                </LinkToSimulation>
              </CardHeader>
              <CardBody>
                <BodyTitle>Informações da Conta</BodyTitle>
                <AccountForm
                  dataPromoter={dataPromoter}
                  handleChange={handleChange}
                  contactEmail={contactEmail}
                  contactMovelPhone={contactMovelPhone}
                  preferenceBank={preferenceBank}
                  urlSimulator={urlSimulator}
                  nameSlug={nameSlug}
                  notificationPhone={notificationPhone}
                />
              </CardBody>
              <CardFooter>
                <Button onClick={handleSubmit} className="btn btn-success">
                  Salvar alterações
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <Card>
              <CardHeader>
                <HeaderTitle>Personalizar Simulador</HeaderTitle>
                <LinkToSimulation
                  href={urlSimulator}
                  target="_blank"
                  className="btn btn-primary"
                >
                  <i className="fas fa-external-link-alt" /> Ver simulador
                </LinkToSimulation>
              </CardHeader>
              <CardBody>
                <div className="alert alert-primary">
                  <i className="fas fa-question-circle" /> Personalize seu
                  simulador alterando a logo e as cores.
                </div>
                <StyleForm
                  colorPrimary={colorPrimary}
                  bgColorPrimary={bgColorPrimary}
                  bgColorSecondary={bgColorSecondary}
                  tagManager={tagManager}
                  handleChange={handleChange}
                  handleChangeStyleSimulator={handleChangeStyleSimulator}
                />

                <h3>
                  Imagem Landing Page{' '}
                  <Badge color="info">
                    Opcional caso utilize a landing page izidev
                  </Badge>
                </h3>
                <Row>
                  <Col md="3">
                    <Label>
                      <div className="border rounded bg-white">
                        <img
                          className="w-100"
                          width={300}
                          alt="peoplesmartphone.png"
                          src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone.png"
                        />
                        <Alert color="secondary" className="m-0 text-center">
                          <Input
                            type="radio"
                            name="img-girl-lp"
                            value="peoplesmartphone.png"
                            onChange={handleChangeStyleSimulator}
                          />
                          Selecionar
                        </Alert>
                      </div>
                    </Label>
                  </Col>
                  <Col md="3">
                    <Label>
                      <div className="border rounded bg-white">
                        <img
                          className="w-100"
                          width={300}
                          alt="peoplesmartphone.png"
                          src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone2.png"
                        />
                        <Alert color="secondary" className="m-0 text-center">
                          <Input
                            type="radio"
                            name="img-girl-lp"
                            value="peoplesmartphone2.png"
                            onChange={handleChangeStyleSimulator}
                          />
                          Selecionar
                        </Alert>
                      </div>
                    </Label>
                  </Col>
                  <Col md="3">
                    <Label>
                      <div className="border rounded bg-white">
                        <img
                          className="w-100"
                          width={300}
                          alt="peoplesmartphone.png"
                          src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone3.png"
                        />
                        <Alert color="secondary" className="m-0 text-center">
                          <Input
                            type="radio"
                            name="img-girl-lp"
                            value="peoplesmartphone3.png"
                            onChange={handleChangeStyleSimulator}
                          />
                          Selecionar
                        </Alert>
                      </div>
                    </Label>
                  </Col>
                  <Col md="3">
                    <Label>
                      <div className="border rounded bg-white">
                        <img
                          className="w-100"
                          width={300}
                          alt="peoplesmartphone.png"
                          src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone4.png"
                        />
                        <Alert color="secondary" className="m-0 text-center">
                          <Input
                            type="radio"
                            name="img-girl-lp"
                            value="peoplesmartphone4.png"
                            onChange={handleChangeStyleSimulator}
                          />
                          Selecionar
                        </Alert>
                      </div>
                    </Label>
                  </Col>
                  <Col md="3">
                    <Label>
                      <div className="border rounded bg-white">
                        <img
                          className="w-100"
                          width={300}
                          alt="peoplesmartphone.png"
                          src="https://izidev.temfgts.com.br/v1/image/peoplesmartphone5.png"
                        />
                        <Alert color="secondary" className="m-0 text-center">
                          <Input
                            type="radio"
                            name="img-girl-lp"
                            value="peoplesmartphone5.png"
                            onChange={handleChangeStyleSimulator}
                          />
                          Selecionar
                        </Alert>
                      </div>
                    </Label>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={handleSubmitStyleSimulator}
                  className="btn btn-success"
                >
                  Salvar alterações
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
