import { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import SampleModalView from 'components/Modals/SampleModalView';
import LevelAccessRegisterView from './LevelAccessRegisterView';
import { listLevelAccessService } from 'services/User/LevelAccess';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { deleteLevelAccessService } from 'services/User/LevelAccess';

const LevelAccessListView = ({ isOpen, setIsOpen = () => {} }) => {
  const [filter, setFilter] = useState({
    name: null,
    active: 1,
  });
  const [openRegister, setOpenRegister] = useState(false);
  const [listAccess, setListAccess] = useState([]);
  const [levelAccessId, setLevelAccessId] = useState(null);

  const handleList = () => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listLevelAccessService(filter)
      .then(({ data }) => {
        Swal.close();
        setListAccess(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja remover este registro? Todos os usuários vinculados a ele, perderão acesso ao sistema.',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, eu quero',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Removendo...',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      deleteLevelAccessService(id)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Registro removido com sucesso',
          }).then(() => {
            Swal.fire();
            handleList();
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <SampleModalView
        labelModal="Nível de acesso"
        icon="far fa-window-restore"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        btnSubmitLabel={'Salvar'}
        btnSubmitAction={() => {
          setIsOpen();
        }}
        showBtnSubmit={false}
        size="xl"
      >
        <Card className="shadow mb-4">
          <CardHeader>Filtro</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    value={filter.name}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        name: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    className="form-control"
                    value={filter.active}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        active: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={handleList}>
              Buscar
            </Button>
            <Button
              color="success"
              onClick={() => {
                setOpenRegister(true);
              }}
            >
              Adicionar nível de acesso
            </Button>
          </CardFooter>
        </Card>
        <Card className="shadow">
          <CardHeader>Níveis cadastrados</CardHeader>
          <Table responsive>
            <thead>
              <tr>
                <th>NOME</th>
                <th>CONFIGURAÇÃO</th>
                <th>STATUS</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {listAccess.map((level, key) => (
                <tr key={key}>
                  <td>
                    <p className="font-14 font-weight-bold">{level.name}</p>
                    <p>
                      Data de cadastro: <strong>{level.createdAt}</strong>
                    </p>
                  </td>
                  <td>
                    <p className="m-0">
                      Visualiza Card resumo FGTS:{' '}
                      <Badge
                        color={level.showCardsFgts == 1 ? 'success' : 'danger'}
                      >
                        {level.showCardsFgts == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                    <p className="m-0">
                      Visualiza valor total propostas Card FGTS:{' '}
                      <Badge
                        color={
                          level.showValueCardDigitation == 1
                            ? 'success'
                            : 'danger'
                        }
                      >
                        {level.showValueCardDigitation == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                    <p className="m-0">
                      Config. avançadas digitação FGTS:{' '}
                      <Badge
                        color={
                          level.showAdvancedOptionsFgts == 1
                            ? 'success'
                            : 'danger'
                        }
                      >
                        {level.showAdvancedOptionsFgts == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                    <p className="m-0">
                      Pode exportar relatórios em Excel:{' '}
                      <Badge
                        color={level.canExportExcel == 1 ? 'success' : 'danger'}
                      >
                        {level.canExportExcel == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                    <p className="m-0">
                      É supervisor de venda:{' '}
                      <Badge
                        color={level.isSupervisor == 1 ? 'success' : 'danger'}
                      >
                        {level.isSupervisor == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                    <p className="m-0">
                      Altera status proposta:{' '}
                      <Badge
                        color={
                          level.isChangeStatusProposal == 1
                            ? 'success'
                            : 'danger'
                        }
                      >
                        {level.isChangeStatusProposal == 1 ? 'SIM' : 'NÃO'}
                      </Badge>
                    </p>
                  </td>
                  <td>
                    <Badge
                      pill
                      className="font-14"
                      color={level.active == 1 ? 'success' : 'warning'}
                    >
                      {level.active == 1 ? 'ATIVO' : 'INATIVO'}
                    </Badge>
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle caret color="secondary">
                        Opções
                      </DropdownToggle>
                      <DropdownMenu dark>
                        <DropdownItem
                          onClick={() => {
                            setLevelAccessId(level.id);
                            setOpenRegister(true);
                          }}
                        >
                          <i className="fas fa-pencil-alt" /> Alterar cadastro
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleDelete(level.id);
                          }}
                        >
                          <i className="fas fa-trash" /> Excluir
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </SampleModalView>
      <LevelAccessRegisterView
        isOpen={openRegister}
        setIsOpen={setOpenRegister}
        levelAccessId={levelAccessId}
        setLevelAccessId={setLevelAccessId}
      />
    </>
  );
};

export default LevelAccessListView;
