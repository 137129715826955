import { Alert, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { maskCPF } from 'utils/mask';

const AccessBankCrefazForm = ({ febrabanId, data, setData }) => {
  if (!febrabanId || febrabanId !== '321') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="warning">
        <i className="fas fa-exclamation-triangle" /> Para funcionamento correto
        da API, os dados devem ser disponibilizados pelo CREFAZ, e a API deve
        ser liberada, para o usuário, pela financeira.
      </Alert>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas Crédito Pessoal
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Acompanhamento de Propostas
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                TIPO LOGIN <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                value={data.promoterIntegration}
                onChange={(ev) => {
                  setData({
                    ...data,
                    promoterIntegration: ev.target.value,
                  });
                }}
              >
                <option value="">CREDENCIAIS</option>
                <option value="PROMOBANK">TOKEN</option>
              </select>
            </FormGroup>
          </Col>
          {(!data.promoterIntegration || data.promoterIntegration === '') && (
            <>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Login CrefazON <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="accessBank"
                    name="accessBank"
                    type="text"
                    autoComplete="false"
                    value={data.login}
                    className=""
                    onChange={(ev) => {
                      setData({
                        ...data,
                        login: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Senha CrefazON <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="accessBank-b"
                    name="accessBank-b"
                    type="text"
                    autoComplete="false"
                    value={data.password}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        password: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    APIKey <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="apikey-b"
                    name="apikey-b"
                    type="text"
                    autoComplete="false"
                    value={data.apiKey}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        apiKey: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </>
          )}
          {data.promoterIntegration === 'PROMOBANK' && (
            <>
              <Col md="12">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Login CrefazON <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="accessBank"
                        name="accessBank"
                        type="text"
                        autoComplete="false"
                        value={data.login}
                        className=""
                        onChange={(ev) => {
                          setData({
                            ...data,
                            login: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>
                        Senha CrefazON <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="accessBank-b"
                        name="accessBank-b"
                        type="text"
                        autoComplete="false"
                        value={data.password}
                        onChange={(ev) => {
                          setData({
                            ...data,
                            password: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>
                    TOKEN <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="apikey-b"
                    name="apikey-b"
                    type="text"
                    autoComplete="false"
                    value={data.tokenAuth}
                    onChange={(ev) => {
                      setData({
                        ...data,
                        tokenAuth: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <p className="m-0">
                  <strong>Obs:</strong> O Token de autenticação Crefaz, tem
                  validade de 7 dias.
                </p>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value),
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                Nome <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.nameUser}
                placeholder="Ex: João"
                onChange={(ev) => {
                  setData({
                    ...data,
                    nameUser: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                Telefone <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.aditional}
                placeholder="Ex: (11) 99966-5533"
                onChange={(ev) => {
                  setData({
                    ...data,
                    aditional: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </CardBody>
  );
};

export default AccessBankCrefazForm;
