import Header from 'components/Headers/Header';
import { TableHeader } from 'components/Table';
import { TableHead } from 'components/Table';
import { TableBody } from 'components/Table';
import { TableData } from 'components/Table';
import { TableRow } from 'components/Table';
import { Table } from 'components/Table';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { maskCPF, phoneMask, moneyMask } from 'utils/mask';
import {
  MobileTitle,
  ContentWrapper,
  CaretRight,
} from 'views/Dashboard/styles';
import ModalTagComponent from 'components/ModalTag/ModalTagComponent';
import Swal from 'sweetalert2';
import { showLinkFormalize } from 'services/Digitation/DigitationService';

import SimulationInfo from 'views/Dashboard/component/simulationInfo';
import DigitationFGTS from 'views/Dashboard/component/DigitationFGTS';
import { cancelProposalGrandinoService } from 'services/Digitation/IntegrationService';
import { listIndication } from 'services/Promoter/IndicationService';
import { useEffect } from 'react';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { indexProposalReportGrandino } from 'services/Digitation/ProposalService';
import { indexProposalReporttGrandinoCsv } from 'services/Digitation/ProposalService';
import { useHistory } from 'react-router-dom';
import ResendPaymentComponent from './components/ResendPaymentComponent';

const ProposalGrandinoBankReportFgts = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [modalInfo, setModalInfo] = useState(false);
  const [dataSimule, setDataSimule] = useState({});
  const [modalDigitationFGTS, setModalDigitationFGTS] = useState(false);
  const [indicationList, setIndicationList] = useState([]);
  const [resendPayment, setResendPayment] = useState({
    isOpen: false,
    simulationid: null,
    proposalId: null,
  });

  const handleIndicationList = async () => {
    await listIndication({ limit: 10000, status: 1, simpleHeader: 1 }).then(
      (result) => {
        setIndicationList(result?.data?.resultSet?.data ?? []);
      }
    );
  };

  const [data, setData] = useState({
    data: [],
  });
  const [tableDetails, setTableDetails] = useState(null);

  const [tagDataModal, setTagDataModal] = useState({
    isOpen: false,
    clientId: null,
  });

  const handleSubmit = (pageSelected = 0, newFilter = null) => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!newFilter) {
      newFilter = filter;
    }

    indexProposalReportGrandino({
      ...newFilter,
      page: pageSelected ?? 1,
    })
      .then((res) => {
        Swal.close();
        setData(res.data.resultSet);
        if (res.data.resultSet.data.length > 0) {
          setPages(res.data.resultSet.pagination.result.pages);
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleCsv = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await indexProposalReporttGrandinoCsv({ ...filter, isCsv: true }).then(
      (res) => {
        Swal.fire({
          icon: 'info',
          title: 'Sucesso',
          text: res.data.message,
        }).then((result) => {
          if (!result.isConfirmed) return;
          history.push('/admin/report-schedule');
        });
      }
    );
  };

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const openModalDigitationFGTS = () => {
    setModalDigitationFGTS(!modalDigitationFGTS);
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const cancelProposal = async (numberProposal) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja cancelar?',
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: 'Continuar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Realizando cancelamento...',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      cancelProposalGrandinoService(numberProposal)
        .then(({ data }) => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: data.message,
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleIndicationList();
    handleSubmit();
  }, []);

  return (
    <>
      <Header titlePage="FGTS - PROPOSTAS DIGITADAS" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="m-0">GRANDINOBANK: PROPOSTAS DIGITADAS - FGTS</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Período</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      value={filter.dateStart}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      value={filter.dateEnd}
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />

                    <select
                      className="form-control border pl-2"
                      id="typeDate"
                      name="typeDate"
                      onChange={(ev) => {
                        setFilter({ ...filter, typeDate: ev.target.value });
                      }}
                      onBlur={(ev) => {
                        setFilter({ ...filter, typeDate: ev.target.value });
                      }}
                    >
                      <option value="createdAt">DATA DE CADASTRO</option>
                      <option value="updatedAt">DATA DE ATUALIZAÇÃO</option>
                    </select>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>CPF do Cliente</Label>
                  <Input
                    id="cpf"
                    name="cpf"
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        cpf: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Nº Contrato</Label>
                  <Input
                    id="numberProposal"
                    name="numberProposal"
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        numberProposal: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    id="statusProposal"
                    name="statusProposal"
                    className="form-control"
                    value={filter.status}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        status: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    <option value="2">Pago</option>
                    <option value="3">Em Análise</option>
                    <option value="1">Em Andamento</option>
                    <option value="4">Com Pendência</option>
                    <option value="5">Reprovado</option>
                    <option value="6">Cancelado</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Vendedor</Label>
                  <select
                    id="indicationId"
                    name="indicationId"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        indicationId: ev.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione...</option>
                    {indicationList.length
                      ? indicationList.map((register, key) => (
                          <option key={key} value={register.id}>
                            {register.name}
                          </option>
                        ))
                      : ''}
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Estado</Label>
                  <select
                    id="state"
                    name="state"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        state: ev.target.value,
                      });
                    }}
                  >
                    <option value="" disabled="">
                      Selecione...
                    </option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Cidade</Label>
                  <Input
                    id="city"
                    name="city"
                    type="text"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        city: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6">
                <Button
                  color="success"
                  onClick={() => {
                    handleSubmit(0);
                  }}
                >
                  Buscar
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    handleCsv();
                  }}
                >
                  EXCEL
                </Button>
                <Button
                  color="primary"
                  className="btn btn-md btn-lg-md"
                  onClick={() => {
                    setDataSimule({});
                    openModalDigitationFGTS();
                  }}
                >
                  <i className="far fa-file-alt" /> DIGITAR FGTS
                </Button>
              </Col>
              {pages.length > 0 ? (
                <PaginationSelector
                  pages={pages}
                  list={handleSubmit}
                  setPage={setPage}
                  page={page}
                />
              ) : (
                <></>
              )}
            </Row>
          </CardFooter>
        </Card>
        {data.data.length > 0 ? (
          <>
            <Card className="mt-3">
              <CardHeader>Registros</CardHeader>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Data Cadastro</TableHead>
                    <TableHead>Cliente</TableHead>
                    <TableHead className="d-none d-lg-table-cell">
                      Contrato
                    </TableHead>
                    <TableHead className="d-none d-lg-table-cell">
                      Status
                    </TableHead>
                    <TableHead className="d-none d-lg-table-cell">
                      Opções
                    </TableHead>
                    <TableHead className="d-xl-none" />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.data.map((register, key) => (
                    <>
                      <TableRow style={{ borderBottom: 0 }}>
                        {register?.tags?.length > 0 && (
                          <TableData colSpan={5}>
                            <div className="bg-secondary rounded p-2">
                              {register.tags.map((tag, tagKey) => (
                                <Badge
                                  key={tagKey}
                                  className={`${tag.color} m-1 font-12 shadow`}
                                >
                                  {tag.title}
                                </Badge>
                              ))}
                            </div>
                          </TableData>
                        )}
                      </TableRow>
                      {register.messageReturnService !== null && (
                        <TableRow style={{ borderBottom: 0 }}>
                          <TableData colSpan={5}>
                            <div className="bg-secondary text-danger font-weight-bold rounded p-2">
                              {register.messageReturnService}
                            </div>
                          </TableData>
                        </TableRow>
                      )}
                      <TableRow style={{ borderTop: 0 }} key={key}>
                        <TableData>{register.createdAt}</TableData>
                        <TableData>
                          <p className="m-0">
                            Nome:{' '}
                            <strong className="font-weight-bold">
                              {register.name}
                            </strong>
                          </p>
                          <p className="m-0">
                            CPF:{' '}
                            <strong className="font-weight-bold">
                              {maskCPF(register.cpf)}
                            </strong>
                          </p>
                          <p className="m-0">
                            Data Nascimento:{' '}
                            <strong className="font-weight-bold">
                              {register.birthDate}
                            </strong>
                          </p>
                          <p className="m-0">
                            <p className="text-primary font-weight-bold">
                              {phoneMask(register.whatsappNumber)} {` `}
                              <a
                                className="btn btn-success btn-sm"
                                href={`https://wa.me/55${register.whatsappNumber}`}
                                target="_blank"
                              >
                                <i className="fab fa-whatsapp" />
                              </a>
                            </p>
                          </p>
                        </TableData>
                        <TableData className="d-none d-xl-table-cell">
                          <p className="m-0">
                            Banco:{' '}
                            <strong className="font-weight-bold">
                              {register.febrabanId} - {register.nameBank}
                            </strong>
                          </p>
                          <p className="m-0">
                            Proposta:{' '}
                            <Badge color="warning">
                              {register.numberProposal}
                            </Badge>
                          </p>
                          <p className="m-0">
                            Vlr. Total FGTS:{' '}
                            <strong className="font-weight-bold">
                              R$ {moneyMask(register.valueFinally)}
                            </strong>
                          </p>
                          <p className="m-0">
                            Vlr. Liberado:{' '}
                            <strong className="font-weight-bold">
                              R$ {moneyMask(register.valueLiberty)}
                            </strong>
                          </p>
                        </TableData>
                        <TableData className="d-none d-xl-table-cell">
                          <p className="m-0">
                            Situação:{' '}
                            <Badge color="info" className="font-12">
                              <strong className="font-weight-bold">
                                {register.situation ?? '---'}
                              </strong>
                            </Badge>
                          </p>
                          <p className="m-0">
                            Atividade:{' '}
                            <Badge color="info" className="font-12">
                              <strong className="font-weight-bold">
                                {register.activity ?? '---'}
                              </strong>
                            </Badge>
                          </p>
                          <p className="m-0">
                            Status Digitação:{' '}
                            <Badge color="secondary">
                              <strong className="font-weight-bold">
                                {register.statusDigitation ?? '---'}
                              </strong>
                            </Badge>
                          </p>
                        </TableData>
                        <TableData className="d-none d-xl-table-cell">
                          <UncontrolledDropdown>
                            <DropdownToggle caret color="secondary">
                              Opções
                            </DropdownToggle>
                            <DropdownMenu dark>
                              <DropdownItem
                                href={`/admin/proposal-details/${
                                  register.proposalId ?? 0
                                }`}
                                target="_blank"
                              >
                                <i className="fas fa-edit" /> Detalhes do
                                Contrato
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  getLinkFormalize(
                                    register.numberProposal,
                                    register.febrabanId
                                  )
                                }
                              >
                                <i className="fas fa-id-card" /> Link de
                                Formalização
                              </DropdownItem>

                              <DropdownItem
                                onClick={() => {
                                  setTagDataModal({
                                    isOpen: true,
                                    clientId: register.clientId,
                                  });
                                }}
                              >
                                <i className="fas fa-tags" /> Etiquetas
                              </DropdownItem>

                              <DropdownItem
                                onClick={() => {
                                  setDataSimule(register);
                                  openModalDigitationFGTS();
                                }}
                              >
                                <i className="fas fa-edit" /> Simular Novamente
                              </DropdownItem>

                              <DropdownItem
                                onClick={() => {
                                  cancelProposal(register.numberProposal);
                                }}
                              >
                                <i className="fas fa-ban" /> Cancelar operação
                              </DropdownItem>

                              <DropdownItem
                                onClick={() => {
                                  setResendPayment({
                                    ...resendPayment,
                                    proposalId: register.proposalId,
                                    simulationid: register.simulationId,
                                    isOpen: true,
                                  });
                                }}
                              >
                                <i className="fas fa-retweet" /> Reapresentar
                                Proposta
                              </DropdownItem>
                              {/* <DropdownItem
                                onClick={() =>
                                  openModalInfo({
                                    ...register,
                                    id: register.simulationId
                                  })
                                }
                              >
                                <i className="fas fa-edit" /> Redigitar Proposta
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </TableData>
                        <TableData className="d-xl-none">
                          <CaretRight
                            active={tableDetails === key}
                            onClick={() => handleTableDetails(key)}
                          />
                        </TableData>
                      </TableRow>
                      {}
                      {tableDetails === key && (
                        <TableRow className="d-xl-none">
                          <TableData colSpan={10}>
                            <ContentWrapper className="d-lg-none">
                              <MobileTitle>Contrato</MobileTitle>
                              <p className="m-0">
                                Banco:{' '}
                                <strong className="font-weight-bold">
                                  {register.febrabanId} - {register.nameBank}
                                </strong>
                              </p>
                              <p className="m-0">
                                Proposta:{' '}
                                <Badge color="warning">
                                  <strong className="font-weight-bold">
                                    {register.numberProposal}
                                  </strong>
                                </Badge>
                              </p>
                              <p className="m-0">
                                Vlr. Total:{' '}
                                <strong className="font-weight-bold">
                                  R$ {moneyMask(register.valueFinally)}
                                </strong>
                              </p>
                              <p className="m-0">
                                Vlr. Liberado:{' '}
                                <strong className="font-weight-bold">
                                  R$ {moneyMask(register.valueLiberty)}
                                </strong>
                              </p>
                            </ContentWrapper>
                            <ContentWrapper>
                              <MobileTitle>Status</MobileTitle>
                              <p className="m-0">
                                Situação:{' '}
                                <Badge color="info">
                                  <strong className="font-weight-bold">
                                    {register.situation ?? '---'}
                                  </strong>
                                </Badge>
                              </p>
                              <p className="m-0">
                                Atividade:{' '}
                                <Badge color="info">
                                  <strong className="font-weight-bold">
                                    {register.activity ?? '---'}
                                  </strong>
                                </Badge>
                              </p>
                              <p className="m-0">
                                Status Digitação:{' '}
                                <Badge color="info">
                                  <strong className="font-weight-bold">
                                    {register.statusDigitation ?? '---'}
                                  </strong>
                                </Badge>
                              </p>
                            </ContentWrapper>
                            <DropdownItem
                              onClick={() => {
                                setResendPayment({
                                  ...resendPayment,
                                  proposalId: register.proposalId,
                                  simulationid: register.simulationId,
                                  isOpen: true,
                                });
                              }}
                            >
                              <i className="fas fa-retweet" /> Reapresentar
                              Proposta
                            </DropdownItem>
                          </TableData>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </>
        ) : (
          <>
            <Alert color="secondary" className="text-center">
              Não possui simulações no momento
            </Alert>
          </>
        )}

        <ModalTagComponent
          tagDataModal={tagDataModal}
          setTagDataModal={setTagDataModal}
          serviceId={2}
        />
      </Container>

      <SimulationInfo isModal={modalInfo} state={setModalInfo} />
      <DigitationFGTS
        isModal={modalDigitationFGTS}
        state={setModalDigitationFGTS}
        dataSimule={dataSimule}
      />
      <ResendPaymentComponent
        isOpen={resendPayment.isOpen}
        setIsOpen={(e) => {
          setResendPayment({
            ...resendPayment,
            isOpen: e,
          });
        }}
        simulationId={resendPayment.simulationid}
        proposalId={resendPayment.proposalId}
      />
    </>
  );
};

export default ProposalGrandinoBankReportFgts;
