import { FormGroup } from 'reactstrap';

const PreferenceBank = (props) => {
  const { preferenceBank, handleChange } = props;

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor="input-last-name">
        Financeira de preferência para simulação
      </label>
      <select
        className="form-control form-control-alternative"
        id="input-last-name"
        placeholder="Banco de preferencia para simulação"
        name="preferenceBank"
        type="text"
        value={preferenceBank}
        onChange={handleChange}
        onBlur={handleChange}
      >
        <option value="626" selected>
          C6 Consignado
        </option>
        <option value="149">FACTA Financeira</option>
        <option value="074">SAFRA</option>
        <option value="623">PAN</option>
        <option value="389">BANCO MERCANTIL</option>
        <option value="329">iCred</option>
        <option value="465">Quero+Crédito</option>
        <option value="318">BMG</option>
        <option value="243">BANCO MASTER</option>
        <option value="329-5">GRANDINOBANK</option>
        <option value="324">LOTUS MAIS</option>
        <option value="274">NOVO SAQUE</option>
        <option value="329-4">V8 DIGITAL</option>
        <option value="329-3">PRATA DIGITAL</option>
        <option value="329-6">VCTEX</option>
        <option value="531">PRESENÇA BANK</option>
        <option value="457">UY3</option>
      </select>
    </FormGroup>
  );
};

export default PreferenceBank;
